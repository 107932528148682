import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { DetailTypeEnum } from 'src/app/core/enums/detail-type.enum';
import { Report } from 'src/app/core/models/reports.model';

import { Equipment } from 'src/app/core/models/equipment.model';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Construction } from 'src/app/core/enums/construction.enum';
import { HeaderService } from 'src/app/core/services/header.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnChanges, OnInit {
  @Input() extended = false;
  @Input() expertOpen = false;
  @Input() mobile = false;
  @Input() comparable = false;
  @Input() isExpert = false;
  @Input() appError = false;
  @Input() reportData: Report = null;
  @Input() columnsOnly = false;
  @Input() isPrintMode = false;
  @Input() reloadDetails = false;
  @Output() validationRequestEmitter = new EventEmitter<Report>();
  @Input() showLoader = false;
  @Input() showButton = true;

  private controlsInError = [false, false, false, false];

  public updateReport: Report;

  public editMode = false;
  private isLoaded = false;
  public detail1: DetailTypeEnum = DetailTypeEnum.constructionYear;
  public detail2: DetailTypeEnum = DetailTypeEnum.garage;
  public detail3: DetailTypeEnum = DetailTypeEnum.livingArea;
  public detail4: DetailTypeEnum = DetailTypeEnum.plotArea;
  public detail5: DetailTypeEnum = DetailTypeEnum.rooms;
  public detail6: DetailTypeEnum = DetailTypeEnum.construction;
  public detail7: DetailTypeEnum = DetailTypeEnum.category;

  constructor(
    public _authService: AuthenticationService,
    private _headerService: HeaderService
  ) {}

  ngOnInit(): void {
    this._headerService.configChangeEmitter.subscribe(value => {
      this.updateReport.config = value;
      this.updateReport.request.config = value;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.expertOpen !== undefined && changes.expertOpen) {
      this.editMode = false;
    }

    if (
      !this.isLoaded ||
      (changes.reloadDetails !== undefined &&
        changes.reloadDetails.currentValue !== undefined &&
        changes.reloadDetails.currentValue)
    ) {
      if (
        (changes.reportData !== undefined &&
          changes.reportData.currentValue !== undefined) ||
        changes.reloadDetails
      ) {
        // first load or a force reload, asses if we need edit mode or not.
        if (this.reportData === undefined) {
          return;
        }

        this.updateReport = JSON.parse(JSON.stringify(this.reportData));

        // this.editMode = (this.updateReport.request.construction_year === undefined &&
        //   this.updateReport.request.plot_area === undefined &&
        //   this.updateReport.request.living_area === undefined &&
        //   this.updateReport.request.equipment === undefined && this.updateReport.request.garages === undefined &&
        //   !this._authService.readOnlyMode);

        this.isLoaded = true;
        this.reloadDetails = false;
      }
    }
  }

  detailsClass() {
    return 'details-container';
  }

  constructionText() {
    if (
      this.updateReport.request.construction === undefined ||
      this.updateReport.request.construction === null ||
      this.updateReport.request === null ||
      this.updateReport.request === undefined ||
      this.updateReport === null ||
      this.updateReport === undefined
    ) {
      return '';
    }
    return Construction[this.updateReport.request.construction];
  }

  categoryClass() {
    switch (this.updateReport.category) {
      case 'ETW': {
        return 'Eigentumswohnung';
      }
      case 'MFH': {
        return 'Eigentumswohnung-block';
      }
      case 'EFH': {
        return 'single-family-house';
      }
      case 'GRD': {
        return 'single-family-house';
      }
    }
    return '';
  }

  getPropType() {
    if (this.updateReport === undefined) {
      return '';
    }
    switch (this.updateReport.category) {
      case 'ETW': {
        return 'Eigentumswohnung';
      }
      case 'MFH': {
        return 'Mehrfamilienhaus';
      }
      case 'EFH': {
        return 'Einfamilienhaus';
      }
      case 'GRD': {
        return 'Grundstück';
      }
    }
    return '';
  }

  getEquipmentValue() {
    let retVal = 0;

    let stringValue = '';
    if (this.updateReport?.request?.equipment?.value) {
      stringValue = this.updateReport.request.equipment.value;
    }

    if (stringValue === 'EINFACH') {
      retVal = 1;
    }
    if (stringValue === 'MITTEL') {
      retVal = 2;
    }
    if (stringValue === 'GEHOBEN') {
      retVal = 3;
    }
    if (stringValue === 'STARK_GEHOBEN') {
      retVal = 4;
    }
    return retVal;
  }

  updateConstructionYear(value: number) {
    this.updateReport.request.construction_year = value;
  }

  formValid(value: DetailTypeEnum) {
    this.controlsInError[value] = false;
  }

  formNotValid(value: DetailTypeEnum) {
    this.controlsInError[value] = true;
  }

  checkIsFormValid() {
    const erroredControls = this.controlsInError.filter(function (element) {
      return element;
    });

    return (
      erroredControls.length === 0 &&
      JSON.stringify(this.reportData) !== JSON.stringify(this.updateReport)
    );
  }

  updatePlotArea(value: number) {
    this.updateReport.request.plot_area = value;
  }

  updateLivingArea(value: number) {
    this.updateReport.request.living_area = value;
  }

  updateGarages(value: boolean) {
    this.updateReport.request.garages = value;
  }

  updateConstruction(value: string) {
    this.updateReport.request.construction = value;
  }

  updateRating(value: string) {
    if (
      this.updateReport.request.equipment === null ||
      this.updateReport.request.equipment === undefined
    ) {
      this.updateReport.request.equipment = new Equipment();
    }
    this.updateReport.request.equipment.value = value;
  }

  requestValidation() {
    this.reportData = this.updateReport;
    this.validationRequestEmitter.emit(this.updateReport);
    this.editMode = false;
  }

  toggleEdit() {
    if (!this.comparable && !this._authService.readOnlyMode) {
      this.editMode = true;
    }
  }
}
