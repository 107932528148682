<div class="flex-grid mainBox">
  <div class="col colLeft">
    <div class="box boxValuation">
      <app-valuation
        [valuationData]="valuationData"
        [reportData]="reportData"
        [extended]="true"
        [expert]="true"
        [saleMode]="this._activeDataService.saleMode"></app-valuation>
    </div>
    <div
      class="box boxRating"
      *ngIf="shouldShowEquip()">
      <app-equipment-rating
        *ngIf="shouldShowEquip()"
        [valuationData]="valuationData"
        [reportData]="reportData"
        [equipmentData]="equipmentData"
        [expanded]="true"
        [expert]="true"
        [saleMode]="this._activeDataService.saleMode"></app-equipment-rating>
    </div>
    <div
      class="box boxTrend"
      *ngIf="shouldShowTrend()">
      <app-trend
        [saleMode]="this._activeDataService.saleMode"
        [expert]="true"
        [extended]="true"
        [valuationData]="valuationData"
        [reportData]="reportData"
        [ipiData]="ipiData"></app-trend>
    </div>
    <div
      class="noTrendBoxSale"
      *ngIf="
        (!shouldShowTrend() || !shouldShowEquip()) &&
        this._activeDataService.saleMode
      "></div>
    <div
      class="noTrendBoxRent"
      *ngIf="
        (!shouldShowTrend() || !shouldShowEquip()) &&
        !this._activeDataService.saleMode
      "></div>
  </div>
  <div class="col colRight">
    <div class="switch-container">
      <div
        class="switch-container-inner singular-switch-text"
        *ngIf="
          !this._activeDataService.reportData?.config?.show_valuation ||
          !this._activeDataService.reportData?.config?.show_rent
        ">
        <span
          class="on-off-toggled"
          *ngIf="_activeDataService.saleMode">
          Marktpreis
        </span>
        <span
          class="on-off-toggled"
          *ngIf="!_activeDataService.saleMode">
          Mietwert
        </span>
      </div>
      <div
        class="switch-container-inner"
        *ngIf="
          this._activeDataService.reportData?.config?.show_valuation &&
          this._activeDataService.reportData?.config?.show_rent
        ">
        <span [ngClass]="{ 'on-off-toggled': _activeDataService.saleMode }">
          Marktpreis
        </span>
        <div class="onoffswitch">
          <input
            type="checkbox"
            name="onoffswitch2"
            class="onoffswitch-checkbox"
            id="myonoffswitch2"
            tabindex="0"
            (change)="toggleMode()"
            [checked]="!this._activeDataService.saleMode" />
          <label
            class="onoffswitch-label"
            for="myonoffswitch2"></label>
        </div>
        <span [ngClass]="{ 'on-off-toggled': !_activeDataService.saleMode }">
          Mietwert
        </span>
      </div>
    </div>

    <div
      class="box boxValuation"
      [ngClass]="
        _activeDataService.saleMode
          ? 'boxValuation-sale'
          : 'boxValuation-rental boxValuation-reset'
      ">
      <div
        class="expert-rental-img"
        *ngIf="!_activeDataService.saleMode">
        <img src="../../../../../assets/expert-rental-illustration.svg" />
      </div>
      <div
        class="chart-title unselectable"
        [ngClass]="
          _activeDataService.saleMode
            ? 'chart-title-sale'
            : 'chart-title-rental'
        "
        *ngIf="regionData !== undefined && _activeDataService.saleMode">
        Kurzfristige Marktpreisentwicklung
      </div>

      <div
        class="chart-container"
        *ngIf="_activeDataService.saleMode && regionData !== undefined">
        <app-line-chart
          [chartSettings]="getRegionalMarketData()"
          [secondaryChartSettings]="getMarketData()"
          [keepAspect]="false"></app-line-chart>
      </div>
    </div>
    <div
      class="box boxValuation"
      [ngClass]="{ 'box-rent-mode': !_activeDataService.saleMode }"
      [ngClass]="
        _activeDataService.saleMode
          ? 'boxValuation-sale'
          : 'boxValuation-rental'
      ">
      <div
        class="chart-title unselectable"
        [ngClass]="
          _activeDataService.saleMode
            ? 'chart-title-sale'
            : 'chart-title-rental'
        "
        *ngIf="!_activeDataService.saleMode">
        Kurzfristige Mietentwicklung
      </div>

      <div
        class="chart-container"
        *ngIf="!_activeDataService.saleMode">
        <app-line-chart
          [chartSettings]="getMarketData()"
          [keepAspect]="false"></app-line-chart>
      </div>

      <div
        class="chart-title unselectable"
        [ngClass]="
          _activeDataService.saleMode
            ? 'chart-title-sale'
            : 'chart-title-rental'
        "
        *ngIf="_activeDataService.saleMode && simData !== undefined">
        Langfristige Marktpreisentwicklung
      </div>

      <div
        class="chart-container"
        *ngIf="_activeDataService.saleMode && simData !== undefined">
        <app-line-chart
          [chartSettings]="getSimData()"
          [keepAspect]="false"></app-line-chart>
      </div>
    </div>
  </div>
</div>
