import { Component, Input } from '@angular/core';
import { Image } from 'src/app/core/models/images.model';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  @Input() images: Image[] = undefined;
  @Input() appError = false;
  @Input() extended = false;
  @Input() expert = false;
  @Input() type = '';
  @Input() isShare = false;
  public activeSlide = 0;

  constructor() {}

  getImageClass() {
    if (this.extended) {
      return 'carousel-image-expanded';
    }
    if (this.expert) {
      return 'carousel-image-expert';
    }
    return 'carousel-image';
  }

  getImageStyle() {
    return {
      'background-image': 'url(' + this.getImageString(0) + ')',
      'background-size': 'cover',
    };
  }

  getImageString(index: number) {
    if (this.type === 'ETW') {
      return {
        'background-image': 'url("../../../assets/ETW-watermark.png',
      };
    }
    if (this.type === 'EFH') {
      return {
        'background-image': 'url("../../../assets/EFH-watermark.png',
      };
    }
    if (this.type === 'MFH') {
      return {
        'background-image': 'url("../../../assets/MFH-watermark.png',
      };
    }

    if (this.images !== undefined) {
      if (this.images[0] === undefined || this.images[0].content === '') {
        return {
          'background-image': 'url("../../../assets/image-place-holder.svg ")',
        };
      }

      return {
        'background-image':
          'url(data:image/' +
          this.images[index].format +
          ';base64,' +
          this.images[index].content +
          ')',
      };
    }
    return {
      'background-image': 'none',
    };
  }
}
