import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { ActiveDataService } from 'src/app/core/services/active-data.service';
import { Image } from '../../../core/models/images.model';
import { Report } from 'src/app/core/models/reports.model';
import { Valuation } from 'src/app/core/models/valuation.model';
import { IpiGetResponse } from 'src/app/core/models/ipi-get-response.model';
import { EquipmentGetResponse } from 'src/app/core/models/equipment-get-response.model';
import { RentResponse } from 'src/app/core/models/rent-response.model';
import { Subscription } from 'rxjs';
import { ReportService } from 'src/app/core/services/report.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { SimIndexResponse } from 'src/app/core/models/sim-index-response.model';
import { ChartGenerator } from 'src/app/core/functions/chartGenerator.function';
import { LineChartData } from 'src/app/core/models/line-chart-data.model';
@Component({
  selector: 'app-expert',
  templateUrl: './expert.component.html',
  styleUrls: ['./expert.component.scss'],
})
export class ExpertComponent implements OnInit {
  @Output() closeExpertEmitter = new EventEmitter();

  public reportImages: Image[] = undefined;
  public imageError = false;

  public reportData: Report = undefined;
  public reportError = false;
  private reportSubscription = new Subscription();

  public valuationData: Valuation = undefined;
  public valuationError = false;
  private valuationSubscription = new Subscription();

  public ipiData: IpiGetResponse = undefined;
  public ipiError = false;
  private ipiSubscription = new Subscription();

  private regionSubscription: Subscription;
  public regionData: IpiGetResponse = undefined;

  private simSubscription: Subscription;
  public simData: SimIndexResponse = undefined;

  public equipmentData: EquipmentGetResponse = undefined;
  public equipmentError = false;
  private equipmentSubscription = new Subscription();

  public currentPanel = 1;
  public showLoader = false;
  public reloadDetails = false;
  public expertResOk = true;
  public tablet = false;

  private showCountry = true;

  showMoreSocio = false;

  public expanded = true;
  public expert = false;

  public showTrend = true;
  public showEquip = true;
  private showComparables = true;

  private showAllTabs = true;

  @Output() saleMode = true;
  @Output() valuationDataOut: any;
  @Output() reportDataOut: any;
  @Output() equipmentDataOut: any;
  @Output() ipiDataOut: any;
  @Output() currentValuationEmitter = new EventEmitter<Valuation>();

  constructor(
    public _activeDataService: ActiveDataService,
    private _reportService: ReportService,
    public _authService: AuthenticationService
  ) {
    this.saleMode = _activeDataService.saleMode;
    this._activeDataService.modeEmitter.subscribe(() => {
      this.loadVariableData();
    });
  }

  ngOnInit() {
    this.loadCoreReport();
    this.loadVariableData();
    this.loadSimIndex();
    this.loadregionData();

    if (window.innerWidth < 1260 || window.innerHeight < 720) {
      this.expertResOk = false;
    } else {
      this.expertResOk = true;
    }
    if (window.innerWidth > 1080 && window.innerWidth <= 1260) {
      this.tablet = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 1260 || window.innerHeight < 720) {
      this.expertResOk = false;
    } else {
      this.expertResOk = true;
    }
    if (window.innerWidth > 1080 && window.innerWidth <= 1260) {
      this.tablet = true;
    }
  }

  closeExpert() {
    if (
      !this.showLoader &&
      this._activeDataService.pendingRequestsInfo() === ''
    ) {
      this.closeExpertEmitter.emit();
    }
  }

  showPanel(panelNumber: any) {
    if (
      !this.showLoader &&
      this._activeDataService.pendingRequestsInfo() === ''
    ) {
      this.currentPanel = panelNumber;
    }
  }

  loadVariableData(reload = false) {
    this.loadValuationData(reload);
    this.loadIpiData(reload);
    this.loadEquipmentData(reload);
  }

  loadCoreReport(reload = false) {
    this.reportSubscription = this._activeDataService.reportEmitter.subscribe(
      (data: Report) => {
        if (data === null) {
          this.reportError = true;
        } else {
          this.reportData = data;
          this.reportDataOut = data;
          this.reloadDetails = true;
        }
        this.reportSubscription.unsubscribe();
      },
      (error: any) => {
        this.reportError = true;
        this.reportSubscription.unsubscribe();
        throw error;
      }
    );
    this._activeDataService.getReportData(reload);
  }

  loadValuationData(reload = false) {
    if (this._activeDataService.saleMode) {
      this.valuationSubscription =
        this._activeDataService.valuationEmitter.subscribe(
          (data: Valuation) => {
            if (data === null) {
              this.valuationError = true;
            } else {
              this.valuationData = data;
              this.valuationDataOut = data;
              this.currentValuationEmitter.emit(data);
            }
            this.valuationSubscription.unsubscribe();
          },
          (error: any) => {
            this.reportError = true;
            this.valuationSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getValuationData(reload);
    } else {
      this._activeDataService.getValuationData();
      this.valuationSubscription =
        this._activeDataService.rentEmitter.subscribe(
          (data: RentResponse) => {
            if (data === null) {
              this.valuationError = true;
            } else {
              this.valuationData = data;
              this.valuationDataOut = data;
              this.currentValuationEmitter.emit(data);
            }
            this.valuationSubscription.unsubscribe();
          },
          (error: any) => {
            this.valuationError = true;
            this.valuationSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getRentData(reload);
    }
  }

  loadIpiData(reload = false) {
    if (this._activeDataService.saleMode) {
      this.ipiSubscription =
        this._activeDataService.ipiValuationEmitter.subscribe(
          (data: IpiGetResponse) => {
            if (data === null) {
              this.ipiError = true;
            } else {
              this.ipiData = data;
              this.ipiDataOut = data;
            }
            this.ipiSubscription.unsubscribe();
          },
          (error: any) => {
            this.ipiError = true;
            this.ipiSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getValuationIpiData(reload);
    } else {
      this.ipiSubscription = this._activeDataService.ipiRentEmitter.subscribe(
        (data: IpiGetResponse) => {
          if (data === null) {
            this.ipiError = true;
          } else {
            this.ipiData = data;
            this.ipiDataOut = data;
          }
          this.ipiSubscription.unsubscribe();
        },
        (error: any) => {
          this.ipiError = true;
          this.ipiSubscription.unsubscribe();
          throw error;
        }
      );
      this._activeDataService.getRentIpiData(reload);
    }
  }

  loadEquipmentData(reload = false) {
    if (this._activeDataService.saleMode) {
      this.equipmentSubscription =
        this._activeDataService.equipmentValuationEmitter.subscribe(
          (data: EquipmentGetResponse) => {
            if (data === null) {
              this.equipmentError = true;
            } else {
              this.equipmentData = data;
              this.equipmentDataOut = data;
            }
            this.equipmentSubscription.unsubscribe();
          },
          (error: any) => {
            this.equipmentError = true;
            this.equipmentSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getValuationEquipment(reload);
    } else {
      this.equipmentSubscription =
        this._activeDataService.equipmentRentEmitter.subscribe(
          (data: EquipmentGetResponse) => {
            if (data === null) {
              this.equipmentError = true;
            } else {
              this.equipmentData = data;
              this.equipmentDataOut = data;
            }
            this.equipmentSubscription.unsubscribe();
          },
          (error: any) => {
            this.equipmentError = true;
            this.equipmentSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getRentEquipment(reload);
    }
  }

  updateValuation(report: Report) {
    this._activeDataService.clearAll();
    this.reloadDetails = false;
    this.showLoader = true;
    this._reportService.put(report.request, report.report_id).subscribe(
      () => {
        this.showLoader = false;
        this.loadCoreReport(true);
        this.loadVariableData(true);
        this._activeDataService.getValuationData();
      },
      (error: any) => {
        this.showLoader = false;
        throw error;
      }
    );
  }

  shouldShowAllTabs() {
    if (
      this._activeDataService.reportData === null ||
      this._activeDataService.reportData === undefined ||
      this._activeDataService.reportData.config === null ||
      this._activeDataService.reportData.config === undefined
    ) {
      return this.showAllTabs;
    }

    this.showAllTabs =
      this._activeDataService.reportData.config.show_compare_prices;
    if (!this.showAllTabs && this.currentPanel === 2) {
      this.currentPanel = 1;
    }
    return this.showAllTabs;
  }

  shouldShowSocio() {
    if (
      this._activeDataService.reportData === null ||
      this._activeDataService.reportData === undefined ||
      this._activeDataService.reportData.config === null ||
      this._activeDataService.reportData.config === undefined
    ) {
      return true;
    }

    if (
      !this._activeDataService.reportData.config.show_sociodemographic &&
      this.currentPanel === 3
    ) {
      this.currentPanel = 1;
    }

    return this._activeDataService.reportData.config.show_sociodemographic;
  }

  // Tablet

  toggleSaleMode() {
    this._activeDataService.saleMode = !this._activeDataService.saleMode;
    this._activeDataService.clearAll();
    this.ipiData = undefined;
    this.regionData = undefined;
    this.loadCoreReport();
    this.loadVariableData();
    this.loadSimIndex();
    if (this._activeDataService.saleMode) {
      this.loadregionData();
    }
  }

  toggleMoreSocio() {
    this.showMoreSocio = !this.showMoreSocio;
  }

  // Graph stuff

  loadSimIndex() {
    this.simSubscription = this._activeDataService.simEmitter.subscribe(
      (data: any) => {
        if (data === null) {
        } else {
          this.simData = data;
        }
        this.simSubscription.unsubscribe();
      },
      (error: any) => {
        this.simSubscription.unsubscribe();
        throw error;
      }
    );
    this._activeDataService.getSimData();
  }

  getMarketData() {
    if (this.showCountry) {
      const chartCalc = new ChartGenerator();
      const returnValue = chartCalc.generateMarketData(
        this.ipiData,
        this.valuationData,
        true
      );
      if (
        !this._activeDataService.saleMode &&
        returnValue !== null &&
        returnValue !== undefined
      ) {
        returnValue.mainColor = '#A2A811';
      }
      return returnValue;
    }
    return new LineChartData();
  }

  toggleExpert() {
    if (!this.showLoader) {
      this.expert = !this.expert;
    }
  }

  getSimData() {
    if (this.simData === undefined) {
      return new LineChartData();
    }

    const chartCalc = new ChartGenerator();
    const returnValue = chartCalc.generateSimData(
      this.simData,
      this.valuationData,
      true
    );
    return returnValue;
  }

  loadregionData(reload = false) {
    if (this._activeDataService.saleMode) {
      this.regionSubscription =
        this._activeDataService.regionValuationEmitter.subscribe(
          (data: IpiGetResponse) => {
            if (data === null) {
            } else {
              this.regionData = data;
            }
            this.regionSubscription.unsubscribe();
          },
          (error: any) => {
            this.regionSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getValuationRegionData(reload);
    }
  }

  shouldShowTrend() {
    if (
      this._activeDataService.reportData === null ||
      this._activeDataService.reportData === undefined ||
      this._activeDataService.reportData.config === null ||
      this._activeDataService.reportData.config === undefined
    ) {
      return this.showTrend;
    }

    this.showTrend = this._activeDataService.reportData.config.show_trend;
    return this.showTrend;
  }

  shouldShowEquip() {
    if (
      this._activeDataService.reportData === null ||
      this._activeDataService.reportData === undefined ||
      this._activeDataService.reportData.config === null ||
      this._activeDataService.reportData.config === undefined
    ) {
      return this.showEquip;
    }

    this.showEquip =
      this._activeDataService.reportData.config.show_equipment_overview;

    return this.showEquip;
  }

  shouldShowComparables() {
    if (
      this._activeDataService.reportData === null ||
      this._activeDataService.reportData === undefined ||
      this._activeDataService.reportData.config === null ||
      this._activeDataService.reportData.config === undefined
    ) {
      return this.showComparables;
    }

    this.showComparables =
      this._activeDataService.reportData.config.show_compare_prices;
    return this.showComparables;
  }

  getRegionalMarketData() {
    const chartCalc = new ChartGenerator();
    const returnValue = chartCalc.generateMarketData(
      this.regionData,
      this.valuationData,
      true
    );
    if (!this._activeDataService.saleMode) {
      returnValue.mainColor = '#A2A811';
    }
    return returnValue;
  }
  toggleExpanded() {
    console.warn('This method was missing...');
  }
}
