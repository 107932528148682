<div
  class="main-content expanded-main-content"
  *ngIf="
    reportData !== undefined &&
    equipmentData !== undefined &&
    valuationData !== undefined
  "
  [ngClass]="{ 'main-content-print': printMode }">
  <div
    [@detailFade]=""
    [ngClass]="{ 'equip-header': !comparable, 'comp-header': comparable }"
    *ngIf="!printMode">
    <div
      [ngClass]="{
        'equip-title-rental': !saleMode,
        'equip-title-sale': saleMode
      }"
      class="detail-text-extra-small equip-title">
      <span
        *ngIf="saleMode"
        [ngClass]="getTypeClass()">
        Ausstattungsstandard
      </span>
      <span
        *ngIf="!saleMode"
        [ngClass]="getTypeClass()">
        Ausstattungsstandard
      </span>
    </div>
  </div>

  <div
    [@detailFade]=""
    class="equip-rating-container"
    [ngClass]="{
      'equip-rating-container-expert': expert,
      'equip-rating-container-print': printMode,
      'equip-rating-empty': this.reportData.request.equipment === undefined
    }">
    <div
      class="overlay-wrapper"
      [ngClass]="{
        'overlay-wrapper-expert': expert,
        'overlay-closed': this.overlayClosed
      }"
      *ngIf="
        (this.reportData.request === undefined ||
          this.reportData.request === null ||
          this.reportData.request.equipment === undefined ||
          this.reportData.request.equipment === null) &&
        !printMode
      ">
      <span class="iconContainer">
        <span
          matTooltip="no Wohnfläche given"
          matTooltipPosition="right"
          matTooltipClass="tooltip"
          class="spreng-icon-info infoIcon"></span>
        <span
          class="spreng-icon-close closeIcon"
          (click)="closeOverlay()"></span>
      </span>
      <div class="overlay-text">
        Bitte füllen Sie den Ausstattungsstandard aus
      </div>
      <div class="overlay-info">
        <span class="spreng-icon-diamond overlay-icon-diamond"></span>
        Ausstattungsstandard
      </div>
    </div>
    <div
      class="simple equip-rating"
      [ngClass]="{
        'select-col': getLevel() === 1,
        'print-rating': printMode,
        'hide-vis':
          (this.reportData.request === undefined ||
            this.reportData.request === null ||
            this.reportData.request.equipment === undefined ||
            this.reportData.request.equipment === null) &&
          !printMode &&
          !overlayClosed
      }">
      <div class="level-block">
        <div class="marker">
          <span
            [ngClass]="{ 'rent-icon-1': !saleMode }"
            class="spreng-icon-star level-icon"
            *ngIf="!printMode"></span>
          <span
            class="spreng-icon-star1 icon8"
            *ngIf="printMode"></span>
        </div>
        <div [ngClass]="getValuationBlockClass(1)"></div>
      </div>
      <div
        class="text-wrapper"
        *ngIf="
          this.reportData.request.equipment !== undefined &&
          exactPriceAlllowedByConfig()
        ">
        <div class="price-text">{{ getTextValue(1) | number : '1.0-0' }} €</div>
        <div
          class="area-text"
          *ngIf="saleMode">
          {{ getTextAreaValue(1) | number : '1.0-0' }} €/m²
        </div>
        <div
          class="area-text"
          *ngIf="!saleMode">
          {{ getTextAreaValue(1) | number : '1.2-2' }} €/m²
        </div>
      </div>
      <div
        class="text-wrapper"
        *ngIf="this.reportData.request.equipment === undefined">
        <div class="price-text">- €</div>
        <div class="area-text">- €/m²</div>
      </div>
      <div
        class="mini-indicator"
        [ngClass]="{
          selected:
            saleMode &&
            (getLevel() === 1 ||
              this.reportData.request === undefined ||
              this.reportData.request.equipment === undefined) &&
            !this.overlayClosed,
          'selected-rent':
            !saleMode &&
            (getLevel() === 1 ||
              this.reportData.request === undefined ||
              this.reportData.request.equipment === undefined),
          'mini-indicator-white': !exactPriceAlllowedByConfig()
        }"></div>
      <div [ngClass]="getValuationTextClass(1)">einfach</div>

      <div
        class="expert-equip"
        [ngClass]="{
          'selected-expert-equip':
            saleMode &&
            (getLevel() === 1 ||
              this.reportData.request === undefined ||
              this.reportData.request.equipment === undefined),
          'selected-rent-expert-equip':
            !saleMode &&
            (getLevel() === 1 ||
              this.reportData.request === undefined ||
              this.reportData.request.equipment === undefined)
        }"
        *ngIf="expert && this.reportData.request.equipment !== undefined">
        <div class="expert-equip-top">
          <div class="text-wrapper">
            <div class="price-text">
              {{ getMinText(1) | number : '1.0-0' }} €
            </div>
            <div
              class="area-text-expert"
              *ngIf="saleMode">
              {{ getAreaTextMin(1) | number : '1.0-0' }} €/m²
            </div>
            <div
              class="area-text-expert"
              *ngIf="!saleMode">
              {{ getAreaTextMin(1) | number : '1.2-2' }} €/m²
            </div>
          </div>
          <div
            class="bottom"
            *ngIf="getLevel() === 1">
            Min Preis
          </div>
        </div>
        <div class="expert-equip-bottom">
          <div class="price-text">{{ getMaxText(1) | number : '1.0-0' }} €</div>
          <div
            class="area-text-expert"
            *ngIf="saleMode">
            {{ getAreaTextMax(1) | number : '1.0-0' }} €/m²
          </div>
          <div
            class="area-text-expert"
            *ngIf="!saleMode">
            {{ getAreaTextMax(1) | number : '1.2-2' }} €/m²
          </div>
          <div
            class="bottom"
            *ngIf="getLevel() === 1">
            Max Preis
          </div>
        </div>
      </div>
    </div>
    <div
      class="middle equip-rating"
      [ngClass]="{
        'select-col': getLevel() === 2,
        'print-rating': printMode,
        'hide-vis':
          (this.reportData.request === undefined ||
            this.reportData.request === null ||
            this.reportData.request.equipment === undefined ||
            this.reportData.request.equipment === null) &&
          !printMode &&
          !overlayClosed
      }">
      <div class="level-block">
        <div class="marker">
          <span
            [ngClass]="{ 'rent-icon-2': !saleMode }"
            class="spreng-icon-stars level-icon"
            *ngIf="!printMode"></span>
          <span
            class="spreng-icon-star1 icon8"
            *ngIf="printMode"></span>
          <span
            class="spreng-icon-star1 icon8"
            *ngIf="printMode"></span>
        </div>
        <div [ngClass]="getValuationBlockClass(2)"></div>
      </div>

      <div
        class="text-wrapper"
        *ngIf="
          this.reportData.request.equipment !== undefined &&
          exactPriceAlllowedByConfig()
        ">
        <div class="price-text">{{ getTextValue(2) | number : '1.0-0' }} €</div>
        <div
          class="area-text"
          *ngIf="saleMode">
          {{ getTextAreaValue(2) | number : '1.0-0' }} €/m²
        </div>
        <div
          class="area-text"
          *ngIf="!saleMode">
          {{ getTextAreaValue(2) | number : '1.2-2' }} €/m²
        </div>
      </div>
      <div
        class="text-wrapper"
        *ngIf="this.reportData.request.equipment === undefined">
        <div class="price-text">- €</div>
        <div class="area-text">- €/m²</div>
      </div>
      <div
        class="mini-indicator"
        [ngClass]="{
          selected:
            saleMode &&
            (getLevel() === 2 ||
              this.reportData.request === undefined ||
              this.reportData.request.equipment === undefined) &&
            !this.overlayClosed,
          'selected-rent':
            !saleMode &&
            (getLevel() === 2 ||
              this.reportData.request === undefined ||
              this.reportData.request.equipment === undefined),
          'mini-indicator-white': !exactPriceAlllowedByConfig()
        }"></div>
      <div [ngClass]="getValuationTextClass(2)">mittel</div>

      <div
        class="expert-equip"
        [ngClass]="{
          'selected-expert-equip':
            saleMode &&
            (getLevel() === 2 ||
              this.reportData.request === undefined ||
              this.reportData.request.equipment === undefined),
          'selected-rent-expert-equip':
            !saleMode &&
            (getLevel() === 2 ||
              this.reportData.request === undefined ||
              this.reportData.request.equipment === undefined)
        }"
        *ngIf="expert && this.reportData.request.equipment !== undefined">
        <div class="expert-equip-top">
          <div class="text-wrapper">
            <div class="price-text">
              {{ getMinText(2) | number : '1.0-0' }} €
            </div>
            <div
              class="area-text-expert"
              *ngIf="saleMode">
              {{ getAreaTextMin(2) | number : '1.0-0' }} €/m²
            </div>
            <div
              class="area-text-expert"
              *ngIf="!saleMode">
              {{ getAreaTextMin(2) | number : '1.2-2' }} €/m²
            </div>
          </div>
          <div
            class="bottom"
            *ngIf="getLevel() === 2">
            Min Preis
          </div>
        </div>
        <div class="expert-equip-bottom">
          <div class="price-text">{{ getMaxText(2) | number : '1.0-0' }} €</div>
          <div
            class="area-text-expert"
            *ngIf="saleMode">
            {{ getAreaTextMax(2) | number : '1.0-0' }} €/m²
          </div>
          <div
            class="area-text-expert"
            *ngIf="!saleMode">
            {{ getAreaTextMax(2) | number : '1.2-2' }} €/m²
          </div>
          <div
            class="bottom"
            *ngIf="getLevel() === 2">
            Max Preis
          </div>
        </div>
      </div>
    </div>
    <div
      class="high equip-rating"
      [ngClass]="{
        'select-col': getLevel() === 3,
        'print-rating': printMode,
        'hide-vis':
          (this.reportData.request === undefined ||
            this.reportData.request === null ||
            this.reportData.request.equipment === undefined ||
            this.reportData.request.equipment === null) &&
          !printMode &&
          !overlayClosed
      }">
      <div class="level-block">
        <div class="marker">
          <span
            [ngClass]="{ 'rent-icon-3': !saleMode }"
            class="spreng-icon-stars1 level-icon"
            style="font-size: 37px; line-height: 11px"
            *ngIf="!printMode"></span>
          <span
            class="spreng-icon-star1 icon8"
            *ngIf="printMode"></span>
          <span
            class="spreng-icon-star1 icon8"
            *ngIf="printMode"></span>
          <span
            class="spreng-icon-star1 icon8"
            *ngIf="printMode"></span>
        </div>
        <div [ngClass]="getValuationBlockClass(3)"></div>
      </div>

      <div
        class="text-wrapper"
        *ngIf="
          this.reportData.request.equipment !== undefined &&
          exactPriceAlllowedByConfig()
        ">
        <div class="price-text">{{ getTextValue(3) | number : '1.0-0' }} €</div>
        <div
          class="area-text"
          *ngIf="saleMode">
          {{ getTextAreaValue(3) | number : '1.0-0' }} €/m²
        </div>
        <div
          class="area-text"
          *ngIf="!saleMode">
          {{ getTextAreaValue(3) | number : '1.2-2' }} €/m²
        </div>
      </div>
      <div
        class="text-wrapper"
        *ngIf="this.reportData.request.equipment === undefined">
        <div class="price-text">- €</div>
        <div class="area-text">- €/m²</div>
      </div>
      <div
        class="mini-indicator"
        [ngClass]="{
          selected:
            saleMode &&
            (getLevel() === 3 ||
              this.reportData.request === undefined ||
              this.reportData.request.equipment === undefined) &&
            !this.overlayClosed,
          'selected-rent':
            !saleMode &&
            (getLevel() === 3 ||
              this.reportData.request === undefined ||
              this.reportData.request.equipment === undefined),
          'mini-indicator-white': !exactPriceAlllowedByConfig()
        }"></div>
      <div [ngClass]="getValuationTextClass(3)">gehoben</div>

      <div
        class="expert-equip"
        [ngClass]="{
          'selected-expert-equip':
            saleMode &&
            (getLevel() === 3 ||
              this.reportData.request === undefined ||
              this.reportData.request.equipment === undefined),
          'selected-rent-expert-equip':
            !saleMode &&
            (getLevel() === 3 ||
              this.reportData.request === undefined ||
              this.reportData.request.equipment === undefined)
        }"
        *ngIf="expert && this.reportData.request.equipment !== undefined">
        <div class="expert-equip-top">
          <div class="text-wrapper">
            <div class="price-text">
              {{ getMinText(3) | number : '1.0-0' }} €
            </div>
            <div
              class="area-text-expert"
              *ngIf="saleMode">
              {{ getAreaTextMin(3) | number : '1.0-0' }} €/m²
            </div>
            <div
              class="area-text-expert"
              *ngIf="!saleMode">
              {{ getAreaTextMin(3) | number : '1.2-2' }} €/m²
            </div>
          </div>
          <div
            class="bottom"
            *ngIf="getLevel() === 3">
            Min Preis
          </div>
        </div>
        <div class="expert-equip-bottom">
          <div class="price-text">{{ getMaxText(3) | number : '1.0-0' }} €</div>
          <div
            class="area-text-expert"
            *ngIf="saleMode">
            {{ getAreaTextMax(3) | number : '1.0-0' }} €/m²
          </div>
          <div
            class="area-text-expert"
            *ngIf="!saleMode">
            {{ getAreaTextMax(3) | number : '1.2-2' }} €/m²
          </div>
          <div
            class="bottom"
            *ngIf="getLevel() === 3">
            Max Preis
          </div>
        </div>
      </div>
    </div>
    <div
      class="very-high equip-rating"
      [ngClass]="{
        'select-col': getLevel() === 4,
        'print-rating': printMode,
        'hide-vis':
          (this.reportData.request === undefined ||
            this.reportData.request === null ||
            this.reportData.request.equipment === undefined ||
            this.reportData.request.equipment === null) &&
          !printMode &&
          !overlayClosed
      }">
      <div class="level-block">
        <div class="marker">
          <span
            [ngClass]="{ 'rent-icon-4': !saleMode }"
            class="spreng-icon-stars2 level-icon"
            style="font-size: 47px; line-height: 18px"
            *ngIf="!printMode"></span>
          <span
            class="spreng-icon-star1 icon8"
            *ngIf="printMode"></span>
          <span
            class="spreng-icon-star1 icon8"
            *ngIf="printMode"></span>
          <span
            class="spreng-icon-star1 icon8"
            *ngIf="printMode"></span>
          <span
            class="spreng-icon-star1 icon8"
            *ngIf="printMode"></span>
        </div>
        <div [ngClass]="getValuationBlockClass(4)"></div>
      </div>

      <div
        class="text-wrapper"
        *ngIf="
          this.reportData.request.equipment !== undefined &&
          exactPriceAlllowedByConfig()
        ">
        <div class="price-text">{{ getTextValue(4) | number : '1.0-0' }} €</div>
        <div
          class="area-text"
          *ngIf="saleMode">
          {{ getTextAreaValue(4) | number : '1.0-0' }} €/m²
        </div>
        <div
          class="area-text"
          *ngIf="!saleMode">
          {{ getTextAreaValue(4) | number : '1.2-2' }} €/m²
        </div>
      </div>
      <div
        class="text-wrapper"
        *ngIf="this.reportData.request.equipment === undefined">
        <div class="price-text">- €</div>
        <div class="area-text">- €/m²</div>
      </div>
      <div
        class="mini-indicator"
        [ngClass]="{
          selected:
            saleMode &&
            (getLevel() === 4 ||
              this.reportData.request === undefined ||
              this.reportData.request.equipment === undefined) &&
            !this.overlayClosed,
          'selected-rent':
            !saleMode &&
            (getLevel() === 4 ||
              this.reportData.request === undefined ||
              this.reportData.request.equipment === undefined),
          'mini-indicator-white': !exactPriceAlllowedByConfig()
        }"></div>
      <div [ngClass]="getValuationTextClass(4)">stark gehoben</div>

      <div
        class="expert-equip"
        [ngClass]="{
          'selected-expert-equip':
            saleMode &&
            (getLevel() === 4 ||
              this.reportData.request === undefined ||
              this.reportData.request.equipment === undefined),
          'selected-rent-expert-equip':
            !saleMode &&
            (getLevel() === 4 ||
              this.reportData.request === undefined ||
              this.reportData.request.equipment === undefined)
        }"
        *ngIf="expert && this.reportData.request.equipment !== undefined">
        <div class="expert-equip-top">
          <div class="text-wrapper">
            <div class="price-text">
              {{ getMinText(4) | number : '1.0-0' }} €
            </div>
            <div
              class="area-text-expert"
              *ngIf="saleMode">
              {{ getAreaTextMin(4) | number : '1.0-0' }} €/m²
            </div>
            <div
              class="area-text-expert"
              *ngIf="!saleMode">
              {{ getAreaTextMin(4) | number : '1.2-2' }} €/m²
            </div>
          </div>
          <div
            class="bottom"
            *ngIf="getLevel() === 4">
            Min Preis
          </div>
        </div>
        <div class="expert-equip-bottom">
          <div class="price-text">{{ getMaxText(4) | number : '1.0-0' }} €</div>
          <div
            class="area-text-expert"
            *ngIf="saleMode">
            {{ getAreaTextMax(4) | number : '1.0-0' }} €/m²
          </div>
          <div
            class="area-text-expert"
            *ngIf="!saleMode">
            {{ getAreaTextMax(4) | number : '1.2-2' }} €/m²
          </div>
          <div
            class="bottom"
            *ngIf="getLevel() === 4">
            Max Preis
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="printMode"
    class="equip-footer">
    <span *ngIf="saleMode">Kauf Ebene der Ausrüstung</span>
    <span *ngIf="!saleMode">Miete Ebene der Ausrüstung</span>
  </div>
</div>
