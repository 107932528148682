<div
  class="switch-container"
  *ngIf="
    expanded &&
    this._activeDataService.reportData?.config?.show_valuation &&
    this._activeDataService.reportData?.config?.show_rent
  "
  [@showToggleButton]="">
  <div class="switch-container-inner">
    <span [ngClass]="{ 'on-off-toggled': this._activeDataService.saleMode }">
      KAUF
    </span>
    <div class="onoffswitch">
      <input
        type="checkbox"
        name="onoffswitch4"
        class="onoffswitch-checkbox"
        id="myonoffswitch4"
        tabindex="0"
        [checked]="!this._activeDataService.saleMode"
        (change)="toggleSaleMode()"
        [disabled]="this.showLoader" />
      <label
        class="onoffswitch-label"
        for="myonoffswitch4"></label>
    </div>
    <span [ngClass]="{ 'on-off-toggled': !this._activeDataService.saleMode }">
      MIETE
    </span>
  </div>
</div>
<div
  class="mobile-container"
  [@expandAnimation]="expanded ? 'show' : 'hide'">
  <div
    class="expand-arrow"
    (click)="toggleExpanded()">
    <div
      class="expand-arrow-inner"
      [ngClass]="{ 'expand-arrow-inner-expanded': expanded }">
      <span class="spreng-icon-chevron-left icon-expand"></span>
    </div>
  </div>

  <div [ngClass]="{ 'mobile-container-inner': expanded }">
    <div
      class="box boxInfo"
      [ngClass]="{ 'boxInfo-notexpanded': !expanded }">
      <div
        *ngIf="expanded"
        class="show-hide-form-mobile"
        (click)="toggleForm()">
        <div *ngIf="!showForm">+</div>
        <div *ngIf="showForm">-</div>
      </div>

      <app-info
        class="infoBar-standard"
        [appError]="reportError"
        [reportData]="reportData"
        [extended]="true"
        [mobile]="true"></app-info>

      <app-details
        *ngIf="expanded && showForm"
        [extended]="true"
        [showLoader]="showLoader"
        [reloadDetails]="reloadDetails"
        [appError]="reportError"
        [reportData]="reportData"
        [columnsOnly]="true"
        [mobile]="true"
        (validationRequestEmitter)="updateValuation($event)"
        class="details"></app-details>
    </div>
    <!-- 
        <div class="box boxDetails">
            <div class="iContainer">
                <span matTooltip="This is an info box For valuation data details" matTooltipPosition="right"
                    matTooltipClass="tooltip" class="spreng-icon-info detailsInfoIcon"></span>
            </div>
            <app-details [extended]="true" [showLoader]="showLoader" [reloadDetails]="reloadDetails"
                [appError]="reportError" [reportData]="reportData" [columnsOnly]="true"
                (validationRequestEmitter)="updateValuation($event)" class="details">
            </app-details>
        </div> -->

    <div
      class="box boxValuation"
      *ngIf="expanded"
      [@showHideExtraInfo]="expanded ? 'show' : 'hide'">
      <app-valuation
        [mobile]="true"
        [valuationData]="valuationData"
        [reportData]="reportData"
        [extended]="true"
        [saleMode]="this._activeDataService.saleMode"></app-valuation>
    </div>
    <div
      class="mobile-share"
      *ngIf="expanded"
      [@showHideExtraInfo]="expanded ? 'show' : 'hide'">
      <!-- <div class="mobile-share-button" (click)="_printService.printReport()">
                <div class="spreng-icon-printer"></div>
            </div>
            <div class="mobile-share-button-text" (click)="_printService.printReport()">Print</div> -->
      <div
        class="mobile-share-button"
        (click)="shareLink()">
        <div class="spreng-icon-share"></div>
      </div>
      <div
        class="mobile-share-button-text"
        (click)="shareLink()">
        Share
      </div>
    </div>
    <div
      class="box boxRating"
      *ngIf="expanded && shouldShowEquip()"
      [@showHideExtraInfo]="expanded ? 'show' : 'hide'">
      <app-equipment-rating
        *ngIf="shouldShowEquip()"
        [valuationData]="valuationData"
        [reportData]="reportData"
        [equipmentData]="equipmentData"
        [expanded]="true"
        [mobile]="true"
        [saleMode]="this._activeDataService.saleMode"></app-equipment-rating>
    </div>
    <div
      class="box boxTrend"
      *ngIf="expanded"
      [@showHideExtraInfo]="expanded ? 'show' : 'hide'">
      <app-trend
        *ngIf="shouldShowTrend()"
        [saleMode]="this._activeDataService.saleMode"
        [extended]="true"
        [valuationData]="valuationData"
        [reportData]="reportData"
        [ipiData]="ipiData"></app-trend>
      <div
        class="noTrendBoxSale"
        *ngIf="!shouldShowTrend() && this._activeDataService.saleMode"></div>
      <div
        class="noTrendBoxRent"
        *ngIf="!shouldShowTrend() && !this._activeDataService.saleMode"></div>
    </div>

    <!-- Graphs -->

    <ng-container *ngIf="expanded && expert">
      <div
        class="box boxValuation"
        [ngClass]="
          _activeDataService.saleMode
            ? 'boxValuation-sale'
            : 'boxValuation-rental boxValuation-reset'
        ">
        <div
          class="chart-title unselectable"
          [ngClass]="
            _activeDataService.saleMode
              ? 'chart-title-sale'
              : 'chart-title-rental'
          "
          *ngIf="regionData !== undefined && _activeDataService.saleMode">
          Marktdaten
        </div>

        <div
          class="chart-container"
          *ngIf="_activeDataService.saleMode && regionData !== undefined">
          <app-line-chart
            *ngIf="ipiData !== undefined && regionData !== undefined"
            [chartSettings]="getRegionalMarketData()"
            [secondaryChartSettings]="getMarketData()"
            [keepAspect]="false"></app-line-chart>
        </div>
      </div>
      <div
        class="box boxValuation"
        [ngClass]="{ 'box-rent-mode': !_activeDataService.saleMode }"
        [ngClass]="
          _activeDataService.saleMode
            ? 'boxValuation-sale'
            : 'boxValuation-rental'
        ">
        <div
          class="chart-title unselectable"
          [ngClass]="
            _activeDataService.saleMode
              ? 'chart-title-sale'
              : 'chart-title-rental'
          "
          *ngIf="!_activeDataService.saleMode">
          Marktdaten
        </div>

        <div
          class="chart-container"
          *ngIf="!_activeDataService.saleMode">
          <app-line-chart
            *ngIf="ipiData !== undefined"
            [chartSettings]="getMarketData()"
            [keepAspect]="false"></app-line-chart>
        </div>

        <div
          class="chart-title unselectable"
          [ngClass]="
            _activeDataService.saleMode
              ? 'chart-title-sale'
              : 'chart-title-rental'
          "
          *ngIf="_activeDataService.saleMode && simData !== undefined">
          Sprengnetter Immobilienmarkt Monitoring
        </div>

        <div
          class="chart-container"
          *ngIf="_activeDataService.saleMode && simData !== undefined">
          <app-line-chart
            *ngIf="ipiData !== undefined"
            [chartSettings]="getSimData()"
            [keepAspect]="false"></app-line-chart>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="expanded && expert && shouldShowComparables()">
      <app-expert-comparables
        [valuation]="valuationData"
        [valuationError]="valuationError"
        [mobile]="true"></app-expert-comparables>
    </ng-container>

    <ng-container
      *ngIf="
        expanded &&
        expert &&
        _activeDataService.reportData?.config?.show_sociodemographic
      ">
      <app-expert-sociodemographic></app-expert-sociodemographic>
    </ng-container>

    <button
      mat-button
      *ngIf="expanded && expert"
      (click)="toggleExpert()"
      class="mobile-expert-button">
      Kompaktansicht
    </button>
    <button
      mat-button
      *ngIf="
        expanded &&
        !expert &&
        _activeDataService.reportData?.config?.show_expert_view
      "
      (click)="toggleExpert()"
      class="mobile-expert-button">
      Expertenansicht
    </button>
  </div>
</div>
