export class LineChartData {
  minY: number;
  maxY: number;
  prefixY: string;
  postfixY: string;
  data: number[] = [];
  labels: string[] = [];
  mainColor: string;
  secondaryColor: string;
  title: string;
}
