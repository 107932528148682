import { Component } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  ChildrenOutletContexts,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from './core/services/authentication.service';
import { HeaderService } from './core/services/header.service';
import { Animations } from './app.component.animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [Animations.routeTrigger],
})
export class AppComponent {
  title = 'maps';

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data
      ?.animation;
  }

  constructor(
    _route: ActivatedRoute,
    public _authService: AuthenticationService,
    public _headerService: HeaderService,
    _router: Router,
    private contexts: ChildrenOutletContexts
  ) {
    _router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (
          event.url.indexOf('systemError') > -1 ||
          event.url.indexOf('accessDenied') > -1
        ) {
          _authService.validating = false;
          return;
        }
        if (
          _router.url.startsWith('/view') ||
          _router.url.startsWith('/print')
        ) {
          _authService.validating = false;
          return;
        } else {
          _route.queryParams.subscribe((params: any) => {
            if (
              (params.t || params.token) &&
              (params.pid || params.system_id) &&
              !_router.url.startsWith('/view')
            ) {
              let token = params.t || params.token;
              let system_id = params.pid || params.system_id;

              _authService
                .validateCredentials(token, system_id)
                .subscribe((response: boolean) => {
                  if (!response) {
                    _router.navigate(['/accessDenied'], {
                      state: { errors: 'No Token' },
                    });
                  }
                });
            } else {
              if (
                !_router.url.startsWith('/view') &&
                !_router.url.startsWith('/print') &&
                !_router.url.startsWith('/edit')
              ) {
                this._authService.validating = false;
                _router.navigate(['/accessDenied'], {
                  state: { errors: 'Not a Valid Page' },
                });
              }
            }
          });
        }
      });
  }
}
