<app-error *ngIf="appError"></app-error>
<div
  class="info-container"
  *ngIf="updateReport"
  [ngClass]="{
    'comparable-object': comparable,
    'mobile-info-container': mobile,
    'print-object': isPrintMode
  }">
  <app-detail
    *ngIf="isPrintMode"
    [mobile]="mobile"
    class="dispFlex postPad"
    [detailValue]="getPropType()"
    [type]="detail7"
    [editMode]="editMode"
    [propType]="reportData.category"
    [isPrintMode]="isPrintMode"
    [isExpert]="isExpert"></app-detail>

  <app-detail
    *ngIf="!comparable && !isPrintMode"
    [mobile]="mobile"
    class="dispFlex"
    [ngClass]="{ 'mobile-dispFlex': mobile }"
    [detailValue]="constructionText()"
    [type]="detail6"
    (click)="toggleEdit()"
    [editMode]="editMode"
    (constructionEmitter)="updateConstruction($event)"
    [extended]="extended"
    [propType]="reportData.category"
    [country]="reportData.address.nation"
    [comparable]="comparable"
    [isPrintMode]="isPrintMode"
    [isExpert]="isExpert"></app-detail>

  <app-detail
    [mobile]="mobile"
    class="item dispFlex"
    [ngClass]="{ 'mobile-dispFlex': mobile }"
    (click)="toggleEdit()"
    [type]="detail1"
    [detailValue]="updateReport.request.construction_year"
    (valueChangeEmitter)="updateConstructionYear($event)"
    (formValidEmitter)="formValid($event)"
    (formNotValidEmitter)="formNotValid($event)"
    [editMode]="editMode"
    [extended]="extended"
    [comparable]="comparable"
    [isPrintMode]="isPrintMode"
    [isExpert]="isExpert"></app-detail>

  <app-star-rating
    [mobile]="mobile"
    class="item dispFlex"
    [ngClass]="{ 'mobile-dispFlex': mobile }"
    (click)="toggleEdit()"
    [level]="getEquipmentValue()"
    [editMode]="editMode"
    [extended]="extended"
    (ratingEmitter)="updateRating($event)"
    [comparable]="comparable"
    [isPrintMode]="isPrintMode"
    [isExpert]="isExpert"></app-star-rating>

  <app-detail
    [mobile]="mobile"
    class="item dispFlex"
    [ngClass]="{ 'mobile-dispFlex': mobile }"
    [type]="detail4"
    (click)="toggleEdit()"
    [detailValue]="updateReport.request.plot_area"
    (valueChangeEmitter)="updatePlotArea($event)"
    (formValidEmitter)="formValid($event)"
    (formNotValidEmitter)="formNotValid($event)"
    [editMode]="editMode"
    [isPrintMode]="isPrintMode"
    [extended]="extended"
    [comparable]="comparable"
    [isExpert]="isExpert"></app-detail>

  <app-detail
    [mobile]="mobile"
    class="item dispFlex"
    [ngClass]="{ 'mobile-dispFlex': mobile }"
    [type]="detail3"
    (click)="toggleEdit()"
    [detailValue]="updateReport.request.living_area"
    [editMode]="editMode"
    (formValidEmitter)="formValid($event)"
    (formNotValidEmitter)="formNotValid($event)"
    [extended]="extended"
    [comparable]="comparable"
    [isEigentumswohnung]="updateReport.category === 'ETW'"
    (valueChangeEmitter)="updateLivingArea($event)"
    [isPrintMode]="isPrintMode"
    [isExpert]="isExpert"></app-detail>

  <app-detail
    [mobile]="mobile"
    *ngIf="!comparable"
    class="dispFlex"
    [ngClass]="{ 'mobile-dispFlex': mobile }"
    [detailValue]="updateReport.request.garages"
    [type]="detail2"
    (click)="toggleEdit()"
    [editMode]="editMode"
    (garageEmitter)="updateGarages($event)"
    [extended]="extended"
    [comparable]="comparable"
    [isPrintMode]="isPrintMode"
    [isExpert]="isExpert"></app-detail>

  <app-detail
    [mobile]="mobile"
    *ngIf="comparable"
    class="item dispFlex"
    [ngClass]="{ 'mobile-dispFlex': mobile }"
    [type]="detail5"
    [detailValue]="updateReport.request.rooms"
    [editMode]="false"
    [extended]="extended"
    [comparable]="comparable"
    [isPrintMode]="isPrintMode"
    [isExpert]="isExpert"></app-detail>

  <button
    mat-stroked-button
    class="sendForValBtn"
    (click)="requestValidation()"
    *ngIf="
      !this.showLoader &&
      !comparable &&
      !_authService.readOnlyMode &&
      this.showButton
    "
    [disabled]="!this.editMode || !checkIsFormValid()">
    Ergebnisse aktualisieren
  </button>
  <div
    class="valuation-loader-bar"
    *ngIf="
      this.showLoader && !comparable && !_authService.readOnlyMode && showButton
    "
    :disabled>
    <div class="progress-line"></div>
    <span class="progress-text">Analyse wird erstellt</span>
  </div>
</div>
