import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { SociodemographicGetResponse } from '../models/sociodemographic-get-response.model';

@Injectable()
export class SociodemographicService {
  constructor(private _httpClient: HttpClient) {}
  private url: string = environment.apiUrl + '/report/sociodemographic';

  get(reportId: string): Observable<SociodemographicGetResponse> {
    return this._httpClient.get<SociodemographicGetResponse>(
      this.url + '?report_id=' + reportId
    );
  }
}
