<div
  class="main-object"
  *ngIf="reportData !== undefined"
  [ngClass]="{
    resultDocked:
      docked && _activeDataService.reportData?.config?.show_expert_view
  }">
  <div class="flex-grid mainBox">
    <div class="col colMain">
      <div class="flex-grid flex-grid-rows">
        <div class="box col minHeight136">
          <app-image
            [appError]="imageError"
            [images]="imageData"
            [type]="reportData.category"></app-image>
        </div>
        <div class="box boxInfo col">
          <app-details
            [showLoader]="false"
            [showButton]="false"
            [reloadDetails]="reloadDetails"
            [appError]="reportError"
            [reportData]="reportData"
            [columnsOnly]="true"
            (validationRequestEmitter)="updateValuation($event)"
            class="details"></app-details>
        </div>
      </div>
    </div>
    <div class="col colMain colDetails">
      <div class="box boxDetails">
        <app-info
          class="infoBar-standard"
          [appError]="reportError"
          [reportData]="reportData"></app-info>
      </div>
      <div class="flex-grid flex-grid-rows valRows">
        <div class="box col">
          <app-valuation
            [@fadeVal]=""
            [valuationData]="valuationData"
            [reportData]="reportData"
            [ipiData]="ipiValuation"
            [appError]="valuationError"></app-valuation>
        </div>

        <div class="box col">
          <app-valuation
            [@fadeVal]=""
            [saleMode]="false"
            [valuationData]="rentData"
            [reportData]="reportData"
            [ipiData]="ipiRent"></app-valuation>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons-container">
    <button
      mat-stroked-button
      class="sendForValBtn"
      (click)="requestValidation()"
      *ngIf="!this.showLoader && !_authService.readOnlyMode"
      [disabled]="this.checkDetailsButton()">
      Ergebnisse aktualisieren
    </button>
    <div
      class="valuation-loader-bar"
      *ngIf="this.showLoader && !_authService.readOnlyMode"
      :disabled>
      <div class="progress-line"></div>
      <span class="progress-text">Analyse wird erstellt</span>
    </div>

    <button
      mat-stroked-button
      class="sendForValBtn"
      *ngIf="docked && _activeDataService.reportData?.config?.show_expert_view"
      (click)="showExpert()">
      Expertenansicht
    </button>
  </div>

  <div
    class="expand-container"
    *ngIf="_activeDataService.reportData?.config?.show_expanded_view">
    <div
      class="expand-button"
      (click)="expand()"
      matTooltip="Erweiterte Ansicht öffnen">
      <span class="spreng-icon-arrow-expand expandIcon"></span>
    </div>
  </div>

  <div class="close-button-container">
    <span
      class="spreng-icon-close close-button"
      (click)="close()"></span>
  </div>

  <div
    class="expert-container"
    *ngIf="docked">
    <img
      class="expert-icon"
      title="Expert"
      src="../../../assets/icons/expand-view.svg" />
  </div>
</div>
