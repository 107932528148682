import { Component, OnInit, Input } from '@angular/core';
import { ActiveDataService } from 'src/app/core/services/active-data.service';
import { Valuation } from 'src/app/core/models/valuation.model';
import { EquipmentGetResponse } from 'src/app/core/models/equipment-get-response.model';
import { Report, ReportConfiguration } from 'src/app/core/models/reports.model';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-equipment-rating',
  templateUrl: './equipment-rating.component.html',
  styleUrls: ['./equipment-rating.component.scss'],
  animations: [
    trigger('detailFade', [
      transition(':leave', [animate('0.75s', style({ opacity: 0 }))]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.75s 0.75s', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class EquipmentRatingComponent implements OnInit {
  @Input() expanded = true;
  @Input() comparable = false;
  @Input() expert = false;
  @Input() printMode = false;
  @Input() mobile = false;

  @Input() saleMode: boolean;
  @Input() valuationData: Valuation;
  @Input() reportData: Report;
  @Input() equipmentData: EquipmentGetResponse;

  public overlayClosed = false;
  public showExactPrice = true;
  public showTrend = true;

  constructor(private _activeDataService: ActiveDataService) {}

  ngOnInit() {
    if (!this.printMode) {
      this.saleMode = this._activeDataService.saleMode;
    }
  }

  getTypeClass() {
    if (this.saleMode) {
      return 'value-text-bold-small sale-text valuation-row';
    }
    return 'value-text-bold-small rent-text valuation-row';
  }

  getIconClass() {
    if (this.saleMode) {
      return 'icon sale';
    }
    return 'icon rent';
  }

  getImagePosition(min: number, max: number) {
    let per = (100 / (max - min)) * (this.valuationData.value - min);
    if (per < 0) {
      per = 0;
    }
    if (per > 100) {
      per = 100;
    }

    per = per - 18;
    return { left: per.toString() + '%' };
  }

  getValuationBlockClass(pos: number) {
    let level = 'level-' + pos.toString();
    if (this.expanded) {
      level = 'expanded-' + level;
    }
    if (
      this.getLevel() === pos ||
      this.reportData.request === undefined ||
      this.reportData.request.equipment === undefined
    ) {
      if (
        this.saleMode &&
        (this.getLevel() === pos || !this.overlayClosed || this.printMode)
      ) {
        return 'selected-block ' + level;
      }
      if (
        !this.saleMode &&
        (this.getLevel() === pos || !this.overlayClosed || this.printMode)
      ) {
        return 'selected-rent-block ' + level;
      }
    }
    if (this.saleMode) {
      return 'block ' + level;
    }
    return 'rent-block ' + level;
  }

  getValuationTextClass(pos: number) {
    if (this.saleMode) {
      return 'bottom';
    }
    return 'bottom' + ' rent-' + pos.toString() + '-cl';
  }

  getMinText(level: number) {
    let filter = 'EINFACH';
    if (level === 2) {
      filter = 'MITTEL';
    }
    if (level === 3) {
      filter = 'GEHOBEN';
    }
    if (level === 4) {
      filter = 'STARK_GEHOBEN';
    }

    const filtered = this.equipmentData.values.filter(function (value) {
      return value.equipment === filter;
    });

    if (filtered[0] === undefined) {
      return 0;
    }

    return filtered[0].range.min;
  }

  getMaxText(level: number) {
    let filter = 'EINFACH';
    if (level === 2) {
      filter = 'MITTEL';
    }
    if (level === 3) {
      filter = 'GEHOBEN';
    }
    if (level === 4) {
      filter = 'STARK_GEHOBEN';
    }

    const filtered = this.equipmentData.values.filter(function (value) {
      return value.equipment === filter;
    });

    if (filtered[0] === undefined) {
      return 0;
    }

    return filtered[0].range.max;
  }

  getTextValue(level: number) {
    let filter = 'EINFACH';
    if (level === 2) {
      filter = 'MITTEL';
    }
    if (level === 3) {
      filter = 'GEHOBEN';
    }
    if (level === 4) {
      filter = 'STARK_GEHOBEN';
    }
    const filtered = this.equipmentData.values.filter(function (value) {
      return value.equipment === filter;
    });

    if (filtered[0] === undefined) {
      return 0;
    }

    return filtered[0].value;
  }

  getTextAreaValue(level: number) {
    let filter = 'EINFACH';
    if (level === 2) {
      filter = 'MITTEL';
    }
    if (level === 3) {
      filter = 'GEHOBEN';
    }
    if (level === 4) {
      filter = 'STARK_GEHOBEN';
    }

    const filtered = this.equipmentData.values.filter(function (value) {
      return value.equipment === filter;
    });

    if (filtered[0] === undefined) {
      return 0;
    }

    return +filtered[0].value / +this.reportData.request.living_area;
  }

  getAreaTextMin(level: number) {
    let filter = 'EINFACH';
    if (level === 2) {
      filter = 'MITTEL';
    }
    if (level === 3) {
      filter = 'GEHOBEN';
    }
    if (level === 4) {
      filter = 'STARK_GEHOBEN';
    }

    const filtered = this.equipmentData.values.filter(function (value) {
      return value.equipment === filter;
    });

    if (filtered[0] === undefined) {
      return 0;
    }

    return +filtered[0].range.min / +this.reportData.request.living_area;
  }

  getAreaTextMax(level: number) {
    let filter = 'EINFACH';
    if (level === 2) {
      filter = 'MITTEL';
    }
    if (level === 3) {
      filter = 'GEHOBEN';
    }
    if (level === 4) {
      filter = 'STARK_GEHOBEN';
    }

    const filtered = this.equipmentData.values.filter(function (value) {
      return value.equipment === filter;
    });

    if (filtered[0] === undefined) {
      return 0;
    }

    return +filtered[0].range.max / +this.reportData.request.living_area;
  }

  getLevel() {
    if (
      this.reportData.request === null ||
      this.reportData.request === undefined ||
      this.reportData.request.equipment === null ||
      this.reportData.request.equipment === undefined
    ) {
      return 0;
    }
    let res;
    switch (this.reportData.request.equipment.value) {
      case 'EINFACH':
        res = 1;
        break;
      case 'MITTEL':
        res = 2;
        break;
      case 'GEHOBEN':
        res = 3;
        break;
      case 'STARK_GEHOBEN':
        res = 4;
        break;
    }
    return res;
  }

  closeOverlay() {
    this.overlayClosed = true;
  }

  exactPriceAlllowedByConfig() {
    if (this._activeDataService.reportData === undefined) {
      return this.showExactPrice;
    }
    if (this._activeDataService.reportData.config === undefined) {
      if (
        this._activeDataService.reportData.config === null ||
        this._activeDataService.reportData.config === undefined
      ) {
        this._activeDataService.reportData.config = new ReportConfiguration();
      }
    }
    this.showExactPrice =
      this._activeDataService.reportData.config.show_exact_price;
    return this.showExactPrice;
  }
}
