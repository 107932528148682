<div
  class="flex-grid socio-container"
  *ngIf="socioResponse !== undefined">
  <div
    class="socio-top"
    *ngIf="!print">
    <div class="socio-data">
      <div class="socio-top-title">
        {{ socioResponse.region }}
      </div>
      <div class="socio-top-data">
        <div class="data-container">
          <span class="data-container-inner">
            <span>Einwohner</span>
            <span class="data">
              {{ socioResponse.residents | number : '1.0-2' }}
            </span>
          </span>
          <span class="data-container-inner">
            <span>Haushalte</span>
            <span class="data">
              {{ socioResponse.households | number : '1.0-2' }}
            </span>
          </span>
        </div>
        <div class="data-container data-container2">
          <span class="data-row-top show-on-mobile">
            Bevölkerungsentwicklung für
          </span>
          <span class="data-container-row">
            <span class="data-container-inner data-container-bev">
              <span
                *ngIf="socioResponse.population_forecast_5 > 0"
                [ngClass]="{
                  'spreng-icon-trending-up':
                    socioResponse.population_forecast_5 > 0
                }"></span>
              <span
                *ngIf="0 > socioResponse.population_forecast_5"
                [ngClass]="{
                  'spreng-icon-trending-down':
                    socioResponse.population_forecast_5
                }"></span>
              <span>
                <span class="data">
                  {{ socioResponse.population_forecast_5 | number : '1.0-2' }} %
                </span>
                +5 Jahren
              </span>
            </span>
            <span class="data-container-inner data-container-bev noborder">
              <span
                *ngIf="socioResponse.population_forecast_10 > 0"
                [ngClass]="{
                  'spreng-icon-trending-up':
                    socioResponse.population_forecast_10 > 0
                }"></span>
              <span
                *ngIf="0 > socioResponse.population_forecast_10"
                [ngClass]="{
                  'spreng-icon-trending-down':
                    socioResponse.population_forecast_10
                }"></span>
              <span>
                <span class="data">
                  {{ socioResponse.population_forecast_10 | number : '1.0-2' }}
                  %
                </span>
                +10 Jahre
              </span>
            </span>
          </span>
          <span class="data-row-bottom hide-on-mobile">
            Bevölkerungsentwicklung für
          </span>
          <div
            *ngIf="!showMoreSocio"
            class="data-row-bottom show-more-socio-text show-on-mobile">
            Mehr soziodemografische Daten
          </div>
          <div
            *ngIf="showMoreSocio"
            class="data-row-bottom show-more-socio-text show-on-mobile">
            Weniger soziodemografische Daten
          </div>
        </div>
      </div>
    </div>
    <div
      class="show-hide-socio-mobile"
      (click)="toggleMoreSocio()">
      <div *ngIf="!showMoreSocio">+</div>
      <div *ngIf="showMoreSocio">-</div>
    </div>
  </div>
  <div
    class="socio-main show-on-mobile"
    *ngIf="showMoreSocio">
    <div [ngClass]="getRowClass()">
      <div
        [ngClass]="getChartClass()"
        *ngIf="!print || page === 1">
        <app-chart-container
          [type]="1"
          [inputData]="chart1"
          *ngIf="chart1 !== undefined"
          [topL]="top1L"
          [topV]="top1V"
          [print]="print"></app-chart-container>
      </div>
      <div [ngClass]="getChartClass()">
        <app-chart-container
          [type]="2"
          [inputData]="chart2"
          [rightChart]="true"
          *ngIf="chart2 !== undefined"
          [topL]="top2L"
          [topV]="top2V"
          [print]="print"></app-chart-container>
      </div>
    </div>
    <div
      [ngClass]="getRowClass()"
      *ngIf="!print || page === 2">
      <div [ngClass]="getChartClass()">
        <app-chart-container
          [type]="3"
          [inputData]="chart3"
          *ngIf="chart3 !== undefined"
          [topL]="top3L"
          [topV]="top3V"
          [print]="print"></app-chart-container>
      </div>
      <div [ngClass]="getChartClass()">
        <app-chart-container
          [type]="4"
          [inputData]="chart4"
          [rightChart]="true"
          *ngIf="chart4 !== undefined"
          [topL]="top4L"
          [topV]="top4V"
          [print]="print"></app-chart-container>
      </div>
    </div>
  </div>

  <div
    class="socio-main hide-on-mobile"
    *ngIf="full">
    <div
      [ngClass]="getRowClass()"
      *ngIf="!print || page === 1">
      <div [ngClass]="getChartClass()">
        <app-chart-container
          [type]="1"
          [inputData]="chart1"
          *ngIf="chart1 !== undefined"
          [topL]="top1L"
          [topV]="top1V"
          [print]="print"></app-chart-container>
      </div>
      <div [ngClass]="getChartClass()">
        <app-chart-container
          [type]="2"
          [inputData]="chart2"
          [rightChart]="true"
          *ngIf="chart2 !== undefined"
          [topL]="top2L"
          [topV]="top2V"
          [print]="print"></app-chart-container>
      </div>
    </div>
    <div
      [ngClass]="getRowClass()"
      *ngIf="!print || page === 2">
      <div [ngClass]="getChartClass()">
        <app-chart-container
          [type]="3"
          [inputData]="chart3"
          *ngIf="chart3 !== undefined"
          [topL]="top3L"
          [topV]="top3V"
          [print]="print"></app-chart-container>
      </div>
      <div [ngClass]="getChartClass()">
        <app-chart-container
          [type]="4"
          [inputData]="chart4"
          [rightChart]="true"
          *ngIf="chart4 !== undefined"
          [topL]="top4L"
          [topV]="top4V"
          [print]="print"></app-chart-container>
      </div>
    </div>
  </div>
</div>
