<div
  class="valuation-request-main unselectable"
  name="valuation-request-panel"
  *ngIf="!this.locked">
  <div
    class="valuation-button top-button"
    [ngClass]="{ 'selected-button': isSelected(0) }"
    (click)="setSelected(0); toggleValReqMain()">
    <div class="button-container ein">
      <span class="spreng-icon-home"></span>
      <span class="button-text">Einfamilien haus</span>
    </div>
  </div>

  <div
    class="valuation-button right-button"
    [ngClass]="{
      'selected-button': isSelected(3),
      'val-disabled': !checkNation()
    }"
    (click)="
      setSelected(3, checkNation()); checkNation() ? toggleValReqMain() : null
    ">
    <div class="button-container meh">
      <span class="spreng-icon-apartment-multiple"></span>
      <span class="button-text">Mehrfamilien haus</span>
    </div>
  </div>

  <div
    class="valuation-button left-button"
    [ngClass]="{ 'selected-button': isSelected(2) }"
    (click)="setSelected(2); toggleValReqMain()">
    <div class="button-container eig">
      <span class="spreng-icon-apartment"></span>
      <span class="button-text">Eigentums wohnung</span>
    </div>
  </div>

  <div class="valuation-button bottom-button val-disabled">
    <div class="button-container gru">
      <span class="spreng-icon-land"></span>
      <span class="button-text">Grundstück</span>
    </div>
  </div>
</div>
<div
  class="valuation-request-secondary"
  [@openCloseValReqMain]="
    valReqMainIsOpen && !this.locked && this.selected !== -1 ? 'open' : 'closed'
  ">
  <div class="valuation-request-row">
    <p class="para1">
      Analyse für
      <span [ngClass]="{ 'highlight-text': this.selected !== -1 }">
        {{ getType() }}
      </span>
      anfordern.
    </p>
  </div>
  <div class="valuation-request-row">
    <p class="para2">Adresse: {{ location }}</p>
  </div>
  <div
    class="valuation-request-row"
    *ngIf="
      _authenticationService.authenticatedUser !== null &&
      _authenticationService.authenticatedUser.product !== null &&
      _authenticationService.authenticatedUser.product.monthly_limit > 0
    ">
    <div class="monthly-report">
      <span class="spreng-icon-info"></span>
      <b>
        {{
          _authenticationService.authenticatedUser.product.monthly_limit -
            _authenticationService.authenticatedUser.product.monthly_count
        }}
      </b>
      Reporte verfügbar
    </div>
  </div>
  <div class="valuation-request-row">
    <button
      mat-button
      class="confirm-button"
      *ngIf="!this.showLoader"
      [disabled]="
        this.isDisabled ||
        (_authenticationService.authenticatedUser.product.monthly_limit > 0 &&
          !(
            _authenticationService.authenticatedUser !== null &&
            _authenticationService.authenticatedUser.product !== null &&
            _authenticationService.authenticatedUser.product.monthly_limit -
              _authenticationService.authenticatedUser.product.monthly_count >
              0
          ))
      "
      (click)="confirmValuation()">
      Analyse anfordern
    </button>
    <div
      class="valuation-loader-bar"
      *ngIf="this.showLoader"
      :disabled>
      <div class="progress-line"></div>
      <span>Analyse wird erstellt</span>
    </div>
  </div>
</div>

<div
  class="valuation-request-locked"
  [@openCloseValReqLocked]="this.locked ? 'open' : 'closed'">
  <div class="valuation-request-row">
    <div
      class="valuation-button valuation-button-locked"
      [ngClass]="{ 'selected-button': isSelected(0) }"
      (click)="setSelected(0)">
      <div class="button-container ein">
        <span class="spreng-icon-home"></span>
        <span class="button-text">Einfamilien haus</span>
      </div>
    </div>
    <div
      class="valuation-button valuation-button-locked"
      [ngClass]="{ 'selected-button': isSelected(2) }"
      (click)="setSelected(2)">
      <div class="button-container eig">
        <span class="spreng-icon-apartment"></span>
        <span class="button-text">Eigentums wohnung</span>
      </div>
    </div>
    <div
      class="valuation-button valuation-button-locked"
      [ngClass]="{
        'selected-button': isSelected(3),
        'val-disabled': !checkNation()
      }"
      (click)="setSelected(3, checkNation())">
      <div class="button-container meh">
        <span class="spreng-icon-apartment-multiple"></span>
        <span class="button-text mfh-button-text">Mehrfamilien haus</span>
      </div>
    </div>
    <div class="valuation-button valuation-button-locked val-disabled">
      <div class="button-container gru">
        <span class="spreng-icon-land"></span>
        <span class="button-text">Grundstück</span>
      </div>
    </div>
  </div>
  <div class="valuation-request-row">
    <p class="para1">
      Analyse für
      <span
        [ngClass]="{ 'highlight-text': this.selected !== -1 }"
        class="1">
        {{ getType() }}
      </span>
      anfordern.
    </p>
  </div>
  <div class="valuation-request-row">
    <p class="para2">Adresse: {{ location }}</p>
  </div>
  <div
    class="valuation-request-row"
    *ngIf="
      _authenticationService.authenticatedUser !== null &&
      _authenticationService.authenticatedUser.product !== null &&
      _authenticationService.authenticatedUser.product.monthly_limit > 0
    ">
    <div class="monthly-report">
      <span class="spreng-icon-info"></span>
      <b>
        {{
          _authenticationService.authenticatedUser.product.monthly_limit -
            _authenticationService.authenticatedUser.product.monthly_count
        }}
      </b>
      Reporte verfügbar
    </div>
  </div>
  <div class="valuation-request-row">
    <button
      mat-button
      class="confirm-button"
      *ngIf="!this.showLoader"
      [disabled]="
        this.isDisabled ||
        (_authenticationService.authenticatedUser.product.monthly_limit > 0 &&
          !(
            _authenticationService.authenticatedUser !== null &&
            _authenticationService.authenticatedUser.product !== null &&
            _authenticationService.authenticatedUser.product.monthly_limit -
              _authenticationService.authenticatedUser.product.monthly_count >
              0
          ))
      "
      (click)="confirmValuation()">
      Analyse anfordern
    </button>
    <div
      class="valuation-loader-bar"
      *ngIf="this.showLoader"
      :disabled>
      <div class="progress-line"></div>
      <span>Analyse wird erstellt</span>
    </div>
  </div>
</div>
