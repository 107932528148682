import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Report } from 'src/app/core/models/reports.model';
import { Valuation } from 'src/app/core/models/valuation.model';
import { IpiGetResponse } from 'src/app/core/models/ipi-get-response.model';
import { EquipmentGetResponse } from 'src/app/core/models/equipment-get-response.model';
import { Image } from '../../../core/models/images.model';
import { ActiveDataService } from 'src/app/core/services/active-data.service';
import { ReportService } from 'src/app/core/services/report.service';
import { RentResponse } from 'src/app/core/models/rent-response.model';
import { HeaderService } from 'src/app/core/services/header.service';
import { SimIndexResponse } from 'src/app/core/models/sim-index-response.model';
import { ChartGenerator } from 'src/app/core/functions/chartGenerator.function';
import { LineChartData } from 'src/app/core/models/line-chart-data.model';
import { ShareComponent } from 'src/app/share/share.component';
import { PrintService } from 'src/app/core/services/print.service';
import {
  animate,
  query,
  style,
  transition,
  trigger,
  state,
  group,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-mobile-result',
  templateUrl: './mobile-result.component.html',
  styleUrls: ['./mobile-result.component.scss'],
  animations: [
    trigger('expandAnimation', [
      state(
        'show',
        style({
          height: '68vh',
          'max-height': '68vh',
        })
      ),
      state(
        'hide',
        style({
          height: '130px',
          'max-height': '130px',
        })
      ),
      transition('show => hide', [
        group([
          animate(
            '400ms ease-in-out',
            style({
              height: '130px',
              'max-height': '130px',
            })
          ),
        ]),
      ]),

      transition('hide => show', [
        group([
          animate(
            '600ms ease-in-out',
            style({
              height: '68vh',
              'max-height': '68vh',
            })
          ),
        ]),
      ]),
    ]),

    trigger('showToggleButton', [
      transition(':leave', [
        query(':self', [animate('0.25s', style({ opacity: 0 }))]),
      ]),
      transition(':enter', [
        query(':self', [
          style({ opacity: 0 }),
          animate('0.25s 0.15s', style({ opacity: 1 })),
        ]),
      ]),
    ]),

    trigger('showHideExtraInfo', [
      transition(':leave', [
        query(':self', [animate('0.25s', style({ opacity: 0 }))]),
      ]),
      transition(':enter', [
        query(':self', [
          style({ opacity: 0 }),
          animate('0.25s 0.15s', style({ opacity: 1 })),
        ]),
      ]),
    ]),
  ],
})
export class MobileResultComponent implements OnInit {
  @Input() reportId: string;

  public reportImages: Image[] = undefined;
  public imageError = false;

  public reportData: Report = undefined;
  public reportError = false;
  private reportSubscription = new Subscription();

  public valuationData: Valuation = undefined;
  public valuationError = false;
  private valuationSubscription = new Subscription();

  public ipiData: IpiGetResponse = undefined;
  public ipiError = false;
  private ipiSubscription = new Subscription();

  public equipmentData: EquipmentGetResponse = undefined;
  public equipmentError = false;
  private equipmentSubscription = new Subscription();

  public showLoader = false;
  public reloadDetails = false;

  public expanded = false;
  public showForm = false;
  public expert = false;

  public regionData: IpiGetResponse = undefined;
  private regionSubscription: Subscription;
  public simData: SimIndexResponse = undefined;
  private simSubscription: Subscription;
  private showCountry = true;
  public showTrend = true;
  private showComparables = true;
  private showEquip = true;

  @Output()
  expandedEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public _activeDataService: ActiveDataService,
    private _reportService: ReportService,
    private _headerService: HeaderService,
    public _printService: PrintService,
    private _dialog: MatDialog
  ) {
    this._headerService.showLogo = !this.expanded;
    this._activeDataService.modeEmitter.subscribe(() => {
      this.ipiData = undefined;
      this.regionData = undefined;
      this.loadCoreReport();
      this.loadVariableData();
      this.loadSimIndex();
      if (this._activeDataService.saleMode) {
        this.loadregionData();
      }
    });
  }

  ngOnInit() {
    this.loadCoreReport();
    this.loadVariableData();
    this.loadSimIndex();
    this.loadregionData();
  }

  toggleExpanded() {
    if (!this.showLoader) {
      this.expanded = !this.expanded;
      this._headerService.showLogo = !this.expanded;
      this.expandedEmitter.emit();
    }
  }

  toggleForm() {
    if (!this.showLoader) {
      this.showForm = !this.showForm;
    }
  }

  toggleExpert() {
    if (!this.showLoader) {
      this.expert = !this.expert;
    }
  }

  loadVariableData(reload = false) {
    this.loadValuationData(reload);
    this.loadIpiData(reload);
    this.loadEquipmentData(reload);
  }

  loadCoreReport(reload = false) {
    this.reportSubscription = this._activeDataService.reportEmitter.subscribe(
      (data: Report) => {
        if (!data.config?.show_valuation) {
          if (this._activeDataService.saleMode) {
            this.toggleSaleMode();
          }
        }
        if (data === null) {
          this.reportError = true;
        } else {
          this.reportData = data;
          this.reloadDetails = true;
        }
        this.reportSubscription.unsubscribe();
      },
      (error: any) => {
        this.reportError = true;
        this.reportSubscription.unsubscribe();
        throw error;
      }
    );
    this._activeDataService.getReportData(reload);
  }

  loadValuationData(reload = false) {
    if (this._activeDataService.saleMode) {
      this.valuationSubscription =
        this._activeDataService.valuationEmitter.subscribe(
          (data: Valuation) => {
            if (data === null) {
              this.valuationError = true;
            } else {
              this.valuationData = data;
            }
            this.valuationSubscription.unsubscribe();
          },
          (error: any) => {
            this.reportError = true;
            this.valuationSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getValuationData(reload);
    } else {
      this.valuationSubscription =
        this._activeDataService.rentEmitter.subscribe(
          (data: RentResponse) => {
            if (data === null) {
              this.valuationError = true;
            } else {
              this.valuationData = data;
            }
            this.valuationSubscription.unsubscribe();
          },
          (error: any) => {
            this.valuationError = true;
            this.valuationSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getRentData(reload);
    }
  }

  loadIpiData(reload = false) {
    if (this._activeDataService.saleMode) {
      this.ipiSubscription =
        this._activeDataService.ipiValuationEmitter.subscribe(
          (data: IpiGetResponse) => {
            if (data === null) {
              this.ipiError = true;
            } else {
              this.ipiData = data;
            }
            this.ipiSubscription.unsubscribe();
          },
          (error: any) => {
            this.ipiError = true;
            this.ipiSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getValuationIpiData(reload);
    } else {
      this.ipiSubscription = this._activeDataService.ipiRentEmitter.subscribe(
        (data: IpiGetResponse) => {
          if (data === null) {
            this.ipiError = true;
          } else {
            this.ipiData = data;
          }
          this.ipiSubscription.unsubscribe();
        },
        (error: any) => {
          this.ipiError = true;
          this.ipiSubscription.unsubscribe();
          throw error;
        }
      );
      this._activeDataService.getRentIpiData(reload);
    }
  }

  loadEquipmentData(reload = false) {
    if (this._activeDataService.saleMode) {
      this.equipmentSubscription =
        this._activeDataService.equipmentValuationEmitter.subscribe(
          (data: EquipmentGetResponse) => {
            if (data === null) {
              this.equipmentError = true;
            } else {
              this.equipmentData = data;
            }
            this.equipmentSubscription.unsubscribe();
          },
          (error: any) => {
            this.equipmentError = true;
            this.equipmentSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getValuationEquipment(reload);
    } else {
      this.equipmentSubscription =
        this._activeDataService.equipmentRentEmitter.subscribe(
          (data: EquipmentGetResponse) => {
            if (data === null) {
              this.equipmentError = true;
            } else {
              this.equipmentData = data;
            }
            this.equipmentSubscription.unsubscribe();
          },
          (error: any) => {
            this.equipmentError = true;
            this.equipmentSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getRentEquipment(reload);
    }
  }

  updateValuation(report: Report) {
    this._activeDataService.clearAll();
    this.reloadDetails = false;
    this.showLoader = true;
    this._reportService.put(report.request, report.report_id).subscribe(
      () => {
        this.showLoader = false;
        this.loadCoreReport(true);
        this.loadVariableData(true);
        this._activeDataService.getValuationData();
      },
      (error: any) => {
        this.showLoader = false;
        throw error;
      }
    );
  }

  toggleSaleMode() {
    this._activeDataService.saleMode = !this._activeDataService.saleMode;
    this._activeDataService.clearAll();
    this.ipiData = undefined;
    this.regionData = undefined;
    this.loadCoreReport();
    this.loadVariableData();
    this.loadSimIndex();
    if (this._activeDataService.saleMode) {
      this.loadregionData();
    }
    this._activeDataService.modeEmitter.emit(this._activeDataService.saleMode);
  }

  // Graph stuff

  loadSimIndex() {
    this.simSubscription = this._activeDataService.simEmitter.subscribe(
      (data: any) => {
        if (data === null) {
        } else {
          this.simData = data;
        }
        this.simSubscription.unsubscribe();
      },
      (error: any) => {
        this.simSubscription.unsubscribe();
        throw error;
      }
    );
    this._activeDataService.getSimData();
  }

  loadregionData(reload = false) {
    if (this._activeDataService.saleMode) {
      this.regionSubscription =
        this._activeDataService.regionValuationEmitter.subscribe(
          (data: IpiGetResponse) => {
            if (data === null) {
            } else {
              this.regionData = data;
            }
            this.regionSubscription.unsubscribe();
          },
          (error: any) => {
            this.regionSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getValuationRegionData(reload);
    }
  }

  getRegionalMarketData() {
    const chartCalc = new ChartGenerator();
    const returnValue = chartCalc.generateMarketData(
      this.regionData,
      this.valuationData,
      true
    );
    if (!this._activeDataService.saleMode) {
      returnValue.mainColor = '#A2A811';
    }
    return returnValue;
  }

  getMarketData() {
    if (this.showCountry) {
      const chartCalc = new ChartGenerator();
      const returnValue = chartCalc.generateMarketData(
        this.ipiData,
        this.valuationData,
        true
      );
      if (!this._activeDataService.saleMode) {
        returnValue.mainColor = '#A2A811';
      }
      return returnValue;
    }
    return new LineChartData();
  }

  getSimData() {
    if (this.simData === undefined) {
      return new LineChartData();
    }

    const chartCalc = new ChartGenerator();
    const returnValue = chartCalc.generateSimData(
      this.simData,
      this.valuationData,
      true
    );
    return returnValue;
  }

  togglebndMode() {
    this.showCountry = !this.showCountry;
  }

  shareLink() {
    if (this._activeDataService.reportData !== undefined) {
      if (
        (
          'https://' +
          window.location.hostname +
          '/view?report=' +
          this._activeDataService.reportData.report_id
        ).length <= 66
      ) {
        this._dialog.open(ShareComponent, {
          width: '799px',
          height: 'auto',
          minWidth: '350px',
          panelClass: 'share-dialog',
          data: this._activeDataService.reportData,
        });
      }
      if (
        (
          'https://' +
          window.location.hostname +
          '/view?report=' +
          this._activeDataService.reportData.report_id
        ).length > 66
      ) {
        this._dialog.open(ShareComponent, {
          width: '600px',
          height: 'auto',
          minWidth: '350px',
          panelClass: 'share-dialog-small',
          data: this._activeDataService.reportData,
        });
      }
    }
  }

  shouldShowTrend() {
    if (
      this._activeDataService.reportData === null ||
      this._activeDataService.reportData === undefined ||
      this._activeDataService.reportData.config === null ||
      this._activeDataService.reportData.config === undefined
    ) {
      return this.showTrend;
    }

    this.showTrend = this._activeDataService.reportData.config.show_trend;
    return this.showTrend;
  }

  shouldShowComparables() {
    if (
      this._activeDataService.reportData === null ||
      this._activeDataService.reportData === undefined ||
      this._activeDataService.reportData.config === null ||
      this._activeDataService.reportData.config === undefined
    ) {
      return this.showComparables;
    }

    this.showComparables =
      this._activeDataService.reportData.config.show_compare_prices;
    return this.showComparables;
  }

  shouldShowEquip() {
    if (
      this._activeDataService.reportData === null ||
      this._activeDataService.reportData === undefined ||
      this._activeDataService.reportData.config === null ||
      this._activeDataService.reportData.config === undefined
    ) {
      return this.showEquip;
    }

    this.showEquip =
      this._activeDataService.reportData.config.show_equipment_overview;
    return this.showEquip;
  }
}
