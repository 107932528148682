export enum Construction {
  DOPPELHAUS = 'Doppelhaushälfte',
  FREISTEHEND = 'Freistehend',
  REIHEN_ENDHAUS = 'Reihenendhaus',
  REIHEN_MITTELHAUS = 'Reihenmittelhaus',
  LOFT = 'Loft',
  PENTHOUSE = 'Penthouse',
  SOUTERRAIN = 'Souterrain',
  ERDGESCHOSSWOHNUNG = 'Erdgeschosswohnung',
  ETAGENWOHNUNG = 'Etagenwohnung',
  HOCHPARTERRE = 'Hochparterre',
  MAISONETTE = 'Maisonette',
  TERRASSENWOHNUNG = 'Terrassenwohnung',
  DACHGESCHOSS = 'Dachgeschoss',
  DACHGESCHOSSPENTHOUSE = 'Dachgeschosspenthouse',
  MITGARTENTEIL = 'Mitgartenteil',
  mitGartenanteil = 'Mit Gartenanteil',
}

export class ConstructionOption {
  country: string;
  propType: string;
  options: string[];
  values: string[];
}
