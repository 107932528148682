import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { AuthenticationResult } from '../models/authentication-result.model';

@Injectable()
export class AuthenticateService {
  private url: string = environment.apiUrl + '/authenticate';

  constructor(private _httpClient: HttpClient) {}

  get(token: string, pid: string): Observable<AuthenticationResult> {
    // eslint-disable-next-line prettier/prettier
    const authUrl = `${this.url}?token=${encodeURIComponent(token)}&system_id=${pid}`;
    return this._httpClient.get<AuthenticationResult>(authUrl);
  }
}
