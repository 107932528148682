import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent {
  @Input() rating = 0;

  constructor() {}

  ratingClass(rating: number) {
    if (this.rating > rating) {
      return 'small-rating rating filled';
    }
    return 'small-rating rating';
  }
}
