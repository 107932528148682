import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MapMainComponent } from './map/map.component';
import { ErrorComponent } from './error/error.component';
import { PortalError } from './core/enums/portal-error.enum';
import { PrintComponent } from './print/print.component';

const routes: Routes = [
  {
    path: '',
    component: MapMainComponent,
    data: { animation: 'HomePage' },
  },
  {
    path: 'view',
    component: MapMainComponent,
    data: { animation: 'ViewPage' },
  },
  {
    path: 'edit',
    component: MapMainComponent,
  },
  {
    path: 'print',
    component: PrintComponent,
  },
  {
    path: 'accessDenied',
    component: ErrorComponent,
    data: { type: PortalError.AccessDenied },
  },
  {
    path: 'systemError',
    component: ErrorComponent,
    data: { type: PortalError.GeneralError },
  },
  {
    path: 'componentError',
    component: ErrorComponent,
    data: { type: PortalError.ComponentError },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
