import {
  ErrorHandler,
  Inject,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { ILogger } from '../abstract/ILogger.abstract';
import { NotificationService } from '../services/notification.service';
import { AppHttpErrorResponse, ErrorService } from '../services/error.service';
import { Router } from '@angular/router';
import StackTrace from 'stacktrace-js';

@Injectable()
export class CustomErrorHandler extends ErrorHandler {
  private get errorLoggingService(): ILogger {
    return this.injector.get<ILogger>(ILogger as Type<ILogger>);
  }

  private get notificationService(): NotificationService {
    return this.injector.get<NotificationService>(
      NotificationService as Type<NotificationService>
    );
  }
  private errorService: ErrorService;
  private router: Router;

  constructor(@Inject(Injector) private injector: Injector) {
    super();
  }

  handleError(err: any) {
    console.error(err);

    if (err.message.includes('ExpressionChangedAfterItHasBeenCheckedError')) {
      return;
    }

    if (err instanceof AppHttpErrorResponse) {
      const httpErr = new Error('');
      httpErr.name = err.name;
      if (err.status === 0 && err.statusText === 'Unknown Error') {
        httpErr.message =
          'HTTP Error: Unknown, this may be a CORS error (eg authentication) or a loss of connection';
      } else {
        httpErr.message = 'HTTP Error: ' + err.message;
      }
      httpErr.stack = err.url;
      if (err.error.errors !== undefined && err.error.errors.length > 0) {
        err.error.errors.forEach(element => {
          const keys = Object.keys(element);
          for (const prop of keys) {
            if (err.isHardError) {
              this.errorService.addToErrorStack({
                error: err.message,
                timestamp: Date.now(),
              });
            } else {
              this.notificationService.notifyError(
                prop + ' : ' + element[prop]
              );
            }

            this.errorLoggingService.logError(
              new Error(err.message + ' || ' + prop + ' : ' + element[prop])
            );
          }
        });

        if (err.isHardError) {
          this.router.navigate(['/accessDenied']);
        }
      } else {
        if (err.isHardError) {
          this.errorService.addToErrorStack({
            error: err.message,
            timestamp: Date.now(),
          });

          this.router.navigate(['/accessDenied']);
        } else {
          this.notificationService.notifyError(
            '!!Ein unerwarteter Fehler ist aufgetreten. Bitte starten Sie den Vorgang noch einmal.'
          );
        }

        this.errorLoggingService.logError(err);
      }
    } else {
      StackTrace.fromError(err).then(stack => {
        err.message =
          err.message +
          ' @ ' +
          stack[0].fileName +
          ' line ' +
          stack[0].lineNumber;
        if (err.isHardError) {
          this.errorService.addToErrorStack({
            error: err.message,
            timestamp: Date.now(),
          });

          this.router.navigate(['/systemError']);
        } else {
          this.notificationService.notifyError(
            'Ein unerwarteter Fehler ist aufgetreten. Bitte starten Sie den Vorgang noch einmal.' +
              err.message
          );
        }
      });
    }
  }
}
