import { Injectable } from '@angular/core';
import { ILogger } from '../abstract/ILogger.abstract';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoggingService implements ILogger {
  constructor() {}

  logDebug(msg: string) {
    if (environment.alertOnError) {
      alert('A debug message has been logged, ' + msg);
    }
  }

  logInfo(msg: string) {
    if (environment.alertOnError) {
      alert('A info message has been logged, ' + msg);
    }
  }

  logWarning(msg: string) {
    if (environment.alertOnError) {
      alert('A warning message has been logged, ' + msg);
    }
  }

  logError(err: Error) {
    if (environment.alertOnError) {
      alert('An exception has been logged, ' + err.message);
    }
  }
}
