import { Component, Input } from '@angular/core';
import { Report } from 'src/app/core/models/reports.model';
import { Construction } from 'src/app/core/enums/construction.enum';
import {
  animate,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  animations: [
    trigger('infoFade', [
      transition(':leave', [
        query('.dispFlex', [animate('0.25s', style({ opacity: 0 }))]),
      ]),
      transition(':enter', [
        query('.dispFlex', [
          style({ opacity: 0 }),
          animate('0.25s 0.15s', style({ opacity: 1 })),
        ]),
      ]),
    ]),
  ],
})
export class InfoComponent {
  @Input() reportData: Report = null;
  @Input() expert = false;
  @Input() appError = false;
  @Input() extended = false;
  @Input() comparable = false;
  @Input() mobile = false;
  constructor() {}

  categoryText() {
    switch (this.reportData.category) {
      case 'ETW': {
        return 'Eigentumswohnung';
      }
      case 'MFH': {
        return 'Mehrfamilienhaus';
      }
      case 'EFH': {
        return 'Einfamilienhaus';
      }
      case 'GRD': {
        return 'Grundstück';
      }
      default: {
        console.log('Unknown house type', this.reportData.category);
      }
    }
  }

  categoryClass() {
    if (this.mobile) {
      return 'spreng-icon-b-home-city mobile-icon';
    } else {
      return 'spreng-icon-b-home-city';
    }
  }

  typeText() {
    return this.reportData.request.construction
      ? Construction[this.reportData.request.construction]
      : 'keine Daten';
  }

  typeClass() {
    if (this.reportData.category === 'EFH') {
      return 'spreng-icon-b-home';
    }
    switch (this.reportData.category) {
      case 'ETW': {
        return 'spreng-icon-b-pin';
      }
      case 'MFH': {
        return 'Mehrfamilienhaus';
      }
      case 'GRD': {
        return 'Grundstück';
      }
      default: {
        console.log('Unknown house type', this.reportData.category);
      }
    }
  }

  getAddress() {
    if (
      this.reportData === null ||
      this.reportData.address === undefined ||
      this.reportData.address === null
    ) {
      return '';
    }
    let returnString = '';
    let houseNum = '';
    if (this.reportData.address.street !== '') {
      returnString = returnString + this.reportData.address.street;
    }
    if (this.reportData.address.house_number !== '') {
      if (returnString !== '') {
        returnString = returnString + '';
      }
      if (this.reportData.address.house_number !== ' ') {
        houseNum = ' ' + this.reportData.address.house_number;
      }
      returnString = returnString + houseNum;
    }
    if (this.reportData.address.town !== '') {
      if (returnString !== '') {
        returnString = returnString + ', ';
      }
      returnString = returnString + this.reportData.address.town;
    }
    return returnString;
  }
}
