import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private currentErrorStack: ErrorsStackLines[] = [];

  constructor() {}

  clearErrorStack() {
    this.currentErrorStack.length = 0;
  }

  addToErrorStack(line: ErrorsStackLines) {
    this.currentErrorStack.push(line);
  }

  getCurrentErrorStack() {
    return this.currentErrorStack;
  }
}

export interface ErrorsStackLines {
  timestamp: number;
  error: string;
}

export class AppHttpErrorResponse extends HttpErrorResponse {
  public isHardError: boolean;

  constructor(error, headers, status, statusText, url, isHardError = false) {
    super({
      error,
      headers,
      status,
      statusText,
      url,
    });
    this.isHardError = isHardError;
  }
}
