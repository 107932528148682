<div
  class="switch-container"
  *ngIf="!mobile && !tablet && !print">
  <div
    class="switch-container-inner singular-switch-text"
    *ngIf="
      !this._activeDataService.reportData?.config?.show_valuation ||
      !this._activeDataService.reportData?.config?.show_rent
    ">
    <span
      class="on-off-toggled"
      *ngIf="_activeDataService.saleMode">
      Marktpreis
    </span>
    <span
      class="on-off-toggled"
      *ngIf="!_activeDataService.saleMode">
      Mietwert
    </span>
  </div>
  <div
    class="switch-container-inner"
    *ngIf="
      this._activeDataService.reportData?.config?.show_valuation &&
      this._activeDataService.reportData?.config?.show_rent
    ">
    <span [ngClass]="{ 'on-off-toggled': _activeDataService.saleMode }">
      Marktpreis
    </span>
    <div class="onoffswitch">
      <input
        type="checkbox"
        name="onoffswitch3"
        class="onoffswitch-checkbox"
        id="myonoffswitch3"
        (change)="toggleMode()"
        tabindex="0"
        [checked]="!this._activeDataService.saleMode"
        title="on off switch" />
      <label
        class="onoffswitch-label"
        for="myonoffswitch3"></label>
    </div>
    <span [ngClass]="{ 'on-off-toggled': !_activeDataService.saleMode }">
      Mietwert
    </span>
  </div>
</div>
<div
  [ngClass]="containerClass()"
  *ngIf="valuation !== undefined && !mobile">
  <div
    class="header"
    [ngClass]="{ rent: !_activeDataService.saleMode || rentPrint }">
    <p *ngIf="_activeDataService.saleMode && !rentPrint">
      Vergleichsobjekte Kauf
    </p>
    <p *ngIf="!_activeDataService.saleMode || rentPrint">
      Vergleichsobjekte Miete
    </p>
  </div>
  <table
    [ngClass]="tableClass()"
    *ngIf="
      valuation.compare_prices !== undefined &&
      valuation.compare_prices.length > 0
    ">
    <thead>
      <tr>
        <th>Objektkategorie</th>
        <th>Baujahr</th>
        <th>Wohnfläche</th>
        <th>Energielabel und Typ</th>
        <th
          *ngIf="
            valuation.compare_prices[0].category !== 'ETW' &&
            valuation.compare_prices[0].category !== 'CONDOMINIUM'
          ">
          Grundstücksfläche
        </th>
        <th *ngIf="!print">Ausstattungsstandard</th>
        <th *ngIf="print">Ausrüstung</th>

        <th>Datum</th>
        <th>Entfernung</th>
        <th>Preis m²</th>
        <th>Vergleichbarkeit</th>
        <th style="width: 50px"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let compare of getComparables(); let i = index"
        class="compare-row"
        [ngClass]="{ rent: !_activeDataService.saleMode || rentPrint }">
        <td
          class="del-row"
          *ngIf="deleteRow === i"
          colspan="9">
          <span class="del-row-inner">
            <span class="delete-pin-text-container">
              <span class="delete-pin-text">
                Dieses Vergleichsobjekt wird dauerhaft von der Karte und aus der
                Tabelle entfernt, der Vorgang kann nicht mehr rückgängig gemacht
                werden.
              </span>
            </span>
            <span class="delete-button-container">
              <span
                class="delete-pin-button"
                (click)="deleteRow = -1">
                Abbrechen
              </span>
              <span
                class="cancel-delete-pin-button"
                (click)="removeElement(compare)">
                Vergleichsobjekt löschen
              </span>
            </span>
          </span>
        </td>
        <ng-container *ngIf="deleteRow !== i">
          <td
            class="compare-cell"
            *ngIf="!compare.construction || compare.category === 'MFH'">
            {{ changeCat(compare.category) }}
          </td>
          <td
            class="compare-cell"
            *ngIf="compare.construction && compare.category !== 'MFH'">
            {{ getConstruction(compare.construction) }}
          </td>
          <td class="compare-cell">{{ compare.construction_year }}</td>
          <td class="compare-cell">{{ compare.living_area }} m²</td>
          <td class="compare-cell">
            <app-energy-info
              [energyCertificate]="compare.energy_certificate"
              [energyClass]="compare.energy_class"></app-energy-info>
          </td>
          <td
            class="compare-cell"
            *ngIf="
              compare.category !== 'ETW' && compare.category !== 'CONDOMINIUM'
            ">
            {{ compare.plot_area }} m²
          </td>
          <td class="compare-cell">
            {{ changeEquip(compare.equipment) | titlecase }}
          </td>
          <td class="compare-cell">{{ formatDate(compare.date) }}</td>
          <td class="compare-cell">ca. {{ compare.distance }}m</td>
          <td
            class="compare-cell"
            *ngIf="_activeDataService.saleMode && !rentPrint">
            {{ compare.value / compare.living_area | number : '1.0-0' }} €/m²
          </td>
          <td
            class="compare-cell"
            *ngIf="!_activeDataService.saleMode || rentPrint">
            {{ compare.value / compare.living_area | number : '1.2-2' }} €/m²
          </td>
          <td class="compare-cell">
            <app-similarity-rating
              [value]="compare.similarity"
              class="print"></app-similarity-rating>
          </td>
          <td
            class="compare-cell remove"
            (click)="removeRequest(i)"
            *ngIf="!_authService.readOnlyMode">
            <span
              class="spreng-icon-pin-delete"
              matTooltip="Vergleichsobjekt entfernen"></span>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
  <div
    class="legend"
    [ngClass]="{ print: print }">
    <div class="item-container">
      <div class="item-text item-header">Legende</div>
    </div>
    <div class="item-container">
      <div class="item-symbol">
        <img
          class="energy-type"
          alt="energy class"
          src="assets/icons/energy/ENERGY_CONSUMPTION.svg" />
      </div>
      <div class="item-text">- Endenergieverbrauch</div>
    </div>
    <div class="item-container">
      <div class="item-symbol">
        <img
          class="energy-type"
          alt="energy class"
          src="assets/icons/energy/ENERGY_DEMAND.svg" />
      </div>
      <div class="item-text">- Endenergiebedarf</div>
    </div>
    <div class="item-container">
      <div class="item-symbol">k.A.</div>
      <div class="item-text">- es sind keine Angaben vorhanden</div>
    </div>
  </div>
</div>

<div
  [ngClass]="containerClass()"
  *ngIf="valuation !== undefined && mobile">
  <div
    class="header"
    [ngClass]="{ rent: !_activeDataService.saleMode || rentPrint }"
    *ngIf="!print">
    <p *ngIf="_activeDataService.saleMode && !rentPrint">
      Vergleichsobjekte Kauf
    </p>
    <p *ngIf="!_activeDataService.saleMode || rentPrint">
      Vergleichsobjekte Miete
    </p>
  </div>

  <ng-container
    *ngIf="
      valuation.compare_prices !== undefined &&
      valuation.compare_prices.length > 0
    ">
    <div class="mobile-table-div-container">
      <table
        class="mobile-table-div"
        *ngFor="let compare of getComparables(); let i = index"
        [ngClass]="{ 'del-row-cond': deleteRow === i }">
        <ng-container *ngIf="deleteRow === i">
          <tr class="mobile-del-row">
            <td
              class="del-row"
              colspan="2">
              <span class="del-row-inner">
                <span class="delete-pin-text-container">
                  <span class="delete-pin-text">
                    Dieses Vergleichsobjekt wird dauerhaft von der Karte und aus
                    der Tabelle entfernt, der Vorgang kann nicht mehr rückgängig
                    gemacht werden.
                  </span>
                </span>
                <span class="delete-button-container">
                  <span
                    class="delete-pin-button"
                    (click)="deleteRow = -1">
                    Abbrechen
                  </span>
                  <span
                    class="cancel-delete-pin-button"
                    (click)="removeElement(compare)">
                    Vergleichsobjekt löschen
                  </span>
                </span>
              </span>
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="deleteRow !== i">
          <tr>
            <td>Objektkategorie</td>
            <td
              class="compare-cell"
              *ngIf="!compare.construction || compare.category === 'MFH'">
              {{ changeCat(compare.category) }}
            </td>
            <td
              class="compare-cell"
              *ngIf="compare.construction && compare.category !== 'MFH'">
              {{ getConstruction(compare.construction) }}
            </td>
          </tr>
          <tr>
            <td>Baujahr</td>
            <td class="compare-cell">{{ compare.construction_year }}</td>
          </tr>
          <tr>
            <td>Wohnfläche</td>
            <td class="compare-cell">{{ compare.living_area }} m²</td>
          </tr>
          <tr
            *ngIf="
              valuation.compare_prices[0].category !== 'ETW' &&
              valuation.compare_prices[0].category !== 'CONDOMINIUM'
            ">
            <td>Grundstücksfläche</td>
            <td
              class="compare-cell"
              *ngIf="
                compare.category !== 'ETW' && compare.category !== 'CONDOMINIUM'
              ">
              {{ compare.plot_area }} m²
            </td>
          </tr>
          <tr>
            <td *ngIf="!print">Ausstattungsstandard</td>
            <td *ngIf="print">Ausrüstung</td>
            <td class="compare-cell">
              {{ changeEquip(compare.equipment) | titlecase }}
            </td>
          </tr>
          <tr>
            <td>Datum</td>
            <td class="compare-cell">{{ formatDate(compare.date) }}</td>
          </tr>
          <tr>
            <td>Entfernung</td>
            <td class="compare-cell">ca. {{ compare.distance }}m</td>
          </tr>
          <tr>
            <td>Preis m²</td>
            <td
              class="compare-cell"
              *ngIf="_activeDataService.saleMode && !rentPrint">
              {{ compare.value / compare.living_area | number : '1.0-0' }} €/m²
            </td>
            <td
              class="compare-cell"
              *ngIf="!_activeDataService.saleMode || rentPrint">
              {{ compare.value / compare.living_area | number : '1.2-2' }} €/m²
            </td>
          </tr>
          <tr>
            <td>Vergleichbarkeit</td>
            <td class="compare-cell">
              <app-similarity-rating
                [value]="compare.similarity"
                class="sim"></app-similarity-rating>
            </td>
          </tr>
          <tr>
            <td
              class="compare-cell remove"
              (click)="removeRequest(i)"
              *ngIf="!_authService.readOnlyMode">
              <span
                class="spreng-icon-pin-delete"
                matTooltip="Vergleichsobjekt entfernen"></span>
            </td>
            <td class="compare-cell energy-info">
              <span class="energy-info-text">Energielabel und Typ</span>
              <app-energy-info
                [energyCertificate]="compare.energy_certificate"
                [energyClass]="compare.energy_class"></app-energy-info>
            </td>
          </tr>
        </ng-container>
      </table>
      <div class="energy-legend">
        <div class="item-container">
          <div class="item-text item-header">Legende</div>
        </div>
        <div class="item-container">
          <div class="item-symbol">
            <img
              class="energy-type"
              alt="energy class"
              src="assets/icons/energy/ENERGY_CONSUMPTION.svg" />
          </div>
          <div class="item-text">- Endenergieverbrauch</div>
        </div>
        <div class="item-container">
          <div class="item-symbol">
            <img
              class="energy-type"
              alt="energy class"
              src="assets/icons/energy/ENERGY_DEMAND.svg" />
          </div>
          <div class="item-text">- Endenergiebedarf</div>
        </div>
        <div class="item-container">
          <div class="item-symbol">k.A.</div>
          <div class="item-text">- es sind keine Angaben vorhanden</div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- <table class="compare-table" *ngIf="valuation.compare_prices !== undefined && valuation.compare_prices.length > 0">
    <thead>
      <tr>
        <th>Objektkategorie</th>
        <th>Baujahr</th>
        <th>Wohnfläche</th>
        <th
          *ngIf="valuation.compare_prices[0].category != 'ETW' && valuation.compare_prices[0].category != 'CONDOMINIUM'">
          Grundstücksfläche</th>
        <th>Ausstattungsstandard</th>
        <th>Datum</th>
        <th>Entfernung</th>
        <th>Preis m²</th>
        <th>Vergleichbarkeit</th>
        <th style="width:50px"></th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let compare of getComparables(); let i=index;" class="compare-row"
        [ngClass]="{'rent':!_activeDataService.saleMode}">
        <td class="del-row" *ngIf="deleteRow == i" colspan="9">
          <span class="del-row-inner">
            <span class="delete-pin-text-container">
              <span class="delete-pin-text">
                Dieses Vergleichsobjekt wird dauerhaft von der Karte und aus der Tabelle entfernt, der Vorgang kann
                nicht mehr rückgängig gemacht werden.
              </span>
            </span>
            <span class="delete-button-container">
              <span class="delete-pin-button" (click)="deleteRow = -1">Abbrechen</span>
              <span class="cancel-delete-pin-button" (click)="removeElement(compare)">Vergleichsobjekt löschen</span>
            </span>
          </span>
        </td>
        <ng-container *ngIf="deleteRow !== i">
          <td class="compare-cell" *ngIf="compare.construction === '' || compare.category === 'MFH'">
            {{changeCat(compare.category)}}</td>
          <td class="compare-cell" *ngIf="compare.construction !== '' && compare.category !== 'MFH'">
            {{compare.construction}}</td>
          <td class="compare-cell">{{compare.construction_year}}</td>
          <td class="compare-cell">{{compare.living_area}} m²</td>
          <td class="compare-cell" *ngIf="compare.category != 'ETW' && compare.category != 'CONDOMINIUM'">
            {{compare.plot_area}} m²</td>
          <td class="compare-cell">{{changeEquip(compare.equipment) |titlecase}}</td>
          <td class="compare-cell">{{formatDate(compare.date)}}</td>
          <td class="compare-cell">ca. {{compare.distance}}m</td>
          <td class="compare-cell" *ngIf="_activeDataService.saleMode">
            {{compare.value/compare.living_area | number: '1.0-0'}} €/m²</td>
          <td class="compare-cell" *ngIf="!_activeDataService.saleMode">
            {{compare.value/compare.living_area | number: '1.2-2'}} €/m²</td>
          <td class="compare-cell">
            <app-similarity-rating [value]="compare.similarity" [sale]="_activeDataService.saleMode" class="sim">
            </app-similarity-rating>
          </td>
          <td class="compare-cell remove" (click)="removeRequest(i)" *ngIf="!_authService.readOnlyMode">
            <span class="spreng-icon-pin-delete" matTooltip="Vergleichsobjekt entfernen"></span>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table> -->
</div>
