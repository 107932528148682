import { Category } from '../enums/category.enum';
import { Address } from './address.model';
import { Coordinates } from './coordinates.model';
import { ReportRequest } from './report-request.model';

export class Reports {
  reports_count: number;
  reports: Report[];
}

export class Report {
  system_id: string;
  report_id: string;
  urls: ReportUrlData;
  user_id: string;
  user_name: string;
  dates: ReportDates;
  version: string;
  category: Category;
  address: Address;
  coordinates: Coordinates;
  request: ReportRequest;
  config: ReportConfiguration;
  value_overwritten: boolean;
}

export class ReportConfigurationUpdateRequest {
  config: ReportConfiguration;
}

export class ReportConfiguration {
  show_trend = true;
  show_compare_prices = true;
  show_exact_price = true;
  show_expanded_view = true;
  show_expert_view = true;
  show_valuation = true;
  show_rent = true;
  show_sociodemographic = true;
  show_equipment_overview = true;
}

export class ReportUrlData {
  edit: string;
  view: string;
}

export class ReportDates {
  created: string;
  updated: string;
}
