import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  HostListener,
} from '@angular/core';
import { ReportRequest } from 'src/app/core/models/report-request.model';
import { Category } from 'src/app/core/enums/category.enum';
import { ReportService } from 'src/app/core/services/report.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { ReportPostResponse } from 'src/app/core/models/report-post-response.model';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { AppHttpErrorResponse } from 'src/app/core/services/error.service';

@Component({
  selector: 'app-valuation-request',
  templateUrl: './valuation-request.component.html',
  styleUrls: ['./valuation-request.component.scss'],
  animations: [
    trigger('openCloseValReqMain', [
      state(
        'open',
        style({
          opacity: '1',
        })
      ),
      state(
        'closed',
        style({
          opacity: '0',
          height: '0px',
        })
      ),
      transition('open => closed', [
        animate('500ms', style({ opacity: 0 })),
        animate('0ms 500ms', style({ height: '0px' })),
      ]),
      transition('closed => open', [
        animate('0ms', style({ height: 'unset' })),
        animate('500ms', style({ opacity: 1 })),
      ]),
    ]),
    trigger('openCloseValReqLocked', [
      state(
        'open',
        style({
          opacity: '1',
        })
      ),
      state(
        'closed',
        style({
          opacity: '0',
          height: '0px',
        })
      ),
      transition('open => closed', [
        animate('500ms 500ms', style({ opacity: 0 })),
        animate('0ms 1000ms', style({ height: '0px' })),
      ]),
      transition('closed => open', [
        animate('0ms 1000ms', style({ height: 'unset' })),
        animate('500ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ValuationRequestComponent implements OnInit {
  @Input() locked = false;
  @Input() location = '';
  @Input() baseReportRequest: ReportRequest;
  @Output() reportId = new EventEmitter<string>();
  @Output() itemSelectedEmitter = new EventEmitter();
  valReqMainIsOpen = false;
  public selected = -1;
  public isDisabled = true;
  public showLoader;
  mobile = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= 1080) {
      this.mobile = true;
      this._headerService.showHeader = true;
    }
  }

  toggleValReqMain() {
    if (!this.valReqMainIsOpen) {
      this.valReqMainIsOpen = !this.valReqMainIsOpen;
    }
    if (this.valReqMainIsOpen) {
      this.valReqMainIsOpen = !this.valReqMainIsOpen;
      setTimeout(() => {
        this.valReqMainIsOpen = !this.valReqMainIsOpen;
      }, 500);
    }
  }

  constructor(
    private _reportService: ReportService,
    public _authenticationService: AuthenticationService,
    private _headerService: HeaderService
  ) {}

  ngOnInit() {
    this.showLoader = false;
    if (this._authenticationService.authenticatedUser !== null) {
      this.baseReportRequest.user_id =
        this._authenticationService.authenticatedUser.user_id;
      this.baseReportRequest.system_id =
        this._authenticationService.authenticatedUser.system_id;
    }
    if (window.innerWidth <= 1080) {
      this.mobile = true;
      this._headerService.showHeader = true;
    }
  }

  isSelected(index: number) {
    return index === this.selected;
  }

  setSelected(index: number, allow = true) {
    if (!allow) {
      return;
    }
    this.itemSelectedEmitter.emit();
    this.selected = index;
    this.isDisabled = false;
  }

  confirmValuation() {
    if (this.selected === -1) {
      return;
    } else if (this.selected === 0) {
      this.baseReportRequest.category = Category.EFH;
    } else if (this.selected === 1) {
      this.baseReportRequest.category = Category.GRD;
    } else if (this.selected === 2) {
      this.baseReportRequest.category = Category.ETW;
    } else if (this.selected === 3) {
      this.baseReportRequest.category = Category.MFH;
    }

    if (this.baseReportRequest.address_geocoded.house_number === undefined) {
      this.baseReportRequest.address_geocoded.house_number = ' ';
    }
    this.showLoader = true;

    this._reportService.post(this.baseReportRequest).subscribe(
      (data: ReportPostResponse) => {
        this.showLoader = false;
        this._authenticationService.authenticatedUser.product.monthly_count =
          data.user.product.monthly_count;
        this._authenticationService.authenticatedUser.product.monthly_limit =
          data.user.product.monthly_limit;
        this._authenticationService.authenticatedUser.product.end_date =
          data.user.product.end_date;
        this.reportId.emit(data.report_id);
      },
      (error: AppHttpErrorResponse) => {
        this.showLoader = false;
        this.reportId.emit();
      }
    );
  }

  getType() {
    if (this.selected === 0) {
      return 'Einfamilienhaus';
    } else if (this.selected === 1) {
      return 'Grundstück';
    } else if (this.selected === 2) {
      return 'Eigentumswohnung';
    } else if (this.selected === 3) {
      return 'Mehrfamilienhaus';
    }
    return 'Objektart auswählen';
  }

  checkNation() {
    if (
      this.baseReportRequest !== null &&
      this.baseReportRequest !== undefined &&
      this.baseReportRequest.address_geocoded !== null &&
      this.baseReportRequest.address_geocoded !== undefined &&
      this.baseReportRequest.address_geocoded.nation === 'AT'
    ) {
      return false;
    }
    return true;
  }
}
