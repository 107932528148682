import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
  HostListener,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Report } from 'src/app/core/models/reports.model';
import { Image } from 'src/app/core/models/images.model';
import { ReportService } from 'src/app/core/services/report.service';
import { ActiveDataService } from 'src/app/core/services/active-data.service';
import { Valuation } from 'src/app/core/models/valuation.model';
import { RentResponse } from 'src/app/core/models/rent-response.model';
import { EquipmentGetResponse } from 'src/app/core/models/equipment-get-response.model';
import { IpiGetResponse } from 'src/app/core/models/ipi-get-response.model';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { DetailsComponent } from 'src/app/shared/details/details.component';
import {
  animate,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-extended',
  templateUrl: './extended.component.html',
  styleUrls: ['./extended.component.scss'],
  animations: [
    trigger('fadeVal', [
      transition(':leave', [
        query(':self', [animate('0.25s', style({ opacity: 0 }))]),
      ]),
      transition(':enter', [
        query(':self', [
          style({ opacity: 0 }),
          animate('0.25s 0.15s', style({ opacity: 1 })),
        ]),
      ]),
    ]),
  ],
})
export class ExtendedComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() reportId = '';
  @Input() reload = false;
  @Output() closeExtendedEmitter = new EventEmitter();
  @Output() expertClicked = new EventEmitter();
  @Output() currentValuationEmitter = new EventEmitter<Valuation>();
  @Output() valuationDataOut: any;
  @Output() reportDataOut: any;
  @Output() equipmentDataOut: any;
  @Output() ipiDataOut: any;

  @Input() expertOpen = false;

  @ViewChild(DetailsComponent) details: DetailsComponent;
  private firstload = true;
  public reloadDetails = false;
  public showLoader = false;
  public extendedResOk = true;

  // data variables
  public reportImages: Image[] = undefined;
  public imageError = false;

  public reportData: Report = undefined;
  public reportError = false;
  private reportSubscription = new Subscription();

  public valuationData: Valuation = undefined;
  public valuationError = false;
  private valuationSubscription = new Subscription();

  public ipiData: IpiGetResponse = undefined;
  public ipiError = false;
  private ipiSubscription = new Subscription();

  public equipmentData: EquipmentGetResponse = undefined;
  public equipmentError = false;
  private equipmentSubscription = new Subscription();

  public showTrend = true;
  public showEquip = true;

  constructor(
    private _reportService: ReportService,
    public _activeDataService: ActiveDataService,
    public _authService: AuthenticationService
  ) {
    _activeDataService.modeEmitter.subscribe(() => {
      //this.loadVariableData();
    });
  }

  ngOnInit() {
    this.loadCoreReport();
    this.loadVariableData();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerHeight < 720) {
      this.extendedResOk = false;
    } else {
      this.extendedResOk = true;
    }
  }

  ngAfterViewInit() {
    if (window.innerHeight < 720) {
      this.extendedResOk = false;
    } else {
      this.extendedResOk = true;
    }
  }

  loadVariableData(reload = false) {
    this.loadValuationData(reload);
    this.loadIpiData(reload);
    this.loadEquipmentData(reload);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.reportId !== null &&
        changes.reportId !== undefined &&
        !this.firstload) ||
      this.reload
    ) {
      this.firstload = false;
      this.loadCoreReport();
      this.loadVariableData();
    }
  }

  private loadCoreReport(reload = false) {
    this.reportSubscription = this._activeDataService.reportEmitter.subscribe(
      (data: Report) => {
        if (data === null) {
          this.reportError = true;
        } else {
          this.reportData = data;
          this.reportDataOut = data;
          this.reloadDetails = !this.reloadDetails;
        }
        this.reportSubscription.unsubscribe();
      },
      (error: any) => {
        this.reportError = true;
        this.reportSubscription.unsubscribe();
        throw error;
      }
    );
    this._activeDataService.getReportData(reload);
  }

  loadValuationData(reload = false) {
    if (this._activeDataService.saleMode) {
      this.valuationSubscription =
        this._activeDataService.valuationEmitter.subscribe(
          (data: Valuation) => {
            if (data === null) {
              this.valuationError = true;
            } else {
              this.valuationData = data;
              this.valuationDataOut = data;
              this.currentValuationEmitter.emit(data);
            }
            this.valuationSubscription.unsubscribe();
          },
          (error: any) => {
            this.reportError = true;
            this.valuationSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getValuationData(reload);
    } else {
      this._activeDataService.getValuationData();
      this.valuationSubscription =
        this._activeDataService.rentEmitter.subscribe(
          (data: RentResponse) => {
            if (data === null) {
              this.valuationError = true;
            } else {
              this.valuationData = data;
              this.valuationDataOut = data;
              this.currentValuationEmitter.emit(data);
            }
            this.valuationSubscription.unsubscribe();
          },
          (error: any) => {
            this.valuationError = true;
            this.valuationSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getRentData(reload);
    }
  }

  loadIpiData(reload = false) {
    if (this._activeDataService.saleMode) {
      this.ipiSubscription =
        this._activeDataService.ipiValuationEmitter.subscribe(
          (data: IpiGetResponse) => {
            if (data === null) {
              this.ipiError = true;
            } else {
              this.ipiData = data;
              this.ipiDataOut = data;
            }
            this.ipiSubscription.unsubscribe();
          },
          (error: any) => {
            this.ipiError = true;
            this.ipiSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getValuationIpiData(reload);
    } else {
      this.ipiSubscription = this._activeDataService.ipiRentEmitter.subscribe(
        (data: IpiGetResponse) => {
          if (data === null) {
            this.ipiError = true;
          } else {
            this.ipiData = data;
            this.ipiDataOut = data;
          }
          this.ipiSubscription.unsubscribe();
        },
        (error: any) => {
          this.ipiError = true;
          this.ipiSubscription.unsubscribe();
          throw error;
        }
      );
      this._activeDataService.getRentIpiData(reload);
    }
  }

  loadEquipmentData(reload = false) {
    if (this._activeDataService.saleMode) {
      this.equipmentSubscription =
        this._activeDataService.equipmentValuationEmitter.subscribe(
          (data: EquipmentGetResponse) => {
            if (data === null) {
              this.equipmentError = true;
            } else {
              this.equipmentData = data;
              this.equipmentDataOut = data;
            }
            this.equipmentSubscription.unsubscribe();
          },
          (error: any) => {
            this.equipmentError = true;
            this.equipmentSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getValuationEquipment(reload);
    } else {
      this.equipmentSubscription =
        this._activeDataService.equipmentRentEmitter.subscribe(
          (data: EquipmentGetResponse) => {
            if (data === null) {
              this.equipmentError = true;
            } else {
              this.equipmentData = data;
              this.equipmentDataOut = data;
            }
            this.equipmentSubscription.unsubscribe();
          },
          (error: any) => {
            this.equipmentError = true;
            this.equipmentSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getRentEquipment(reload);
    }
  }

  closeExpanded() {
    if (
      !this.showLoader &&
      this._activeDataService.pendingRequestsInfo() === ''
    ) {
      this.closeExtendedEmitter.emit();
    }
  }

  modeChange() {
    if (
      !this.showLoader &&
      this._activeDataService.pendingRequestsInfo() === ''
    ) {
      this._activeDataService.saleMode = !this._activeDataService.saleMode;
      this.loadVariableData();
      this._activeDataService.modeEmitter.emit();
    }
  }

  updateValuation(report: Report) {
    this._activeDataService.clearAll();
    this.reloadDetails = false;
    this.showLoader = true;
    report.request.config = report.config;
    this._reportService.put(report.request, report.report_id).subscribe(
      () => {
        this.loadCoreReport();
        this.loadVariableData();
        this.showLoader = false;
        this.reloadDetails = true;
      },
      (error: any) => {
        this.showLoader = false;
        throw error;
      }
    );
  }

  showExpert() {
    if (
      !this.showLoader &&
      this._activeDataService.pendingRequestsInfo() === ''
    ) {
      this.expertClicked.emit();
    }
  }

  checkDetailsButton() {
    if (this.details === undefined || this.details === null) {
      return true;
    }
    return !this.details.editMode || !this.details.checkIsFormValid();
  }

  requestValidation() {
    this.details.requestValidation();
  }

  shouldShowTrend() {
    if (
      this._activeDataService.reportData === null ||
      this._activeDataService.reportData === undefined ||
      this._activeDataService.reportData.config === null ||
      this._activeDataService.reportData.config === undefined
    ) {
      return this.showTrend;
    }

    this.showTrend = this._activeDataService.reportData.config.show_trend;
    return this.showTrend;
  }

  shouldShowEquip() {
    if (
      this._activeDataService.reportData === null ||
      this._activeDataService.reportData === undefined ||
      this._activeDataService.reportData.config === null ||
      this._activeDataService.reportData.config === undefined
    ) {
      return this.showEquip;
    }

    this.showEquip =
      this._activeDataService.reportData.config.show_equipment_overview;
    return this.showEquip;
  }
}
