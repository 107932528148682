import {
  Component,
  OnInit,
  Input,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  animate,
  style,
  transition,
  trigger,
  state,
} from '@angular/animations';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.css'],
  animations: [
    trigger('showHideMobileLegend', [
      state(
        'closed',
        style({
          opacity: '0',
          width: '135px',
          height: '189px',
          'background-color': '#3B3C3E',
        })
      ),
      state(
        'open',
        style({
          opacity: '1',
        })
      ),
      transition('open => closed', [animate('600ms')]),
      transition('closed => open', [animate('600ms')]),
    ]),

    trigger('showHideMobileLegendBar', [
      state(
        'hide',
        style({
          opacity: '0',
          width: '15px',
          height: '116px',
          'z-index': 1,
        })
      ),
      state(
        'show',
        style({
          height: '48px',
          opacity: '1',
        })
      ),
      transition('show => hide', [
        animate(
          '100ms',
          style({
            'border-color': 'transparent',
          })
        ),
        animate('500ms'),
      ]),
      transition('hide => show', [animate('500ms')]),
    ]),
  ],
})
export class LegendComponent implements OnInit {
  public isOpen = false;
  @Input() prices: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  @Input() location = '';
  @Input() mobileLocation = '';
  @Input() desktopClose = false;
  @Input() altLegend = false;
  @Output() legendCloseEmitter = new EventEmitter();
  isMobile;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.isOpen && window.innerWidth > 1080) {
      this.hideLegend();
    }

    if (window.innerWidth < 1080) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  constructor() {}

  ngOnInit() {
    if (window.innerWidth < 1080) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  getLocation() {}

  showLegend() {
    if (!this.isMobile) {
      document.getElementById('legend').classList.remove('legend');
      document.getElementById('legend').classList.add('legend-mobile');
      document
        .getElementById('container')
        .classList.remove('component-container');
      document
        .getElementById('container')
        .classList.add('component-container-mobile');
      document.getElementById('header').classList.remove('hide-for-mobile');
      if (document.getElementById('legend-locationM') !== null) {
        document
          .getElementById('legend-locationM')
          .classList.remove('hide-for-mobile');
        document
          .getElementById('legend-locationM')
          .classList.remove('hide-for-tablet');
      }
      document.getElementById('key').classList.remove('hide-for-mobile');
      document.getElementById('header').classList.remove('hide-for-tablet');
      if (document.getElementById('legend-location') !== null) {
        document
          .getElementById('legend-location')
          .classList.remove('hide-for-tablet');
      }
      document.getElementById('key').classList.remove('hide-for-tablet');
    }
    if (this.isMobile) {
      document.getElementById('mobile-legend').classList.remove('legend');
      document.getElementById('mobile-legend').classList.add('legend-mobile');
      document
        .getElementById('mobile-container')
        .classList.remove('component-container');
      document
        .getElementById('mobile-container')
        .classList.add('component-container-mobile');
      document
        .getElementById('mobile-header')
        .classList.remove('hide-for-mobile');
      if (document.getElementById('mobile-legend-locationM') !== null) {
        document
          .getElementById('mobile-legend-locationM')
          .classList.remove('hide-for-mobile');
        document
          .getElementById('mobile-legend-locationM')
          .classList.remove('hide-for-tablet');
      }
      document.getElementById('mobile-key').classList.remove('hide-for-mobile');
      document
        .getElementById('mobile-header')
        .classList.remove('hide-for-tablet');
      if (document.getElementById('mobile-legend-location') !== null) {
        document
          .getElementById('mobile-legend-location')
          .classList.remove('hide-for-tablet');
      }
      document.getElementById('mobile-key').classList.remove('hide-for-tablet');
    }
    this.isOpen = true;
  }

  hideLegend() {
    if (this.desktopClose) {
      this.legendCloseEmitter.emit();
      return;
    }
    if (!this.isMobile) {
      document.getElementById('legend').classList.add('legend');
      document.getElementById('legend').classList.remove('legend-mobile');
      document.getElementById('container').classList.add('component-container');
      document
        .getElementById('container')
        .classList.remove('component-container-mobile');
      document.getElementById('header').classList.add('hide-for-mobile');
      if (document.getElementById('legend-locationM') !== null) {
        document
          .getElementById('legend-locationM')
          .classList.add('hide-for-tablet');
        document
          .getElementById('legend-locationM')
          .classList.add('hide-for-mobile');
      }
      if (document.getElementById('legend-location') !== null) {
        document
          .getElementById('legend-location')
          .classList.add('hide-for-tablet');
      }
      document.getElementById('key').classList.add('hide-for-mobile');
      document.getElementById('header').classList.add('hide-for-tablet');
      document.getElementById('key').classList.add('hide-for-tablet');
    }

    if (this.isMobile) {
      document.getElementById('mobile-legend').classList.add('legend');
      document
        .getElementById('mobile-legend')
        .classList.remove('legend-mobile');
      document
        .getElementById('mobile-container')
        .classList.add('component-container');
      document
        .getElementById('mobile-container')
        .classList.remove('component-container-mobile');
      document.getElementById('mobile-header').classList.add('hide-for-mobile');
      if (document.getElementById('mobile-legend-locationM') !== null) {
        document
          .getElementById('mobile-legend-locationM')
          .classList.add('hide-for-tablet');
        document
          .getElementById('mobile-legend-locationM')
          .classList.add('hide-for-mobile');
      }
      if (document.getElementById('mobile-legend-location') !== null) {
        document
          .getElementById('mobile-legend-location')
          .classList.add('hide-for-tablet');
      }
      document.getElementById('mobile-key').classList.add('hide-for-mobile');
      document.getElementById('mobile-header').classList.add('hide-for-tablet');
      document.getElementById('mobile-key').classList.add('hide-for-tablet');
    }
    this.isOpen = false;
  }
}
