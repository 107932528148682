import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class MatPaginatorIntlDe extends MatPaginatorIntl {
  nextPageLabel = 'nächste Seite';
  previousPageLabel = 'vorhergehende Seite';
  lastPageLabel = 'letzte Seite';
  firstPageLabel = 'erste Seite';
}
