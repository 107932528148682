<div
  class="info-container"
  *ngIf="distance !== undefined">
  <div class="dispFlex">
    <div class="infoIconBoxDistance infoBoxDistance">
      <span><img src="../../../assets/icons/distance.svg" /></span>
    </div>
    <div class="infoTextBoxDetail infoBoxDistance">
      <span
        class="distance-value"
        matTooltip=" Entfernung zum Objekt">
        {{ distance / 1000 | number : '1.0-2' }} km
      </span>
      <div
        class="distance-text"
        style="width: 100%; text-transform: uppercase">
        Entfernung
      </div>
    </div>
  </div>
</div>
