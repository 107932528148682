<div
  class="chart-container"
  *ngIf="inputData !== undefined">
  <div
    class="inner-chart-container"
    [ngClass]="{ 'right-chart': rightChart }">
    <div class="chart-header">{{ title }}</div>
    <div
      class="chart-content"
      *ngIf="chartHasData()">
      <div
        class="legend-top-mobile-container show-on-mobile"
        *ngIf="!print">
        <div class="legend-top">
          <div *ngIf="type === 1">
            <span>{{ this.topV | number : '1.0-2' }}%</span>
            {{ this.topL }}
          </div>
          <div *ngIf="type === 2">
            <span>{{ this.topV | number : '1.0-2' }}%</span>
            {{ this.topL }} Jahre
          </div>
          <div *ngIf="type === 3">
            <span>{{ this.topV | number : '1.0-2' }}%</span>
            {{ this.topL }}
          </div>
          <div *ngIf="type === 4">
            <span>{{ this.topV | number : '1.0-2' }}%</span>
            {{ this.topL }}
          </div>
        </div>
      </div>

      <div class="inner-chart">
        <div class="chart">
          <div
            class="pie-chart"
            *ngIf="type === 1">
            <canvas
              baseChart
              width="1"
              height="1"
              [data]="chartData"
              [legend]="chartLegend"
              [options]="chartOptions"
              [type]="chartType"></canvas>
          </div>
          <div
            class="bar-chart"
            *ngIf="type === 2">
            <canvas
              baseChart
              [data]="chartData"
              [legend]="chartLegend"
              [options]="chartOptions"
              [type]="chartType"></canvas>
          </div>
          <div
            class="pie-chart"
            *ngIf="type === 3">
            <canvas
              baseChart
              width="1"
              height="1"
              [data]="chartData"
              [legend]="chartLegend"
              [options]="chartOptions"
              [type]="chartType"></canvas>
          </div>
          <div
            class="pie-chart"
            *ngIf="type === 4">
            <canvas
              baseChart
              width="1"
              height="1"
              [data]="chartData"
              [legend]="chartLegend"
              [options]="chartOptions"
              [type]="chartType"></canvas>
          </div>
        </div>
      </div>
      <div class="chart-legend">
        <div
          class="legend-top hide-on-mobile"
          *ngIf="!print">
          <div *ngIf="type === 1">
            <span>{{ this.topV | number : '1.0-2' }}%</span>
            {{ this.topL }}
          </div>
          <div *ngIf="type === 2">
            <span>{{ this.topV | number : '1.0-2' }}%</span>
            {{ this.topL }} Jahre
          </div>
          <div *ngIf="type === 3">
            <span>{{ this.topV | number : '1.0-2' }}%</span>
            {{ this.topL }}
          </div>
          <div *ngIf="type === 4">
            <span>{{ this.topV | number : '1.0-2' }}%</span>
            {{ this.topL }}
          </div>
        </div>
        <div class="legend-data">
          <div *ngIf="type !== 2">
            <table class="legend-data-table">
              <tr>
                <td
                  class="chart-color-4"
                  *ngIf="type === 1">
                  <span>
                    {{ this.inputData.pieData[4] | number : '1.0-2' }}%
                  </span>
                </td>
                <td
                  class="chart-color-0"
                  *ngIf="type !== 1">
                  <span>
                    {{ this.inputData.pieData[4] | number : '1.0-2' }}%
                  </span>
                </td>
                <td *ngIf="type === 4">
                  <span class="pie-icon chart-color-4"></span>
                </td>
                <td *ngIf="type === 1">
                  <span class="pie-icon chart-color-0"></span>
                </td>
                <td *ngIf="type === 3">
                  <span>
                    <img src="{{ getPeopleIcon(this.inputData.labels[4]) }}" />
                  </span>
                </td>
                <td *ngIf="type === 4">
                  <span>
                    <img src="{{ getHousingIcon(this.inputData.labels[4]) }}" />
                  </span>
                </td>
                <td>
                  <span>{{ getLabel(this.inputData.labels[4]) }}</span>
                </td>
              </tr>
              <tr>
                <td
                  class="chart-color-3"
                  *ngIf="type === 1">
                  <span>
                    {{ this.inputData.pieData[3] | number : '1.0-2' }}%
                  </span>
                </td>
                <td
                  class="chart-color-1"
                  *ngIf="type !== 1">
                  <span>
                    {{ this.inputData.pieData[3] | number : '1.0-2' }}%
                  </span>
                </td>
                <td *ngIf="type === 4">
                  <span class="pie-icon chart-color-3"></span>
                </td>
                <td *ngIf="type === 1">
                  <span class="pie-icon chart-color-1"></span>
                </td>
                <td *ngIf="type === 3">
                  <span>
                    <img src="{{ getPeopleIcon(this.inputData.labels[3]) }}" />
                  </span>
                </td>
                <td *ngIf="type === 4">
                  <span>
                    <img src="{{ getHousingIcon(this.inputData.labels[3]) }}" />
                  </span>
                </td>
                <td>
                  <span>{{ getLabel(this.inputData.labels[3]) }}</span>
                </td>
              </tr>
              <tr>
                <td class="chart-color-2">
                  <span>
                    {{ this.inputData.pieData[2] | number : '1.0-2' }}%
                  </span>
                </td>
                <td *ngIf="type === 1 || type === 4">
                  <span class="pie-icon chart-color-2"></span>
                </td>
                <td *ngIf="type === 3">
                  <span>
                    <img src="{{ getPeopleIcon(this.inputData.labels[2]) }}" />
                  </span>
                </td>
                <td *ngIf="type === 4">
                  <span>
                    <img src="{{ getHousingIcon(this.inputData.labels[2]) }}" />
                  </span>
                </td>
                <td>
                  <span>{{ getLabel(this.inputData.labels[2]) }}</span>
                </td>
              </tr>
              <tr>
                <td
                  class="chart-color-1"
                  *ngIf="type === 1">
                  <span>
                    {{ this.inputData.pieData[1] | number : '1.0-2' }}%
                  </span>
                </td>
                <td
                  class="chart-color-3"
                  *ngIf="type !== 1">
                  <span>
                    {{ this.inputData.pieData[1] | number : '1.0-2' }}%
                  </span>
                </td>
                <td *ngIf="type === 4">
                  <span class="pie-icon chart-color-1"></span>
                </td>
                <td *ngIf="type === 1">
                  <span class="pie-icon chart-color-3"></span>
                </td>
                <td *ngIf="type === 3">
                  <span>
                    <img src="{{ getPeopleIcon(this.inputData.labels[1]) }}" />
                  </span>
                </td>
                <td *ngIf="type === 4">
                  <span>
                    <img src="{{ getHousingIcon(this.inputData.labels[1]) }}" />
                  </span>
                </td>
                <td>
                  <span>{{ getLabel(this.inputData.labels[1]) }}</span>
                </td>
              </tr>
              <tr>
                <td
                  class="chart-color-0"
                  *ngIf="type === 1">
                  <span>
                    {{ this.inputData.pieData[0] | number : '1.0-2' }}%
                  </span>
                </td>
                <td
                  class="chart-color-4"
                  *ngIf="type !== 1">
                  <span>
                    {{ this.inputData.pieData[0] | number : '1.0-2' }}%
                  </span>
                </td>
                <td *ngIf="type === 4">
                  <span class="pie-icon chart-color-0"></span>
                </td>
                <td *ngIf="type === 1">
                  <span class="pie-icon chart-color-4"></span>
                </td>
                <td *ngIf="type === 3">
                  <span>
                    <img src="{{ getPeopleIcon(this.inputData.labels[0]) }}" />
                  </span>
                </td>
                <td *ngIf="type === 4">
                  <span>
                    <img src="{{ getHousingIcon(this.inputData.labels[0]) }}" />
                  </span>
                </td>
                <td>
                  <span>{{ getLabel(this.inputData.labels[0]) }}</span>
                </td>
              </tr>
            </table>
          </div>
          <div *ngIf="type === 2">
            <table class="legend-data-table">
              <tr>
                <td class="chart-color-4">
                  <span>
                    {{
                      this.inputData.barData[4].data[0].toString()
                        | number : '1.0-2'
                    }}%
                  </span>
                </td>
                <td><span class="bar-icon chart-color-4"></span></td>
                <td>
                  <span>{{ this.inputData.labels[4] }} Jahre</span>
                </td>
              </tr>
              <tr>
                <td class="chart-color-3">
                  <span>
                    {{
                      this.inputData.barData[3].data[0].toString()
                        | number : '1.0-2'
                    }}%
                  </span>
                </td>
                <td><span class="bar-icon chart-color-3"></span></td>
                <td>
                  <span>{{ this.inputData.labels[3] }} Jahre</span>
                </td>
              </tr>
              <tr>
                <td class="chart-color-2">
                  <span>
                    {{
                      this.inputData.barData[2].data[0].toString()
                        | number : '1.0-2'
                    }}%
                  </span>
                </td>
                <td><span class="bar-icon chart-color-2"></span></td>
                <td>
                  <span>{{ this.inputData.labels[2] }} Jahre</span>
                </td>
              </tr>
              <tr>
                <td class="chart-color-1">
                  <span>
                    {{
                      this.inputData.barData[1].data[0].toString()
                        | number : '1.0-2'
                    }}%
                  </span>
                </td>
                <td><span class="bar-icon chart-color-1"></span></td>
                <td>
                  <span>{{ this.inputData.labels[1] }} Jahre</span>
                </td>
              </tr>
              <tr>
                <td class="chart-color-0">
                  <span>
                    {{
                      this.inputData.barData[0].data[0].toString()
                        | number : '1.0-2'
                    }}%
                  </span>
                </td>
                <td><span class="bar-icon chart-color-0"></span></td>
                <td>
                  <span>{{ this.inputData.labels[0] }} Jahre</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="chart-content no-data"
      *ngIf="!chartHasData()">
      Für diesen Ort bzw. Ortsteil liegen noch keine sozio-demografischen Daten
      vor.
    </div>
  </div>
</div>
