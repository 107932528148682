import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { PriceRangeRequest } from '../models/price-range-request.model';
import { PriceRangeResponse } from '../models/price-range-response.model';

@Injectable()
export class PriceRangeService {
  constructor(private _httpClient: HttpClient) {}
  private url: string = environment.apiUrl + '/price_range';

  post(request: PriceRangeRequest): Observable<PriceRangeResponse> {
    return this._httpClient.post<PriceRangeResponse>(this.url, request);
  }
}
