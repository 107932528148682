import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.css'],
})
export class SwitchComponent {
  @Input() value = false;
  @Output() switchEventEmitter = new EventEmitter<boolean>();

  constructor() {}

  valueChange(event: any) {
    this.value = !this.value;
    this.switchEventEmitter.emit(this.value);
  }

  saleActive() {
    if (!this.value) {
      return { 'margin-right': '7px', color: 'var(--app-sale-switch-color)' };
    }
    return { 'margin-right': '7px' };
  }

  rentActive() {
    if (this.value) {
      return { 'margin-left': '7px', color: 'var(--app-rent-switch-color)' };
    }
    return { 'margin-left': '7px' };
  }
}
