import { Injectable } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import { Print } from '../models/print.model';
import { ReportConfiguration } from '../models/reports.model';
import { AuthenticationService } from './authentication.service';
import { ValuationService } from './valuation.service';
import { UserDataService } from './user-data.service';
import { ReportService } from './report.service';
import { RentService } from './rent.service';

@Injectable()
export class PrintService {
  constructor(
    private _authService: AuthenticationService,
    private _userDataService: UserDataService,
    private _reportService: ReportService,
    private _valuationService: ValuationService,
    private _rentService: RentService
  ) {}

  loadPrintData(reportId: string): Observable<Print> {
    let printData: Print = new Print();
    let sources: Observable<any>[] = [];

    sources = sources.concat([
      this._reportService.get(reportId),
      this._valuationService.get(reportId),
      this._rentService.get(reportId),
      this._valuationService.getIpi(reportId),
      this._rentService.getIpi(reportId),
      this._valuationService.getEquipment(reportId),
      this._rentService.getEquipment(reportId),
      this._valuationService.getRegion(reportId),
    ]);

    if (this._authService.authenticatedUser !== null) {
      printData.userData = this._authService.authenticatedUser;
    } else {
      sources.push(this._userDataService.getReportUser(reportId));
    }

    const printDataObservable = forkJoin(sources).pipe(
      map(
        ([
          reportData,
          valuationData,
          rentData,
          valuationIpiData,
          rentIpiData,
          valuationEquipment,
          rentEquipment,
          valuationRegionData,
          userData,
        ]) => {
          printData.reportData = reportData;
          if (!reportData.config) {
            printData.reportData.config = new ReportConfiguration();
          }
          printData.valuationData = valuationData;
          printData.rentData = rentData;
          printData.valuationIpiData = valuationIpiData;
          printData.rentIpiData = rentIpiData;
          printData.valuationEquipmentData = valuationEquipment;
          printData.rentEquipmentData = rentEquipment;
          printData.valuationRegionData = valuationRegionData;
          printData.userData = userData || this._authService.authenticatedUser;
          return printData;
        }
      )
    );

    return printDataObservable;
  }
}
