<div
  [@detailFade]=""
  *ngIf="!editMode || _authService.readOnlyMode"
  class="infoIconBox flex"
  [ngClass]="{ 'print-iconBox': isPrintMode, 'comparable-icon': comparable }">
  <span
    class="spreng-icon-diamond icon32 star-icon"
    [ngClass]="{ 'mobile-icon': mobile }"></span>
</div>

<div
  [@detailFade]=""
  *ngIf="!editMode || _authService.readOnlyMode"
  class="infoTextBox infoBox"
  [ngClass]="{ printInfoText: isPrintMode, 'comparable-stars': comparable }">
  <div
    class="star-container detail-text"
    [ngClass]="{ 'print-star-container': isPrintMode }">
    <span
      [ngClass]="isFilled(0) ? 'spreng-icon-star1' : 'spreng-icon-star-outline'"
      class="icon16"></span>
    <span
      [ngClass]="isFilled(1) ? 'spreng-icon-star1' : 'spreng-icon-star-outline'"
      class="icon16"></span>
    <span
      [ngClass]="isFilled(2) ? 'spreng-icon-star1' : 'spreng-icon-star-outline'"
      class="icon16"></span>
    <span
      [ngClass]="isFilled(3) ? 'spreng-icon-star1' : 'spreng-icon-star-outline'"
      class="icon16"></span>
  </div>
  <div
    style="width: 100%"
    [ngClass]="{
      'expert-detail-text': isExpert,
      'detail-text-2': comparable,
      'print-detail-text': isPrintMode
    }">
    Ausstattungsstandard
  </div>
</div>

<div
  [@detailFade]=""
  *ngIf="editMode && !_authService.readOnlyMode"
  class="infoBox">
  <mat-form-field
    class="inputFormField"
    appearance="outline">
    <mat-select
      class="inputTxtField"
      placeholder="Ausstattungsstandard"
      (selectionChange)="dropDownChange()"
      [(value)]="dropDownValue">
      <mat-option value="EINFACH">Einfach</mat-option>
      <mat-option value="MITTEL">Mittel</mat-option>
      <mat-option value="GEHOBEN">Gehoben</mat-option>
      <mat-option value="STARK_GEHOBEN">Stark Gehoben</mat-option>
    </mat-select>
    <span
      matPrefix
      class="spreng-icon-diamond icon16"></span>
  </mat-form-field>
</div>
