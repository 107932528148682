import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { Report } from 'src/app/core/models/reports.model';
import { Valuation } from 'src/app/core/models/valuation.model';
import { ActiveDataService } from 'src/app/core/services/active-data.service';
import { ComparePrice } from 'src/app/core/models/compare-price.model';
import { ReportRequest } from 'src/app/core/models/report-request.model';
import { Equipment } from 'src/app/core/models/equipment.model';

@Component({
  selector: 'app-comparable-object',
  templateUrl: './comparable-object.component.html',
  styleUrls: ['./comparable-object.component.scss'],
})
export class ComparableObjectComponent implements OnInit {
  @Input() reportId = '';
  @Input() comparableData: ComparePrice;
  @Output() objectClosedEmitter = new EventEmitter<boolean>();

  public reportData: Report;
  public valuationData: Valuation;
  public tablet = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth > 1080 && window.innerWidth <= 1260) {
      this.tablet = true;
    }
  }

  constructor(public _activeDataService: ActiveDataService) {}

  ngOnInit() {
    if (this.comparableData !== null && this.comparableData !== undefined) {
      this.reportData = new Report();
      this.reportData.request = new ReportRequest();
      this.reportData.request.equipment = new Equipment();
      this.reportData.category = this.comparableData.category;
      this.reportData.request.construction_year =
        this.comparableData.construction_year;
      this.reportData.request.equipment.value = this.comparableData.equipment;
      this.reportData.request.living_area = this.comparableData.living_area;
      this.reportData.request.plot_area = this.comparableData.plot_area;
      this.reportData.request.rooms = this.comparableData.rooms;
      this.valuationData = new Valuation();
      this.valuationData.value = this.comparableData.value;
    }

    if (window.innerWidth > 1080 && window.innerWidth <= 1260) {
      this.tablet = true;
    }
  }

  close() {
    this.objectClosedEmitter.emit(true);
  }
}
