<div
  id="container"
  class="component-container hide-for-mobile">
  <div
    class="close"
    (click)="hideLegend()"
    *ngIf="isOpen || desktopClose">
    <span class="spreng-icon-close"></span>
  </div>
  <div
    id="header"
    class="legend-header hide-for-mobile"
    style="display: inline-block; width: 100%">
    Preis €/m²
  </div>
  <div
    id="legend-location"
    class="legend-location hide-for-mobile"
    ng-init="limit = 10">
    {{ location.length > 52 ? (location | slice : 0 : 52) + '...' : location }}
  </div>
  <div
    id="legend-locationM"
    class="legend-location hide-for-tablet hide-for-desktop"
    *ngIf="isOpen">
    {{
      mobileLocation.length > 15
        ? (mobileLocation | slice : 0 : 15) + '...'
        : mobileLocation
    }}
  </div>
  <div class="inner-container">
    <div
      class="legend-container"
      [ngClass]="{ 'legend-container-open': isOpen }">
      <div class="legend hide-for-mobile"></div>
      <div
        id="legend"
        class="legend hide-for-desktop"
        *ngIf="isOpen"></div>
    </div>
    <div
      id="key"
      class="key hide-for-mobile">
      <div class="key-value">> {{ prices[9] | number : '1.0-0' }} €/m²</div>
      <div class="key-value">{{ prices[7] | number : '1.0-0' }} €/m²</div>
      <div class="key-value">{{ prices[5] | number : '1.0-0' }} €/m²</div>
      <div class="key-value">{{ prices[3] | number : '1.0-0' }} €/m²</div>
      <div class="key-value">&lt; {{ prices[1] | number : '1.0-0' }} €/m²</div>
    </div>
  </div>
</div>
<div
  id="mobile-container"
  class="component-container hide-for-desktop"
  [@showHideMobileLegend]="isOpen ? 'open' : 'closed'">
  <div
    class="close"
    (click)="hideLegend()"
    *ngIf="isOpen || desktopClose">
    <span class="spreng-icon-close"></span>
  </div>
  <div
    id="mobile-header"
    class="legend-header hide-for-mobile"
    style="display: inline-block; width: 100%">
    Preis €/m²
  </div>
  <div
    id="mobile-legend-location"
    class="legend-location hide-for-mobile"
    ng-init="limit = 10">
    {{ location.length > 52 ? (location | slice : 0 : 52) + '...' : location }}
  </div>
  <div
    id="mobile-legend-locationM"
    class="legend-location hide-for-tablet hide-for-desktop"
    *ngIf="isOpen">
    {{
      mobileLocation.length > 15
        ? (mobileLocation | slice : 0 : 15) + '...'
        : mobileLocation
    }}
  </div>
  <div class="inner-container">
    <div
      class="legend-container"
      [ngClass]="{ 'legend-container-open': isOpen }">
      <div class="legend hide-for-mobile"></div>
      <div
        id="mobile-legend"
        class="legend hide-for-desktop"
        *ngIf="isOpen"></div>
    </div>
    <div
      id="mobile-key"
      class="key hide-for-mobile">
      <div class="key-value">> {{ prices[9] | number : '1.0-0' }} €/m²</div>
      <div class="key-value">{{ prices[7] | number : '1.0-0' }} €/m²</div>
      <div class="key-value">{{ prices[5] | number : '1.0-0' }} €/m²</div>
      <div class="key-value">{{ prices[3] | number : '1.0-0' }} €/m²</div>
      <div class="key-value">&lt; {{ prices[1] | number : '1.0-0' }} €/m²</div>
    </div>
  </div>
</div>
<div
  id="mobile-legend"
  class="legend-mobile hide-for-desktop"
  (click)="showLegend()"
  [@showHideMobileLegendBar]="isOpen ? 'hide' : 'show'"></div>
