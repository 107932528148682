import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { SimIndexResponse } from '../models/sim-index-response.model';

@Injectable()
export class SimService {
  constructor(private _httpClient: HttpClient) {}
  private url: string = environment.apiUrl + '/report/index/sim';

  get(reportId: string): Observable<SimIndexResponse> {
    return this._httpClient.get<SimIndexResponse>(
      this.url + '?report_id=' + reportId
    );
  }
}
