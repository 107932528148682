<app-error *ngIf="appError"></app-error>
<div
  class="equip-header'"
  *ngIf="!printMode">
  <div
    [ngClass]="{
      'equip-title-rental': !saleMode,
      'equip-title-sale': saleMode
    }"
    class="detail-text-extra-small equip-title">
    <span
      *ngIf="saleMode && extended"
      [ngClass]="getTypeClass()">
      Preisentwicklung & Prognose
    </span>
    <span
      *ngIf="!saleMode && extended"
      [ngClass]="getTypeClass()">
      Trend & Prognonse
    </span>
  </div>
</div>

<div
  *ngIf="dataLoaded()"
  [ngClass]="{
    'print-trend-container': printMode,
    'trend-not-allowed-print-container': !trendAlllowedByConfig() && printMode
  }">
  <div
    *ngIf="!trendAlllowedByConfig() && printMode"
    class="trend-not-allowed-print"
    [ngClass]="{
      'trend-not-allowed-print-sale': saleMode,
      'trend-not-allowed-print-rent': !saleMode
    }"></div>
  <table
    *ngIf="trendAlllowedByConfig()"
    class="extended-trend-table"
    [ngClass]="{ 'print-trend-table': printMode }">
    <tr>
      <td [ngClass]="{ 'print-trend-td': printMode }">
        {{ getThisYearText() }}
      </td>
      <td
        style="font-size: 1.8vh"
        [ngClass]="{ 'print-trend-td2': printMode }">
        <span *ngIf="getThisYearTrend() > 0">+</span>
        {{ getThisYearTrend() | number : '1.1-1' }}
      </td>
      <td [ngClass]="{ 'print-trend-td2': printMode }">
        <span *ngIf="getThisYearTrend() > 0">+</span>
        {{
          (this.valuationData.value * getThisYearTrend()) / 100
            | number : '1.0-0'
        }}
      </td>
      <td [ngClass]="{ 'print-trend-td2': printMode }">
        <span
          [ngClass]="getThisYearTrendValue()"
          class="trend-icon"></span>
      </td>
    </tr>
    <tr>
      <td [ngClass]="{ 'print-trend-td': printMode }">
        {{ getOneYearText() }}
      </td>
      <td
        style="font-size: 1.8vh"
        [ngClass]="{ 'print-trend-td2': printMode }">
        <span *ngIf="getOneYearTrend() > 0">+</span>
        {{ getOneYearTrend() | number : '1.1-1' }}
      </td>
      <td [ngClass]="{ 'print-trend-td2': printMode }">
        <span *ngIf="getOneYearTrend() > 0">+</span>
        {{
          (this.valuationData.value * getOneYearTrend()) / 100
            | number : '1.0-0'
        }}
      </td>
      <td [ngClass]="{ 'print-trend-td2': printMode }">
        <span
          [ngClass]="getOneYearTrendValue()"
          class="trend-icon"></span>
      </td>
    </tr>
    <tr>
      <td [ngClass]="{ 'print-trend-td': printMode }">
        {{ getTwoYearText() }}
      </td>
      <td
        style="font-size: 1.8vh"
        [ngClass]="{ 'print-trend-td2': printMode }">
        <span *ngIf="getTwoYearTrend() > 0">+</span>
        {{ getTwoYearTrend() | number : '1.1-1' }}
      </td>
      <td [ngClass]="{ 'print-trend-td2': printMode }">
        <span *ngIf="getTwoYearTrend() > 0">+</span>
        {{
          (this.valuationData.value * getTwoYearTrend()) / 100
            | number : '1.0-0'
        }}
      </td>
      <td [ngClass]="{ 'print-trend-td2': printMode }">
        <span
          [ngClass]="getTwoYearTrendValue()"
          class="trend-icon"></span>
      </td>
    </tr>
  </table>
  <div
    *ngIf="trendAlllowedByConfig() && printMode"
    class="print-table-footer">
    <span *ngIf="saleMode">Kauf Trend and Prognose</span>
    <span *ngIf="!saleMode">Miete Trend and Prognose</span>
  </div>
</div>
