<div class="energy-container">
  <div
    class="energy-info"
    *ngIf="energyClassFilename || energyCertificate; else noData">
    <div class="energy-label-container">
      <img
        *ngIf="energyClassFilename; else noEnergyClass"
        class="energy-label"
        alt="energy class"
        src="{{ 'assets/icons/energy/' + energyClassFilename }}" />

      <ng-template #noEnergyClass>k.A.</ng-template>
    </div>
    <div class="energy-type-container">
      <img
        *ngIf="energyCertificate; else noEnergyCertificate"
        class="energy-type"
        alt="energy type"
        src="{{ 'assets/icons/energy/' + energyCertificate + '.svg' }}" />

      <ng-template #noEnergyCertificate>k.A.</ng-template>
    </div>
  </div>

  <ng-template #noData>
    <div class="energy-no-data">k.A.</div>
  </ng-template>
</div>
