export class IpiGetResponse {
  date: string;
  values: IpiValue[];
}

export class IpiValue {
  year: number;
  month: number;
  quarter: number;
  index: number;
  value: number;
  trend: string;
  scale: string;
  code: string;
  region: string;
}
