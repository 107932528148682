import { Component, Input } from '@angular/core';
import { Valuation } from 'src/app/core/models/valuation.model';
import { ComparePrice } from 'src/app/core/models/compare-price.model';
import { ActiveDataService } from 'src/app/core/services/active-data.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { RentService } from 'src/app/core/services/rent.service';
import { ValuationService } from 'src/app/core/services/valuation.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ComparableUpdater } from 'src/app/core/interfaces/comparable-updater.interface';
import { Construction } from 'src/app/core/enums/construction.enum';

@Component({
  selector: 'app-expert-comparables',
  templateUrl: './expert-comparables.component.html',
  styleUrls: ['./expert-comparables.component.scss'],
})
export class ExpertComparablesComponent {
  @Input() valuation: Valuation;
  @Input() valuationError = false;
  @Input() mobile = false;
  @Input() tablet = false;
  @Input() print = false;
  @Input() rentPrint = false;

  equipmentText: string;
  public removing = false;

  deleteRow = -1;

  constructor(
    public _activeDataService: ActiveDataService,
    public _authService: AuthenticationService,
    public _rentService: RentService,
    public _saleService: ValuationService,
    public _notificationService: NotificationService
  ) {}

  removeElement(compare: ComparePrice) {
    this.deleteRow = -1;
    if (this._authService.readOnlyMode) {
      return;
    }

    this.valuation.compare_prices.forEach((element, index) => {
      if (element === compare) {
        element.hidden = true;
        this.valuation.compare_prices.splice(index, 1);
      }
    });

    let service: ComparableUpdater = this._rentService;
    if (this._activeDataService.saleMode) {
      service = this._saleService;
    }

    this.removing = true;
    const sub = service
      .updateComparables(this._activeDataService.reportId, {
        compare_prices: this.valuation.compare_prices,
      })
      .subscribe(
        () => {
          this._notificationService.notifySuccess('Vergleichsobjekt entfernt');
          this.removing = false;
          sub.unsubscribe();
        },
        () => {
          this.removing = false;
        }
      );

    this.valuation.compare_prices = this.valuation.compare_prices.filter(
      function (value) {
        if (value === compare) {
          value.hidden = true;
        }
        return value.hidden !== true;
      }
    );
  }

  getComparables() {
    return this.valuation.compare_prices.filter(function (value) {
      return !value.hidden;
    });
  }

  changeEquip(text: string) {
    this.equipmentText = text.toLowerCase();
    this.equipmentText = this.equipmentText.replace(/_/g, ' ');
    return this.equipmentText;
  }

  changeCat(text: string) {
    switch (text) {
      case 'ETW': {
        return 'Eigentumswohnungs';
      }
      case 'MFH': {
        return 'Mehrfamilienhaus';
      }
      case 'EFH': {
        return 'Einfamilienhaus';
      }
      case 'GRD': {
        return 'Grundstück';
      }
      default: {
        console.log('Unknown house type', text);
      }
    }
  }

  removeRequest(index: number) {
    if (!this.removing) {
      this.deleteRow = index;
    }
  }

  toggleMode() {
    this._activeDataService.saleMode = !this._activeDataService.saleMode;
    this.deleteRow = -1;
    this._activeDataService.modeEmitter.emit(this._activeDataService.saleMode);
  }

  getConstruction(val: string) {
    return Construction[val];
  }

  formatDate(d1) {
    const dateTemp = new Date(Date.parse(d1));
    const year = dateTemp.getFullYear();
    return year;
  }

  tableClass() {
    if (this.print) {
      return 'compare-table-print';
    }
    return 'compare-table';
  }

  containerClass() {
    if (this.print) {
      if (!this._activeDataService.saleMode) {
        return 'compare-container-print rent';
      }
      return 'compare-container-print';
    }
    if (!this._activeDataService.saleMode) {
      return 'compare-container rent';
    }
    return 'compare-container';
  }
}
