import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: 'img[appImageFallback]',
})
export class ImageFallbackDirective {
  @Input() appImageFallback: string;

  @Input() set src(val: string) {
    this.renderer.setProperty(this.el.nativeElement, 'src', val);
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('error')
  onError() {
    const parentNode = this.renderer.parentNode(this.el.nativeElement);
    this.renderer.removeChild(parentNode, this.el.nativeElement);
    const text = this.renderer.createText(this.appImageFallback);
    this.renderer.appendChild(parentNode, text);
  }
}
