import { Component, Input, OnInit } from '@angular/core';
import { SocioChartData } from 'src/app/core/models/SocioChartData.model';
import { SociodemographicService } from 'src/app/core/services/socio-demographic.service';
import { ActiveDataService } from 'src/app/core/services/active-data.service';
import {
  SociodemographicGetResponse,
  SocidemographicListItem,
} from 'src/app/core/models/sociodemographic-get-response.model';

@Component({
  selector: 'app-expert-sociodemographic',
  templateUrl: './expert-sociodemographic.component.html',
  styleUrls: ['./expert-sociodemographic.component.scss'],
})
export class ExpertSociodemographicComponent implements OnInit {
  @Input() full = true;
  @Input() print = false;
  @Input() page = 1;
  @Input() reportId?: string = null;

  constructor(
    private _socioDemographicService: SociodemographicService,
    private _activeDataService: ActiveDataService
  ) {}

  public socioResponse: SociodemographicGetResponse;
  public chart1: SocioChartData;
  public chart2: SocioChartData;
  public chart3: SocioChartData;
  public chart4: SocioChartData;

  public top1V: number;
  public top1L: string;
  public top2V: number;
  public top2L: string;
  public top3V: number;
  public top3L: string;
  public top4V: number;
  public top4L: string;

  showMoreSocio = false;

  ngOnInit() {
    const reportIdToUse = this.reportId || this._activeDataService.reportId;

    this._socioDemographicService
      .get(reportIdToUse)
      .subscribe((data: SociodemographicGetResponse) => {
        this.socioResponse = data;
        this.buildData(data);
      });
  }

  private buildData(data: SociodemographicGetResponse) {
    this.chart1 = new SocioChartData();
    this.chart2 = new SocioChartData();
    this.chart3 = new SocioChartData();
    this.chart4 = new SocioChartData();

    this.chart1.title = 'Haushaltsnettoeinkommen pro Monat';
    this.chart2.title = 'Altersverteilung';
    this.chart3.title = 'Haushaltsgröße';
    this.chart4.title = 'Verteilung Gebäudetypen';

    this.chart1.labels = [
      data.net_household_income[0].name,
      data.net_household_income[1].name,
      data.net_household_income[2].name,
      data.net_household_income[3].name,
      data.net_household_income[4].name,
    ];

    this.top1L =
      data.net_household_income[
        this.getTopValue(data.net_household_income)
      ].name;
    this.top1V =
      data.net_household_income[
        this.getTopValue(data.net_household_income)
      ].value;

    this.chart2.labels = [
      data.ages[0].name,
      data.ages[1].name,
      data.ages[2].name,
      data.ages[3].name,
      data.ages[4].name,
    ];

    this.chart3.labels = [
      data.household_size[4].name,
      data.household_size[3].name,
      data.household_size[2].name,
      data.household_size[1].name,
      data.household_size[0].name,
    ];

    this.chart4.labels = [
      data.object_distribution[4].name,
      data.object_distribution[3].name,
      data.object_distribution[2].name,
      data.object_distribution[1].name,
      data.object_distribution[0].name,
    ];

    this.chart1.pieData = [
      data.net_household_income[0].value,
      data.net_household_income[1].value,
      data.net_household_income[2].value,
      data.net_household_income[3].value,
      data.net_household_income[4].value,
    ];

    this.chart2.barData = [
      {
        data: [data.ages[0].value],
        label: this.chart2.labels[0],
        stack: 'a',
        backgroundColor: '#70BBCC',
        barThickness: 'flex',
        barPercentage: 0.9,
      },
      {
        data: [data.ages[1].value],
        label: this.chart2.labels[1],
        stack: 'a',
        backgroundColor: '#62A4B3',
        barThickness: 'flex',
        barPercentage: 0.9,
      },
      {
        data: [data.ages[2].value],
        label: this.chart2.labels[2],
        stack: 'a',
        backgroundColor: '#548C99',
        barThickness: 'flex',
        barPercentage: 0.9,
      },
      {
        data: [data.ages[3].value],
        label: this.chart2.labels[3],
        stack: 'a',
        backgroundColor: '#45737E',
        barThickness: 'flex',
        barPercentage: 0.9,
      },
      {
        data: [data.ages[4].value],
        label: this.chart2.labels[4],
        stack: 'a',
        backgroundColor: '#385D65',
        barThickness: 'flex',
        barPercentage: 0.9,
      },
    ];

    this.chart3.pieData = [
      data.household_size[4].value,
      data.household_size[3].value,
      data.household_size[2].value,
      data.household_size[1].value,
      data.household_size[0].value,
    ];

    this.chart4.pieData = [
      data.object_distribution[4].value,
      data.object_distribution[3].value,
      data.object_distribution[2].value,
      data.object_distribution[1].value,
      data.object_distribution[0].value,
    ];

    this.top1L =
      data.net_household_income[
        this.getTopValue(data.net_household_income)
      ].name;
    this.top1V =
      data.net_household_income[
        this.getTopValue(data.net_household_income)
      ].value;
    this.top2L = data.ages[this.getTopValue(data.ages)].name;
    this.top2V = data.ages[this.getTopValue(data.ages)].value;
    this.top3L =
      data.household_size[this.getTopValue(data.household_size)].name;
    this.top3V =
      data.household_size[this.getTopValue(data.household_size)].value;
    this.top4L =
      data.object_distribution[this.getTopValue(data.object_distribution)].name;
    this.top4V =
      data.object_distribution[
        this.getTopValue(data.object_distribution)
      ].value;
  }

  getTopValue(list: SocidemographicListItem[]) {
    let top = 0;
    let index = 0;
    for (let i = 0; i < 5; i++) {
      if (list[i].value > top) {
        top = list[i].value;
        index = i;
      }
    }
    return index;
  }

  toggleMoreSocio() {
    this.showMoreSocio = !this.showMoreSocio;
  }

  getRowClass() {
    if (this.print) {
      return 'socio-chart-row-print';
    }
    return 'socio-chart-row';
  }

  getChartClass() {
    if (this.print) {
      return 'socio-chart-container-print';
    }
    return 'socio-chart-container';
  }
}
