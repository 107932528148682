<div class="share-title">
  <div
    name="share-report-title"
    class="share-title-text">
    Sprengnetter Maps Report jetzt teilen
  </div>
  <div class="spreng-icon-close-container">
    <span
      class="spreng-icon-close"
      mat-button
      mat-dialog-close></span>
  </div>
  <!-- <h2 mat-dialog-title>Sprengnetter Maps Report jetzt teilen<button class="close" mat-button mat-dialog-close>X</button></h2> -->
</div>
<mat-dialog-content
  class="share-content"
  [ngClass]="{ 'small-share-content': this.longReport }">
  <div class="share-content-inner">
    <div class="share-image-container">
      <app-image
        class="share-image"
        *ngIf="reportData !== undefined"
        [type]="reportData.category"
        [isShare]="true"></app-image>
    </div>
    <div class="share-text-container">
      <div
        name="share-property-type"
        class="detail-text">
        {{ getTypeText() }}
      </div>
      <div name="share-address">
        {{ getAddressText() }}
      </div>
    </div>
  </div>
  <div
    class="share-content-inner2"
    [ngClass]="{ 'small-share-content-inner2': this.longReport }">
    <div
      class="link-title"
      [ngClass]="{ 'small-link-title': this.longReport }">
      Direktlink zum Report
    </div>
    <table
      class="link-container"
      [ngClass]="{ 'small-link-container': this.longReport }">
      <tr>
        <td style="text-align: center">
          <div
            name="share-link"
            class="copy-link">
            {{ getLink() }}
          </div>
        </td>
        <td style="height: 100%; padding: 0">
          <div class="copy-link-button-container">
            <button
              name="share-copy-button"
              mat-stroked-button
              (click)="copyLink()"
              class="copy-link-button">
              Link kopieren
            </button>
          </div>
        </td>
      </tr>
    </table>
  </div>
</mat-dialog-content>
