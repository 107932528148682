<div
  class="shrink-button"
  *ngIf="tablet"
  (click)="closeExpert()">
  <span
    class="spreng-icon-arrow-collapse-all shrinkIcon"
    matTooltip="Expertenansicht schließen"></span>
</div>
<div
  class="expert-container"
  *ngIf="reportData !== undefined">
  <!-- <div class="expert-overlay">
        Die Bildschirmauflösung wird nicht unterstützt. Bitte versuchen Sie es mit einer größeren Bildschirmauflösung.
    </div><span class="spreng-icon-close close-icon" (click)="closeExpert()"></span> -->
  <ng-container *ngIf="!tablet">
    <div>
      <div
        class="expert-tab-button expert-tab-button-1"
        [ngClass]="currentPanel === 1 ? 'tab-selected' : 'tab-unselected'"
        (click)="showPanel(1)">
        <div
          *ngIf="currentPanel === 1"
          class="tab-selected-border"></div>
        <span class="spreng-icon-magnify-house icon"></span>
        <span class="tab-text">
          Detail
          <br />
          Analyse
        </span>
      </div>
      <div
        class="expert-tab-button expert-tab-button-2"
        *ngIf="shouldShowAllTabs()"
        [ngClass]="currentPanel === 2 ? 'tab-selected' : 'tab-unselected'"
        (click)="showPanel(2)">
        <div
          *ngIf="currentPanel === 2"
          class="tab-selected-border"></div>
        <span class="spreng-icon-compare-house icon"></span>
        <span class="tab-text">
          Vergleichs
          <br />
          Objekte
        </span>
      </div>
      <div
        class="expert-tab-button"
        [ngClass]="{
          'tab-selected': currentPanel === 3,
          'tab-unselected': currentPanel !== 3,
          'expert-tab-button-2': !shouldShowAllTabs(),
          'expert-tab-button-3': shouldShowAllTabs()
        }"
        (click)="showPanel(3)"
        *ngIf="shouldShowSocio()">
        <div
          *ngIf="currentPanel === 3"
          class="tab-selected-border"></div>
        <span class="spreng-icon-socio-democratic icon"></span>
        <span class="tab-text">
          Sozio
          <br />
          Demo
          <br />
          Grafie
        </span>
      </div>
    </div>

    <div class="expert-left-panel">
      <div class="expert-left-panel-inner">
        <div class="col colLeft">
          <div class="box minHeight125 boxImage">
            <app-image
              [appError]="imageError"
              [images]="reportImages"
              [extended]="true"
              [type]="reportData.category"
              style="display: inline"></app-image>
          </div>
          <div class="box boxInfo">
            <app-info
              class="infoBar-standard"
              [appError]="reportError"
              [reportData]="reportData"
              [extended]="true"></app-info>
          </div>
          <div class="box boxDetails">
            <div class="iContainer"></div>
            <app-details
              [extended]="true"
              [showLoader]="showLoader"
              [reloadDetails]="reloadDetails"
              [appError]="reportError"
              [reportData]="reportData"
              [columnsOnly]="true"
              [isExpert]="true"
              (validationRequestEmitter)="updateValuation($event)"
              class="details"></app-details>
          </div>
        </div>
      </div>
    </div>
    <div class="expert-right-panel">
      <div
        class="expert-right-panel-inner"
        *ngIf="currentPanel === 1">
        <app-expert-valuation
          [valuationData]="valuationDataOut"
          [reportData]="reportDataOut"
          [equipmentData]="equipmentDataOut"
          [ipiData]="ipiDataOut"></app-expert-valuation>
      </div>
      <div
        class="expert-right-panel-inner"
        *ngIf="currentPanel === 2">
        <app-expert-comparables
          [valuation]="valuationData"
          [valuationError]="valuationError"></app-expert-comparables>
      </div>
      <div
        class="expert-right-panel-inner"
        *ngIf="currentPanel === 3">
        <app-expert-sociodemographic></app-expert-sociodemographic>
      </div>
    </div>
  </ng-container>

  <!-- TABLET VIEW -->
  <div
    *ngIf="!expert"
    class="dataPrivacyLinkContainer dataImprintRow hide-for-mobile hide-for-tablet">
    <div class="dataImprintColumn">
      <a
        href="{{ _authService.privacyUrl }}"
        target="_blank">
        Datenschutzerklärung
      </a>
    </div>
    <div class="dataImprintColumn">
      <a
        href="{{ _authService.imprintUrl }}"
        target="_blank">
        Impressum
      </a>
    </div>
  </div>

  <div
    class="shrink-button hide-for-tablet"
    (click)="closeExpert()">
    <span
      class="spreng-icon-arrow-collapse-all shrinkIcon"
      matTooltip="Expertenansicht schließen"></span>
  </div>

  <div
    class="close-button-container"
    *ngIf="tablet"
    (click)="closeExpert()">
    <span class="spreng-icon-close"></span>
  </div>

  <ng-container *ngIf="tablet">
    <div
      class="tablet-switch"
      *ngIf="
        this._activeDataService.reportData?.config?.show_valuation &&
        this._activeDataService.reportData?.config?.show_rent
      ">
      <div class="switch-container">
        <div class="switch-container-inner">
          <span
            [ngClass]="{ 'on-off-toggled': this._activeDataService.saleMode }">
            KAUF
          </span>
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch4"
              class="onoffswitch-checkbox"
              id="myonoffswitch4"
              tabindex="0"
              [checked]="!this._activeDataService.saleMode"
              (change)="toggleSaleMode()"
              [disabled]="this.showLoader" />
            <label
              class="onoffswitch-label"
              for="myonoffswitch4"></label>
          </div>
          <span
            [ngClass]="{ 'on-off-toggled': !this._activeDataService.saleMode }">
            MIETE
          </span>
        </div>
      </div>
    </div>

    <div class="mobile-container">
      <div
        class="expand-arrow"
        (click)="toggleExpanded()">
        <div
          class="expand-arrow-inner"
          [ngClass]="{ 'expand-arrow-inner-expanded': expanded }">
          <span class="spreng-icon-chevron-left icon-expand"></span>
        </div>
      </div>

      <div [ngClass]="{ 'mobile-container-inner': expanded }">
        <div class="col colLeft">
          <div class="box minHeight125 boxImage">
            <app-image
              [appError]="imageError"
              [images]="reportImages"
              [extended]="true"
              [type]="reportData.category"
              style="display: inline"></app-image>
          </div>

          <div class="box boxInfo">
            <app-info
              class="infoBar-standard"
              [appError]="reportError"
              [reportData]="reportData"
              [extended]="true"
              [mobile]="true"></app-info>
          </div>

          <div class="box boxInfo">
            <app-details
              [extended]="true"
              [showLoader]="showLoader"
              [reloadDetails]="reloadDetails"
              [appError]="reportError"
              [reportData]="reportData"
              [columnsOnly]="true"
              [mobile]="true"
              (validationRequestEmitter)="updateValuation($event)"
              class="details"></app-details>
          </div>
        </div>

        <div class="col colRight">
          <div
            class="box boxValuation"
            *ngIf="expanded">
            <app-valuation
              [mobile]="true"
              [valuationData]="valuationData"
              [reportData]="reportData"
              [extended]="true"
              [saleMode]="this._activeDataService.saleMode"></app-valuation>
          </div>
          <div
            class="box boxRating"
            *ngIf="expanded && shouldShowEquip()">
            <app-equipment-rating
              *ngIf="shouldShowEquip()"
              [valuationData]="valuationData"
              [reportData]="reportData"
              [equipmentData]="equipmentData"
              [expanded]="true"
              [mobile]="true"
              [saleMode]="
                this._activeDataService.saleMode
              "></app-equipment-rating>
          </div>
          <div
            class="box boxTrend"
            *ngIf="expanded">
            <app-trend
              *ngIf="shouldShowTrend()"
              [saleMode]="this._activeDataService.saleMode"
              [extended]="true"
              [valuationData]="valuationData"
              [reportData]="reportData"
              [ipiData]="ipiData"></app-trend>
            <div
              class="noTrendBoxSale"
              *ngIf="
                !shouldShowTrend() && this._activeDataService.saleMode
              "></div>
            <div
              class="noTrendBoxRent"
              *ngIf="
                !shouldShowTrend() && !this._activeDataService.saleMode
              "></div>
          </div>
        </div>
      </div>

      <!--

        <div class="mobile-share" *ngIf="expanded">
          <div class="mobile-share-button" (click)="_printService.printReport()">
                        <div class="spreng-icon-printer"></div>
                    </div>
                    <div class="mobile-share-button-text" (click)="_printService.printReport()">Print</div>
          <div class="mobile-share-button" (click)="shareLink()">
            <div class="spreng-icon-share"></div>
          </div>
          <div class="mobile-share-button-text" (click)="shareLink()">Share</div>
        </div>

        -->

      <!-- Graphs -->

      <ng-container>
        <div class="mobile-container graph-container">
          <div class="col colLeft chartLeftTablet">
            <div
              class="box boxValuation graph-box"
              [ngClass]="
                _activeDataService.saleMode
                  ? 'boxValuation-sale'
                  : 'boxValuation-rental boxValuation-reset'
              ">
              <div
                class="chart-title unselectable"
                [ngClass]="
                  _activeDataService.saleMode
                    ? 'chart-title-sale'
                    : 'chart-title-rental'
                "
                *ngIf="regionData !== undefined && _activeDataService.saleMode">
                Marktdaten
              </div>

              <div
                class="chart-container"
                *ngIf="_activeDataService.saleMode && regionData !== undefined">
                <app-line-chart
                  *ngIf="ipiData !== undefined && regionData !== undefined"
                  [chartSettings]="getRegionalMarketData()"
                  [secondaryChartSettings]="getMarketData()"
                  [keepAspect]="false"></app-line-chart>
              </div>
            </div>
          </div>
          <div class="col colRight">
            <div
              class="box boxValuation graph-box"
              [ngClass]="{ 'box-rent-mode': !_activeDataService.saleMode }"
              [ngClass]="
                _activeDataService.saleMode
                  ? 'boxValuation-sale'
                  : 'boxValuation-rental'
              ">
              <div
                class="chart-title unselectable"
                [ngClass]="
                  _activeDataService.saleMode
                    ? 'chart-title-sale'
                    : 'chart-title-rental'
                "
                *ngIf="!_activeDataService.saleMode">
                Marktdaten
              </div>

              <div
                class="chart-container"
                *ngIf="!_activeDataService.saleMode">
                <app-line-chart
                  *ngIf="ipiData !== undefined"
                  [chartSettings]="getMarketData()"
                  [keepAspect]="false"></app-line-chart>
              </div>

              <div
                class="chart-title unselectable"
                [ngClass]="
                  _activeDataService.saleMode
                    ? 'chart-title-sale'
                    : 'chart-title-rental'
                "
                *ngIf="_activeDataService.saleMode && simData !== undefined">
                Sprengnetter Immobilienmarkt Monitoring
              </div>

              <div
                class="chart-container"
                *ngIf="_activeDataService.saleMode && simData !== undefined">
                <app-line-chart
                  *ngIf="ipiData !== undefined"
                  [chartSettings]="getSimData()"
                  [keepAspect]="false"></app-line-chart>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="mobile-container compare-container">
        <ng-container>
          <app-expert-comparables
            [valuation]="valuationData"
            [valuationError]="valuationError"
            [tablet]="true"></app-expert-comparables>
        </ng-container>
      </div>

      <div class="mobile-container socio-container">
        <ng-container>
          <app-expert-sociodemographic
            [full]="showMoreSocio"></app-expert-sociodemographic>
        </ng-container>
      </div>

      <div class="mobile-container socio-switch-container">
        <div
          *ngIf="!showMoreSocio"
          class="show-more-socio-text">
          Mehr soziodemografische Daten
        </div>
        <div
          *ngIf="showMoreSocio"
          class="show-more-socio-text">
          Weniger soziodemografische Daten
        </div>

        <div
          class="show-hide-socio-mobile"
          (click)="toggleMoreSocio()">
          <div *ngIf="!showMoreSocio">+</div>
          <div *ngIf="showMoreSocio">-</div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
