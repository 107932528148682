import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { RentResponse } from '../models/rent-response.model';
import { IpiGetResponse } from '../models/ipi-get-response.model';
import { EquipmentGetResponse } from '../models/equipment-get-response.model';
import { ComparePriceUpdate } from '../models/compare-price-update.model';
import { ComparableUpdater } from '../interfaces/comparable-updater.interface';

@Injectable()
export class RentService implements ComparableUpdater {
  constructor(private _httpClient: HttpClient) {}
  private url: string = environment.apiUrl + '/report/rent';

  get(reportId: string): Observable<RentResponse> {
    return this._httpClient.get<RentResponse>(
      this.url + '?report_id=' + reportId
    );
  }

  getEquipment(reportId: string): Observable<EquipmentGetResponse> {
    return this._httpClient.get<EquipmentGetResponse>(
      this.url + '/equipment?report_id=' + reportId
    );
  }

  getIpi(reportId: string): Observable<IpiGetResponse> {
    return this._httpClient.get<IpiGetResponse>(
      this.url + '/ipi?report_id=' + reportId
    );
  }

  getRegion(reportId: string): Observable<IpiGetResponse> {
    return this._httpClient.get<IpiGetResponse>(
      this.url + '/ipi/region?report_id=' + reportId
    );
  }

  updateComparables(reportId: string, updatedComparables: ComparePriceUpdate) {
    return this._httpClient.put(
      this.url + '/compare_prices?report_id=' + reportId,
      updatedComparables
    );
  }
}
