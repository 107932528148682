import { Component, OnInit, Output, HostListener } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { HeaderService } from 'src/app/core/services/header.service';
import { Reports, Report } from 'src/app/core/models/reports.model';
import { ValuationHistoryComponent } from '../valuation-history/valuation-history.component';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'closed',
        style({
          left: 'calc(50vw - (550px * 0.5))',
          'z-index': '0',
          opacity: '0.6',
        })
      ),
      state(
        'open',
        style({
          left: '*',
          'z-index': '0',
        })
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),

    trigger('mobileOpenClose', [
      state(
        'mobileClosed',
        style({
          'margin-top': '100vh',
        })
      ),
      state(
        'mobileOpen',
        style({
          'margin-top': '0',
        })
      ),
      transition('mobileOpen => mobileClosed', [
        animate(
          '600ms ease-in-out',
          style({
            'margin-top': '100vh',
          })
        ),
      ]),
      transition('mobileClosed => mobileOpen', [
        animate(
          '600ms ease-in-out',
          style({
            'margin-top': '0',
          })
        ),
      ]),
    ]),

    trigger('showHideMobileSearchButton', [
      transition(':leave', [
        query(':self', [animate('400ms', style({ opacity: 0 }))]),
      ]),
      transition(':enter', [
        query(':self', [
          style({ opacity: 0 }),
          animate('0.25s 0.15s', style({ opacity: 1 })),
        ]),
      ]),
    ]),

    trigger('showHideButton', [
      transition(':leave', [
        query(':self', [animate('400ms', style({ opacity: 0 }))]),
      ]),
      transition(':enter', [
        query(':self', [
          style({ opacity: 0 }),
          animate('400ms 400ms', style({ opacity: 1 })),
        ]),
      ]),
    ]),
  ],
})
export class AddressSearchComponent implements OnInit {
  @Output() activePanelChange: EventEmitter<number> = new EventEmitter();
  @Output() historicReportEmitter: EventEmitter<Report> =
    new EventEmitter<Report>();

  public activePanel = 1;
  public recentQueries: Reports = new Reports();
  private blockPanelChange = false;
  private dialogOpen = false;
  private historicReportSubscription: Subscription;
  public resolutionTest = '';
  public showDebug = false;
  public mobile = false;
  public smallDesktop = false;

  public windowHeight = '';

  isOpen = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setWindowScrollHeight();
    this.resolutionTest = window.innerWidth + 'x' + window.innerHeight;
    if (window.innerWidth <= 1080) {
      this.mobile = true;
    }
    if (window.innerWidth > 1080 && window.innerWidth <= 1780) {
      this.smallDesktop = true;
    }
    if (this.mobile) {
      this._headerService.showHeader = true;
    }
  }

  constructor(
    private _headerService: HeaderService,
    private _dialog: MatDialog
  ) {
    this.showDebug = environment.showDebugInfo;
  }

  ngOnInit() {
    this.setWindowScrollHeight();
    this.resolutionTest = window.innerWidth + 'x' + window.innerHeight;
    if (window.innerWidth <= 1080) {
      this.mobile = true;
    }

    if (window.innerWidth > 1080 && window.innerWidth <= 1780) {
      this.smallDesktop = true;
    }

    if (this.mobile) {
      this._headerService.showHeader = true;
    }

    this._headerService.activePanel.subscribe((data: any) => {
      if (data) {
        this.activatePanel(1);
      }
    });
  }

  completeSearch() {
    this._headerService.toggleHeader();
  }

  activatePanel(panel: number, blockNext = false) {
    if (this.mobile) {
      this.blockPanelChange = false;

      if (panel === 1) {
        this.isOpen = !this.isOpen;
      }
    }
    if (!this.blockPanelChange) {
      this.activePanel = panel;
      this.activePanelChange.emit(panel);
    }

    if (panel !== 1 && this.mobile) {
      this._headerService.hideSearchBar();
    } else {
      if (this.mobile) {
        this._headerService.showAddressSearchBar();
      }
    }

    this.blockPanelChange = blockNext;
    if (this.activePanel === 2) {
      this.isOpen = true;
    } else {
      this.isOpen = false;
    }
  }

  hoverLink(isHovered: boolean) {
    if (isHovered) {
      document
        .getElementsByName('history-panel')[0]
        .classList.add('history-hover');
    } else {
      document
        .getElementsByName('history-panel')[0]
        .classList.remove('history-hover');
    }
  }

  setWindowScrollHeight() {
    this.windowHeight = window.innerHeight.toString() + 'px';
  }

  toggleFullHistory() {
    let wdth = '35vw';
    if (this.mobile) {
      wdth = '100vw';
    } else if (this.smallDesktop) {
      wdth = '50vw';
    }
    if (!this.dialogOpen) {
      const dialogRef = this._dialog.open(ValuationHistoryComponent, {
        width: wdth,
        height: this.windowHeight,
        maxWidth: '100vw',
        maxHeight: '100vh',
        hasBackdrop: false,
        panelClass: 'overlayPane',
        position: { right: '0' },
      });
      const instance = dialogRef.componentInstance;
      this.historicReportSubscription =
        instance.historicReportEmitter.subscribe((report: Report) => {
          this.loadReport(report);
        });
      instance.title = 'Übersicht analysierter Immobilien ()';
      instance.limit = 500;
      instance.hideHeaderRow = false;
      instance.isSideBar = true;
      if (this.mobile) {
        instance.hideShareRow = false;
      }

      this.dialogOpen = true;

      dialogRef.afterClosed().subscribe(result => {
        this.dialogOpen = false;
        this.historicReportSubscription.unsubscribe();
      });
    } else {
      this.dialogOpen = false;
      this.historicReportSubscription.unsubscribe();
      this._dialog.closeAll();
    }
  }

  loadReport(report: Report) {
    this.historicReportEmitter.emit(report);
    if (this.mobile) {
      this._headerService.showHeader = true;
    }

    this.activePanelChange.emit(1);
  }

  panelChange(
    event,
    historyClick = false,
    linkClick = false,
    panelClick = false
  ) {
    if (
      (historyClick && this.activePanel === 2) ||
      (linkClick && this.isOpen) ||
      (panelClick && !this.isOpen)
    ) {
      return;
    }

    if (this.activePanel === 1) {
      this.activatePanel(2);
      this.isOpen = true;
    } else {
      this.activatePanel(1);
      this.isOpen = false;
    }
    event.stopPropagation();
  }
}
