import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-similarity-rating',
  templateUrl: './similarity-rating.component.html',
  styleUrls: ['./similarity-rating.component.scss'],
})
export class SimilarityRatingComponent {
  @Input() value: number;
  @Input() class = '';

  constructor() {}

  getClass(filled: boolean) {
    if (filled) {
      return 'rating-icon ' + this.class + ' filled';
    }
    return 'rating-icon ' + this.class + ' outline';
  }
}
