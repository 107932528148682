import { EventEmitter, Injectable } from '@angular/core';
import { ReportConfiguration } from '../models/reports.model';

@Injectable()
export class HeaderService {
  public headerChange: EventEmitter<boolean> = new EventEmitter();
  public showHeader = false;
  public showSearchBar = false;
  public showLogo = true;
  public logoClickEmitter = new EventEmitter();
  public printClickedEmitter = new EventEmitter();
  public reloadHeatMapEmitter = new EventEmitter();
  public showHeaderSearchEmitter = new EventEmitter();
  public showAddressSearchEmiiter = new EventEmitter();
  public hideAddressSearchEmiiter = new EventEmitter();
  public configChangeEmitter = new EventEmitter<ReportConfiguration>();

  public activePanel = new EventEmitter();

  public maxMultiplier = 1.2;
  public minMultiplier = 0.8;

  reloadHeatMap() {
    this.reloadHeatMapEmitter.emit();
  }

  showSearch() {
    return this.showSearchBar;
    this.showHeaderSearchEmitter.emit();
  }

  setSearch(val: boolean) {
    this.showSearchBar = val;
    if (val) {
      this.showHeaderSearchEmitter.emit();
    }
  }

  toggleHeader() {
    this.showHeader = !this.showHeader;
    if (!this.showHeader) {
      this.showSearchBar = false;
    }
    this.headerChange.emit(this.showHeader);
  }

  loadHeader() {
    this.showHeader = true;
    this.headerChange.emit(this.showHeader);
  }

  unloadHeader() {
    this.showHeader = false;
    this.headerChange.emit(false);
  }

  toggleSearchBar() {
    this.showSearchBar = !this.showSearchBar;
    if (this.showSearchBar) {
      this.showHeaderSearchEmitter.emit();
    }
  }

  goToAddressSearch(mobile = false) {
    this.logoClickEmitter.emit();
    this.activePanel.emit(1);
    if (mobile) {
      this.showHeader = true;
      this.hideAddressSearchEmiiter.emit(false);
    }
  }

  hideSearchBar() {
    this.showSearchBar = false;
    this.showAddressSearchEmiiter.emit();
  }

  showAddressSearchBar() {
    this.showSearchBar = false;
    this.hideAddressSearchEmiiter.emit();
  }
}
