import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PortalError } from '../core/enums/portal-error.enum';
import { AuthenticationService } from '../core/services/authentication.service';
import { ErrorService, ErrorsStackLines } from '../core/services/error.service';
import { HeaderService } from '../core/services/header.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  public title = '';
  public description = '';
  public url = 'https://dps.maps.sprengnetter.de/';
  public moreDetail = false;
  public errorText: any;
  public state: Observable<object>;
  public errStack: ErrorsStackLines[];

  constructor(
    public route: ActivatedRoute,
    public _authService: AuthenticationService,
    public _headerService: HeaderService,
    private _errorService: ErrorService,
    private _router: Router
  ) {
    this.errStack = [];
    if (_authService.loginUrl !== '') {
      this.url = _authService.loginUrl;
    }
    if (route.snapshot.data.type === PortalError.AccessDenied) {
      this.title = 'Zugriffsfehler';
      this.description =
        'Dieser Link ist nicht mehr gültig, versuchen Sie sich erneut einzuloggen.';
    } else if (route.snapshot.data.type === PortalError.GeneralError) {
      this.title = 'Allgemeiner Fehler';
      this.description =
        'Das System ist nicht erreichbar, bitte versuchen Sie es später erneut.';
    } else {
      this._router.navigate(['/systemError']);
      this.title = 'Component error';
      this.description =
        'Ein unerwarteter Fehler ist aufgetreten. Bitte starten Sie den Vorgang noch einmal.';
    }
  }

  ngOnInit() {
    this.errStack = this._errorService.getCurrentErrorStack();
  }

  homeClick() {
    if (!this._authService.readOnlyMode) {
      this._headerService.goToAddressSearch(false);
    }
  }
}
