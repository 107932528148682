import { Component, OnInit, Input } from '@angular/core';
import { ComparePrice } from 'src/app/core/models/compare-price.model';

@Component({
  selector: 'app-construction',
  templateUrl: './construction.component.html',
  styleUrls: ['./construction.component.scss'],
})
export class ConstructionComponent implements OnInit {
  @Input() comparable: ComparePrice;

  imgSrc = '';
  constructionText: any;
  constructor() {}

  ngOnInit() {
    if (this.comparable !== undefined) {
      this.constructionText = this.comparable.construction;
      this.constructionText = this.constructionText.toLowerCase();
      this.constructionText = this.constructionText.replace(/_/g, ' ');
    }
  }

  getIcon() {
    if (this.constructionText === 'freistehend') {
      this.imgSrc = '../../../assets/icons/free-standing.svg';
      return this.imgSrc;
    }
    if (this.constructionText === 'doppelhaus') {
      this.imgSrc = '../../../assets/icons/semi-detached-house.svg';
      return this.imgSrc;
    }
    if (this.constructionText === 'reihen endhaus') {
      this.imgSrc = '../../../assets/icons/terraced.svg';
      return this.imgSrc;
    }
    if (this.constructionText === 'reihen mittelhaus') {
      this.imgSrc = '../../../assets/icons/terraced-middle.svg';
      return this.imgSrc;
    }
    if (
      this.constructionText === 'loft' ||
      this.constructionText === 'penthouse' ||
      this.constructionText === 'dachgeschoss' ||
      this.constructionText === 'terrassenwohnung' ||
      this.constructionText === 'dachgeschosspenthouse'
    ) {
      this.imgSrc = '../../../assets/icons/apartment-top.svg';
      return this.imgSrc;
    }
    if (
      this.constructionText === 'souterrain' ||
      this.constructionText === 'erdgeschosswohnung'
    ) {
      this.imgSrc = '../../../assets/icons/apartment-ground.svg';
      return this.imgSrc;
    }
    if (
      this.constructionText === 'etagenwohnung' ||
      this.constructionText === 'hochparterre' ||
      this.constructionText === 'maisonette' ||
      this.constructionText === 'mitgartenteil'
    ) {
      this.imgSrc = '../../../assets/icons/apartment-middle.svg';
      return this.imgSrc;
    }

    // The below are types, not constructions. Need to know how we deal with them
    if (this.constructionText === 'eigentumswohnung') {
      this.imgSrc = '../../../assets/icons/apartment-middle.svg';
      return this.imgSrc;
    }
    if (this.constructionText === 'mehrfamilienhaus') {
      this.imgSrc = '../../../assets/icons/apartment-middle.svg';
      return this.imgSrc;
    }
    if (this.constructionText === 'einfamilienhaus') {
      this.imgSrc = '../../../assets/icons/home.svg';
      return this.imgSrc;
    }
    if (this.constructionText === 'grundstück') {
      this.imgSrc = '../../../assets/icons/apartment-middle.svg';
      return this.imgSrc;
    }
  }

  getIconFont() {
    switch (this.comparable.category) {
      case 'ETW': {
        return 'spreng-icon-apartment';
      }
      case 'MFH': {
        return 'spreng-icon-home';
      }
      case 'EFH': {
        return 'spreng-icon-home';
      }
      case 'GRD': {
        return 'spreng-icon-land';
      }
      default: {
        console.log('Unknown house type', this.comparable.category);
      }
    }
  }

  categoryText() {
    switch (this.comparable.category) {
      case 'ETW': {
        return 'Eigentumswohnung';
      }
      case 'MFH': {
        return 'Mehrfamilienhaus';
      }
      case 'EFH': {
        return 'Einfamilienhaus';
      }
      case 'GRD': {
        return 'Grundstück';
      }
      default: {
        console.log('Unknown house type', this.comparable.category);
      }
    }
  }
}
