import { Component, Input } from '@angular/core';
import { Print } from '../core/models/print.model';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ChartGenerator } from '../core/functions/chartGenerator.function';
import { LineChartData } from '../core/models/line-chart-data.model';
import { PrintService } from '../core/services/print.service';
import { NotificationService } from '../core/services/notification.service';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
})
export class PrintComponent {
  public printData: Print;
  public dataLoaded = false;
  public reportId: string;

  @Input() set id(reportId: string) {
    this.reportId = reportId;
    this.printService.loadPrintData(reportId).subscribe({
      next: (data: Print) => {
        this.printData = data;
        this.dataLoaded = true;
      },
      error: (err: any) => {
        this.notificationService.notifyApiError(err);
      },
    });
  }

  constructor(
    _router: Router,
    private printService: PrintService,
    private notificationService: NotificationService
  ) {}

  getSrc() {
    let url = environment.staticImageLink;
    url = url.replace(
      '[LAT]',
      this.printData.reportData.coordinates.lat.toString()
    );
    url = url.replace(
      '[LNG]',
      this.printData.reportData.coordinates.lng.toString()
    );
    url = url.replace(
      '[LAT]',
      this.printData.reportData.coordinates.lat.toString()
    );
    url = url.replace(
      '[LNG]',
      this.printData.reportData.coordinates.lng.toString()
    );
    return url;
  }

  getAddress() {
    if (
      this.printData === undefined ||
      this.printData.reportData === null ||
      this.printData.reportData.address === undefined ||
      this.printData.reportData.address === null
    ) {
      return '';
    }
    let returnString = '';
    let houseNum = '';
    if (this.printData.reportData.address.street !== '') {
      returnString = returnString + this.printData.reportData.address.street;
    }
    if (this.printData.reportData.address.house_number !== '') {
      if (returnString !== '') {
        returnString = returnString + '';
      }
      if (this.printData.reportData.address.house_number !== ' ') {
        houseNum = ' ' + this.printData.reportData.address.house_number;
      }
      returnString = returnString + houseNum;
    }
    if (this.printData.reportData.address.town !== '') {
      if (returnString !== '') {
        returnString = returnString + ', ';
      }
      returnString = returnString + this.printData.reportData.address.town;
    }
    return returnString;
  }

  getRegionalMarketData(saleMode: boolean) {
    const chartCalc = new ChartGenerator();
    let returnValue = new LineChartData();

    if (!saleMode || this.printData === undefined) {
      return returnValue;
    } else {
      returnValue = chartCalc.generateMarketData(
        this.printData.valuationRegionData,
        this.printData.valuationData
      );
    }
    return returnValue;
  }

  getMarketData(saleMode: boolean = false) {
    const chartCalc = new ChartGenerator();
    let returnValue = new LineChartData();

    if (this.printData === undefined) {
      return returnValue;
    }

    if (!saleMode) {
      returnValue = chartCalc.generateMarketData(
        this.printData.rentIpiData,
        this.printData.rentData
      );
      returnValue.mainColor = '#A2A811';
    } else {
      returnValue = chartCalc.generateMarketData(
        this.printData.valuationIpiData,
        this.printData.valuationData
      );
    }
    return returnValue;
  }

  formatDate(d1) {
    const dateTemp = new Date(Date.parse(d1));
    const month = dateTemp.getMonth() + 1;
    const day = dateTemp.getDate();
    const year = dateTemp.getFullYear();

    let dayString = day.toString();
    if (day < 10) {
      dayString = '0' + dayString;
    }

    let monthString = month.toString();
    if (month < 10) {
      monthString = '0' + monthString;
    }

    return dayString + '.' + monthString + '.' + year;
  }

  showCharts() {
    return this.printData.reportData?.config?.show_expert_view;
  }

  showRentalData() {
    return this.printData.reportData?.config?.show_rent;
  }

  showMarketData() {
    return this.printData.reportData?.config?.show_valuation;
  }

  showComparables() {
    return this.printData.reportData?.config?.show_compare_prices;
  }

  showSocio() {
    return this.printData.reportData?.config?.show_sociodemographic;
  }

  showEquip() {
    return this.printData.reportData?.config?.show_equipment_overview;
  }
}
