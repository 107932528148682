<div
  class="extended-object"
  *ngIf="reportData !== undefined">
  <div class="flex-grid mainBox">
    <div
      class="extended-overlay"
      *ngIf="!extendedResOk">
      Die Bildschirmauflösung wird nicht unterstützt. Bitte versuchen Sie es mit
      einer größeren Bildschirmauflösung.
    </div>
    <ng-container *ngIf="extendedResOk">
      <div class="col colLeft">
        <div class="box minHeight125 boxImage">
          <app-image
            [appError]="imageError"
            [images]="reportImages"
            [extended]="true"
            [type]="reportData.category"></app-image>
        </div>
        <div class="box boxInfo">
          <app-info
            class="infoBar-standard"
            [appError]="reportError"
            [reportData]="reportData"
            [extended]="true"></app-info>
        </div>
        <div class="box boxDetails">
          <app-details
            [extended]="true"
            [showLoader]="showLoader"
            [reloadDetails]="reloadDetails"
            [expertOpen]="expertOpen"
            [appError]="reportError"
            [reportData]="reportData"
            [columnsOnly]="true"
            [showButton]="false"
            (validationRequestEmitter)="updateValuation($event)"
            class="details"></app-details>
        </div>
      </div>
      <div class="col colRight">
        <div class="switch-container">
          <div
            *ngIf="
              this._activeDataService.reportData?.config?.show_valuation &&
              this._activeDataService.reportData?.config?.show_rent
            ">
            <span
              [ngClass]="{
                'on-off-toggled': this._activeDataService.saleMode
              }">
              Marktpreis
            </span>
            <div class="onoffswitch">
              <input
                type="checkbox"
                name="onoffswitch"
                class="onoffswitch-checkbox"
                id="myonoffswitch"
                tabindex="0"
                [disabled]="this.showLoader"
                [checked]="!this._activeDataService.saleMode"
                (change)="modeChange()" />
              <label
                class="onoffswitch-label"
                for="myonoffswitch"></label>
            </div>
            <span
              [ngClass]="{
                'on-off-toggled': !this._activeDataService.saleMode
              }">
              Mietwert
            </span>
          </div>
        </div>
        <div class="box boxValuation">
          <app-valuation
            [valuationData]="valuationData"
            [reportData]="reportData"
            [extended]="true"
            [saleMode]="this._activeDataService.saleMode"></app-valuation>
        </div>
        <div
          class="box boxRating"
          *ngIf="shouldShowEquip()">
          <app-equipment-rating
            *ngIf="shouldShowEquip()"
            [valuationData]="valuationData"
            [reportData]="reportData"
            [equipmentData]="equipmentData"
            [expanded]="true"
            [saleMode]="
              this._activeDataService.saleMode
            "></app-equipment-rating>
        </div>
        <div
          class="box boxTrend"
          *ngIf="shouldShowTrend()">
          <app-trend
            *ngIf="shouldShowTrend()"
            [saleMode]="this._activeDataService.saleMode"
            [extended]="true"
            [valuationData]="valuationData"
            [reportData]="reportData"
            [ipiData]="ipiData"></app-trend>
        </div>
        <div
          class="noTrendBoxSale"
          *ngIf="
            (!shouldShowTrend() || !shouldShowEquip()) &&
            this._activeDataService.saleMode
          "></div>
        <div
          class="noTrendBoxRent"
          *ngIf="
            (!shouldShowTrend() || !shouldShowEquip()) &&
            !this._activeDataService.saleMode
          "></div>
      </div>
    </ng-container>
  </div>
  <div class="buttons-container">
    <div class="left">
      <button
        mat-stroked-button
        class="sendForValBtn"
        (click)="requestValidation()"
        *ngIf="!this.showLoader && !_authService.readOnlyMode"
        [disabled]="this.checkDetailsButton()">
        Ergebnisse aktualisieren
      </button>
      <div
        class="valuation-loader-bar"
        *ngIf="this.showLoader && !_authService.readOnlyMode"
        :disabled>
        <div class="progress-line"></div>
        <span class="progress-text">Analyse wird erstellt</span>
      </div>
    </div>
    <div class="right">
      <button
        mat-stroked-button
        class="sendForValBtn"
        (click)="showExpert()"
        *ngIf="_activeDataService.reportData?.config?.show_expert_view">
        Expertenansicht
      </button>
    </div>
  </div>
  <div class="collapse-container">
    <div
      class="collapse-button"
      (click)="closeExpanded()"
      matTooltip="Erweiterte Ansicht schließen">
      <span class="spreng-icon-arrow-collapse collapseIcon"></span>
    </div>
  </div>
  <div
    class="close-button-container"
    (click)="closeExpanded()">
    <span class="spreng-icon-close"></span>
  </div>
</div>
