import { Injectable } from '@angular/core';
import { IpiGetResponse } from '../models/ipi-get-response.model';

@Injectable({
  providedIn: 'root',
})
export class TrendCalculator {
  constructor(private ipiData: IpiGetResponse) {}

  getOneYearTrend() {
    if (this.ipiData === undefined) {
      return 0;
    }
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    const currentQuarter = Math.floor((currentMonth + 2) / 3);

    let foundAt = -1;
    for (let i = 0; i < this.ipiData.values.length; i++) {
      if (
        this.ipiData.values[i].year === currentYear &&
        this.ipiData.values[i].quarter === currentQuarter
      ) {
        foundAt = i;
        i = this.ipiData.values.length;
      }
    }

    if (this.ipiData.values[foundAt + 4] === undefined) {
      foundAt = this.ipiData.values.length - 5;
    }

    if (foundAt === -1) {
      return 0;
    }
    return (
      (this.ipiData.values[foundAt + 4].index /
        this.ipiData.values[foundAt].index -
        1) *
      100
    );
  }

  getOneYearTrendValue() {
    if (this.ipiData === undefined) {
      return '3';
    }

    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    const currentQuarter = Math.floor((currentMonth + 2) / 3);

    let foundAt = -1;
    for (let i = 0; i < this.ipiData.values.length; i++) {
      if (
        this.ipiData.values[i].year === currentYear &&
        this.ipiData.values[i].quarter === currentQuarter
      ) {
        foundAt = i;
        i = this.ipiData.values.length;
      }
    }

    if (this.ipiData.values[foundAt + 4] === undefined) {
      foundAt = this.ipiData.values.length - 5;
    }

    if (foundAt === -1) {
      return '3';
    }
    return this.ipiData.values[foundAt + 4].trend;
  }

  getOneYearText() {
    const currentYear = new Date().getFullYear();

    return currentYear + ' - ' + (currentYear + 1);
  }

  getTwoYearTrend() {
    if (this.ipiData === undefined) {
      return 0;
    }

    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    const currentQuarter = Math.floor((currentMonth + 2) / 3);

    let foundAt = -1;
    for (let i = 0; i < this.ipiData.values.length; i++) {
      if (
        this.ipiData.values[i].year === currentYear + 1 &&
        this.ipiData.values[i].quarter === currentQuarter
      ) {
        foundAt = i;
        i = this.ipiData.values.length;
      }
    }

    if (this.ipiData.values[foundAt + 4] === undefined) {
      foundAt = this.ipiData.values.length - 5;
    }

    if (foundAt === -1) {
      return 0;
    }
    return (
      (this.ipiData.values[foundAt + 4].index /
        this.ipiData.values[foundAt].index -
        1) *
      100
    );
  }

  getTwoYearTrendValue() {
    if (this.ipiData === undefined) {
      return '3';
    }

    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    const currentQuarter = Math.floor((currentMonth + 2) / 3);

    let foundAt = -1;
    for (let i = 0; i < this.ipiData.values.length; i++) {
      if (
        this.ipiData.values[i].year === currentYear + 1 &&
        this.ipiData.values[i].quarter === currentQuarter
      ) {
        foundAt = i;
        i = this.ipiData.values.length;
      }
    }

    if (this.ipiData.values[foundAt + 4] === undefined) {
      foundAt = this.ipiData.values.length - 5;
    }

    if (foundAt === -1) {
      return '3';
    }

    return this.ipiData.values[foundAt + 4].trend;
  }

  getTwoYearText() {
    const currentYear = new Date().getFullYear();

    return currentYear + 1 + ' - ' + (currentYear + 2);
  }

  getThisYearTrend() {
    if (this.ipiData === undefined) {
      return 0;
    }

    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    const currentQuarter = Math.floor((currentMonth + 2) / 3);

    let foundAt = -1;
    for (let i = 0; i < this.ipiData.values.length; i++) {
      if (
        this.ipiData.values[i].year === currentYear - 1 &&
        this.ipiData.values[i].quarter === currentQuarter
      ) {
        foundAt = i;
        i = this.ipiData.values.length;
      }
    }

    if (this.ipiData.values[foundAt + 4] === undefined) {
      foundAt = this.ipiData.values.length - 5;
    }

    if (foundAt === -1) {
      return 0;
    }

    return (
      (this.ipiData.values[foundAt + 4].index /
        this.ipiData.values[foundAt].index -
        1) *
      100
    );
  }

  getThisYearTrendValue() {
    if (this.ipiData === undefined) {
      return '3';
    }

    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    const currentQuarter = Math.floor((currentMonth + 2) / 3);

    let foundAt = -1;
    for (let i = 0; i < this.ipiData.values.length; i++) {
      if (
        this.ipiData.values[i].year === currentYear - 1 &&
        this.ipiData.values[i].quarter === currentQuarter
      ) {
        foundAt = i;
        i = this.ipiData.values.length;
      }
    }

    if (this.ipiData.values[foundAt + 4] === undefined) {
      foundAt = this.ipiData.values.length - 5;
    }

    if (foundAt === -1) {
      return '3';
    }

    return this.ipiData.values[foundAt + 4].trend;
  }

  getThisYearText() {
    const currentYear = new Date().getFullYear();

    return currentYear - 1 + ' - ' + currentYear;
  }
}
