import { Report } from './reports.model';
import { Address } from './address.model';
import { Equipment } from './equipment.model';
import { Category } from '../enums/category.enum';

export class ReportRequest extends Report {
  edit_url: string;
  share_url: string;
  category: Category;
  address_geocoded: Address;
  construction: string;
  construction_year: number;
  living_area: number;
  plot_area: number;
  rooms: number;
  floor: number;
  floor_number: number;
  elevator: boolean;
  garages: boolean;
  outdoor_parking_space: boolean;
  equipment: Equipment;
}
