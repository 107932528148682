import { Reports } from '../models/reports.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class ReportsService {
  constructor(
    private _httpClient: HttpClient,
    _authenticationService: AuthenticationService
  ) {
    if (_authenticationService.authenticatedUser !== null) {
      this.url =
        this.url +
        '?system_id=' +
        _authenticationService.authenticatedUser.system_id +
        '&user_id=' +
        _authenticationService.authenticatedUser.user_id;
    }
  }
  private url: string = environment.apiUrl + '/reports';

  get(start = 0): Observable<Reports> {
    let requestUrl = this.url;
    if (start > 0) {
      requestUrl = this.url + '&start=' + start;
    }

    return this._httpClient.get<Reports>(requestUrl);
  }
}
