import { Component, OnInit, Injectable, Input } from '@angular/core';
import { GeocoderService } from 'src/app/core/services/geocoder.service';
import { environment } from 'src/environments/environment';
import { MapboxSettings } from 'src/app/core/mapbox.settings';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

@Component({
  selector: 'app-geocoder',
  templateUrl: './geocoder.component.html',
  styleUrls: ['./geocoder.component.scss'],
})
@Injectable()
export class GeocoderComponent implements OnInit {
  @Input() suggestionsTop = false;

  constructor(
    private _geoCoderService: GeocoderService,
    private _authService: AuthenticationService
  ) {}

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit() {
    const mapboxSettings = new MapboxSettings();
    if (
      this._authService.authenticatedUser !== null &&
      this._authService.authenticatedUser.nations.length > 0
    ) {
      mapboxSettings.countries = this._authService.authenticatedUser.nations
        .toString()
        .toLowerCase();
    }
    const geocoder = new MapboxGeocoder({
      accessToken: environment.mapboxAccessToken,
      proximity: { longitude: 10.4515, latitude: 51.1657 },
      countries: mapboxSettings.countries,
      language: 'de',
      limit: 10,
      types: 'address', // "country", "region", "postcode", "district", "place", "locality", "neighborhood", "address", "poi"
      placeholder: 'Geben Sie eine Adresse ein',
      render(item) {
        const container = document.getElementsByClassName('suggestions')[0];
        container.scrollTop = 0;
        let dispText = item.place_name;
        const element = document.getElementsByClassName('geocoder')[0];
        const positionInfo = element.getBoundingClientRect();
        const width = positionInfo.width;
        const maxChars = 1 + width / 7;

        if (maxChars > 0 && dispText.length > maxChars) {
          dispText = dispText.slice(0, maxChars) + '...';
        }

        return (
          '<div name="geocoder-result" style="text-align:left;">' +
          dispText +
          '</div>'
        );
      },
    });

    geocoder.setMinLength(3);

    geocoder.on('result', e => {
      this._geoCoderService.geocoderSearchEmitter.emit(e);
    });

    geocoder.addTo('.geocoder');
  }

  formatSearchResult(item) {}
}
