import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { AppHttpErrorResponse } from './error.service';

@Injectable()
export class NotificationService {
  constructor(private _toastr: ToastrService) {}

  notifyApiError(err: AppHttpErrorResponse) {
    if (err.error.errors === null || err.error.errors === undefined) {
      this._toastr.error(err.error, err.message);
    } else {
      err.error.errors.forEach(element => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            this.notifyError(key + ': ' + element[key]);
          }
        }
      });
    }
  }

  notifyError(error: string) {
    this._toastr.error(error);
  }

  notifyWarning(warning: string) {
    this._toastr.warning(warning);
  }

  notifySuccess(success: string) {
    this._toastr.success(success);
  }
}
