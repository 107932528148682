import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { PoiGetResponse } from '../models/poi-get-response.model';

@Injectable()
export class PoiService {
  constructor(private _httpClient: HttpClient) {}
  private url: string = environment.apiUrl + '/report/poi';

  get(reportId: string): Observable<PoiGetResponse> {
    return this._httpClient.get<PoiGetResponse>(
      this.url + '?report_id=' + reportId
    );
  }
}
