<!--<app-loading *ngIf="images === undefined"></app-loading>-->
<!-- <carousel  [(activeSlide)]="activeSlide" *ngIf="images !== undefined">     
    <slide *ngFor="let image of images; let i = index" class="carousel-slide">
        <img [ngClass]="getImageClass()" [src]="getImageString(i)" style="display: block;" (click)="imageClick(i)">
    </slide>
</carousel> 
[ngClass]="getImageClass()" 
-->

<!-- <img [src]="getImageString(0)" class="imgToShow" (click)="imageClick(0)"> -->

<div
  class="imgToShow"
  [ngStyle]="getImageString(0)"
  [ngClass]="{ shareImage: isShare }"></div>
