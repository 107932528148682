import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { DetailComponent } from './detail/detail.component';
import { DetailsComponent } from './details/details.component';
import { ImageComponent } from './image/image.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { InfoComponent } from './info/info.component';
import { TrendComponent } from './trend/trend.component';
import { ValuationComponent } from './valuation/valuation.component';
import { SwitchComponent } from './controls/switch/switch.component';
import { RatingComponent } from './rating/rating.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { SimilarityRatingComponent } from './similarity-rating/similarity-rating.component';
import { ErrorComponent } from '../error/error.component';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { DistanceComponent } from './distance/distance.component';
import { ConstructionComponent } from './construction/construction.component';
import { EquipmentRatingComponent } from './equipment-rating/equipment-rating.component';
import { ChartContainerComponent } from './chart-container/chart-container.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { ImageFallbackDirective } from './img-fallback/image-fallback.directive';
import { EnergyInfoComponent } from './energy-info/energy-info.component';

@NgModule({
  declarations: [
    StarRatingComponent,
    DetailComponent,
    DetailsComponent,
    ErrorComponent,
    ImageComponent,
    InfoComponent,
    TrendComponent,
    ValuationComponent,
    RatingComponent,
    LineChartComponent,
    SimilarityRatingComponent,
    SwitchComponent,
    DistanceComponent,
    ConstructionComponent,
    EquipmentRatingComponent,
    ChartContainerComponent,
    ImageFallbackDirective,
    EnergyInfoComponent,
  ],
  imports: [
    CommonModule,
    NgChartsModule,
    CarouselModule.forRoot(),
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
  ],
  exports: [
    StarRatingComponent,
    DetailsComponent,
    ErrorComponent,
    ImageComponent,
    InfoComponent,
    TrendComponent,
    ValuationComponent,
    RatingComponent,
    LineChartComponent,
    SimilarityRatingComponent,
    SwitchComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    DistanceComponent,
    ReactiveFormsModule,
    ConstructionComponent,
    EquipmentRatingComponent,
    ChartContainerComponent,
    ImageFallbackDirective,
    EnergyInfoComponent,
  ],
})
export class SharedModule {}
