<div
  class="page main-page"
  *ngIf="
    dataLoaded &&
    printData !== undefined &&
    printData.reportData !== undefined &&
    printData.userData !== undefined
  ">
  <div class="page-header">
    <span class="title">Kompaktanalyse</span>
    <span class="small-title">
      {{ formatDate(printData.reportData.dates.updated) }}
    </span>
  </div>
  <div class="page-sub-header">
    <span class="sub-title">
      Ansprechpartner: {{ printData.userData.user_data.salutation }}
      {{ printData.userData.user_data.firstname }}
      {{ printData.userData.user_data.lastname }}
    </span>
    <span class="small-title">
      <div
        *ngIf="printData.userData.user_data.email !== ''"
        class="contact-info">
        <span>
          <img
            src="../../assets/icons/email.svg"
            alt="email" />
        </span>
      </div>
      <div
        *ngIf="printData.userData.user_data.email !== ''"
        class="contact-info">
        <span>{{ printData.userData.user_data.email }}</span>
      </div>
      <br />
      <div
        *ngIf="
          printData.userData.user_data.phone !== '' &&
          printData.userData.user_data.phone !== undefined
        "
        class="contact-info">
        <span>
          <img
            src="../../assets/icons/phone.svg"
            alt="phone" />
        </span>
      </div>
      <div
        *ngIf="
          printData.userData.user_data.phone !== '' &&
          printData.userData.user_data.phone !== undefined
        "
        class="contact-info">
        <span>{{ printData.userData.user_data.phone }}</span>
      </div>
    </span>
  </div>
  <div class="sub-header-spacing"></div>
  <div class="main-content">
    <div class="top-row">
      <div class="mapImg">
        <img
          [src]="getSrc()"
          alt=""
          style="width: 252px; height: 155px" />
        <div class="mapImg-info">
          <span class="spreng-icon-pin"></span>
          <span class="mapImg-text">{{ getAddress() }}</span>
        </div>
      </div>
      <div class="info">
        <app-details
          [isPrintMode]="true"
          [reportData]="printData.reportData"
          [columnsOnly]="true"
          class="details"
          [reloadDetails]="true"></app-details>
      </div>
    </div>
    <div class="mid-row">
      <div
        class="data-container"
        *ngIf="showMarketData()">
        <span class="data-header">Marktpreis</span>
        <div class="inner-data-container">
          <div class="valuation">
            <app-valuation
              [valuationData]="printData.valuationData"
              [reportData]="printData.reportData"
              [saleMode]="true"
              [printMode]="true"></app-valuation>
          </div>
          <div class="equiment-trend-container">
            <div
              class="equipment"
              *ngIf="showEquip()">
              <app-equipment-rating
                [valuationData]="printData.valuationData"
                [reportData]="printData.reportData"
                [equipmentData]="printData.valuationEquipmentData"
                [printMode]="true"
                [saleMode]="true"></app-equipment-rating>
            </div>
            <div class="trend">
              <app-trend
                [saleMode]="true"
                [printMode]="true"
                [valuationData]="printData.valuationData"
                [reportData]="printData.reportData"
                [ipiData]="printData.valuationIpiData"></app-trend>
            </div>
          </div>
        </div>
      </div>
      <div
        class="data-container rent-container"
        *ngIf="!showMarketData()">
        <span class="data-header">Mietwert</span>
        <div class="inner-data-container">
          <div class="valuation">
            <app-valuation
              [valuationData]="printData.rentData"
              [reportData]="printData.reportData"
              [saleMode]="false"
              [printMode]="true"></app-valuation>
          </div>
          <div class="equiment-trend-container">
            <div
              class="equipment"
              *ngIf="showEquip()">
              <app-equipment-rating
                [valuationData]="printData.rentData"
                [reportData]="printData.reportData"
                [equipmentData]="printData.rentEquipmentData"
                [printMode]="true"
                [saleMode]="false"></app-equipment-rating>
            </div>
            <div class="trend">
              <app-trend
                [saleMode]="false"
                [printMode]="true"
                [valuationData]="printData.rentData"
                [reportData]="printData.reportData"
                [ipiData]="printData.rentIpiData"></app-trend>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-row">
      <div
        class="chart-info"
        *ngIf="
          (!showMarketData() || !showRentalData()) &&
          !showComparables() &&
          !showCharts()
        ">
        <div class="chart-info-icon">
          <span class="spreng-icon-info-outline"></span>
        </div>
        <div class="chart-info-text">
          <span>
            Die Sprengnetter Immobiliendatenbank ist eine der größten
            Deutschlands und Österreichs. Das Besondere: Dort sind nicht nur
            Millionen von Angebotspreisen und Angebotsmieten, sondern auch
            Hunderttausende aktuelle und real erzielte Kaufpreise für alle Lagen
            in Deutschland und Österreich gespeichert. Für die Bewertung stehen
            damit weit über eine Milliarde Objektinformationen zur Verfügung.
            Der Inhalt dieses Dokuments unterliegt dem Urheberrecht.
            Veränderungen, Kürzungen, Erweiterungen und Ergänzungen bedürfen der
            vorherigen Einwilligung der Sprengnetter GmbH. Die Darstellung der
            Lage des Bewertungsobjekts Verwendung der statischen Webkarte von
            Mapbox und deren bereitgestellten Datengrundlagen basierend auf dem
            Datenmaterial von OpenStreetMaps.
            Datenquellen:https://www.mapbox.com/about/maps
            http://www.openstreetmap.org/copyright. Bei diesem Dokument handelt
            es sich um das Ergebnis einer automatisierten Bewertung, die
            ausschließlich auf den Angaben des Nutzers und den hinterlegten
            Daten beruht. Seitens der Sprengnetter Verlag und Software GmbH
            wurden die Angaben nicht überprüft. Das Objekt wurde durch die
            Sprengnetter GmbH nicht besichtigt. Von der Sprengnetter GmbH kann
            deshalb keine Gewähr für die Richtigkeit der dargestellten Daten
            übernommen werden. Sprengnetter GmbH, Heerstraße 109, 53474 Bad
            Neuenahr-Ahrweiler
          </span>
        </div>
      </div>
      <div
        class="data-container rent-container"
        *ngIf="showRentalData() && showMarketData()">
        <span class="data-header">Mietwert</span>
        <div class="inner-data-container">
          <div class="valuation">
            <app-valuation
              [valuationData]="printData.rentData"
              [reportData]="printData.reportData"
              [saleMode]="false"
              [printMode]="true"></app-valuation>
          </div>
          <div class="equiment-trend-container">
            <div
              class="equipment"
              *ngIf="showEquip()">
              <app-equipment-rating
                [valuationData]="printData.rentData"
                [reportData]="printData.reportData"
                [equipmentData]="printData.rentEquipmentData"
                [printMode]="true"
                [saleMode]="false"></app-equipment-rating>
            </div>
            <div class="trend">
              <app-trend
                [saleMode]="false"
                [printMode]="true"
                [valuationData]="printData.rentData"
                [reportData]="printData.reportData"
                [ipiData]="printData.rentIpiData"></app-trend>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-footer">
    <span class="sub-title">
      {{ getAddress() }}
    </span>
    <span class="footer-image">
      <img
        src="../../assets/icons/Sprengnetter-logo.svg"
        alt="logo" />
    </span>
  </div>
</div>
<div
  class="page chart-page"
  *ngIf="
    dataLoaded &&
    printData !== undefined &&
    printData.reportData !== undefined &&
    printData.userData !== undefined &&
    showComparables() &&
    showMarketData()
  ">
  <div class="page-header">
    <span class="title">Kompaktanalyse</span>
    <span class="small-title">
      {{ formatDate(printData.reportData.dates.updated) }}
    </span>
  </div>
  <div class="page-sub-header">
    <span class="sub-title">
      Ansprechpartner: {{ printData.userData.user_data.salutation }}
      {{ printData.userData.user_data.firstname }}
      {{ printData.userData.user_data.lastname }}
    </span>
    <span class="small-title">
      <div
        *ngIf="printData.userData.user_data.email !== ''"
        class="contact-info email-icon">
        <span>
          <img
            src="../../assets/icons/email.svg"
            alt="email" />
        </span>
      </div>
      <div
        *ngIf="printData.userData.user_data.email !== ''"
        class="contact-info">
        <span>{{ printData.userData.user_data.email }}</span>
      </div>
      <br />
      <div
        *ngIf="
          printData.userData.user_data.phone !== '' &&
          printData.userData.user_data.phone !== undefined
        "
        class="contact-info phone-icon">
        <span>
          <img
            src="../../assets/icons/phone.svg"
            alt="phone" />
        </span>
      </div>
      <div
        *ngIf="
          printData.userData.user_data.phone !== '' &&
          printData.userData.user_data.phone !== undefined
        "
        class="contact-info">
        <span>{{ printData.userData.user_data.phone }}</span>
      </div>
    </span>
  </div>
  <div class="main-content">
    <div class="inner-content">
      <app-expert-comparables
        [valuation]="printData.valuationData"
        [print]="true"
        [rentPrint]="false"></app-expert-comparables>
    </div>
  </div>
  <div class="page-footer">
    <span class="sub-title">
      {{ getAddress() }}
    </span>
    <span class="footer-image">
      <img
        src="../../assets/icons/Sprengnetter-logo.svg"
        alt="logo" />
    </span>
  </div>
</div>
<div
  class="page chart-page"
  *ngIf="
    dataLoaded &&
    printData !== undefined &&
    printData.reportData !== undefined &&
    printData.userData !== undefined &&
    showComparables() &&
    showRentalData()
  ">
  <div class="page-header">
    <span class="title">Kompaktanalyse</span>
    <span class="small-title">
      {{ formatDate(printData.reportData.dates.updated) }}
    </span>
  </div>
  <div class="page-sub-header">
    <span class="sub-title">
      Ansprechpartner: {{ printData.userData.user_data.salutation }}
      {{ printData.userData.user_data.firstname }}
      {{ printData.userData.user_data.lastname }}
    </span>
    <span class="small-title">
      <div
        *ngIf="printData.userData.user_data.email !== ''"
        class="contact-info email-icon">
        <span>
          <img
            src="../../assets/icons/email.svg"
            alt="email" />
        </span>
      </div>
      <div
        *ngIf="printData.userData.user_data.email !== ''"
        class="contact-info">
        <span>{{ printData.userData.user_data.email }}</span>
      </div>
      <br />
      <div
        *ngIf="
          printData.userData.user_data.phone !== '' &&
          printData.userData.user_data.phone !== undefined
        "
        class="contact-info phone-icon">
        <span>
          <img
            src="../../assets/icons/phone.svg"
            alt="phone" />
        </span>
      </div>
      <div
        *ngIf="
          printData.userData.user_data.phone !== '' &&
          printData.userData.user_data.phone !== undefined
        "
        class="contact-info">
        <span>{{ printData.userData.user_data.phone }}</span>
      </div>
    </span>
  </div>
  <div class="main-content">
    <div class="inner-content">
      <app-expert-comparables
        [valuation]="printData.rentData"
        [print]="true"
        [rentPrint]="true"></app-expert-comparables>
    </div>
  </div>
  <div class="page-footer">
    <span class="sub-title">
      {{ getAddress() }}
    </span>
    <span class="footer-image">
      <img
        src="../../assets/icons/Sprengnetter-logo.svg"
        alt="logo" />
    </span>
  </div>
</div>
<div
  class="page chart-page"
  *ngIf="
    dataLoaded &&
    printData !== undefined &&
    printData.reportData !== undefined &&
    printData.userData !== undefined &&
    showSocio()
  ">
  <div class="page-header">
    <span class="title">Kompaktanalyse</span>
    <span class="small-title">
      {{ formatDate(printData.reportData.dates.updated) }}
    </span>
  </div>
  <div class="page-sub-header">
    <span class="sub-title">
      Ansprechpartner: {{ printData.userData.user_data.salutation }}
      {{ printData.userData.user_data.firstname }}
      {{ printData.userData.user_data.lastname }}
    </span>
    <span class="small-title">
      <div
        *ngIf="printData.userData.user_data.email !== ''"
        class="contact-info email-icon">
        <span>
          <img
            src="../../assets/icons/email.svg"
            alt="email" />
        </span>
      </div>
      <div
        *ngIf="printData.userData.user_data.email !== ''"
        class="contact-info">
        <span>{{ printData.userData.user_data.email }}</span>
      </div>
      <br />
      <div
        *ngIf="
          printData.userData.user_data.phone !== '' &&
          printData.userData.user_data.phone !== undefined
        "
        class="contact-info phone-icon">
        <span>
          <img
            src="../../assets/icons/phone.svg"
            alt="phone" />
        </span>
      </div>
      <div
        *ngIf="
          printData.userData.user_data.phone !== '' &&
          printData.userData.user_data.phone !== undefined
        "
        class="contact-info">
        <span>{{ printData.userData.user_data.phone }}</span>
      </div>
    </span>
  </div>
  <div class="main-content">
    <div class="inner-content">
      <app-expert-sociodemographic
        [full]="true"
        [print]="true"
        [reportId]="reportId"></app-expert-sociodemographic>
    </div>
  </div>
  <div class="page-footer">
    <span class="sub-title">
      {{ getAddress() }}
    </span>
    <span class="footer-image">
      <img
        src="../../assets/icons/Sprengnetter-logo.svg"
        alt="logo" />
    </span>
  </div>
</div>
<div
  class="page chart-page"
  *ngIf="
    dataLoaded &&
    printData !== undefined &&
    printData.reportData !== undefined &&
    printData.userData !== undefined &&
    showSocio()
  ">
  <div class="page-header">
    <span class="title">Kompaktanalyse</span>
    <span class="small-title">
      {{ formatDate(printData.reportData.dates.updated) }}
    </span>
  </div>
  <div class="page-sub-header">
    <span class="sub-title">
      Ansprechpartner: {{ printData.userData.user_data.salutation }}
      {{ printData.userData.user_data.firstname }}
      {{ printData.userData.user_data.lastname }}
    </span>
    <span class="small-title">
      <div
        *ngIf="printData.userData.user_data.email !== ''"
        class="contact-info email-icon">
        <span>
          <img
            src="../../assets/icons/email.svg"
            alt="email" />
        </span>
      </div>
      <div
        *ngIf="printData.userData.user_data.email !== ''"
        class="contact-info">
        <span>{{ printData.userData.user_data.email }}</span>
      </div>
      <br />
      <div
        *ngIf="
          printData.userData.user_data.phone !== '' &&
          printData.userData.user_data.phone !== undefined
        "
        class="contact-info phone-icon">
        <span>
          <img
            src="../../assets/icons/phone.svg"
            alt="phone" />
        </span>
      </div>
      <div
        *ngIf="
          printData.userData.user_data.phone !== '' &&
          printData.userData.user_data.phone !== undefined
        "
        class="contact-info">
        <span>{{ printData.userData.user_data.phone }}</span>
      </div>
    </span>
  </div>
  <div class="main-content">
    <div class="inner-content">
      <app-expert-sociodemographic
        [full]="true"
        [print]="true"
        [page]="2"
        [reportId]="reportId"></app-expert-sociodemographic>
    </div>
  </div>
  <div class="page-footer">
    <span class="sub-title">
      {{ getAddress() }}
    </span>
    <span class="footer-image">
      <img
        src="../../assets/icons/Sprengnetter-logo.svg"
        alt="logo" />
    </span>
  </div>
</div>
<div
  class="page chart-page"
  *ngIf="
    dataLoaded &&
    printData !== undefined &&
    printData.reportData !== undefined &&
    printData.userData !== undefined &&
    !(
      (!showMarketData() || !showRentalData()) &&
      !showComparables() &&
      !showCharts()
    )
  ">
  <div class="page-header">
    <span class="title">Kompaktanalyse</span>
    <span class="small-title">
      {{ formatDate(printData.reportData.dates.updated) }}
    </span>
  </div>
  <div class="page-sub-header">
    <span class="sub-title">
      Ansprechpartner: {{ printData.userData.user_data.salutation }}
      {{ printData.userData.user_data.firstname }}
      {{ printData.userData.user_data.lastname }}
    </span>
    <span class="small-title">
      <div
        *ngIf="printData.userData.user_data.email !== ''"
        class="contact-info email-icon">
        <span>
          <img
            src="../../assets/icons/email.svg"
            alt="email" />
        </span>
      </div>
      <div
        *ngIf="printData.userData.user_data.email !== ''"
        class="contact-info">
        <span>{{ printData.userData.user_data.email }}</span>
      </div>
      <br />
      <div
        *ngIf="
          printData.userData.user_data.phone !== '' &&
          printData.userData.user_data.phone !== undefined
        "
        class="contact-info phone-icon">
        <span>
          <img
            src="../../assets/icons/phone.svg"
            alt="phone" />
        </span>
      </div>
      <div
        *ngIf="
          printData.userData.user_data.phone !== '' &&
          printData.userData.user_data.phone !== undefined
        "
        class="contact-info">
        <span>{{ printData.userData.user_data.phone }}</span>
      </div>
    </span>
  </div>
  <div class="main-content">
    <span
      class="blur-text"
      *ngIf="!showCharts() || !showMarketData()">
      Für mehr Informationen kontaktieren Sie einfach Ihren Berater
    </span>
    <div
      class="blur-container blur-sale"
      *ngIf="!showCharts() || !showMarketData()"></div>
    <div class="chart-container">
      <span class="data-header">Marktpreis</span>
      <app-line-chart
        [chartSettings]="getRegionalMarketData(true)"
        [secondaryChartSettings]="getMarketData(true)"
        [keepAspect]="false"
        [printView]="true"></app-line-chart>
    </div>
    <span
      class="blur-text"
      *ngIf="!showCharts() || !showRentalData()">
      Für mehr Informationen kontaktieren Sie einfach Ihren Berater
    </span>
    <div
      class="blur-container blur-rent"
      *ngIf="!showCharts() || !showRentalData()"></div>
    <div class="chart-container chart-rent-container">
      <span class="data-header">Miete</span>
      <app-line-chart
        [chartSettings]="getMarketData()"
        [keepAspect]="false"
        [printView]="true"></app-line-chart>
    </div>

    <div class="chart-info">
      <div class="chart-info-icon">
        <span class="spreng-icon-info-outline"></span>
      </div>
      <div class="chart-info-text">
        <span>
          Die Sprengnetter Immobiliendatenbank ist eine der größten Deutschlands
          und Österreichs. Das Besondere: Dort sind nicht nur Millionen von
          Angebotspreisen und Angebotsmieten, sondern auch Hunderttausende
          aktuelle und real erzielte Kaufpreise für alle Lagen in Deutschland
          und Österreich gespeichert. Für die Bewertung stehen damit weit über
          eine Milliarde Objektinformationen zur Verfügung. Der Inhalt dieses
          Dokuments unterliegt dem Urheberrecht. Veränderungen, Kürzungen,
          Erweiterungen und Ergänzungen bedürfen der vorherigen Einwilligung der
          Sprengnetter Property Valuation Finance GmbH. Bei diesem Dokument
          handelt es sich um das Ergebnis einer automatisierten Bewertung, die
          ausschließlich auf den Angaben des Nutzers und den hinterlegten Daten
          beruht. Das Objekt wurde durch die Sprengnetter Property Valuation
          Finance GmbH nicht besichtigt und die die Angaben nicht überprüft. Von
          der Sprengnetter GmbH kann deshalb keine Gewähr für die Richtigkeit
          der dargestellten Daten übernommen werden. Sprengnetter Property
          Valuation Finance GmbH, Sprengnetter-Campus 1, 53474 Bad
          Neuenahr-Ahrweiler. Die Darstellung der Lage des Bewertungsobjekts
          Verwendung der statischen Webkarte von Mapbox und deren
          bereitgestellten Datengrundlagen basierend auf dem Datenmaterial von
          OpenStreetMaps. Datenquellen: https://www.mapbox.com/about/maps
          http://www.openstreetmap.org/copyright.
        </span>
      </div>
    </div>
  </div>
  <div class="page-footer">
    <span class="sub-title">
      {{ getAddress() }}
    </span>
    <span class="footer-image">
      <img
        src="../../assets/icons/Sprengnetter-logo.svg"
        alt="logo" />
    </span>
  </div>
</div>
