import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { Image } from 'src/app/core/models/images.model';
import { Report } from 'src/app/core/models/reports.model';
import { ReportService } from 'src/app/core/services/report.service';
import { Valuation } from 'src/app/core/models/valuation.model';
import { RentResponse } from 'src/app/core/models/rent-response.model';
import { IpiGetResponse } from 'src/app/core/models/ipi-get-response.model';
import { ValuationService } from 'src/app/core/services/valuation.service';
import { RentService } from 'src/app/core/services/rent.service';
import { environment } from 'src/environments/environment';
import { HelperFunctions } from 'src/app/core/functions/helper.functions';
import { ActiveDataService } from 'src/app/core/services/active-data.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { DetailsComponent } from 'src/app/shared/details/details.component';
import {
  animate,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-valuation-result',
  templateUrl: './valuation-result.component.html',
  styleUrls: ['./valuation-result.component.scss'],
  animations: [
    trigger('fadeVal', [
      transition(':leave', [
        query(':self', [animate('0.25s', style({ opacity: 0 }))]),
      ]),
      transition(':enter', [
        query(':self', [
          style({ opacity: 0 }),
          animate('0.25s 0.15s', style({ opacity: 1 })),
        ]),
      ]),
    ]),
  ],
})
export class ValuationResultComponent implements OnInit, OnChanges {
  @Input() reportId = '';
  @Output() objectClosedEmitter = new EventEmitter<boolean>();
  @Output() lockRequestEmitter = new EventEmitter<boolean>();
  @Output() currentValuationEmitter = new EventEmitter<Valuation>();
  @Output() expandEmitter = new EventEmitter();
  @Output() expertClicked = new EventEmitter();
  @Input() docked = false;
  @ViewChild(DetailsComponent) details: DetailsComponent;
  public trendValuationIndex = 0;

  public reloadDetails = false;
  // data variables
  public imageData: Image[];
  public imageError = false;

  public reportData: Report;
  public reportError = false;
  private reportTrys = 0;
  private reportSubscription: Subscription = null;

  public valuationData: Valuation;
  public valuationError = false;
  private valuationTrys = 0;
  private valuationSubscription: Subscription = null;

  public rentData: RentResponse;
  public rentError = false;
  private rentTrys = 0;
  private rentSubscription: Subscription = null;

  public ipiRent: IpiGetResponse;
  private ipiRentSubscription: Subscription;
  private ipiRentTrys = 0;

  public ipiValuation: IpiGetResponse;
  private ipiValuationSubscription: Subscription;
  private ipiValuationTrys = 0;

  public showLoader = false;
  private firstload = true;

  private origRepDate = null;
  private origValuationDate = null;
  private origRentDate = null;
  private origIpiValDate = null;
  private origIpiRentDate = null;

  constructor(
    private _reportService: ReportService,
    private _valuationService: ValuationService,
    private _rentService: RentService,
    public _activeDataService: ActiveDataService,
    public _authService: AuthenticationService
  ) {}

  ngOnInit() {
    if (this.reportId !== '') {
      if (this._activeDataService.ipiValuation === undefined) {
        this.loadIpiValuation();
      } else {
        this.ipiValuation = this._activeDataService.ipiValuation;
      }

      if (this._activeDataService.rentData === undefined) {
        this.loadRent();
      } else {
        this.rentData = this._activeDataService.rentData;
      }

      if (this._activeDataService.valuationData === undefined) {
        this.loadValuation();
      } else {
        this.valuationData = this._activeDataService.valuationData;
      }

      if (this._activeDataService.reportData === undefined) {
        this.loadCoreReport();
      } else {
        this.reportData = this._activeDataService.reportData;
      }

      if (this._activeDataService.ipiRent === undefined) {
        this.loadIpiRent();
      } else {
        this.ipiRent = this._activeDataService.ipiRent;
      }
    }

    this.firstload = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.reportId !== null &&
      changes.reportId !== undefined &&
      !this.firstload
    ) {
      this.imageData = undefined;
      this.valuationData = undefined;
      this.rentData = undefined;
      this.reportData = undefined;
      this.ipiValuation = undefined;
      this.ipiRent = undefined;

      this.loadCoreReport();
      this.loadValuation();
      this.loadRent();
      this.loadIpiValuation();
      this.loadIpiRent();
    }
  }

  close() {
    this.objectClosedEmitter.emit(true);
  }

  loadCoreReport() {
    this.reportTrys = this.reportTrys + 1;
    this.reportSubscription = this._reportService.get(this.reportId).subscribe(
      (data: Report) => {
        if (data === null) {
          if (this.reportTrys < environment.apiRetries) {
            const helper = new HelperFunctions();
            helper.delay(1000 * this.reportTrys).then(() => {
              this.loadCoreReport();
            });
          }
        }

        if (
          this.origRepDate !== null &&
          this.origRepDate === data.dates?.updated
        ) {
          if (this.reportTrys < environment.apiRetries) {
            const helper = new HelperFunctions();
            helper.delay(1000 * this.reportTrys).then(() => {
              this.loadCoreReport();
            });
          }
        }

        this.origRepDate = data.dates?.updated;

        if (data.config?.show_valuation === false) {
          this._activeDataService.saleMode = false;
        }

        this.reportData = data;
        this._activeDataService.reportData = data;
        this._activeDataService.cachedConfig = undefined;

        this.reportSubscription.unsubscribe();
      },
      () => {
        this.reportSubscription.unsubscribe();
        this.reportError = true;
        this.valuationError = true;
        this.rentError = true;
      }
    );
  }

  loadValuation(emitData = false) {
    this.valuationTrys = this.valuationTrys + 1;
    this.valuationSubscription = this._valuationService
      .get(this.reportId)
      .subscribe(
        (data: Valuation) => {
          if (data === null) {
            if (this.valuationTrys < environment.apiRetries) {
              const helper = new HelperFunctions();
              helper.delay(1000 * this.valuationTrys).then(() => {
                this.loadValuation();
              });
            }
          }

          if (
            this.origValuationDate !== null &&
            this.origValuationDate === data.date
          ) {
            if (this.valuationTrys < environment.apiRetries) {
              const helper = new HelperFunctions();
              helper.delay(1000 * this.valuationTrys).then(() => {
                this.loadValuation();
              });
            }
          }

          this.origValuationDate = data.date;

          this.valuationData = data;
          this._activeDataService.valuationData = data;
          this._activeDataService.getValuationData();
          if (emitData) {
            this.currentValuationEmitter.emit(data);
          }
          this.valuationSubscription.unsubscribe();
        },
        () => {
          this.valuationSubscription.unsubscribe();
          this.valuationError = true;
        }
      );
  }

  loadRent(emitData = false) {
    this.rentTrys = this.rentTrys + 1;
    this.rentSubscription = this._rentService.get(this.reportId).subscribe(
      (data: RentResponse) => {
        if (data === null) {
          if (this.rentTrys < environment.apiRetries) {
            const helper = new HelperFunctions();
            helper.delay(1000 * this.rentTrys).then(() => {
              this.loadRent();
            });
          }
        }

        if (this.origRentDate !== null && this.origRentDate === data.date) {
          if (this.rentTrys < environment.apiRetries) {
            const helper = new HelperFunctions();
            helper.delay(1000 * this.rentTrys).then(() => {
              this.loadRent();
            });
          }
        }

        this.origRentDate = data.date;
        this._activeDataService.rentData = data;
        this.rentData = data;
        this.rentSubscription.unsubscribe();
      },
      () => {
        this.rentSubscription.unsubscribe();
        this.rentError = true;
      }
    );
  }

  loadIpiValuation() {
    this.ipiValuationTrys = this.ipiValuationTrys + 1;
    this.ipiValuationSubscription = this._valuationService
      .getIpi(this.reportId)
      .subscribe(
        (data: IpiGetResponse) => {
          if (data === null) {
            if (this.ipiValuationTrys < environment.apiRetries) {
              const helper = new HelperFunctions();
              helper.delay(1000 * this.ipiValuationTrys).then(() => {
                this.loadIpiValuation();
              });
            }
          }

          if (
            this.origIpiValDate !== null &&
            this.origIpiValDate === data.date
          ) {
            if (this.ipiValuationTrys < environment.apiRetries) {
              const helper = new HelperFunctions();
              helper.delay(1000 * this.ipiValuationTrys).then(() => {
                this.loadIpiValuation();
              });
            }
          }

          this.origIpiValDate = data.date;
          this._activeDataService.ipiValuation = data;
          this.ipiValuation = data;
          this.ipiValuationSubscription.unsubscribe();
        },
        () => {
          this.ipiValuationSubscription.unsubscribe();
          this.valuationError = true;
        }
      );
  }

  loadIpiRent() {
    this.ipiRentTrys = this.ipiRentTrys + 1;
    this.ipiRentSubscription = this._rentService
      .getIpi(this.reportId)
      .subscribe(
        (data: IpiGetResponse) => {
          if (data === null) {
            if (this.ipiRentTrys < environment.apiRetries) {
              const helper = new HelperFunctions();
              helper.delay(1000 * this.ipiRentTrys).then(() => {
                this.loadIpiRent();
              });
            }
          }
          if (
            this.origIpiRentDate !== null &&
            this.origIpiRentDate === data.date
          ) {
            if (this.ipiRentTrys < environment.apiRetries) {
              const helper = new HelperFunctions();
              helper.delay(1000 * this.ipiRentTrys).then(() => {
                this.loadIpiRent();
              });
            }
          }

          this.origIpiRentDate = data.date;
          this._activeDataService.ipiRent = data;
          this.ipiRent = data;
          this.ipiRentSubscription.unsubscribe();
        },
        () => {
          this.ipiRentSubscription.unsubscribe();
          this.rentError = true;
        }
      );
  }

  updateValuation(report: Report) {
    this._activeDataService.clearAll();
    report.config = this._activeDataService.cachedConfig;
    report.request.config = this._activeDataService.cachedConfig;
    this.reloadDetails = false;
    this.showLoader = true;
    this._reportService.put(report.request, report.report_id).subscribe({
      next: () => {
        this.loadCoreReport();
        this.loadValuation(true);
        this.loadRent();
        this.loadIpiValuation();
        this.loadIpiRent();
        this.showLoader = false;
        this.reloadDetails = true;
        this.expand();
      },
      error: (error: any) => {
        this.showLoader = false;
        throw error;
      },
    });
  }

  requestValidation() {
    this.details.requestValidation();
  }

  expand() {
    if (
      !this.showLoader &&
      this._activeDataService.pendingRequestsInfo() === '' &&
      (this._activeDataService.reportData?.config.show_expanded_view ||
        this._activeDataService.cachedConfig?.show_expanded_view)
    ) {
      this.expandEmitter.emit();
    }
  }

  showExpert() {
    if (
      !this.showLoader &&
      this._activeDataService.pendingRequestsInfo() === ''
    ) {
      this.expertClicked.emit();
    }
  }

  checkDetailsButton() {
    if (this.details === undefined || this.details === null) {
      return true;
    }
    return !this.details.editMode || !this.details.checkIsFormValid();
  }
}
