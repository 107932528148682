<div class="rating-flex">
  <div class="raiting-container">
    <div class="rating">
      <div
        *ngIf="value > 0"
        [ngClass]="getClass(true)"></div>
      <div
        *ngIf="!(value > 0)"
        [ngClass]="getClass(false)"></div>
      <div
        *ngIf="value > 1"
        [ngClass]="getClass(true)"></div>
      <div
        *ngIf="!(value > 1)"
        [ngClass]="getClass(false)"></div>
      <div
        *ngIf="value > 2"
        [ngClass]="getClass(true)"></div>
      <div
        *ngIf="!(value > 2)"
        [ngClass]="getClass(false)"></div>
      <div
        *ngIf="value > 3"
        [ngClass]="getClass(true)"></div>
      <div
        *ngIf="!(value > 3)"
        [ngClass]="getClass(false)"></div>
      <div
        *ngIf="value > 4"
        [ngClass]="getClass(true)"></div>
      <div
        *ngIf="!(value > 4)"
        [ngClass]="getClass(false)"></div>
    </div>
  </div>
</div>
