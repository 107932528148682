<div class="rating-flex">
  <div class="raiting-container">
    <div class="rating">
      <div
        *ngIf="rating > 0"
        class="rating-icon filled"></div>
      <div
        *ngIf="!(rating > 0)"
        class="rating-icon outline"></div>
      <div
        *ngIf="rating > 1"
        class="rating-icon filled"></div>
      <div
        *ngIf="!(rating > 1)"
        class="rating-icon outline"></div>
      <div
        *ngIf="rating > 2"
        class="rating-icon filled"></div>
      <div
        *ngIf="!(rating > 2)"
        class="rating-icon outline"></div>
      <div
        *ngIf="rating > 3"
        class="rating-icon filled"></div>
      <div
        *ngIf="!(rating > 3)"
        class="rating-icon outline"></div>
      <div
        *ngIf="rating > 4"
        class="rating-icon filled"></div>
      <div
        *ngIf="!(rating > 4)"
        class="rating-icon outline"></div>
    </div>
    <span class="rating-text">Vergleichbarkeit</span>
  </div>
</div>
