import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { Reports, Report } from 'src/app/core/models/reports.model';
import { ReportsService } from 'src/app/core/services/reports.service';

import { Subscription } from 'rxjs';
import { PrintService } from 'src/app/core/services/print.service';
import { ShareComponent } from 'src/app/share/share.component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ILogger } from 'src/app/core/abstract/ILogger.abstract';
import { AppHttpErrorResponse } from 'src/app/core/services/error.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-valuation-history',
  templateUrl: './valuation-history.component.html',
  styleUrls: ['./valuation-history.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ValuationHistoryComponent implements OnInit {
  private availableData = 0;
  private displayData: Report[] = [];
  constructor(
    private _reportsService: ReportsService,
    public _printService: PrintService,
    private _loggingService: ILogger,
    private _dialog: MatDialog
  ) {
    if (this.hideHeaderRow) {
      this.hideRowStyle = 'display: none;';
    }
    this.reportSubscription = _reportsService.get().subscribe(
      (data: Reports) => {
        if (this.title !== '') {
          this.title = this.title.replace('()', '(' + data.reports_count + ')');
        }

        this.totalDataLength = data.reports.length;
        this.availableData = data.reports_count;
        this.displayData = data.reports;

        if (this.limit != null) {
          this.displayData = this.displayData.slice(0, this.limit);
        }

        this.dataSource = new MatTableDataSource<Report>(this.displayData);
        this.dataSource.paginator = this.paginator;
        this.reportSubscription.unsubscribe();
      },
      (error: AppHttpErrorResponse) => {
        this.error = true;
        this.reportSubscription.unsubscribe();
      }
    );
  }

  @Input() title = '';
  @Input() limit = 0;
  @Input() hideHeaderRow = true;
  @Input() isSideBar = false;
  @Input() hideShareRow = true;
  @Output() historicReportEmitter: EventEmitter<Report> =
    new EventEmitter<Report>();

  public resolutionTest = '';
  mobile = false;
  tablet = false;

  public error = false;

  public hideRowStyle = '';

  dataSource: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public totalDataLength = 0;
  public pageSize = 10;
  public pageSizeOptions: number[] = [5, 10, 25, 100];
  private reportSubscription: Subscription;

  displayedColumns: string[] = ['date', 'address', 'edited', 'share', 'print'];
  expandedElement: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resolutionTest = window.innerWidth + 'x' + window.innerHeight;
    if (window.innerWidth <= 1080) {
      this.mobile = true;
    }
    if (window.innerWidth > 1080 && window.innerWidth <= 1260) {
      this.tablet = true;
    }
  }

  ngOnInit() {
    if (this.dataSource !== undefined) {
      this.dataSource.paginator = this.paginator;
    }

    this.resolutionTest = window.innerWidth + 'x' + window.innerHeight;
    if (window.innerWidth <= 1080) {
      this.mobile = true;
    }
    if (window.innerWidth > 1080 && window.innerWidth <= 1260) {
      this.tablet = true;
    }
  }

  showPagination() {
    if (
      this.totalDataLength > this.pageSizeOptions[0] &&
      this.limit > this.pageSizeOptions[0]
    ) {
      return true;
    } else {
      return false;
    }
  }

  howManyMonthsAgo(date: string) {
    const d1 = new Date(date);
    const d2 = new Date();
    const diff = this.monthDiff(d1, d2);

    if (diff === 0) {
      return 'Diesen Monat';
    } else if (diff === 1) {
      return 'Letzten Monat';
    } else {
      return 'vor ' + diff + ' Monaten';
    }
  }

  monthDiff(d1, d2) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  formatDate(d1) {
    const dateTemp = new Date(Date.parse(d1));
    const month = dateTemp.getMonth() + 1;
    const day = dateTemp.getDate();
    const year = dateTemp.getFullYear();

    let dayString = day.toString();
    if (day < 10) {
      dayString = '0' + dayString;
    }

    let monthString = month.toString();
    if (month < 10) {
      monthString = '0' + monthString;
    }

    return dayString + '.' + monthString + '.' + year;
  }

  loadReport(report: Report) {
    if (this.hideShareRow) {
      this.historicReportEmitter.emit(report);
    } else {
    }
  }

  loadReportFromDetailRow(report: Report) {
    this.historicReportEmitter.emit(report);
  }

  shareLink(report: Report) {
    if (
      (
        'https://' +
        window.location.hostname +
        '/view?report=' +
        report.report_id
      ).length <= 66
    ) {
      this._dialog.open(ShareComponent, {
        width: '799px',
        height: 'auto',
        minWidth: '350px',
        panelClass: 'share-dialog',
        data: report,
      });
    }
    if (
      (
        'https://' +
        window.location.hostname +
        '/view?report=' +
        report.report_id
      ).length > 66
    ) {
      this._dialog.open(ShareComponent, {
        width: '600px',
        height: 'auto',
        minWidth: '350px',
        panelClass: 'share-dialog-small',
        data: report,
      });
    }
  }

  pageChange(event: PageEvent) {
    const maxPages = event.length / event.pageSize;
    if (event.pageIndex + 3 > maxPages) {
      if (this.availableData > event.length) {
        this.reportSubscription = this._reportsService
          .get(event.length)
          .subscribe(
            (data: Reports) => {
              if (this.title !== '') {
                this.title = this.title.replace(
                  '()',
                  '(' + data.reports_count + ')'
                );
              }
              this.totalDataLength = this.totalDataLength + data.reports.length;

              const dataRef = this.displayData;
              data.reports.forEach(function (element) {
                dataRef.push(element);
              });
              this.dataSource = new MatTableDataSource<Report>(dataRef);
              this.dataSource.paginator = this.paginator;
              this.reportSubscription.unsubscribe();
            },
            (error: AppHttpErrorResponse) => {
              this._loggingService.logDebug(
                'Valuation history page change error response : ' +
                  JSON.stringify(error)
              );
              this.error = true;
              this.reportSubscription.unsubscribe();
            }
          );
      }
    }
  }
}
