<app-error *ngIf="appError"></app-error>
<div
  class="info-container"
  [@infoFade]=""
  *ngIf="reportData !== null && reportData !== undefined">
  <div
    class="dispFlex"
    [ngClass]="{ 'small-margin': extended && !mobile }"
    *ngIf="!comparable">
    <div class="infoIconBoxDetail infoBoxDetail">
      <span
        class="spreng-icon-b-pin icon32"
        [ngClass]="{ 'mobile-icon': mobile }"
        matTooltip="Adresse"></span>
    </div>
    <div class="infoTextBoxDetail infoBoxDetail">
      <span class="address-text">
        <span
          class="innerAddressText"
          [ngClass]="{ 'mobile-font': mobile }">
          {{ getAddress() }}
        </span>
      </span>
    </div>
  </div>

  <div
    class="dispFlex"
    [ngClass]="{ 'small-margin': extended && !mobile }">
    <div class="infoIconBoxDetail infoBoxDetail">
      <span
        class="icon32 spreng-icon-b-home-city"
        [ngClass]="{ 'mobile-icon': mobile }"
        matTooltip="Objektart"></span>
    </div>
    <div class="infoTextBoxDetail infoBoxDetail">
      <span
        class="category-text"
        [ngClass]="{ 'mobile-font': mobile }">
        {{ categoryText() }}
      </span>
    </div>
  </div>
</div>
