import { Component, Input } from '@angular/core';
import { EnergyCertificate } from 'src/app/core/enums/energy-certificate.enum';
import { EnergyClass } from 'src/app/core/enums/energy-class.enum';
import EnergyFileMap from './class-filename.map';

@Component({
  selector: 'app-energy-info',
  templateUrl: './energy-info.component.html',
  styleUrls: ['./energy-info.component.scss'],
})
export class EnergyInfoComponent {
  energyClassFilename: string | undefined = undefined;

  @Input() set energyClass(energyClassValue: EnergyClass) {
    this.energyClassFilename = EnergyFileMap.get(energyClassValue);
  }

  @Input() energyCertificate: EnergyCertificate;
}
