<div
  class="address-search-container"
  [@openCloseAddrSearch]=""
  id="address-search-container-main"
  *ngIf="addressSearch">
  <app-address-search
    (activePanelChange)="addressPanelChange($event)"
    (historicReportEmitter)="loadReport($event)"></app-address-search>
</div>
<div
  name="map-overlay"
  class="map-overlay"
  *ngIf="!addressSearch && preventInteraction"></div>
<div
  [ngClass]="{
    'map-wrapper-without-search': !searchbarCheck(),
    'map-wrapper-with-search': searchbarCheck(),
    'show-address-search': addressSearchCheck(),
    'hide-address-search': !addressSearchCheck()
  }">
  <mgl-map
    #map
    id="theMap"
    [style]="mapboxSettings.style"
    [zoom]="[mapboxSettings.zoom]"
    [pitch]="[mapboxSettings.pitch]"
    [bearing]="[mapboxSettings.bearing]"
    [center]="mapboxSettings.mapCenter"
    [attributionControl]="mapboxSettings.attributionControl"
    (mapLoad)="mapLoaded()"
    (zoomEnd)="zoomEnd()"
    (move)="movingMap()"
    (moveEnd)="finishedMoving()"
    (mapMouseDown)="mapClick()">
    <mgl-control
      *ngIf="!tablet"
      mglScale
      unit="metric"
      position="bottom-right"></mgl-control>

    <mgl-control
      *ngIf="!tablet"
      mglNavigation
      position="bottom-right"></mgl-control>
    <mgl-marker
      [lngLat]="[this.coordinates.lng, this.coordinates.lat]"
      *ngIf="mapIsLoaded && !addressSearch && showMainPin"
      [offset]="mapboxSettings.pinOffset"
      className="top-icon">
      <div
        class="main-pin"
        (click)="markerClick()">
        <span>
          <img src="../../assets/icons/Pin-red.svg" />
          <div
            class="valuation-text-container"
            *ngIf="
              mapIsLoaded &&
              !addressSearch &&
              showValuationResult &&
              showMainPin &&
              exactPriceAlllowedByConfig()
            ">
            <div class="valuation-text">
              <p>EUR {{ getValue() | number : '1.0-0' }}</p>
            </div>
          </div>
        </span>
      </div>
    </mgl-marker>
    <span
      *ngIf="
        _activeDataService.poiData !== undefined &&
        this.mapComponent?.mapInstance.getZoom() >= 12 &&
        !expert &&
        !addressSearch
      ">
      <span *ngFor="let poi of _activeDataService.poiData.pois; let i = index">
        <mgl-marker
          #poiMarker
          [lngLat]="[poi.coordinates.lng, poi.coordinates.lat]">
          <div class="pin">
            <span><img [src]="getPoiImage(poi)" /></span>
          </div>
        </mgl-marker>
        <mgl-popup
          [marker]="poiMarker"
          className="poi-popup">
          <span *ngIf="poi.name !== ''">{{ poi.name }}</span>
          <span>{{ poi.description }}</span>
          <span *ngIf="poi.distance">
            Entfernung - {{ poi.distance | number : '1.0-0' }}m
          </span>
        </mgl-popup>
      </span>
    </span>

    <span
      *ngFor="let comparable of reportValuation.compare_prices; let i = index">
      <mgl-marker
        [lngLat]="[comparable.lng, comparable.lat]"
        *ngIf="
          mapIsLoaded &&
          !addressSearch &&
          !comparable.hidden &&
          !expert &&
          comparablesAlllowedByConfig()
        "
        className="comparable-object-pin comparable-pin"
        [offset]="mapboxSettings.pinOffset">
        <div
          class="pin hide-for-mobile"
          (click)="showComparable(i)">
          <span *ngIf="!comparable.display">
            <img src="../../assets/icons/Pin-blue.svg" />
          </span>
          <span *ngIf="comparable.display">
            <img src="../../assets/icons/Pin-light-blue.svg" />
          </span>
        </div>
      </mgl-marker>
      <mgl-popup
        [lngLat]="[comparable.lng, comparable.lat]"
        [closeOnClick]="false"
        [closeButton]="false"
        [anchor]="'top'"
        [offset]="mapboxSettings.compareObjectOffset"
        className="comparable hide-for-mobile">
        <div
          class="comparable-object"
          *ngIf="shouldShowComparable(i)">
          <app-comparable-object
            class="hide-for-mobile"
            [reportId]="valuationReportId"
            [comparableData]="comparable"
            (objectClosedEmitter)="closeComparable(i)"></app-comparable-object>
        </div>
      </mgl-popup>
    </span>
  </mgl-map>
  <div
    [@openCloseValReqBox]=""
    [ngClass]="{ 'popup-locked': this.lockedPopup }"
    *ngIf="
      mapIsLoaded && !addressSearch && needsValuation && valuationPopupShown
    "
    [ngStyle]="getValuationRequestPosition()">
    <app-valuation-request
      class="valuation-object"
      [baseReportRequest]="reportRequest"
      [location]="location"
      [locked]="this.lockedPopup"
      (reportId)="processValuationReportResponse($event)"
      (itemSelectedEmitter)="requestItemSelected()"></app-valuation-request>
  </div>
  <app-legend
    [location]="location"
    [mobileLocation]="mobileLocation"
    [prices]="getPricingData()"
    [altLegend]="useAltHeatmap()"
    [desktopClose]="!defaultLegendPostion"
    (legendCloseEmitter)="moveLegend()"
    name="map-legend"
    [ngClass]="{
      'map-legend': defaultLegendPostion,
      'map-legend-expanded': !defaultLegendPostion,
      'snap-bottom': !this.openResult && !(shouldShowResult() && !expanded),
      'snap-middle':
        this.openResult &&
        mapIsLoaded &&
        !addressSearch &&
        needsValuation &&
        valuationPopupShown
    }"
    *ngIf="
      mapIsLoaded &&
      this.pricingData.length > 0 &&
      showLegend &&
      (!expanded || !defaultLegendPostion)
    "></app-legend>
  <div
    *ngIf="shouldShowResult() && !expanded"
    [@openValResCont]=""
    class="hide-for-mobile valuation-results-container"
    [ngStyle]="getValuationResultPosition()">
    <app-valuation-result
      [ngClass]="valuationResultClass()"
      [reportId]="valuationReportId"
      [docked]="valuationResultLocked"
      (objectClosedEmitter)="valuationResultClosed()"
      (currentValuationEmitter)="valuationUpdated($event)"
      (expandEmitter)="toggleExpanded()"
      (expertClicked)="toggleExpert()"></app-valuation-result>
  </div>

  <div
    *ngIf="shouldShowResult()"
    class="hide-for-desktop mobile-results-container">
    <app-mobile-result
      class="hide-for-tablet hide-for-desktop"
      (expandedEmitter)="toggleExpanded()"
      [reportId]="valuationReportId"></app-mobile-result>
  </div>

  <div
    *ngIf="shouldShowResult() && expanded"
    [@openValResExtCont]=""
    class="extended-results-container hide-for-mobile">
    <app-extended
      [reportId]="valuationReportId"
      (closeExtendedEmitter)="toggleExpanded()"
      [expertOpen]="expert"
      (expertClicked)="toggleExpert()"
      [reload]="reload"
      (currentValuationEmitter)="valuationUpdated($event)"></app-extended>
  </div>

  <div
    *ngIf="shouldShowResult() && expert"
    class="expert-overlay hide-for-mobile"></div>
  <div
    [@openCloseExpert]=""
    *ngIf="shouldShowResult() && expert"
    class="expert-results-container hide-for-mobile">
    <app-expert
      (closeExpertEmitter)="toggleExpert()"
      (currentValuationEmitter)="valuationUpdated($event)"></app-expert>
  </div>

  <div
    class="resolution-test"
    *ngIf="!addressSearch && showDebug">
    Effective Resolution : {{ resInfo }}, Zoom Level :
    {{ this.mapComponent?.mapInstance.getZoom().toString() }}, layer :
    {{ getActiveHeatmap() }}
  </div>

  <div
    class="dummy-legend"
    *ngIf="(expanded || expert) && this.pricingData.length > 0"
    (click)="moveLegend()"></div>

  <div
    *ngIf="!expert && !addressSearch"
    class="dataPrivacyLinkContainer dataImprintRow hide-for-mobile hide-for-tablet">
    <div class="dataImprintColumn">
      <a
        href="{{ _authService.privacyUrl }}"
        target="_blank">
        Datenschutzerklärung
      </a>
    </div>
    <div class="dataImprintColumn">
      <a
        href="{{ _authService.imprintUrl }}"
        target="_blank">
        Impressum
      </a>
    </div>
  </div>
</div>
