<div *ngIf="comparable !== undefined">
  <!-- below may need a isEmpty() funtion, to check for '' and for the various types in construction field -->

  <div
    *ngIf="comparable.construction && comparable.category !== 'MFH'"
    class="construction-component-container">
    <span class="construction-icon">
      <img
        src="{{ getIcon() }}"
        class="construction-icon" />
    </span>
    <span class="construction-text">{{ this.constructionText }}</span>
  </div>
  <div
    *ngIf="!comparable.construction || comparable.category === 'MFH'"
    class="construction-component-container">
    <span class="construction-icon">
      <span class="{{ getIconFont() }}"></span>
    </span>
    <span class="construction-text">{{ categoryText() }}</span>
  </div>
</div>
