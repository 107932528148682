import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  animations: [
    trigger('detailFade', [
      transition(':leave', [animate('1ms', style({ opacity: 0 }))]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.75s 0.75s', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class StarRatingComponent implements OnInit {
  @Input() level = 0;
  @Input() editMode = false;
  @Input() extended = false;
  @Input() comparable = false;
  @Output() ratingEmitter = new EventEmitter<string>();
  @Input() isPrintMode = false;
  @Input() mobile = false;
  @Input() isExpert = false;

  public dropDownValue = '';

  constructor(public _authService: AuthenticationService) {}

  ngOnInit() {
    if (this.level === 1) {
      this.dropDownValue = 'EINFACH';
    }
    if (this.level === 2) {
      this.dropDownValue = 'MITTEL';
    }
    if (this.level === 3) {
      this.dropDownValue = 'GEHOBEN';
    }
    if (this.level === 4) {
      this.dropDownValue = 'STARK_GEHOBEN';
    }
  }

  isFilled(pos: number) {
    return this.level > pos;
  }

  dropDownChange() {
    this.ratingEmitter.emit(this.dropDownValue);
  }
}
