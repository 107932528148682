<mat-toolbar
  *ngIf="this._headerService.showHeader"
  color="primary">
  <app-header class="Header"></app-header>
</mat-toolbar>
<div
  [@routeAnimations]="getRouteAnimationData()"
  *ngIf="!_authService.validating">
  <router-outlet #outlet="outlet"></router-outlet>
</div>
