import { EnergyClass } from 'src/app/core/enums/energy-class.enum';

const EnergyFileMap = new Map<EnergyClass, string>([
  [EnergyClass.APlus, 'APlus.svg'],
  [EnergyClass.A, 'A.svg'],
  [EnergyClass.B, 'B.svg'],
  [EnergyClass.C, 'C.svg'],
  [EnergyClass.D, 'D.svg'],
  [EnergyClass.E, 'E.svg'],
  [EnergyClass.F, 'F.svg'],
  [EnergyClass.G, 'G.svg'],
  [EnergyClass.H, 'H.svg'],
]);

export default EnergyFileMap;
