<div class="previous-valuations-container">
  <div class="titleRow">
    <div class="titleColumn">
      <button
        *ngIf="isSideBar"
        [mat-dialog-close]
        class="rowBtn sidebar-back"
        mat-button>
        <span
          class="mat-button-label-rotate spreng-icon-arrow-left close-button-back"></span>
      </button>
    </div>
    <div class="titleDoubleColumn">
      <h2 class="titleText">{{ title }}</h2>
    </div>
    <div class="titleColumn"></div>
  </div>

  <div class="mat-typography history-content">
    <div class="previous-valuations">
      <table
        mat-table
        [dataSource]="dataSource"
        multiTemplateDataRows
        class="data-table">
        <ng-container matColumnDef="date">
          <th
            mat-header-cell
            *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let report"
            [ngClass]="{ textAlignCenter: isSideBar }">
            <div
              name="valuation-history-date"
              class="dateBox">
              {{ formatDate(report.dates.updated) }}
            </div>
            <div
              class="dateSubBox"
              *ngIf="isSideBar">
              {{ howManyMonthsAgo(report.dates.updated) }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th
            mat-header-cell
            *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let report">
            <div
              name="valuation-history-result-link"
              class="blueText truncateText"
              (click)="loadReport(report)">
              {{
                report.category +
                  '-' +
                  report.address.street +
                  '-' +
                  report.address.house_number +
                  '-' +
                  report.address.town
              }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="edited">
          <th
            mat-header-cell
            *matHeaderCellDef>
            <span [hidden]="!hideHeaderRow">Edited</span>
          </th>
          <td
            mat-cell
            *matCellDef="let report">
            <div class="edit-label-container">
              <span
                *ngIf="report.value_overwritten"
                style="font-size: small">
                EDITED
              </span>
            </div>
          </td>
        </ng-container>

        <ng-container
          matColumnDef="expandedDetail"
          *ngIf="!hideShareRow">
          <td
            class="row-detail-container"
            mat-cell
            *matCellDef="let report"
            colspan="4">
            <div
              class="example-element-detail"
              [@detailExpand]="
                report === expandedElement ? 'expanded' : 'collapsed'
              ">
              <button
                [mat-dialog-close]
                (click)="loadReportFromDetailRow(report)"
                class="rowBtn detail-button sidebar-back"
                mat-button>
                <span
                  class="mat-button-label-rotate spreng-icon-pin detail-button-icon"></span>
              </button>
              <button
                (click)="shareLink(report)"
                class="rowBtn detail-button sidebar-back"
                mat-button>
                <span
                  class="mat-button-label-rotate spreng-icon-share detail-button-icon"></span>
              </button>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="share">
          <th
            mat-header-cell
            *matHeaderCellDef>
            <span [hidden]="!hideHeaderRow">Share</span>
          </th>
          <td
            mat-cell
            *matCellDef="let report">
            <div class="share-button-container">
              <button
                name="valuation-history-share-button"
                class="rowBtn"
                mat-button
                (click)="shareLink(report)">
                <span
                  class="mat-button-label-rotate spreng-icon-share"
                  matTooltip="Teilen"></span>
              </button>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="print">
          <th
            mat-header-cell
            *matHeaderCellDef>
            <span [hidden]="!hideHeaderRow">Print</span>
          </th>
          <td
            mat-cell
            *matCellDef="let report"></td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
          [style.display]="
            hideHeaderRow || !(mobile || tablet) ? 'none' : null
          "></tr>
        <tr
          mat-row
          [ngClass]="{ rowHeightSidebar: isSideBar, rowHeightMain: !isSideBar }"
          *matRowDef="let myRowData; columns: displayedColumns"
          class="example-element-row"
          [class.example-expanded-row]="expandedElement === myRowData"
          (click)="expandedElement = myRowData"></tr>
        <ng-container *ngIf="!hideShareRow">
          <tr
            mat-row
            *matRowDef="let myRowData; columns: ['expandedDetail']"
            class="example-detail-row"></tr>
        </ng-container>
      </table>
      <mat-paginator
        [ngClass]="{ 'paginator--centered': isSideBar }"
        [ngStyle]="{ display: showPagination() ? 'flex' : 'none' }"
        [length]="totalDataLength"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [hidePageSize]="true"
        [showFirstLastButtons]="true"
        MatPaginatorIntl
        (page)="pageChange($event)"></mat-paginator>
    </div>
    <div
      class="component-error"
      *ngIf="error">
      <app-error></app-error>
    </div>
  </div>
</div>
