import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { NgxMapboxGLModule, MapService } from 'ngx-mapbox-gl';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ILogger } from './core/abstract/ILogger.abstract';
import { CustomErrorHandler } from './core/handlers/custom-error-handler';
import { LoggingService } from './core/services/logging.service';
import { NotificationService } from './core/services/notification.service';
import { environment } from 'src/environments/environment';
import { LegendComponent } from './map/legend/legend.component';
import { AuthenticationService } from './core/services/authentication.service';
import { MapMainComponent } from './map/map.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './layout/header/header.component';

import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HeaderService } from './core/services/header.service';
import { AddressSearchComponent } from './map/address-search/address-search.component';
import { ReportsService } from './core/services/reports.service';
import { ValuationHistoryComponent } from './map/valuation-history/valuation-history.component';
import { AuthenticateService } from './core/services/authenticate.service';
import { ValuationRequestComponent } from './map/valuation-request/valuation-request.component';
import { ValuationResultComponent } from './map/valuation-result/valuation-result.component';
import { SharedModule } from './shared/shared.module';
import { ReportService } from './core/services/report.service';
import { ValuationService } from './core/services/valuation.service';
import { registerLocaleData, DecimalPipe } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { RentService } from './core/services/rent.service';
import { ComparableObjectComponent } from './map/comparable-object/comparable-object.component';
import { ExtendedComponent } from './map/valuation-result/extended/extended.component';
import { ActiveDataService } from './core/services/active-data.service';
import { ExpertComponent } from './map/valuation-result/expert/expert.component';
import { ExpertValuationComponent } from './map/valuation-result/expert/expert-valuation/expert-valuation.component';
import { ExpertComparablesComponent } from './map/valuation-result/expert/expert-comparables/expert-comparables.component';
import { ExpertSociodemographicComponent } from './map/valuation-result/expert/expert-sociodemographic/expert-sociodemographic.component';
import { SimService } from './core/services/sim.service';
import { PrintComponent } from './print/print.component';
import { UserDataService } from './core/services/user-data.service';
import { CustomHttpHeaderInterceptor } from './core/interceptors/http-header-interceptor';
import { MobileResultComponent } from './map/valuation-result/mobile/mobile-result.component';
import { PrintService } from './core/services/print.service';
import { ShareComponent } from './share/share.component';
import { SociodemographicService } from './core/services/socio-demographic.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PoiService } from './core/services/poi.service';
import { MatPaginatorIntlDe } from './core/services/paginatorText';
import { PriceRangeService } from './core/services/price-range.service';
import { GeocoderComponent } from './map/geocoder/geocoder.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import {
  MatPaginatorModule,
  MatPaginatorIntl,
} from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    LegendComponent,
    MapMainComponent,
    HeaderComponent,
    AddressSearchComponent,
    ValuationHistoryComponent,
    ValuationRequestComponent,
    ValuationResultComponent,
    ComparableObjectComponent,
    ExtendedComponent,
    ExpertComponent,
    ExpertValuationComponent,
    ExpertComparablesComponent,
    MobileResultComponent,
    ExpertSociodemographicComponent,
    PrintComponent,
    ShareComponent,
    GeocoderComponent,
  ],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapboxAccessToken,
    }),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
      disableTimeOut: false,
      easeTime: 300,
      extendedTimeOut: 1000,
      tapToDismiss: true,
      closeButton: true,
      newestOnTop: true,
      progressBar: true,
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    MatToolbarModule,
    MatDialogModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatMenuModule,
  ],
  providers: [
    AuthenticationService,
    AuthenticateService,
    HeaderService,
    MapService,
    ReportsService,
    DecimalPipe,
    ReportService,
    ValuationService,
    RentService,
    SimService,
    UserDataService,
    ActiveDataService,
    SociodemographicService,
    PrintService,
    PoiService,
    PriceRangeService,
    { provide: ILogger, useClass: LoggingService },
    NotificationService,
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpHeaderInterceptor,
      multi: true,
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
