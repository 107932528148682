export const environment = {
  production: false,
  alertOnError: false,
  apiUrl: 'https://api.dev.maps.sprengnetter.de/service/api',
  sessionTimeOut: 7200000,
  apiRetries: 3,
  showDebugInfo: true,
  bearerToken: 'bWFwc0FQSV9kZXZfMTQwODIwMjA=',
  // eslint-disable-next-line max-len
  staticImageLink:
    'https://api.mapbox.com/styles/v1/sprengnetter-avm/ckbbyx5ob0jjd1inur4linaqe/static/url-https%3A%2F%2Fapp.dev.maps.sprengnetter.de%2Fassets%2Ficons%2FPin-grey@2x.png([LNG],[LAT])/[LNG],[LAT],14.0,0,60)/252x155@2x?access_token=pk.eyJ1Ijoic3ByZW5nbmV0dGVyLWF2bSIsImEiOiJjazJuNHNxMWYwbXFzM21vZm8yczBoOHJhIn0.rgglcYfP9i7p3sOo8NXolg&logo=false&attribution=false',
  mapboxAccessToken:
    'pk.eyJ1Ijoic3ByZW5nbmV0dGVyLWF2bSIsImEiOiJjazJuNHNxMWYwbXFzM21vZm8yczBoOHJhIn0.rgglcYfP9i7p3sOo8NXolg',
  authEnabled: false,
};

export const corsResourceMap: [string, string[]][] = [];
