import { Report } from './reports.model';
import { Valuation } from './valuation.model';
import { IpiGetResponse } from './ipi-get-response.model';
import { RentResponse } from './rent-response.model';
import { EquipmentGetResponse } from './equipment-get-response.model';
import { UserData } from './user-data.model';

export class Print {
  reportData: Report;
  valuationData: Valuation;
  valuationIpiData: IpiGetResponse;
  valuationRegionData: IpiGetResponse;
  valuationEquipmentData: EquipmentGetResponse;
  rentData: RentResponse;
  rentIpiData: IpiGetResponse;
  rentEquipmentData: EquipmentGetResponse;
  mapUrl: string;
  userData: UserData;
}
