import {
  Report,
  ReportConfigurationUpdateRequest,
} from '../models/reports.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ReportRequest } from '../models/report-request.model';
import { ReportPostResponse } from '../models/report-post-response.model';
import { ReportOverwriteRequest } from '../models/report-overwrite-request.model';
import { ReportOverwriteResponse } from '../models/report-overwrite-response.model';

@Injectable()
export class ReportService {
  constructor(private _httpClient: HttpClient) {}
  private url: string = environment.apiUrl + '/report';

  get(reportId: string): Observable<Report> {
    return this._httpClient.get<Report>(this.url + '?report_id=' + reportId);
  }

  post(reportRequest: ReportRequest): Observable<ReportPostResponse> {
    return this._httpClient.post<ReportPostResponse>(this.url, reportRequest);
  }

  put(
    reportRequest: ReportRequest,
    reportId: string
  ): Observable<ReportPostResponse> {
    return this._httpClient.put<ReportPostResponse>(
      this.url + '?report_id=' + reportId,
      reportRequest
    );
  }

  updateConfig(
    configRequest: ReportConfigurationUpdateRequest,
    reportId: string
  ) {
    return this._httpClient.put(
      this.url + '/config?report_id=' + reportId,
      configRequest
    );
  }

  overwriteValuation(
    reportOverwriteRequest: ReportOverwriteRequest,
    reportId: string
  ): Observable<ReportOverwriteResponse> {
    return this._httpClient.patch<ReportOverwriteResponse>(
      this.url + '/overwrite' + '?report_id=' + reportId,
      reportOverwriteRequest
    );
  }
}
