import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ChartOptions, ChartType, ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { SocioChartData } from 'src/app/core/models/SocioChartData.model';

@Component({
  selector: 'app-chart-container',
  templateUrl: './chart-container.component.html',
  styleUrls: ['./chart-container.component.scss'],
})
export class ChartContainerComponent implements OnInit {
  @ViewChild('barChart', { read: BaseChartDirective })
  chart: BaseChartDirective;
  @Input() rightChart = false;
  @Input() inputData: SocioChartData;
  @Input() type = 0;
  @Input() print = false;
  public title = '';
  @Input() topV = 0;
  @Input() topL = '';

  public chartOptions: any = {
    responsive: true,
  };

  public chartType: ChartType;
  public chartLegend = false;

  public chartData: ChartData;

  private barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    scales: {
      x: {
        display: false,
      },
    },
    elements: {
      line: {
        // makes lines straight rather than curved.
        tension: 0,
      },
    },
  };

  constructor() {}

  ngOnInit() {
    if (this.print) {
      this.rightChart = false;
    }
    switch (this.type) {
      case 1:
        this.title = this.inputData.title;
        this.chartType = 'doughnut';
        this.chartData = {
          labels: this.inputData.labels,
          datasets: [
            {
              data: this.inputData.pieData,
              backgroundColor: [
                '#70BBCC',
                '#62A4B3',
                '#548C99',
                '#45737E',
                '#385D65',
              ],
            },
          ],
        };
        this.chartOptions.cutoutPercentage = 50;

        if (this.print) {
          this.chartOptions.hover = { mode: null };
        }

        this.chartOptions.tooltips = {
          enabled: !this.print,
          callbacks: {
            label(toolTipItem, data) {
              if (window.innerWidth > 1080) {
                return (
                  data.labels[toolTipItem.index] +
                  ' ' +
                  data.datasets[0].data[toolTipItem.index] +
                  '%'
                );
              }
              return data.labels[toolTipItem.index];
            },
          },
        };
        this.chartOptions.maintainAspectRatio = true;

        break;
      case 2:
        this.title = this.inputData.title;
        this.chartType = 'bar';
        this.chartOptions = this.barChartOptions;

        this.chartData = {
          labels: [' '],
          datasets: this.inputData.barData,
        };
        if (this.print) {
          this.chartOptions.hover = { mode: null };
        }
        this.chartOptions.tooltips = {
          enabled: !this.print,
          callbacks: {
            label(toolTipItem, data) {
              if (window.innerWidth > 1080) {
                return (
                  data.datasets[toolTipItem.datasetIndex].label +
                  ' ' +
                  toolTipItem.value +
                  '%'
                );
              }
              return data.datasets[toolTipItem.datasetIndex].label;
            },
          },
        };
        break;
      case 3:
        this.title = this.inputData.title;
        this.chartType = 'polarArea';
        this.chartData = {
          labels: this.inputData.labels,
          datasets: [
            {
              data: this.inputData.pieData,
              backgroundColor: [
                '#385D65',
                '#45737E',
                '#548C99',
                '#62A4B3',
                '#70BBCC',
              ],
            },
          ],
        };

        const minTicks =
          Math.round((Math.min(...this.inputData.pieData) - 10) / 10) * 10;
        const maxTicks =
          Math.round((Math.max(...this.inputData.pieData) + 10) / 10) * 10;
        this.chartOptions.scale = {
          ticks: {
            min: minTicks,
            max: maxTicks,
            stepSize: 10,
            showLabelBackdrop: false,
            z: 1,
          },
          gridLines: {
            z: 1,
            drawOnChartArea: true,
          },
        };
        if (this.print) {
          this.chartOptions.hover = { mode: null };
        }
        this.chartOptions.tooltips = {
          enabled: !this.print,
          callbacks: {
            label(toolTipItem, data) {
              if (window.innerWidth > 1080) {
                return (
                  data.labels[toolTipItem.index] +
                  ' ' +
                  data.datasets[0].data[toolTipItem.index] +
                  '%'
                );
              }

              let label: string = data.labels[toolTipItem.index];
              label = label.replace('Eine', '1');
              label = label.replace('Zwei', '2');
              label = label.replace('Drei', '3');
              label = label.replace('Vier', '4');
              label = label.replace('Fünf und mehr', '5+');
              return label;
            },
          },
        };
        break;
      case 4:
        this.title = this.inputData.title;
        this.chartType = 'doughnut';
        this.chartData = {
          labels: this.inputData.labels,
          datasets: [
            {
              data: this.inputData.pieData,
              backgroundColor: [
                '#385D65',
                '#45737E',
                '#548C99',
                '#62A4B3',
                '#70BBCC',
              ],
            },
          ],
        };
        this.chartOptions.cutoutPercentage = 50;

        if (this.print) {
          this.chartOptions.hover = { mode: null };
        }
        this.chartOptions.tooltips = {
          enabled: !this.print,
          callbacks: {
            label(toolTipItem, data) {
              if (window.innerWidth > 1080) {
                return (
                  data.labels[toolTipItem.index] +
                  ' ' +
                  data.datasets[0].data[toolTipItem.index] +
                  '%'
                );
              }
              return data.labels[toolTipItem.index];
            },
          },
        };
        break;
    }
  }

  getPeopleIcon(labelText: string) {
    if (
      labelText === 'Fünf und mehr Personen' ||
      labelText === '5+ Personen im Haushalt'
    ) {
      return '../../../assets/icons/5-people.svg';
    }
    if (
      labelText === 'Vier Personen' ||
      labelText === '4 Personen im Haushalt'
    ) {
      return '../../../assets/icons/4-people.svg';
    }
    if (
      labelText === 'Drei Personen' ||
      labelText === '3 Personen im Haushalt'
    ) {
      return '../../../assets/icons/3-people.svg';
    }
    if (
      labelText === 'Zwei Personen' ||
      labelText === '2  Personen im Haushalt'
    ) {
      return '../../../assets/icons/2-people.svg';
    }
    if (labelText === 'Eine Person' || labelText === '1 Personen im Haushalt') {
      return '../../../assets/icons/1-people.svg';
    }
  }

  getHousingIcon(labelText: string) {
    if (labelText === 'Einfamilienhaus' || labelText === '1 Wohneinheit') {
      return '../../../assets/icons/single-family-house.svg';
    }
    if (labelText === 'Sonderform' || labelText === 'Sonstiges') {
      return '../../../assets/icons/gherkin.svg';
    }
    if (labelText === 'Reihenhaus' || labelText === '2 Wohneinheiten') {
      return '../../../assets/icons/house-category.svg';
    }
    if (labelText === 'Mehrfamilienhaus' || labelText === '3+ Wohneinheiten') {
      return '../../../assets/icons/apartment.svg';
    }
    if (labelText === 'Gewerbe') {
      return '../../../assets/icons/store.svg';
    }
  }

  getLabel(labelText: string) {
    if (labelText === 'Fünf und mehr Personen') {
      return '5+ Personen im Haushalt';
    }
    if (labelText === 'Vier Personen') {
      return '4 Personen im Haushalt';
    }
    if (labelText === 'Drei Personen') {
      return '3 Personen im Haushalt';
    }
    if (labelText === 'Zwei Personen') {
      return '2 Personen im Haushalt';
    }
    if (labelText === 'Eine Person') {
      return '1 Person im Haushalt';
    }

    if (labelText === 'Einfamilienhaus') {
      return 'Einfamilienhäuser';
    }
    if (labelText === 'Sonderform') {
      return 'Sonderformen';
    }
    if (labelText === 'Reihenhaus') {
      return 'Reihenhäuser';
    }
    if (labelText === 'Mehrfamilienhaus') {
      return 'Mehrfamilienhäuser';
    }
    if (labelText === 'Gewerbe') {
      return 'Gewerbeobjekte';
    }

    if (labelText === '5000€+') {
      return '5.000€+';
    }
    if (labelText === '3500€ - 5000€') {
      return '3.500€ - 5.000€';
    }
    if (labelText === '2500€ - 3500€') {
      return '2.500€ - 3.500€';
    }
    if (labelText === '1500€ - 2500€') {
      return '1.500€ - 2.500€';
    }
    if (labelText === '0€ - 1500€') {
      return '0€ - 1.500€';
    } else {
      return labelText;
    }
  }

  chartHasData() {
    return this.chartData.datasets
      .reduce((acc, element) => acc.concat(element.data), [])
      .some(element => element !== 0);
  }
}
