import { HeatmapLayer } from './models/heatmap-layer.model';
import { Tileset } from './models/tileset.model';

export class MapboxSettings {
  public mapCenter: [number, number] = [9.9468, 53.5626];
  public countries = 'de,at';
  public showHeatMapFor: string[] = ['DE', 'AT'];
  public style = 'mapbox://styles/sprengnetter-avm/ckbbyx5ob0jjd1inur4linaqe';
  public zoom = 16.0;
  public searchLimit = 10;
  public bearing = -15.9;
  public pitch = 45;
  public attributionControl = false;
  public valueRadius = 2000; // 2KM
  public pinOffset: [number, number] = [-15, -45];
  public valuationOffset = [-15, -45];
  public compareObjectOffset: [number, number] = [0, 0];
  public heatMapColours: string[] = [
    '#D4DEEC',
    '#8AB4E6',
    '#5CB5E6',
    '#47A7B3',
    '#5DB347',
    '#AFCC3D',
    '#FFE44D',
    '#FFBB33',
    '#FF7819',
    '#B30D00',
  ];

  public de_altRange: number[] = [
    564, 1263, 1525, 1743, 1943, 2143, 2381, 2663, 3045, 3751,
  ];

  public at_altRange: number[] = [
    306, 1320, 1567, 1775, 1967, 2165, 2424, 2758, 3188, 3953,
  ];

  public tilesets: Tileset[] = [
    {
      id: 'de200_points',
      url: 'sprengnetter-avm.de200_points',
    },
    {
      id: 'de800_points',
      url: 'sprengnetter-avm.de800_points',
    },
    {
      id: 'de3200_points',
      url: 'sprengnetter-avm.de3200_points',
    },
    {
      id: 'at200_points',
      url: 'sprengnetter-avm.at200_points',
    },
    {
      id: 'at800_points',
      url: 'sprengnetter-avm.at800_points',
    },
    {
      id: 'at3200_points',
      url: 'sprengnetter-avm.at3200_points',
    },
  ];

  public heatmaps: HeatmapLayer[] = [
    {
      id: 'heatmap_de_200',
      source: 'de200_points',
      minZoom: 10,
      maxZoom: 24,
      stops: [
        [8, 1],
        [22.0, 32768],
      ],
    },
    {
      id: 'heatmap_de_800',
      source: 'de800_points',
      minZoom: 7,
      maxZoom: 10,
      stops: [
        [5.9, 2],
        [17.9, 8192],
      ],
    },
    {
      id: 'heatmap_de_3200',
      source: 'de3200_points',
      minZoom: 4,
      maxZoom: 7,
      stops: [
        [4, 2],
        [9.1, 64],
      ],
    },
    {
      id: 'heatmap_at_200',
      source: 'at200_points',
      minZoom: 10,
      maxZoom: 24,
      stops: [
        [8, 1],
        [22.0, 32768],
      ],
    },
    {
      id: 'heatmap_at_800',
      source: 'at800_points',
      minZoom: 7,
      maxZoom: 10,
      stops: [
        [5.9, 2],
        [17.9, 8192],
      ],
    },
    {
      id: 'heatmap_at_3200',
      source: 'at3200_points',
      minZoom: 4,
      maxZoom: 7,
      stops: [
        [4, 2],
        [9.1, 64],
      ],
    },
  ];
}
