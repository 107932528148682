import { Report, ReportConfiguration } from '../models/reports.model';
import { Valuation } from '../models/valuation.model';
import { RentResponse } from '../models/rent-response.model';
import { IpiGetResponse } from '../models/ipi-get-response.model';
import { EquipmentGetResponse } from '../models/equipment-get-response.model';
import { Subscription, throwError } from 'rxjs';
import { HelperFunctions } from '../functions/helper.functions';
import { ReportService } from './report.service';
import { Injectable, EventEmitter } from '@angular/core';
import { ValuationService } from './valuation.service';
import { RentService } from './rent.service';
import { environment } from 'src/environments/environment';
import { SimIndexResponse } from '../models/sim-index-response.model';
import { SimService } from './sim.service';
import { PoiGetResponse } from '../models/poi-get-response.model';
import { PoiService } from './poi.service';

@Injectable()
export class ActiveDataService {
  // Position 0 reserved for image data
  public pendingRequests: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  public reportTimes: string[] = [
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ];
  public reportTries: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  public reportSubscritpions: Subscription[] = [
    new Subscription(),
    new Subscription(),
    new Subscription(),
    new Subscription(),
    new Subscription(),
    new Subscription(),
    new Subscription(),
    new Subscription(),
    new Subscription(),
    new Subscription(),
    new Subscription(),
    new Subscription(),
  ];

  public reportData: Report;
  public reportEmitter = new EventEmitter<Report>();

  public valuationData: Valuation;
  public valuationEmitter = new EventEmitter<Valuation>();

  public rentData: RentResponse;
  public rentEmitter = new EventEmitter<RentResponse>();

  public ipiRent: IpiGetResponse;
  public ipiRentEmitter = new EventEmitter<IpiGetResponse>();

  public ipiValuation: IpiGetResponse;
  public ipiValuationEmitter = new EventEmitter<IpiGetResponse>();

  public valuationEquipment: EquipmentGetResponse;
  public equipmentValuationEmitter = new EventEmitter<EquipmentGetResponse>();

  public rentEquipment: EquipmentGetResponse;
  public equipmentRentEmitter = new EventEmitter<EquipmentGetResponse>();

  public regionValution: IpiGetResponse;
  public regionValuationEmitter = new EventEmitter<IpiGetResponse>();

  public regionRent: IpiGetResponse;
  public regionRentEmitter = new EventEmitter<IpiGetResponse>();

  public simEmitter = new EventEmitter<SimIndexResponse>();
  public simData: SimIndexResponse;

  public potEmitter = new EventEmitter<PoiGetResponse>();
  public poiData: PoiGetResponse;

  public saleMode = true;
  public reportId = '';

  public modeEmitter = new EventEmitter<boolean>();

  public cachedConfig: ReportConfiguration;

  constructor(
    private _reportService: ReportService,
    private _valuationService: ValuationService,
    private _rentService: RentService,
    private _simService: SimService,
    private _poiService: PoiService
  ) {}

  clearAll(includePoi = false) {
    this.cachedConfig = this.reportData?.config;
    this.reportTries = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.rentData = undefined;
    this.reportData = undefined;
    this.ipiRent = undefined;
    this.valuationData = undefined;
    this.ipiValuation = undefined;
    this.rentEquipment = undefined;
    this.valuationEquipment = undefined;
    this.regionRent = undefined;
    this.regionValution = undefined;
    this.simData = undefined;
    if (includePoi) {
      this.poiData = undefined;
    }
  }

  getReportData(reload = false) {
    if (this.reportData && !reload) {
      this.reportTimes[1] = this.reportData.dates.updated;
      this.reportEmitter.emit(this.reportData);
    } else {
      this.pendingRequests[1] = true;
      this.reportSubscritpions[1] = this._reportService
        .get(this.reportId)
        .subscribe(
          (data: Report) => {
            if (data.config === null || data.config === undefined) {
              data.config = new ReportConfiguration();
            }

            this.pendingRequests[1] = false;
            if (
              (reload &&
                this.reportTimes[1] === data.dates.updated &&
                this.reportTimes[1] !== undefined) ||
              data === null
            ) {
              if (this.reportTries[1] < environment.apiRetries) {
                const helper = new HelperFunctions();
                this.reportTries[1] = this.reportTries[1] + 1;
                helper.delay(1000 * this.reportTries[1]).then(() => {
                  this.getReportData(reload);
                });
              }
            } else {
              this.reportEmitter.emit(data);
              this.reportData = data;
              this.cachedConfig = undefined;
              this.reportTimes[1] = data.dates.updated;
              this.reportSubscritpions[1].unsubscribe();
            }
          },
          (error: any) => {
            this.pendingRequests[1] = false;
            this.reportSubscritpions[1].unsubscribe();
            this.reportEmitter.emit(null);
            throwError(error);
          }
        );
    }
  }

  getValuationData(reload = false) {
    if (this.valuationData !== undefined && !reload) {
      this.reportTimes[2] = this.valuationData.date;
      this.valuationEmitter.emit(this.valuationData);
    } else {
      this.pendingRequests[2] = true;
      this.reportSubscritpions[2] = this._valuationService
        .get(this.reportId)
        .subscribe(
          (data: Valuation) => {
            this.pendingRequests[2] = false;
            if (
              (reload &&
                this.reportTimes[2] === data.date &&
                this.reportTimes[2] !== undefined) ||
              data === null
            ) {
              if (this.reportTries[2] < environment.apiRetries) {
                const helper = new HelperFunctions();
                this.reportTries[2] = this.reportTries[2] + 1;
                helper.delay(1000 * this.reportTries[2]).then(() => {
                  this.getValuationData(reload);
                });
              }
            } else {
              this.valuationEmitter.emit(data);
              this.valuationData = data;
              this.reportTimes[2] = data.date;
              this.reportSubscritpions[2].unsubscribe();
            }
          },
          (error: any) => {
            this.pendingRequests[2] = false;
            this.reportSubscritpions[2].unsubscribe();
            this.valuationEmitter.emit(null);
            throwError(error);
          }
        );
    }
  }

  getRentData(reload = false) {
    if (this.rentData !== undefined && !reload) {
      this.reportTimes[3] = this.rentData.date;
      this.rentEmitter.emit(this.rentData);
    } else {
      this.pendingRequests[3] = true;
      this.reportSubscritpions[3] = this._rentService
        .get(this.reportId)
        .subscribe(
          (data: RentResponse) => {
            this.pendingRequests[3] = false;
            if (
              (reload &&
                this.reportTimes[3] === data.date &&
                this.reportTimes[3] !== undefined) ||
              data === null
            ) {
              if (this.reportTries[3] < environment.apiRetries) {
                const helper = new HelperFunctions();
                this.reportTries[3] = this.reportTries[3] + 1;
                helper.delay(1000 * this.reportTries[3]).then(() => {
                  this.getRentData(reload);
                });
              }
            } else {
              this.rentEmitter.emit(data);
              this.rentData = data;
              this.reportTimes[3] = data.date;
              this.reportSubscritpions[3].unsubscribe();
            }
          },
          (error: any) => {
            this.pendingRequests[3] = false;
            this.reportSubscritpions[3].unsubscribe();
            this.rentEmitter.emit(null);
            throwError(error);
          }
        );
    }
  }

  getValuationIpiData(reload = false) {
    if (this.ipiValuation !== undefined && !reload) {
      this.reportTimes[7] = this.ipiValuation.date;
      this.ipiValuationEmitter.emit(this.ipiValuation);
    } else {
      this.pendingRequests[7] = true;
      this.reportSubscritpions[7] = this._valuationService
        .getIpi(this.reportId)
        .subscribe(
          (data: IpiGetResponse) => {
            this.pendingRequests[7] = false;
            if (
              (reload &&
                this.reportTimes[7] === data.date &&
                this.reportTimes[7] !== undefined) ||
              data === null
            ) {
              if (this.reportTries[7] < environment.apiRetries) {
                const helper = new HelperFunctions();
                this.reportTries[7] = this.reportTries[7] + 1;
                helper.delay(1000 * this.reportTries[7]).then(() => {
                  this.getValuationIpiData(reload);
                });
              }
            } else {
              this.ipiValuationEmitter.emit(data);
              this.ipiValuation = data;
              this.reportTimes[7] = data.date;
              this.reportSubscritpions[7].unsubscribe();
            }
          },
          (error: any) => {
            this.pendingRequests[7] = false;
            this.reportSubscritpions[7].unsubscribe();
            this.ipiValuationEmitter.emit(null);
            throwError(error);
          }
        );
    }
  }

  getRentIpiData(reload = false) {
    if (this.ipiRent !== undefined && !reload) {
      this.reportTimes[4] = this.ipiRent.date;
      this.ipiRentEmitter.emit(this.ipiRent);
    } else {
      this.pendingRequests[4] = true;
      this.reportSubscritpions[4] = this._rentService
        .getIpi(this.reportId)
        .subscribe(
          (data: IpiGetResponse) => {
            this.pendingRequests[4] = false;
            if (
              (reload &&
                this.reportTimes[4] === data.date &&
                this.reportTimes[4] !== undefined) ||
              data === null
            ) {
              if (this.reportTries[4] < environment.apiRetries) {
                const helper = new HelperFunctions();
                this.reportTries[4] = this.reportTries[4] + 1;
                helper.delay(1000 * this.reportTries[4]).then(() => {
                  this.getRentIpiData(reload);
                });
              }
            } else {
              this.ipiRentEmitter.emit(data);
              this.ipiRent = data;
              this.reportTimes[4] = data.date;
              this.reportSubscritpions[4].unsubscribe();
            }
          },
          (error: any) => {
            this.pendingRequests[4] = false;
            this.reportSubscritpions[4].unsubscribe();
            this.ipiRentEmitter.emit(null);
            throwError(error);
          }
        );
    }
  }

  getValuationEquipment(reload = false) {
    if (this.valuationEquipment !== undefined && !reload) {
      this.reportTimes[5] = this.valuationEquipment.date;
      this.equipmentValuationEmitter.emit(this.valuationEquipment);
    } else {
      this.pendingRequests[5] = true;
      this.reportSubscritpions[5] = this._valuationService
        .getEquipment(this.reportId)
        .subscribe(
          (data: EquipmentGetResponse) => {
            this.pendingRequests[5] = false;
            if (
              (reload &&
                this.reportTimes[5] === data.date &&
                this.reportTimes[5] !== undefined) ||
              data === null
            ) {
              if (this.reportTries[5] < environment.apiRetries) {
                const helper = new HelperFunctions();
                this.reportTries[5] = this.reportTries[5] + 1;
                helper.delay(1000 * this.reportTries[5]).then(() => {
                  this.getValuationEquipment(reload);
                });
              }
            } else {
              this.equipmentValuationEmitter.emit(data);
              this.valuationEquipment = data;
              this.reportTimes[5] = data.date;
              this.reportSubscritpions[5].unsubscribe();
            }
          },
          (error: any) => {
            this.pendingRequests[5] = false;
            this.reportSubscritpions[5].unsubscribe();
            this.equipmentValuationEmitter.emit(null);
            throwError(error);
          }
        );
    }
  }

  getRentEquipment(reload = false) {
    if (this.rentEquipment !== undefined && !reload) {
      this.reportTimes[6] = this.rentEquipment.date;
      this.equipmentRentEmitter.emit(this.rentEquipment);
    } else {
      this.pendingRequests[6] = true;
      this.reportSubscritpions[6] = this._rentService
        .getEquipment(this.reportId)
        .subscribe(
          (data: EquipmentGetResponse) => {
            this.pendingRequests[6] = false;
            if (
              (reload &&
                this.reportTimes[6] === data.date &&
                this.reportTimes[6] !== undefined) ||
              data === null
            ) {
              if (this.reportTries[6] < environment.apiRetries) {
                const helper = new HelperFunctions();
                this.reportTries[6] = this.reportTries[6] + 1;
                helper.delay(1000 * this.reportTries[6]).then(() => {
                  this.getRentEquipment(reload);
                });
              }
            } else {
              this.equipmentRentEmitter.emit(data);
              this.rentEquipment = data;
              this.reportTimes[6] = data.date;
              this.reportSubscritpions[6].unsubscribe();
            }
          },
          (error: any) => {
            this.pendingRequests[6] = false;
            this.reportSubscritpions[6].unsubscribe();
            this.equipmentRentEmitter.emit(null);
            throwError(error);
          }
        );
    }
  }

  getValuationRegionData(reload = false) {
    if (this.regionValution !== undefined && !reload) {
      this.reportTimes[8] = this.regionValution.date;
      this.regionValuationEmitter.emit(this.regionValution);
    } else {
      this.pendingRequests[8] = true;
      this.reportSubscritpions[8] = this._valuationService
        .getRegion(this.reportId)
        .subscribe(
          (data: IpiGetResponse) => {
            this.pendingRequests[8] = false;
            if (
              (reload &&
                this.reportTimes[8] === data.date &&
                this.reportTimes[8] !== undefined) ||
              data === null
            ) {
              if (this.reportTries[8] < environment.apiRetries) {
                const helper = new HelperFunctions();
                this.reportTries[8] = this.reportTries[8] + 1;
                helper.delay(1000 * this.reportTries[8]).then(() => {
                  this.getValuationRegionData(reload);
                });
              }
            } else {
              this.regionValuationEmitter.emit(data);
              this.regionValution = data;
              this.reportTimes[8] = data.date;
              this.reportSubscritpions[8].unsubscribe();
            }
          },
          (error: any) => {
            this.pendingRequests[8] = false;
            this.reportSubscritpions[8].unsubscribe();
            this.regionValuationEmitter.emit(null);
            throwError(error);
          }
        );
    }
  }

  getRentRegionData(reload = false) {
    if (this.regionRent !== undefined && !reload) {
      this.reportTimes[9] = this.regionRent.date;
      this.regionRentEmitter.emit(this.regionRent);
    } else {
      this.pendingRequests[9] = true;
      this.reportSubscritpions[9] = this._rentService
        .getRegion(this.reportId)
        .subscribe(
          (data: IpiGetResponse) => {
            this.pendingRequests[9] = false;
            if (
              (reload &&
                this.reportTimes[9] === data.date &&
                this.reportTimes[9] !== undefined) ||
              data === null
            ) {
              if (this.reportTries[9] < environment.apiRetries) {
                const helper = new HelperFunctions();
                this.reportTries[9] = this.reportTries[9] + 1;
                helper.delay(1000 * this.reportTries[9]).then(() => {
                  this.getRentRegionData(reload);
                });
              }
            } else {
              this.regionRentEmitter.emit(data);
              this.regionRent = data;
              this.reportTimes[9] = data.date;
              this.reportSubscritpions[9].unsubscribe();
            }
          },
          (error: any) => {
            this.pendingRequests[9] = false;
            this.reportSubscritpions[9].unsubscribe();
            this.regionRentEmitter.emit(null);
            throwError(error);
          }
        );
    }
  }

  getSimData(reload = false) {
    if (this.simData !== undefined && !reload) {
      this.reportTimes[10] = this.simData.date;
      this.simEmitter.emit(this.simData);
    } else {
      this.pendingRequests[10] = true;
      this.reportSubscritpions[10] = this._simService
        .get(this.reportId)
        .subscribe(
          (data: SimIndexResponse) => {
            this.pendingRequests[10] = false;
            if (
              (reload &&
                this.reportTimes[10] === data.date &&
                this.reportTimes[10] !== undefined) ||
              data === null
            ) {
              if (this.reportTries[10] < environment.apiRetries) {
                const helper = new HelperFunctions();
                this.reportTries[10] = this.reportTries[10] + 1;
                helper.delay(1000 * this.reportTries[10]).then(() => {
                  this.getSimData(reload);
                });
              }
            } else {
              this.simEmitter.emit(data);
              this.simData = data;
              this.reportTimes[10] = data.date;
              this.reportSubscritpions[10].unsubscribe();
            }
          },
          (error: any) => {
            this.pendingRequests[10] = false;
            this.reportSubscritpions[10].unsubscribe();
            this.simEmitter.emit(null);
            throwError(error);
          }
        );
    }
  }

  getPoiData(reload = false) {
    if (this.poiData !== undefined && !reload) {
      this.reportTimes[11] = this.poiData.date;
      this.potEmitter.emit(this.poiData);
    } else {
      this.pendingRequests[11] = true;
      this.reportSubscritpions[11] = this._poiService
        .get(this.reportId)
        .subscribe(
          (data: PoiGetResponse) => {
            this.pendingRequests[11] = false;
            if (
              (reload &&
                this.reportTimes[11] === data.date &&
                this.reportTimes[11] !== undefined) ||
              data === null
            ) {
              if (this.reportTries[11] < environment.apiRetries) {
                const helper = new HelperFunctions();
                this.reportTries[11] = this.reportTries[11] + 1;
                helper.delay(1000 * this.reportTries[11]).then(() => {
                  this.getPoiData(reload);
                });
              }
            } else {
              this.potEmitter.emit(data);
              this.poiData = data;
              this.reportTimes[11] = data.date;
              this.reportSubscritpions[11].unsubscribe();
            }
          },
          (error: any) => {
            this.pendingRequests[11] = false;
            this.reportSubscritpions[11].unsubscribe();
            this.potEmitter.emit(null);
            throwError(error);
          }
        );
    }
  }

  pendingRequestsInfo() {
    let retVal = '';
    if (this.pendingRequests[1]) {
      retVal = retVal + '[report data]';
    }
    if (this.pendingRequests[2]) {
      retVal = retVal + '[valuation data]';
    }
    if (this.pendingRequests[3]) {
      retVal = retVal + '[rent data]';
    }
    if (this.pendingRequests[4]) {
      retVal = retVal + '[rent ipi data]';
    }
    if (this.pendingRequests[5]) {
      retVal = retVal + '[valuation equipment]';
    }
    if (this.pendingRequests[6]) {
      retVal = retVal + '[rent equipment]';
    }
    if (this.pendingRequests[7]) {
      retVal = retVal + '[valuation ipi data]';
    }
    if (this.pendingRequests[8]) {
      retVal = retVal + '[valuation region]';
    }
    if (this.pendingRequests[9]) {
      retVal = retVal + '[rent region]';
    }
    if (this.pendingRequests[10]) {
      retVal = retVal + '[sim data]';
    }
    if (this.pendingRequests[11]) {
      retVal = retVal + '[poi data]';
    }

    return retVal;
  }
}
