import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DetailTypeEnum } from 'src/app/core/enums/detail-type.enum';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Category } from 'src/app/core/enums/category.enum';
import { Construction } from 'src/app/core/enums/construction.enum';
import options from '../../../assets/construction-options.json';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  animations: [
    trigger('detailFade', [
      transition(':leave', [animate('1ms', style({ opacity: 0 }))]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.75s 0.75s', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class DetailComponent implements OnInit {
  @Input() extended = false;
  @Input() type: DetailTypeEnum;
  @Input() editMode = false;
  @Input() detailValue: any;
  @Input() comparable = false;
  @Input() isEigentumswohnung = false;
  @Input() propType: Category;
  @Input() isPrintMode = false;
  @Input() mobile = false;
  @Input() isExpert = false;
  @Input() country = 'DE';
  @Output() valueChangeEmitter = new EventEmitter<number>();
  @Output() garageEmitter = new EventEmitter<boolean>();
  @Output() formValidEmitter = new EventEmitter<DetailTypeEnum>();
  @Output() formNotValidEmitter = new EventEmitter<DetailTypeEnum>();
  @Output() constructionEmitter = new EventEmitter<string>();

  private icon = '';
  private inputIcon = '';
  private text = '';
  private minValue = 0;
  private maxValue = 1000000;
  public isDropDown = false;
  public dropDownValue = '';
  public numberInput: UntypedFormControl;
  public yearInput: UntypedFormControl;

  public dropDownOptions: string[] = [];
  public dropDownValues: string[] = [];
  private dropdownOptions = options.data;
  constructor(public _authService: AuthenticationService) {}

  ngOnInit() {
    switch (this.type) {
      case DetailTypeEnum.constructionYear: {
        this.text = 'Baujahr';
        this.icon = 'spreng-icon-crane';
        this.inputIcon = 'spreng-icon-crane';
        this.minValue = 1800;
        this.maxValue = new Date().getFullYear();
        break;
      }
      case DetailTypeEnum.livingArea: {
        this.text = 'Wohnfläche';
        this.icon = 'spreng-icon-square-man';
        this.inputIcon = 'spreng-icon-square-man';
        this.minValue = 0;
        this.maxValue = 1000;
        if (this.isEigentumswohnung) {
          this.maxValue = 500;
        }
        break;
      }
      case DetailTypeEnum.garage: {
        this.dropDownOptions = ['Ja', 'Nein'];
        this.dropDownValues = ['ja', 'nein'];
        this.text = 'Garage';
        this.icon = 'spreng-icon-garage';
        this.inputIcon = 'spreng-icon-garage';
        if (this.detailValue === undefined) {
          this.isDropDown = true;
          break;
        }
        if (this.detailValue) {
          this.dropDownValue = 'ja';
        }
        if (!this.detailValue) {
          this.dropDownValue = 'nein';
        }
        this.isDropDown = true;
        break;
      }
      case DetailTypeEnum.construction: {
        const propRef = this.propType;
        const countRef = this.country;
        const opts = this.dropdownOptions.filter(function (value) {
          return value.country === countRef && value.propType === propRef;
        });
        this.dropDownOptions = opts[0].options;
        this.dropDownValues = opts[0].values;

        this.text = 'Objektkategorie';
        this.icon = 'spreng-icon-home';
        this.inputIcon = 'spreng-icon-home';

        const constructionValueArray = Object.values(Construction) as string[];
        const constructionKeyArray = Object.keys(Construction);
        const location = constructionValueArray.indexOf(this.detailValue);
        this.dropDownValue = constructionKeyArray[location];

        this.isDropDown = true;
        break;
      }
      case DetailTypeEnum.plotArea: {
        this.text = 'Grundstücksfläche';
        this.icon = 'spreng-icon-square-house';
        this.inputIcon = 'spreng-icon-square-house';
        this.minValue = 0;
        this.maxValue = 5000;
        break;
      }
      case DetailTypeEnum.rooms: {
        this.text = 'Anzahl Räume';
        this.icon = 'spreng-icon-garage';
        break;
      }
      case DetailTypeEnum.category: {
        this.text = '';
        this.icon = 'spreng-icon-home-city';
        break;
      }
    }
    this.yearInput = new UntypedFormControl('', [
      Validators.pattern('^(\\s*|\\d+)$'),
      Validators.min(this.minValue),
      Validators.max(this.maxValue),
    ]);
    // USE THIS ONE WHEN DECIMAL IMPLEMENTED:
    // this.numberInput = new FormControl('', [Validators.pattern('^[0-9]+\\.[0-9]{2}'), Validators.min(this.minValue), Validators.max(this.maxValue)]);
    this.numberInput = new UntypedFormControl('', [
      Validators.pattern('^(\\s*|\\d+)$'),
      Validators.min(this.minValue),
      Validators.max(this.maxValue),
    ]);
    this.yearInput.markAllAsTouched();
    this.numberInput.markAllAsTouched();
  }

  detailText() {
    return this.text;
  }

  detailValueText() {
    if (
      this.detailValue !== undefined &&
      this.detailValue !== null &&
      this.detailValue !== '' &&
      this.detailValue !== 0 &&
      !isNaN(this.detailValue)
    ) {
      return this.detailValue;
    }
    return this.text;
  }

  detailPostfix() {
    switch (this.type) {
      case DetailTypeEnum.livingArea: {
        return ' m²';
      }
      case DetailTypeEnum.plotArea: {
        return ' m²';
      }
      default: {
        return '';
      }
    }
  }

  formatNumber() {
    switch (this.type) {
      case DetailTypeEnum.livingArea: {
        return true;
      }
      case DetailTypeEnum.plotArea: {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  isGarage() {
    return this.type === DetailTypeEnum.garage;
  }

  garageValue() {
    if (this.detailValue === undefined) {
      return '-';
    }
    if (this.detailValue) {
      return 'Ja';
    }
    return 'Nein';
  }

  iconClass(isInput: boolean) {
    let icon = isInput ? this.inputIcon : this.icon;
    if (this.mobile && !isInput) {
      icon = icon + ' mobile-icon';
    }
    return icon;
  }

  valueChange($event = null) {
    if (this.numberInput.valid && this.yearInput.valid) {
      this.formValidEmitter.emit(this.type);
    } else {
      this.formNotValidEmitter.emit(this.type);
    }

    if (+$event.srcElement.value === 0 || isNaN(+$event.srcElement.value)) {
      this.valueChangeEmitter.emit(undefined);
    } else {
      this.valueChangeEmitter.emit(+$event.srcElement.value);
    }
  }

  dropDownChange() {
    if (this.type === DetailTypeEnum.garage) {
      this.garageEmitter.emit(this.dropDownValue !== 'nein');
    } else {
      this.constructionEmitter.emit(this.dropDownValue);
    }
  }

  getErrorMessage() {
    if (this.numberInput.hasError('pattern')) {
      return 'Muss eine Zahl sein';
    }

    if (this.numberInput.hasError('min')) {
      return 'Muss größer sein als ' + (this.minValue - 1);
    }

    if (this.numberInput.hasError('max')) {
      return 'Muss kleiner sein als ' + (this.maxValue + 1);
    }

    if (this.yearInput.hasError('pattern')) {
      return 'Muss eine Zahl sein';
    }

    if (this.yearInput.hasError('min')) {
      return 'Muss größer sein als ' + (this.minValue - 1);
    }

    if (this.yearInput.hasError('max')) {
      return 'Muss kleiner sein als ' + (this.maxValue + 1);
    }
  }
}
