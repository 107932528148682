<div class="error-container">
  <img src="../../assets/errorsvg.svg" />
  <p class="error-title">{{ title }}</p>
  <p class="error-text">{{ description }}</p>

  <div
    href=""
    *ngIf="!moreDetail && errStack.length > 0"
    class="error-detail-button">
    <a (click)="moreDetail = !moreDetail">Zeig mehr...</a>
  </div>
  <div
    href=""
    *ngIf="moreDetail"
    class="error-detail-button">
    <a (click)="moreDetail = !moreDetail">Zeige weniger...</a>
  </div>
  <div
    *ngIf="moreDetail"
    class="error-detail-more">
    <ng-container *ngFor="let err of errStack">
      <p>{{ err.timestamp | date : 'medium' }} - {{ err.error }}</p>
    </ng-container>
  </div>

  <p
    class="error-button"
    *ngIf="title === 'Zugriffsfehler'">
    <a href="{{ url }}">Wieder einloggen</a>
  </p>

  <p
    class="error-button"
    *ngIf="title !== 'Zugriffsfehler' && !_authService.readOnlyMode"
    (click)="homeClick()">
    <a href="{{ url }}">Homepage</a>
  </p>
</div>
