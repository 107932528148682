<div
  class="comparable-object"
  *ngIf="comparableData !== undefined && !tablet">
  <div class="flex-grid mainBox">
    <div class="col colMain">
      <div class="flex-grid flex-grid-rows">
        <div class="box boxInfo col category-icon-container">
          <app-construction [comparable]="comparableData"></app-construction>
        </div>
        <div
          class="box boxInfo col height55"
          *ngIf="
            comparableData.construction && comparableData.category !== 'MFH'
          ">
          <app-info
            class="infoBar-standard"
            [comparable]="true"
            [reportData]="reportData"></app-info>
        </div>
        <div
          class="box boxInfo col"
          [ngClass]="{
            height55: comparableData.construction,
            height116:
              !comparableData.construction || comparableData.category === 'MFH'
          }">
          <app-distance [distance]="comparableData.distance"></app-distance>
        </div>
      </div>
    </div>
    <div class="col colMain colDetails">
      <div class="box boxDetails">
        <app-details
          [comparable]="true"
          [reportData]="reportData"
          [columnsOnly]="true"
          class="details"></app-details>
      </div>
    </div>
    <div class="col colMain colRatings">
      <div
        class="flex-grid flex-grid-rows"
        style="width: 140px">
        <div class="box col valuationRow">
          <app-valuation
            [saleMode]="_activeDataService.saleMode"
            [valuationData]="valuationData"
            [reportData]="reportData"
            [comparable]="true"></app-valuation>
        </div>
        <div class="box col energyClassRow">
          <div class="energyClassRow-content">
            <app-energy-info
              [energyClass]="comparableData.energy_class"
              [energyCertificate]="
                comparableData.energy_certificate
              "></app-energy-info>
            <div class="label">Energylabel & Typ</div>
          </div>
        </div>

        <div class="box col ratingRow">
          <app-rating [rating]="comparableData.similarity"></app-rating>
        </div>
      </div>
    </div>
  </div>
  <div class="close-button-container">
    <span
      class="spreng-icon-close close-button"
      (click)="close()"></span>
  </div>

  <div class="expert-container">
    <img
      class="expert-icon"
      src="../../../assets/icons/expand-view.svg" />
  </div>
</div>

<div
  class="comparable-object tab"
  *ngIf="comparableData !== undefined && tablet">
  <div class="flex-grid mainBox">
    <div class="rw">
      <div class="col flex-grid flex-grid-rows">
        <div class="box boxInfo col category-icon-container">
          <app-construction [comparable]="comparableData"></app-construction>
        </div>
      </div>

      <div class="col flex-grid flex-grid-rows">
        <div
          class="box boxInfo col height55"
          *ngIf="
            comparableData.construction && comparableData.category !== 'MFH'
          ">
          <app-info
            class="infoBar-standard"
            [comparable]="true"
            [reportData]="reportData"></app-info>
        </div>
        <div
          class="box boxInfo col"
          [ngClass]="{
            height55: comparableData.construction,
            height116:
              !comparableData.construction || comparableData.category === 'MFH'
          }">
          <app-distance [distance]="comparableData.distance"></app-distance>
        </div>
      </div>
    </div>
    <div class="rw">
      <div class="col flex-grid flex-grid-rows">
        <div class="box boxDetails">
          <app-details
            [comparable]="true"
            [reportData]="reportData"
            [columnsOnly]="true"
            class="details"></app-details>
        </div>
      </div>
      <div class="flex-grid flex-grid-rows">
        <div class="box col valuationRow">
          <app-valuation
            [saleMode]="_activeDataService.saleMode"
            [valuationData]="valuationData"
            [reportData]="reportData"
            [comparable]="true"></app-valuation>
        </div>

        <div class="box col ratingRow">
          <app-rating [rating]="comparableData.similarity"></app-rating>
        </div>
      </div>
    </div>
  </div>
  <div class="close-button-container">
    <span
      class="spreng-icon-close close-button"
      (click)="close()"></span>
  </div>

  <div class="expert-container">
    <img
      class="expert-icon"
      src="../../../assets/icons/expand-view.svg" />
  </div>
</div>
