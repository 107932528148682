import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { LineChartData } from 'src/app/core/models/line-chart-data.model';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnChanges {
  @Input() chartSettings: LineChartData = new LineChartData();
  @Input() secondaryChartSettings: LineChartData = new LineChartData();
  @Input() keepAspect = false;
  @Input() printView = false;
  @Input() labels: string[];

  public lineChartLegend = false;
  public lineChartType: ChartType = 'line';
  public lineChartLabels: string[] = [];
  public lineChartData: ChartDataset[];
  public lineChartOptions: ChartOptions = {};

  constructor(public pipe: DecimalPipe) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartSettings'] && changes['chartSettings'].currentValue) {
      this.updateChart();
    }
  }

  updateChart() {
    const preRef = this.chartSettings.prefixY;
    const postRef = this.chartSettings.postfixY;

    const pipeRef = this.pipe;
    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      // responsiveAnimationDuration: 0,
      elements: {
        line: {
          // makes lines straight rather than curved.
          tension: 0,
        },
      },
      plugins: {
        legend: { display: true },
      },
      scales: {
        y: {
          type: 'linear',
          position: 'left',
          ticks: {
            font: {
              size: 10,
            },
            callback(value, index, values) {
              // modify here to add delimiters in
              return (
                preRef + pipeRef.transform(value, '1.0-0').toString() + postRef
              );
            },
          },
        },
      },
    };
    if (this.secondaryChartSettings.data.length) {
      this.lineChartData = [
        {
          data: this.chartSettings.data,
          label: this.chartSettings.title,
          fill: false,
          borderColor: this.chartSettings.mainColor,
          backgroundColor: this.chartSettings.mainColor,
          pointBackgroundColor: this.chartSettings.secondaryColor,
          pointBorderColor: this.chartSettings.mainColor,
          pointHoverBackgroundColor: this.chartSettings.mainColor,
          pointHoverBorderColor: this.chartSettings.mainColor,
        },
        {
          data: this.secondaryChartSettings.data,
          label: this.secondaryChartSettings.title,
          fill: false,
          borderColor: '#40C97C',
          backgroundColor: '#40C97C',
          pointBackgroundColor: '#FFFFFF',
          pointBorderColor: '#40C97C',
          pointHoverBackgroundColor: '#40C97C',
          pointHoverBorderColor: '#40C97C',
        },
      ];
    } else {
      this.lineChartData = [
        {
          data: this.chartSettings.data,
          label: this.chartSettings.title,
          fill: false,
          borderColor: this.chartSettings.mainColor,
          backgroundColor: this.chartSettings.mainColor,
          pointBackgroundColor: this.chartSettings.secondaryColor,
          pointBorderColor: this.chartSettings.mainColor,
          pointHoverBackgroundColor: this.chartSettings.mainColor,
          pointHoverBorderColor: this.chartSettings.mainColor,
        },
      ];
    }

    this.lineChartLabels = this.chartSettings.labels;
  }
}
