import {
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
  HttpHandler,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppHttpErrorResponse } from '../services/error.service';

@Injectable()
export class CustomHttpHeaderInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const newReq = req.clone({
      headers: req.headers
        .append('Authorization', 'Bearer ' + environment.bearerToken)
        .append('Session-Token', window.localStorage.getItem('session_token')),
    });

    return next.handle(newReq).pipe(
      retry(1),
      catchError((httpError: AppHttpErrorResponse) => {
        httpError.isHardError = true;
        return throwError(httpError);
      })
    );
  }
}
