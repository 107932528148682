<div class="address-search-container">
  <div
    class="resolution-test"
    *ngIf="showDebug">
    Current Effective Resolution : {{ resolutionTest }}
  </div>
  <button
    mat-button
    *ngIf="mobile && activePanel === 2"
    (click)="toggleFullHistory()"
    class="panel-button toggle-full-history-button"
    [@showHideButton]="">
    Alle Immobilien
  </button>
  <button
    *ngIf="mobile && activePanel === 2"
    class="rowBtn mobile-history-button mobile-history-button-back"
    mat-button
    (click)="activatePanel(1)"
    [@showHideMobileSearchButton]="">
    <span class="mat-button-label-rotate spreng-icon-magnify"></span>
  </button>

  <button
    class="rowBtn mobile-history-button"
    mat-button
    (click)="activatePanel(2, true)">
    <span class="mat-button-label-rotate spreng-icon-history"></span>
  </button>
  <div
    name="search-panel"
    (click)="!mobile && panelChange($event, false, false, true)"
    *ngIf="activePanel === 1 || (activePanel === 2 && !mobile)"
    [@showHideButton]=""
    class="general-panel address-search-panel"
    [ngClass]="{
      'active-panel': activePanel === 1,
      'passive-panel': activePanel !== 1
    }">
    <div class="panel-container">
      <div class="panel-header">
        <span class="panel-icon"><svg class="search-icon"></svg></span>
        <span
          name="lbl_panel_title"
          class="panel-title">
          Neue Immobilie
        </span>
      </div>
      <div
        class="panel-content"
        (click)="$event.stopPropagation()">
        <app-geocoder></app-geocoder>
      </div>
      <div class="panel-footer">
        <span
          (click)="panelChange($event, false, true)"
          (mouseenter)="hoverLink(true)"
          (mouseleave)="hoverLink(false)"
          name="link_toggle_panel"
          class="panel-link">
          Übersicht analysierter Immobilien
        </span>
      </div>
    </div>
  </div>
  <div
    *ngIf="!mobile"
    name="history-panel"
    [@openClose]="isOpen ? 'open' : 'closed'"
    (click)="panelChange($event, true)"
    class="general-panel search-history-panel"
    [ngClass]="{
      'active-panel': activePanel === 2,
      'passive-panel': activePanel !== 2
    }">
    <div
      class="panel-container"
      *ngIf="activePanel === 2">
      <div
        name="search-panel-header"
        class="panel-header">
        <span class="panel-icon"><svg class="history-icon"></svg></span>
        <span
          name="latest-valuations-panel-title"
          class="panel-title">
          Letzte Immobilien
        </span>
      </div>
      <div class="panel-content">
        <app-valuation-history
          name="latest-valuation-list"
          [limit]="3"
          [hideHeaderRow]="true"
          (historicReportEmitter)="loadReport($event)"
          class="valuation-history"></app-valuation-history>
      </div>
      <div class="panel-footer">
        <button
          mat-button
          *ngIf="!mobile"
          (click)="toggleFullHistory()"
          class="panel-button">
          Alle Immobilien
        </button>

        <div (click)="panelChange($event)">
          <button
            name="latest-valuations-close-button"
            class="rowBtn close-button-back"
            mat-button
            (click)="panelChange($event)">
            <span class="mat-button-label-rotate spreng-icon-arrow-left"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="mobile"
    name="history-panel"
    [@mobileOpenClose]="isOpen ? 'mobileOpen' : 'mobileClosed'"
    (click)="!mobile && panelChange($event)"
    class="general-panel search-history-panel"
    [ngClass]="{
      'active-panel': activePanel === 2,
      'passive-panel': activePanel !== 2
    }">
    <div
      class="panel-container"
      *ngIf="activePanel === 2">
      <div
        name="search-panel-header"
        class="panel-header">
        <span class="panel-icon"><svg class="history-icon"></svg></span>
        <span
          name="latest-valuations-panel-title"
          class="panel-title">
          Letzte Immobilien
        </span>
      </div>
      <div class="panel-content">
        <div>
          <app-valuation-history
            name="latest-valuation-list"
            [limit]="3"
            [hideHeaderRow]="true"
            (historicReportEmitter)="
              loadReport($event)
            "></app-valuation-history>
        </div>
      </div>
    </div>
  </div>
</div>
