import { Component, OnInit, Input } from '@angular/core';
import { ActiveDataService } from 'src/app/core/services/active-data.service';
import { ChartGenerator } from 'src/app/core/functions/chartGenerator.function';
import { IpiGetResponse } from 'src/app/core/models/ipi-get-response.model';
import { Subscription } from 'rxjs';
import { LineChartData } from 'src/app/core/models/line-chart-data.model';
import { SimIndexResponse } from 'src/app/core/models/sim-index-response.model';

@Component({
  selector: 'app-expert-valuation',
  templateUrl: './expert-valuation.component.html',
  styleUrls: ['./expert-valuation.component.scss'],
})
export class ExpertValuationComponent implements OnInit {
  @Input() valuationData: any;
  @Input() reportData: any;
  @Input() equipmentData: any;
  @Input() ipiData: any;

  public regionData: IpiGetResponse = undefined;
  public showTrend = true;
  public showEquip = true;

  private regionSubscription: Subscription;
  public simData: SimIndexResponse = undefined;
  private simSubscription: Subscription;
  private showCountry = true;

  constructor(public _activeDataService: ActiveDataService) {}

  ngOnInit() {
    this.loadSimIndex();
    this.loadregionData();
  }

  loadSimIndex() {
    this.simSubscription = this._activeDataService.simEmitter.subscribe(
      (data: any) => {
        if (data === null) {
        } else {
          this.simData = data;
        }
        this.simSubscription.unsubscribe();
      },
      (error: any) => {
        this.simSubscription.unsubscribe();
        throw error;
      }
    );
    this._activeDataService.getSimData();
  }

  loadregionData(reload = false) {
    if (this._activeDataService.saleMode) {
      this.regionSubscription =
        this._activeDataService.regionValuationEmitter.subscribe(
          (data: IpiGetResponse) => {
            if (data === null) {
            } else {
              this.regionData = data;
            }
            this.regionSubscription.unsubscribe();
          },
          (error: any) => {
            this.regionSubscription.unsubscribe();
            throw error;
          }
        );
      this._activeDataService.getValuationRegionData(reload);
    }
  }

  getRegionalMarketData(): LineChartData {
    const chartCalc = new ChartGenerator();
    const returnValue = chartCalc.generateMarketData(
      this.regionData,
      this.valuationData
    );
    if (!this._activeDataService.saleMode) {
      returnValue.mainColor = '#A2A811';
    }
    return returnValue;
  }

  getMarketData(): LineChartData {
    if (this.showCountry) {
      const chartCalc = new ChartGenerator();
      const returnValue = chartCalc.generateMarketData(
        this.ipiData,
        this.valuationData
      );
      if (!this._activeDataService.saleMode) {
        returnValue.mainColor = '#A2A811';
      }
      return returnValue;
    } else {
      return new LineChartData();
    }
  }

  getSimData(): LineChartData {
    let returnValue = new LineChartData();

    if (this.simData) {
      const chartCalc = new ChartGenerator();
      returnValue = chartCalc.generateSimData(
        this.simData,
        this.valuationData,
        false
      );
    }

    return returnValue;
  }

  togglebndMode() {
    this.showCountry = !this.showCountry;
  }

  toggleMode() {
    this._activeDataService.saleMode = !this._activeDataService.saleMode;
    if (!this._activeDataService.saleMode) {
      this.showCountry = true;
    }
    this.loadSimIndex();
    if (this._activeDataService.saleMode) {
      this.loadregionData();
    }
    this._activeDataService.modeEmitter.emit();
  }

  shouldShowTrend() {
    if (
      this._activeDataService.reportData === null ||
      this._activeDataService.reportData === undefined ||
      this._activeDataService.reportData.config === null ||
      this._activeDataService.reportData.config === undefined
    ) {
      return this.showTrend;
    }

    this.showTrend = this._activeDataService.reportData.config.show_trend;
    return this.showTrend;
  }

  shouldShowEquip() {
    if (
      this._activeDataService.reportData === null ||
      this._activeDataService.reportData === undefined ||
      this._activeDataService.reportData.config === null ||
      this._activeDataService.reportData.config === undefined
    ) {
      return this.showEquip;
    }

    this.showEquip =
      this._activeDataService.reportData.config.show_equipment_overview;

    return this.showEquip;
  }
}
