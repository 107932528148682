<app-error *ngIf="appError"></app-error>
<div
  class="valuation-container val-card"
  *ngIf="dataLoaded() && !extended && !printMode">
  <div
    class="clear"
    *ngIf="dataLoaded() && configAllowed()">
    <div [ngClass]="{ 'val-header': !comparable, 'comp-header': comparable }">
      <div
        [ngClass]="{
          'val-title-rental': !saleMode,
          'val-title-sale': saleMode
        }"
        class="detail-text-extra-small val-title">
        <span
          *ngIf="saleMode && !extended && !expert && !comparable"
          [ngClass]="getTypeClass()">
          Marktpreis
        </span>
        <span
          *ngIf="!saleMode && !extended && !expert && !comparable"
          [ngClass]="getTypeClass()">
          Miete
        </span>
        <span
          *ngIf="saleMode && !extended && expert && !comparable"
          [ngClass]="getTypeClass()">
          Marktpreis und Preisspanne
        </span>
        <span
          *ngIf="!saleMode && !extended && expert && !comparable"
          [ngClass]="getTypeClass()">
          Miete und Mietspanne
        </span>
        <span
          *ngIf="saleMode && !extended && !expert && comparable"
          [ngClass]="getTypeClass()">
          Preisangebot
        </span>
        <span
          *ngIf="!saleMode && !extended && !expert && comparable"
          [ngClass]="getTypeClass()">
          Mietangebot
        </span>
      </div>
    </div>
    <div class="iContainer">
      <span
        *ngIf="!comparable"
        matTooltip="Bei dieser Bewertung handelt es sich um eine automatisierte Schätzung, basierend auf den angegebenen Daten. Die Schätzwerte stellen keine rechtsverbindlichen Marktwerte oder Mietwerte dar."
        matTooltipPosition="right"
        matTooltipClass="tooltip"
        class="spreng-icon-info detailsInfoIcon"></span>
    </div>
    <div
      [ngClass]="{ 'valuation-contents': comparable }"
      *ngIf="comparable || shouldShowExactPrice()">
      <div class="value-text-bold-large valuation-row">
        {{ valuationData.value | number : '1.0-0' }} €
        <span *ngIf="reportData?.value_overwritten">*</span>
      </div>
      <div
        class="valuation-row val-sq-m"
        [ngClass]="{
          'value-text-bold-small': !comparable,
          'value-text-bold-comparable': comparable
        }"
        *ngIf="
          this.reportData.request.living_area !== undefined &&
          this.reportData.request.living_area !== null &&
          saleMode
        "
        [ngStyle]="{
          'margin-bottom':
            reportData?.value_overwritten || comparable ? '0px' : '20px'
        }">
        {{
          valuationData.value / this.reportData.request.living_area
            | number : '1.0-0'
        }}
        €/m²
      </div>
      <div
        class="valuation-row val-sq-m"
        [ngClass]="{
          'value-text-bold-small': !comparable,
          'value-text-bold-comparable': comparable
        }"
        *ngIf="
          this.reportData.request.living_area !== undefined &&
          this.reportData.request.living_area !== null &&
          !saleMode
        "
        [ngStyle]="{
          'margin-bottom':
            reportData?.value_overwritten || comparable ? '10px' : '20px'
        }">
        {{
          valuationData.value / this.reportData.request.living_area
            | number : '1.2-2'
        }}
        €/m²
      </div>
      <div
        class="valuation-row val-sq-m"
        [ngClass]="{
          'value-text-bold-small': !comparable,
          'value-text-bold-comparable': comparable
        }"
        *ngIf="
          (this.reportData.request.living_area === undefined ||
            this.reportData.request.living_area === null) &&
          this.showExactPrice
        "
        [ngStyle]="{
          'margin-bottom':
            reportData?.value_overwritten || comparable ? '10px' : '20px'
        }">
        -€/m²
        <span
          matTooltip="Bitte geben Sie die Wohnfläche ein, um den Quadratmeterpreis zu berechnen."
          matTooltipPosition="right"
          matTooltipClass="tooltip"
          class="spreng-icon-info infoIcon"></span>
      </div>
      <div
        class="overwrite-text"
        style="text-align: center; font-size: 8px">
        <span *ngIf="reportData?.value_overwritten">
          *Die Werte wurden von Ihrem Berater angepasst
        </span>
      </div>
    </div>
    <div
      *ngIf="!comparable && !shouldShowExactPrice()"
      class="price-box">
      <div class="left-info">
        <div class="side-info-text">
          <span>{{ valuationData.meta.range.min | number : '1.0-0' }} €</span>
          <span
            *ngIf="saleMode"
            class="small-boxed-text">
            {{
              valuationData.meta.range.min / this.reportData.request.living_area
                | number : '1.0-0'
            }}
            €/m²
          </span>
          <span
            *ngIf="!saleMode"
            class="small-boxed-text">
            {{
              valuationData.meta.range.min / this.reportData.request.living_area
                | number : '1.2-2'
            }}
            €/m²
          </span>
        </div>
        <span
          *ngIf="saleMode"
          class="small-boxed-text side-info-label">
          Minimaler Preis
        </span>
        <span
          *ngIf="!saleMode"
          class="small-boxed-text side-info-label">
          Minimale Miete
        </span>
      </div>

      <div class="right-info">
        <div class="side-info-text">
          <span>{{ valuationData.meta.range.max | number : '1.0-0' }} €</span>
          <span
            class="small-boxed-text"
            *ngIf="saleMode">
            {{
              valuationData.meta.range.max / this.reportData.request.living_area
                | number : '1.0-0'
            }}
            €/m²
          </span>
          <span
            class="small-boxed-text"
            *ngIf="!saleMode">
            {{
              valuationData.meta.range.max / this.reportData.request.living_area
                | number : '1.2-2'
            }}
            €/m²
          </span>
        </div>
        <span
          *ngIf="saleMode"
          class="small-boxed-text side-info-label">
          Maximaler Preis
        </span>
        <span
          *ngIf="!saleMode"
          class="small-boxed-text side-info-label">
          Maximale Miete
        </span>
      </div>
    </div>
    <div
      class="overwrite-text"
      style="text-align: center; font-size: 8px; padding-top: 3px">
      <span
        *ngIf="
          !comparable &&
          reportData?.value_overwritten &&
          !shouldShowExactPrice()
        ">
        *Die Werte wurden von Ihrem Berater angepasst
      </span>
    </div>
    <div class="val-row-bottom value-text-bold-small valuation-row">
      <ng-container *ngIf="shouldShowTrend()">
        <span class="one-year-text">
          {{ getOneYearText() }}
        </span>
        <span class="trend-text">
          {{ getOneYearTrend() | number : '1.1-1' }}%
        </span>
        <span
          [ngClass]="getOneYearTrendValue()"
          class="trend-icon"></span>
      </ng-container>
    </div>
  </div>
</div>

<div
  class="valuation-container valuation-container-extended"
  *ngIf="dataLoaded() && extended">
  <div
    [ngClass]="{
      'val-header': !comparable,
      'comp-header': comparable,
      'no-gap': !shouldShowExactPrice() && !mobile
    }"
    class="val-header-extended">
    <div
      [ngClass]="{ 'val-title-rental': !saleMode, 'val-title-sale': saleMode }"
      class="detail-text-extra-small val-title">
      <span
        [@detailFade]=""
        *ngIf="saleMode"
        [ngClass]="getTypeClass()">
        Marktpreis
      </span>
      <span
        [@detailFade]=""
        *ngIf="!saleMode"
        [ngClass]="getTypeClass()">
        Miete
      </span>
    </div>
  </div>

  <div
    class="value-text val-text-extended"
    [ngClass]="{ 'value-text-expert': expert }"
    *ngIf="shouldShowExactPrice()">
    <div
      class="val-border"
      [ngClass]="{ 'rent-border': !saleMode }">
      <span
        *ngIf="!comparable"
        matTooltip="Bei dieser Bewertung handelt es sich um eine automatisierte Schätzung, basierend auf den angegebenen Daten. Die Schätzwerte stellen keine rechtsverbindlichen Marktwerte oder Mietwerte dar."
        matTooltipPosition="right"
        matTooltipClass="tooltip"
        class="spreng-icon-info valuationInfoIcon hide-for-mobile"></span>

      <div class="value-text-container">
        <span class="value-section">
          <span
            *ngIf="canEdit && !editingValue"
            (click)="closeEditors(); editingValue = !editingValue"
            id="editValBtn-Val"
            class="editValBtn">
            <img
              class="edit-icon"
              src="../../../assets/editicon.svg" />
          </span>
          <span
            *ngIf="canEdit && editingValue"
            (click)="saveNewValue()"
            id="editValBtn-Val"
            class="editValBtn">
            <img
              class="save-icon"
              src="../../../assets/saveicon.svg" />
          </span>

          <span
            *ngIf="!editingValue"
            class="value-text">
            {{ valuationData.value | number : '1.0-0' }} €
          </span>
          <form [formGroup]="valueEditorForm">
            <input
              formControlName="valueEditor"
              *ngIf="editingValue"
              placeholder="{{ valuationData.value | number : '1.0-0' }}" />
            <span *ngIf="editingValue">€</span>
          </form>
        </span>

        <span class="area-section">
          <span
            *ngIf="saleMode"
            class="area-value-text">
            {{
              valuationData.value / this.reportData.request.living_area
                | number : '1.0-0'
            }}
            €/m²
          </span>

          <span
            *ngIf="!saleMode"
            class="area-value-text">
            {{
              valuationData.value / this.reportData.request.living_area
                | number : '1.2-2'
            }}
            €/m²
          </span>
        </span>
      </div>

      <div
        *ngIf="saleMode"
        class="sub-text">
        Marktpreis
        <span *ngIf="reportData?.value_overwritten">*</span>
      </div>
      <div
        *ngIf="!saleMode"
        class="sub-text">
        Miete
        <span *ngIf="reportData?.value_overwritten">*</span>
      </div>
      <div class="overwrite-text">
        <span *ngIf="reportData?.value_overwritten">
          *Die Werte wurden von Ihrem Berater angepasst
        </span>
      </div>
    </div>
  </div>

  <div
    class="value-info val-info-extended"
    [ngClass]="{ 'val-info-expert': expert }">
    <form [formGroup]="valueRangeEditorForm">
      <div
        class="left-info"
        [ngClass]="{ 'left-info-mobile': mobile }">
        <div
          class="side-info-text"
          [ngClass]="{
            'side-info-text-no-exact': !shouldShowExactPrice() && !mobile
          }">
          <span
            *ngIf="canEdit && !editingValueMin"
            (click)="closeEditors(); editingValueMin = !editingValueMin"
            class="editValRangeBtn">
            <img
              class="edit-icon-small"
              src="../../../assets/editicon.svg" />
          </span>
          <span
            (click)="saveNewValueRange(true)"
            *ngIf="canEdit && editingValueMin"
            class="editValRangeBtn">
            <img
              class="save-icon-small"
              src="../../../assets/saveicon.svg" />
          </span>

          <span *ngIf="!editingValueMin">
            {{ valuationData.meta.range.min | number : '1.0-0' }} €
          </span>
          <span class="range-section">
            <span>
              <input
                formControlName="minValueEditor"
                *ngIf="editingValueMin"
                placeholder="{{
                  valuationData.meta.range.min | number : '1.0-0'
                }}" />
              <span *ngIf="editingValueMin">€</span>
            </span>

            <span
              *ngIf="saleMode"
              class="small-boxed-text"
              [ngClass]="{
                'small-boxed-text-no-exact': !shouldShowExactPrice() && !mobile
              }">
              {{
                valuationData.meta.range.min /
                  this.reportData.request.living_area | number : '1.0-0'
              }}
              €/m²
            </span>
            <span
              *ngIf="!saleMode"
              class="small-boxed-text"
              [ngClass]="{
                'small-boxed-text-no-exact': !shouldShowExactPrice() && !mobile
              }">
              {{
                valuationData.meta.range.min /
                  this.reportData.request.living_area | number : '1.2-2'
              }}
              €/m²
            </span>
          </span>
        </div>
        <span
          *ngIf="saleMode"
          class="small-boxed-text side-info-label">
          Minimaler Preis
        </span>
        <span
          *ngIf="!saleMode"
          class="small-boxed-text side-info-label">
          Minimale Miete
        </span>
      </div>

      <div
        class="right-info"
        [ngClass]="{ 'right-info-mobile': mobile }">
        <div
          class="side-info-text"
          [ngClass]="{
            'side-info-text-no-exact': !shouldShowExactPrice() && !mobile
          }">
          <span
            *ngIf="canEdit && !editingValueMax"
            (click)="closeEditors(); editingValueMax = !editingValueMax"
            class="editValRangeBtn">
            <img
              class="edit-icon-small"
              src="../../../assets/editicon.svg" />
          </span>
          <span
            *ngIf="canEdit && editingValueMax"
            (click)="saveNewValueRange(false)"
            class="editValRangeBtn">
            <img
              class="save-icon-small"
              src="../../../assets/saveicon.svg" />
          </span>

          <span *ngIf="!editingValueMax">
            {{ valuationData.meta.range.max | number : '1.0-0' }} €
          </span>
          <span class="range-section">
            <span>
              <input
                formControlName="maxValueEditor"
                *ngIf="editingValueMax"
                placeholder="{{
                  valuationData.meta.range.max | number : '1.0-0'
                }}" />
              <span *ngIf="editingValueMax">€</span>
            </span>
            <span
              class="small-boxed-text"
              *ngIf="saleMode"
              [ngClass]="{
                'small-boxed-text-no-exact': !shouldShowExactPrice() && !mobile
              }">
              {{
                valuationData.meta.range.max /
                  this.reportData.request.living_area | number : '1.0-0'
              }}
              €/m²
            </span>
            <span
              class="small-boxed-text"
              *ngIf="!saleMode"
              [ngClass]="{
                'small-boxed-text-no-exact': !shouldShowExactPrice() && !mobile
              }">
              {{
                valuationData.meta.range.max /
                  this.reportData.request.living_area | number : '1.2-2'
              }}
              €/m²
            </span>
          </span>
        </div>
        <span
          *ngIf="saleMode"
          class="small-boxed-text side-info-label">
          Maximaler Preis
        </span>
        <span
          *ngIf="!saleMode"
          class="small-boxed-text side-info-label">
          Maximale Miete
        </span>
      </div>
    </form>
  </div>

  <div
    class="overwrite-text"
    style="text-align: center; padding-bottom: 10px">
    <span *ngIf="reportData?.value_overwritten && !shouldShowExactPrice()">
      *Die Werte wurden von Ihrem Berater angepasst
    </span>
  </div>
</div>

<div
  class="print-container"
  *ngIf="dataLoaded() && printMode">
  <div class="left-info">
    <div class="side-info-text">
      <span>{{ valuationData.meta.range.min | number : '1.0-0' }} €</span>
      <span
        *ngIf="saleMode"
        class="small-boxed-text">
        {{
          valuationData.meta.range.min / this.reportData.request.living_area
            | number : '1.0-0'
        }}
        €/m²
      </span>
      <span
        *ngIf="!saleMode"
        class="small-boxed-text">
        {{
          valuationData.meta.range.min / this.reportData.request.living_area
            | number : '1.2-2'
        }}
        €/m²
      </span>
    </div>
    <span class="small-boxed-text side-info-label">Minimaler Preis</span>
  </div>

  <div
    class="main-detail"
    *ngIf="shouldShowExactPrice()">
    <div class="value-text-container">
      <span class="value-text">
        {{ valuationData.value | number : '1.0-0' }} €
      </span>
      <span
        class="area-value-text"
        *ngIf="saleMode">
        {{
          valuationData.value / this.reportData.request.living_area
            | number : '1.0-0'
        }}
        €/m²
      </span>
      <span
        class="area-value-text"
        *ngIf="!saleMode">
        {{
          valuationData.value / this.reportData.request.living_area
            | number : '1.2-2'
        }}
        €/m²
      </span>
    </div>

    <div
      *ngIf="saleMode"
      class="sub-text">
      Marktpreis
      <span *ngIf="reportData?.value_overwritten">*</span>
    </div>
    <div
      *ngIf="!saleMode"
      class="sub-text">
      Miete
      <span *ngIf="reportData?.value_overwritten">*</span>
    </div>
    <div class="overwrite-text">
      <span *ngIf="reportData?.value_overwritten">
        *Die Werte wurden von Ihrem Berater angepasst
      </span>
    </div>
  </div>

  <div class="right-info">
    <div class="side-info-text">
      <span>{{ valuationData.meta.range.max | number : '1.0-0' }} €</span>
      <span
        class="small-boxed-text"
        *ngIf="saleMode">
        {{
          valuationData.meta.range.max / this.reportData.request.living_area
            | number : '1.0-0'
        }}
        €/m²
      </span>
      <span
        class="small-boxed-text"
        *ngIf="!saleMode">
        {{
          valuationData.meta.range.max / this.reportData.request.living_area
            | number : '1.2-2'
        }}
        €/m²
      </span>
    </div>
    <span class="small-boxed-text side-info-label">Maximaler Preis</span>
  </div>

  <div
    *ngIf="reportData?.value_overwritten && !shouldShowExactPrice()"
    class="overwrite-text"
    style="text-align: center; padding-bottom: 10px; color: black">
    <span>*Die Werte wurden von Ihrem Berater angepasst</span>
  </div>
</div>
