<div
  [@detailFade]=""
  *ngIf="!editMode || _authService.readOnlyMode"
  class="infoIconBox flex"
  [ngClass]="{ 'comparable-icon': comparable, 'print-icon': isPrintMode }">
  <span
    [ngClass]="iconClass(false)"
    class="iconBox icon32"></span>
</div>
<div
  [@detailFade]=""
  *ngIf="!editMode"
  class="infoTextBox infoBox"
  [ngClass]="{ printInfoText: isPrintMode, printInfoTextFirst: type === 6 }">
  <span
    *ngIf="formatNumber()"
    [ngClass]="{ 'detail-text': !comparable, 'comparable-value': comparable }"
    style="text-transform: lowercase">
    {{ detailValue | number : '1.2-2' : 'de' }}{{ detailPostfix() }}
  </span>
  <span
    *ngIf="!formatNumber() && !isGarage()"
    [ngClass]="{ 'detail-text': !comparable, 'comparable-value': comparable }">
    {{ detailValue || '-' }}{{ detailPostfix() }}
  </span>
  <span
    *ngIf="isGarage()"
    [ngClass]="{ 'detail-text': !comparable, 'comparable-value': comparable }">
    {{ garageValue() }}
  </span>
  <div
    style="width: 100%"
    [ngClass]="{
      'expert-detail-text': isExpert,
      'comparable-detail-text-2': comparable,
      'print-detail-text': isPrintMode
    }">
    {{ detailText() }}
  </div>
</div>

<div
  [@detailFade]=""
  *ngIf="!_authService.readOnlyMode && editMode && !isDropDown"
  class="infoBox 1">
  <mat-form-field
    class="inputFormField"
    appearance="outline">
    <input
      *ngIf="type === 0"
      [formControl]="yearInput"
      class="inputTxtField"
      matInput
      placeholder="{{ detailValueText() }}"
      (keyup)="valueChange($event)" />
    <input
      *ngIf="type !== 0"
      [formControl]="numberInput"
      class="inputTxtField"
      matInput
      placeholder="{{ detailValueText() }}"
      (keyup)="valueChange($event)" />

    <span
      matPrefix
      [ngClass]="iconClass(true)"
      class="icon16"></span>
    <mat-error *ngIf="numberInput.invalid">{{ getErrorMessage() }}</mat-error>
    <mat-error *ngIf="yearInput.invalid">{{ getErrorMessage() }}</mat-error>
  </mat-form-field>
</div>

<div
  [@detailFade]=""
  *ngIf="!_authService.readOnlyMode && editMode && isDropDown"
  class="infoBox 2">
  <mat-form-field
    class="inputFormField"
    appearance="outline"
    style="width: 200px">
    <mat-select
      class="inputTxtField"
      placeholder="{{ detailText() }}"
      (selectionChange)="dropDownChange()"
      [(value)]="dropDownValue">
      <mat-option
        *ngFor="let ddOpt of dropDownOptions; let i = index"
        value="{{ dropDownValues[i] }}">
        {{ ddOpt }}
      </mat-option>
    </mat-select>
    <span
      matPrefix
      [ngClass]="iconClass(true)"
      class="icon16"></span>
  </mat-form-field>
</div>
