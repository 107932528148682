<div style="display: flex">
  <div
    class="switch-text"
    [ngStyle]="saleActive()">
    Marktpreis
  </div>
  <label class="switch">
    <input
      type="checkbox"
      (click)="valueChange($event)"
      *ngIf="!value" />
    <input
      type="checkbox"
      (click)="valueChange($event)"
      *ngIf="value"
      checked />
    <span class="slider round"></span>
  </label>
  <div
    class="switch-text"
    [ngStyle]="rentActive()">
    Mietwert
  </div>
</div>
