import { animate, style, transition, trigger } from '@angular/animations';

export const Animations = {
  routeTrigger: trigger('routeAnimations', [
    transition('* => DeniedPage', [
      style({ transform: 'translateY(-100%)' }),
      animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
    ]),
    transition('DeniedPage => *', [
      animate('200ms ease-in', style({ transform: 'translateY(-100%)' })),
    ]),
    transition('* => HomePage', [
      style({ opacity: 0 }),
      animate('0.75s', style({ opacity: 1 })),
    ]),
    transition('HomePage => *', [animate('1.25s', style({ opacity: 0 }))]),
  ]),
};
