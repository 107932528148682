import { Component, OnInit, Input } from '@angular/core';
import { Report } from 'src/app/core/models/reports.model';
import { IpiGetResponse } from 'src/app/core/models/ipi-get-response.model';
import { Valuation } from 'src/app/core/models/valuation.model';
import { TrendCalculator } from 'src/app/core/functions/trendCalculator.function';
import { ActiveDataService } from 'src/app/core/services/active-data.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { ReportService } from 'src/app/core/services/report.service';
import { ReportOverwriteRequest } from 'src/app/core/models/report-overwrite-request.model';
import { ReportOverwriteResponse } from 'src/app/core/models/report-overwrite-response.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserDataService } from 'src/app/core/services/user-data.service';
import { UserData } from 'src/app/core/models/user-data.model';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-valuation',
  templateUrl: './valuation.component.html',
  styleUrls: ['./valuation.component.scss'],
  animations: [
    trigger('detailFade', [
      transition(':enter', [
        animate('0s 2s', style({ opacity: 0 })),
        animate('2s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('2s', style({ opacity: 0 })),
        animate('0s', style({ display: 'none' })),
      ]),
    ]),
    trigger('openCloseSearchBar', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 })),
      ]),
      transition('* => void', [animate('0.5s', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ValuationComponent implements OnInit {
  @Input() comparable = false;
  @Input() saleMode = true;
  @Input() extended = false;
  @Input() expert = false;
  @Input() printMode = false;
  @Input() mobile = false;

  @Input() reportData: Report;
  @Input() ipiData: IpiGetResponse;
  @Input() valuationData: Valuation;
  @Input() appError = false;

  private trendHelper: TrendCalculator;
  showExactPrice = true;
  private showTrend = true;

  canEdit = false;

  editingValue = false;
  editingValueMin = false;
  editingValueMax = false;

  public valueEditorForm: UntypedFormGroup;
  public valueRangeEditorForm: UntypedFormGroup;

  constructor(
    private _activeDataService: ActiveDataService,
    private _reportService: ReportService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private userService: UserDataService,
    private authService: AuthenticationService
  ) {
    this.valueEditorForm = this.formBuilder.group({
      valueEditor: '',
    });

    this.valueRangeEditorForm = this.formBuilder.group({
      minValueEditor: '',
      maxValueEditor: '',
    });
  }

  ngOnInit() {
    if (!this.authService.readOnlyMode) {
      if (this.reportData.report_id) {
        this.userService.getReportUser(this.reportData.report_id).subscribe({
          next: (rdata: UserData) => {
            this.canEdit = rdata.allow_value_overwrite;
          },
          error: (error: any) => {
            this.canEdit = false;
            this.notificationService.notifyApiError(error);
          },
        });
      } else {
        this.canEdit = false;
      }
    } else {
      this.canEdit = false;
    }
  }

  getTypeClass() {
    if (this.saleMode) {
      return 'value-text-bold-small sale-text valuation-row';
    }
    return 'value-text-bold-small rent-text valuation-row';
  }

  getIconClass() {
    if (this.saleMode) {
      return 'icon sale';
    }
    return 'icon rent';
  }

  dataLoaded() {
    if (this.comparable || this.extended || this.printMode) {
      return (
        this.reportData !== undefined &&
        this.reportData !== null &&
        this.valuationData !== undefined &&
        this.valuationData !== null
      );
    }
    return (
      this.reportData !== undefined &&
      this.reportData !== null &&
      this.valuationData !== undefined &&
      this.valuationData !== null &&
      this.ipiData !== undefined &&
      this.ipiData !== null
    );
  }

  getOneYearText() {
    if (this.trendHelper === undefined) {
      this.trendHelper = new TrendCalculator(this.ipiData);
    }

    return this.trendHelper.getOneYearText();
  }

  getOneYearTrend() {
    if (this.trendHelper === undefined) {
      this.trendHelper = new TrendCalculator(this.ipiData);
    }

    return this.trendHelper.getOneYearTrend();
  }

  getOneYearTrendValue() {
    if (this.trendHelper === undefined) {
      this.trendHelper = new TrendCalculator(this.ipiData);
    }

    switch (this.trendHelper.getOneYearTrendValue()) {
      case '1': {
        return 'spreng-icon-trending-1 trend-icon-1';
      }
      case '2': {
        return 'spreng-icon-trending-2 trend-icon-2';
      }
      case '3': {
        return 'spreng-icon-trending-3 trend-icon-3';
      }
      case '4': {
        return 'spreng-icon-trending-4 trend-icon-4';
      }
      case '5': {
        return 'spreng-icon-trending-5 trend-icon-5';
      }
      default: {
        return '';
      }
    }
  }

  shouldShowExactPrice() {
    const valueFromActiveDataService =
      this._activeDataService.reportData?.config?.show_exact_price;
    const valueFromReportData = this.reportData.config?.show_exact_price;

    this.showExactPrice = this.getValueOrDefault(
      valueFromActiveDataService,
      valueFromReportData,
      this.showExactPrice
    );

    return this.showExactPrice;
  }

  shouldShowTrend() {
    const valueFromActiveDataService =
      this._activeDataService.reportData?.config?.show_trend;
    const valueFromReportData = this.reportData.config?.show_trend;

    this.showTrend = this.getValueOrDefault(
      valueFromActiveDataService,
      valueFromReportData,
      this.showTrend
    );

    return this.showTrend;
  }

  configAllowed() {
    if (this.saleMode) {
      if (this._activeDataService.cachedConfig) {
        return this._activeDataService.cachedConfig.show_valuation;
      }
      return this._activeDataService.reportData?.config?.show_valuation;
    } else {
      if (this._activeDataService.cachedConfig) {
        return this._activeDataService.cachedConfig.show_rent;
      }
      return this._activeDataService.reportData?.config?.show_rent;
    }
  }

  closeEditors() {
    this.editingValue = false;
    this.editingValueMin = false;
    this.editingValueMax = false;
  }

  saveNewValue() {
    this.closeEditors();
    const theVal = this.valueEditorForm.get('valueEditor')?.value;

    if (this.isValid(theVal)) {
      let req: ReportOverwriteRequest;

      if (this.saleMode) {
        req = { rent: {}, valuation: { value: theVal } };
      } else {
        req = { valuation: {}, rent: { value: theVal } };
      }

      this._reportService
        .overwriteValuation(req, this.reportData.report_id)
        .subscribe(
          (rdata: ReportOverwriteResponse) => {
            this.valuationData.value = theVal;
            this.reportData.value_overwritten = true;
            this.reloadActiveDataService();
          },
          (error: any) => {
            this.notificationService.notifyError(
              'Validierungsfehler: Bitte geben Sie eine Zahl ein.'
            );
          }
        );
    } else {
      this.notificationService.notifyError(
        'Validierungsfehler: Bitte geben Sie eine Zahl ein.'
      );
    }
  }

  saveNewValueRange(isMin: boolean) {
    this.closeEditors();

    const rangeVal = isMin
      ? this.valueRangeEditorForm.get('minValueEditor')?.value
      : this.valueRangeEditorForm.get('maxValueEditor')?.value;

    if (this.isValid(rangeVal)) {
      let req: ReportOverwriteRequest;

      if (this.saleMode) {
        req = isMin
          ? { rent: {}, valuation: { range: { min: rangeVal } } }
          : { rent: {}, valuation: { range: { max: rangeVal } } };
      } else {
        req = isMin
          ? { valuation: {}, rent: { range: { min: rangeVal } } }
          : { valuation: {}, rent: { range: { max: rangeVal } } };
      }

      this._reportService
        .overwriteValuation(req, this.reportData.report_id)
        .subscribe(
          (rdata: ReportOverwriteResponse) => {
            if (isMin) {
              this.valuationData.meta.range.min = rangeVal;
            } else {
              this.valuationData.meta.range.max = rangeVal;
            }
            this.reportData.value_overwritten = true;

            this.reloadActiveDataService();
          },
          (error: any) => {
            this.notificationService.notifyError(
              'Validierungsfehler: Bitte geben Sie eine Zahl ein.'
            );
          }
        );
    } else {
      this.notificationService.notifyError(
        'Validierungsfehler: Bitte geben Sie eine Zahl ein.'
      );
    }
  }

  isValid(x: any) {
    if (x <= 0) {
      return false;
    } else {
      return true;
    }
  }

  reloadActiveDataService() {
    this._activeDataService.valuationEmitter.emit(this.valuationData);
    this._activeDataService.getPoiData(true);
    this._activeDataService.getRentData(true);
    this._activeDataService.getRentEquipment(true);
    this._activeDataService.getRentIpiData(true);
    // this._activeDataService.getRentRegionData(true);
    this._activeDataService.getReportData(true);
    this._activeDataService.getSimData(true);
    this._activeDataService.getValuationData(true);
    this._activeDataService.getValuationEquipment(true);
    this._activeDataService.getValuationIpiData(true);
    // this._activeDataService.getValuationRegionData(true);
  }

  private getValueOrDefault(expr1: any, expr2: any, defaultExpr: any): any {
    if (expr1 !== undefined && expr1 !== null) {
      return expr1;
    } else if (expr2 !== undefined && expr2 !== null) {
      return expr2;
    } else {
      return defaultExpr;
    }
  }
}
