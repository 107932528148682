import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { UserData } from '../models/user-data.model';

@Injectable()
export class UserDataService {
  constructor(private _httpClient: HttpClient) {}
  private url: string = environment.apiUrl + '/user';

  get(): Observable<UserData> {
    return this._httpClient.get<UserData>(this.url);
  }

  getReportUser(reportId: string): Observable<UserData> {
    this.url = environment.apiUrl + '/report/user_data';
    return this._httpClient.get<UserData>(this.url + '?report_id=' + reportId);
  }
}
