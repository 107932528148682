import { Valuation } from '../models/valuation.model';
import { LineChartData } from '../models/line-chart-data.model';
import { IpiGetResponse } from '../models/ipi-get-response.model';
import { SimIndexResponse } from '../models/sim-index-response.model';

export class ChartGenerator {
  generateMarketData(
    dataSet: IpiGetResponse,
    valuation: Valuation,
    mobile = false
  ): LineChartData {
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const currentQuarter = Math.floor((currentMonth + 2) / 3);

    let thisTitle = '';
    dataSet.values.forEach(value => {
      if (
        value.region !== undefined &&
        value.region !== null &&
        value.region !== ''
      ) {
        thisTitle = value.region;
      }
    });
    let showQ = true;
    let foundAt = -1;
    for (let i = 0; i < dataSet.values.length; i++) {
      if (
        dataSet.values[i].year === currentYear &&
        dataSet.values[i].quarter === undefined
      ) {
        foundAt = i;
        i = dataSet.values.length;
      }
      if (
        dataSet.values[i].year === currentYear &&
        dataSet.values[i].quarter !== undefined &&
        dataSet.values[i].quarter === currentQuarter
      ) {
        foundAt = i;
        i = dataSet.values.length;
      }
    }

    if (foundAt === -1) {
      for (let i = 0; i < dataSet.values.length; i++) {
        if (dataSet.values[i].year === currentYear) {
          foundAt = i;
          i = dataSet.values.length;
        }
      }
      if (foundAt === -1) {
        return;
      }
      showQ = false;
    }

    const currentIndex = dataSet.values[foundAt].index;

    const lineChart: LineChartData = {
      minY: 5,
      maxY: 25,
      prefixY: '',
      postfixY: ' €',
      data: [],
      labels: [],
      mainColor: '#9b8af2',
      secondaryColor: '#fff',
      title: thisTitle,
    };

    let pos = 0;
    dataSet.values.forEach(element => {
      if (element.quarter === currentQuarter || !mobile) {
        const newValue = (valuation.value * element.index) / currentIndex;
        lineChart.data[pos] = Math.round(newValue);
        if (showQ) {
          lineChart.labels[pos] =
            element.year.toString() + ' Q' + element.quarter.toString();
        } else {
          lineChart.labels[pos] = element.year.toString();
        }
        pos++;
      }
    });

    return lineChart;
  }

  generateSimData(
    dataSet: SimIndexResponse,
    valuation: Valuation,
    mobile = false
  ): LineChartData {
    const yearsToShow = [];
    for (let i = 0; i < dataSet.values.length; i++) {
      yearsToShow.push(dataSet.values[dataSet.values.length - (i + 1)].year);
      i = i + 2;
    }

    let thisTitle = '';
    dataSet.values.forEach(value => {
      if (
        value.region !== undefined &&
        value.region !== null &&
        value.region !== ''
      ) {
        thisTitle = value.region;
      }
    });

    const currentIndex = dataSet.values[dataSet.values.length - 1].index;
    const firstValue =
      (valuation.value * (dataSet.values[0].index / 100)) /
      (currentIndex / 100);
    const lineChart: LineChartData = {
      minY: Math.round(firstValue),
      maxY: Math.round(firstValue),
      prefixY: '',
      postfixY: ' €',
      data: [],
      labels: [],
      mainColor: '#9b8af2',
      secondaryColor: '#fff',
      title: thisTitle,
    };

    let pos = 0;
    dataSet.values.forEach(element => {
      if (this.arrayContains(yearsToShow, element.year) || !mobile) {
        const newValue = (valuation.value * element.index) / currentIndex;
        lineChart.data[pos] = Math.round(newValue);
        lineChart.labels[pos] = element.year.toString();
        pos++;
      }
    });

    return lineChart;
  }

  arrayContains(array: string[], value) {
    const filteredArray = array.filter(element => {
      return element === value;
    });

    return filteredArray.length > 0;
  }
}
