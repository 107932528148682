<div
  [ngClass]="{
    'chart-container': !printView,
    'chart-container-print': printView
  }">
  <canvas
    baseChart
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [options]="lineChartOptions"
    [type]="lineChartType"
    style="height: 100%; width: 100%"></canvas>
</div>
