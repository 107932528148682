import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Report } from '../core/models/reports.model';
import { NotificationService } from '../core/services/notification.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  public reportData: Report;
  public longReport: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: Report,
    private _notificationService: NotificationService,
    private _dialog: MatDialog
  ) {
    this.reportData = _data;
  }

  ngOnInit() {
    this.longReport = this.getLinkLength();
  }

  getTypeText() {
    if (this.reportData.category === 'EFH') {
      return 'Einfamilienhaus';
    } else if (this.reportData.category === 'ETW') {
      return 'Eigentumswohnung';
    } else if (this.reportData.category === 'MFH') {
      return 'Mehrfamilienhaus';
    } else if (this.reportData.category === 'GRD') {
      return 'Grundstück';
    }
  }

  getAddressText() {
    if (
      this.reportData === null ||
      this.reportData.address === undefined ||
      this.reportData.address === null
    ) {
      return '';
    }
    let returnString = '';
    let houseNum = '';
    if (this.reportData.address.street !== '') {
      returnString = returnString + this.reportData.address.street;
    }
    if (this.reportData.address.house_number !== '') {
      if (returnString !== '') {
        returnString = returnString + '';
      }
      if (this.reportData.address.house_number !== ' ') {
        houseNum = ' ' + this.reportData.address.house_number;
      }
      returnString = returnString + houseNum;
    }
    if (this.reportData.address.town !== '') {
      if (returnString !== '') {
        returnString = returnString + ', ';
      }
      returnString = returnString + this.reportData.address.town;
    }
    return returnString;
  }

  getLinkLength() {
    if (this.getLink().length > 66) {
      return true;
    } else {
      return false;
    }
  }

  getLink() {
    return this.reportData.urls.view;
  }

  copyLink() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.getLink();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._notificationService.notifySuccess('Link wurde kopiert');
    this._dialog.closeAll();
  }
}
