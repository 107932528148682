<mat-toolbar-row
  *ngIf="!tablet"
  class="mainToolBar HeaderRow hide-for-tablet unselectable">
  <div class="opaque-header hide-for-mobile hide-for-tablet"></div>
  <div class="hide-for-mobile hide-for-tablet">
    <img
      alt="logo"
      class="logo"
      src="{{ _authService.authenticatedUser.images.logo.desktop_url }}"
      (click)="logoClick()"
      *ngIf="
        _authService.authenticatedUser !== null &&
        _authService.authenticatedUser.images !== null &&
        _authService.authenticatedUser.images.logo !== null
      " />
  </div>
  <div
    class="logo hide-for-desktop mobile-logo"
    *ngIf="_headerService.showLogo"
    (click)="logoClick(true)"
    [@showHideLogo]="">
    <img
      alt="logo"
      name="main-logo"
      *ngIf="
        _authService.authenticatedUser !== null &&
        _authService.authenticatedUser.images !== null &&
        _authService.authenticatedUser.images.logo !== null
      "
      class="logo"
      src="{{ _authService.authenticatedUser.images.logo.mobile_url }}" />
  </div>
  <div
    class="user-icon icon"
    *ngIf="!_authService.readOnlyMode">
    <img
      alt="logo"
      name="main-logo"
      class="icon user-image"
      src="{{ _authService.authenticatedUser.images.photo.desktop_url }}" />
  </div>

  <div
    class="user-menu hide-for-mobile hide-for-tablet"
    *ngIf="!_authService.readOnlyMode"
    (click)="showUserDropdown = !showUserDropdown">
    <div class="inner-user-menu">
      <div></div>
      <span class="user-dropdown spreng-icon-chevron-left"></span>
      {{ getName() }}
    </div>
  </div>

  <div
    [@openCloseMonthlyLimit]=""
    class="monthly-limit hide-for-mobile hide-for-tablet"
    *ngIf="
      _authService.authenticatedUser &&
      _authService.authenticatedUser.product &&
      showUserDropdown
    ">
    <span class="monthly-limit-title">Profile</span>
    <div
      class="monthly-report"
      [ngClass]="{
        'no-limit': _authService.authenticatedUser.product.monthly_limit === 0
      }">
      <span class="spreng-icon-info"></span>
      <span
        *ngIf="
          _authService.authenticatedUser.product.monthly_limit > 0;
          then hasLimitBlock;
          else hasNoLimitBlock
        "></span>
      <ng-template #hasLimitBlock>
        <b>
          {{
            _authService.authenticatedUser.product.monthly_limit -
              _authService.authenticatedUser.product.monthly_count
          }}
        </b>
        Maps Reporte verfügbar bis
        <b>{{ formatDate(_authService.authenticatedUser.product.end_date) }}</b>
      </ng-template>
      <ng-template #hasNoLimitBlock>
        Sie besitzen uneingeschränkte Maps-Abrufe bis
        <b>{{ formatDate(_authService.authenticatedUser.product.end_date) }}</b>
      </ng-template>
    </div>
  </div>

  <div
    [@openCloseSearchBar]=""
    *ngIf="showSearch"
    class="search-bar"
    [ngClass]="{
      'searchbar-noconfig':
        _activeDataService.reportData === null ||
        _activeDataService.reportData === undefined
    }">
    <app-geocoder></app-geocoder>
  </div>

  <div
    *ngIf="
      !_headerService.showSearchBar &&
      !_authService.readOnlyMode &&
      !_router.url.startsWith('/edit')
    "
    class="icon search-back"
    (click)="toggleSearch()">
    <img
      alt="search"
      class="search-icon"
      src="../../../assets/icons/magnify-white.svg" />
  </div>
  <div
    class="icon share-back hide-for-mobile"
    *ngIf="!_authService.readOnlyMode"
    (click)="shareLink()">
    <img
      alt="share"
      class="icon share-icon"
      src="../../../assets/icons/share.svg"
      onmouseover="this.src='../../../assets/icons/share-hover.svg'"
      onmouseout="this.src='../../../assets/icons/share.svg'"
      matTooltip="Teilen" />
  </div>

  <div
    class="icon print-back share hide-for-mobile"
    (click)="printClick()"
    *ngIf="printIconEnabled"
    [ngClass]="{ 'print-noconfig': _authService.readOnlyMode }">
    <img
      alt="print"
      class="icon print-icon"
      src="../../../assets/icons/print.svg"
      onmouseover="this.src='../../../assets/icons/printer-hover.svg'"
      onmouseout="this.src='../../../assets/icons/print.svg'"
      matTooltip="Drucken" />
  </div>
  <div
    class="disabled-icon disabled-back hide-for-mobile"
    *ngIf="!printIconEnabled"
    [ngClass]="{ 'print-noconfig': _authService.readOnlyMode }">
    <img
      alt="print"
      class="disabled-icon print-icon"
      src="../../../assets/icons/print-disabled.svg"
      matTooltip="Drucken" />
  </div>

  <div
    class="icon config-back share hide-for-mobile"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="configMenu"
    *ngIf="!_authService.readOnlyMode">
    <span class="spreng-icon-settings config-icon"></span>
    <mat-menu
      #configMenu="matMenu"
      xPosition="before">
      <div
        class="config-menu-inner"
        (click)="$event.stopPropagation()">
        <h2
          class="config-menu-title"
          (click)="$event.stopPropagation()">
          Anzeigeoptionen
        </h2>
        <mat-checkbox
          [checked]="this.showExactPrice"
          class="config-menu-option"
          (change)="this.showExactPrice = !this.showExactPrice"
          (click)="$event.stopPropagation()">
          Anzeige exakter Marktpreisindikation
        </mat-checkbox>
        <mat-checkbox
          [checked]="this.showTrend"
          (click)="$event.stopPropagation()"
          (change)="this.showTrend = !this.showTrend"
          class="config-menu-option">
          Anzeige der Preisentwicklungsdaten
        </mat-checkbox>
        <mat-checkbox
          [checked]="this.showComparable"
          class="config-menu-option"
          (click)="$event.stopPropagation()"
          (change)="this.showComparable = !this.showComparable">
          Anzeige der Vergleichsobjekte
        </mat-checkbox>
        <mat-checkbox
          [checked]="this.showExpanded"
          class="config-menu-option"
          (click)="$event.stopPropagation()"
          (change)="toggleExpandedConfig()">
          Anzeige erweiterte Ansicht
        </mat-checkbox>
        <mat-checkbox
          [checked]="this.showEquip && this.showExpanded"
          [disabled]="!this.showExpanded"
          class="config-menu-option"
          (click)="$event.stopPropagation()"
          (change)="toggleEquipmentConfig()">
          Anzeige Ausstattungslevel
        </mat-checkbox>
        <mat-checkbox
          [checked]="this.showExpert && this.showExpanded"
          [disabled]="!this.showExpanded"
          class="config-menu-option"
          (click)="$event.stopPropagation()"
          (change)="toggleExpertConfig()">
          Anzeige Expertenansicht
        </mat-checkbox>
        <mat-checkbox
          [checked]="this.showSocio && this.showExpert"
          [disabled]="!this.showExpert"
          class="config-menu-option"
          (click)="$event.stopPropagation()"
          (change)="this.showSocio = !this.showSocio">
          Anzeige Soziodemographie
        </mat-checkbox>
        <mat-checkbox
          [checked]="this.showMarket"
          [disabled]="!this.showRental"
          class="config-menu-option"
          (click)="$event.stopPropagation()"
          (change)="this.showMarket = !this.showMarket">
          Anzeige Marktpreis
        </mat-checkbox>
        <mat-checkbox
          [checked]="this.showRental"
          [disabled]="!this.showMarket"
          class="config-menu-option"
          (click)="$event.stopPropagation()"
          (change)="this.showRental = !this.showRental">
          Anzeige Mietwert
        </mat-checkbox>
        <button
          mat-button
          class="save-config-button"
          (click)="applyChanges()"
          [disabled]="
            updatingConfig ||
            !(_activeDataService.reportEmitter | async)?.config
          ">
          Report Updaten
        </button>
      </div>
    </mat-menu>
  </div>
</mat-toolbar-row>
<!--
      <mat-toolbar-row class="hide-for-desktop">


      <div class="user-icon" >
          <img class="user-image" [src]="userLogo()">
      </div>
  </mat-toolbar-row> -->

<mat-toolbar-row
  *ngIf="tablet"
  class="tabletToolBar HeaderRow unselectable">
  <div
    class="logo mobile-logo"
    *ngIf="
      _headerService.showLogo &&
      _authService.authenticatedUser !== null &&
      _authService.authenticatedUser.images !== null &&
      _authService.authenticatedUser.images.logo !== null
    "
    (click)="logoClick()">
    <img
      alt="logo"
      class="logo"
      src="{{ _authService.authenticatedUser.images.logo.desktop_url }}" />
  </div>

  <div
    class="user-icon icon"
    *ngIf="!_authService.readOnlyMode"
    (click)="showUserDropdown = !showUserDropdown">
    <img
      alt="user"
      class="icon user-image"
      src="{{ _authService.authenticatedUser.images.photo.desktop_url }}" />
  </div>

  <div
    class="user-menu hide-for-mobile hide-for-tablet"
    *ngIf="!_authService.readOnlyMode"
    (click)="showUserDropdown = !showUserDropdown">
    <div class="inner-user-menu">
      <div></div>
      <span class="user-dropdown spreng-icon-chevron-left"></span>
      {{ getName() }}
    </div>
  </div>
  <div
    class="monthly-limit hide-for-mobile"
    *ngIf="
      _authService.authenticatedUser !== null &&
      _authService.authenticatedUser !== undefined &&
      showUserDropdown
    ">
    <span class="monthly-limit-title">{{ getName() }}</span>
    <div
      class="monthly-report"
      *ngIf="
        _authService.authenticatedUser.product !== null &&
        _authService.authenticatedUser.product.monthly_limit > 0
      ">
      <span class="spreng-icon-info"></span>
      <b>
        {{
          _authService.authenticatedUser.product.monthly_limit -
            _authService.authenticatedUser.product.monthly_count
        }}
      </b>
      Maps Reporte verfügbar bis
      <b>{{ formatDate(_authService.authenticatedUser.product.end_date) }}</b>
    </div>
  </div>

  <div
    *ngIf="showSearch"
    class="search-bar">
    <app-geocoder [suggestionsTop]="tablet"></app-geocoder>
  </div>
  <div
    *ngIf="
      !_headerService.showSearchBar &&
      !_authService.readOnlyMode &&
      !_router.url.startsWith('/edit')
    "
    class="icon search-back"
    (click)="toggleSearch()">
    <img
      alt="search"
      class="search-icon"
      src="../../../assets/icons/magnify-white.svg" />
  </div>
  <div
    class="icon share-back hide-for-mobile"
    *ngIf="!_authService.readOnlyMode"
    (click)="shareLink()">
    <img
      alt="share"
      class="icon share-icon"
      src="../../../assets/icons/share.svg"
      onmouseover="this.src='../../../assets/icons/share-hover.svg'"
      onmouseout="this.src='../../../assets/icons/share.svg'"
      matTooltip="Teilen" />
  </div>

  <div
    class="icon print-back share hide-for-mobile"
    *ngIf="
      _activeDataService.reportData?.config?.show_expanded_view ||
      !_activeDataService.reportData
    "
    (click)="printClick()"
    [ngClass]="{ 'print-noconfig': _authService.readOnlyMode }">
    <img
      alt="print"
      class="icon print-icon"
      src="../../../assets/icons/print.svg"
      onmouseover="this.src='../../../assets/icons/printer-hover.svg'"
      onmouseout="this.src='../../../assets/icons/print.svg'"
      matTooltip="Drucken" />
  </div>
  <div
    class="disabled-icon disabled-back hide-for-mobile"
    *ngIf="
      _activeDataService.reportData &&
      !_activeDataService.reportData?.config?.show_expanded_view
    "
    [ngClass]="{ 'print-noconfig': _authService.readOnlyMode }">
    <img
      alt="print"
      class="disabled-icon print-icon"
      src="../../../assets/icons/print-disabled.svg"
      matTooltip="Drucken" />
  </div>

  <div
    class="icon config-back share hide-for-mobile"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="configMenu"
    *ngIf="!_authService.readOnlyMode">
    <span class="spreng-icon-settings config-icon"></span>
    <mat-menu
      #configMenu="matMenu"
      xPosition="before">
      <div
        class="config-menu-inner"
        (click)="$event.stopPropagation()">
        <h2
          class="config-menu-title"
          (click)="$event.stopPropagation()">
          Anzeigeoptionen
        </h2>
        <mat-checkbox
          [checked]="this.showExactPrice"
          class="config-menu-option"
          (change)="this.showExactPrice = !this.showExactPrice"
          (click)="$event.stopPropagation()">
          Anzeige exakter Marktpreisindikation
        </mat-checkbox>
        <mat-checkbox
          [checked]="this.showTrend"
          (click)="$event.stopPropagation()"
          (change)="this.showTrend = !this.showTrend"
          class="config-menu-option">
          Anzeige der Preisentwicklungsdaten
        </mat-checkbox>
        <mat-checkbox
          [checked]="this.showComparable"
          class="config-menu-option"
          (click)="$event.stopPropagation()"
          (change)="this.showComparable = !this.showComparable">
          Anzeige der Vergleichsobjekte
        </mat-checkbox>
        <mat-checkbox
          [checked]="this.showExpanded"
          class="config-menu-option"
          (click)="$event.stopPropagation()"
          (change)="toggleExpandedConfig()">
          Anzeige erweiterte Ansicht
        </mat-checkbox>
        <mat-checkbox
          [checked]="this.showEquip && this.showExpanded"
          [disabled]="!this.showExpanded"
          class="config-menu-option"
          (click)="$event.stopPropagation()"
          (change)="toggleEquipmentConfig()">
          Anzeige Ausstattungslevel
        </mat-checkbox>
        <mat-checkbox
          [checked]="this.showExpert && this.showExpanded"
          [disabled]="!this.showExpanded"
          class="config-menu-option"
          (click)="$event.stopPropagation()"
          (change)="toggleExpertConfig()">
          Anzeige Expertenansicht
        </mat-checkbox>
        <mat-checkbox
          [checked]="this.showSocio"
          [disabled]="!this.showExpert"
          class="config-menu-option"
          (click)="$event.stopPropagation()"
          (change)="this.showSocio = !this.showSocio">
          Anzeige Soziodemographie
        </mat-checkbox>
        <mat-checkbox
          [checked]="this.showMarket"
          [disabled]="!this.showRental"
          class="config-menu-option"
          (click)="$event.stopPropagation()"
          (change)="this.showMarket = !this.showMarket">
          Anzeige Marktpreis
        </mat-checkbox>
        <mat-checkbox
          [checked]="this.showRental"
          [disabled]="!this.showMarket"
          class="config-menu-option"
          (click)="$event.stopPropagation()"
          (change)="this.showRental = !this.showRental">
          Anzeige Mietwert
        </mat-checkbox>
        <button
          mat-button
          class="save-config-button"
          (click)="applyChanges()"
          [disabled]="updatingConfig || !_activeDataService.reportData?.config">
          Report Updaten
        </button>
      </div>
    </mat-menu>
  </div>
</mat-toolbar-row>
<!--
      <mat-toolbar-row class="hide-for-desktop">


      <div class="user-icon" >
          <img class="user-image" [src]="userLogo()">
      </div>
  </mat-toolbar-row> -->
