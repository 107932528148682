import { AuthenticationResult } from '../models/authentication-result.model';
import { Observable, Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticateService } from './authenticate.service';
import { Router } from '@angular/router';
import { UserData } from '../models/user-data.model';
import { UserDataService } from './user-data.service';
import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from './notification.service';

@Injectable()
export class AuthenticationService {
  public authenticatedUser: UserData = null;
  public validating = true;
  public readOnlyMode = true;
  public loginUrl = '';
  public privacyUrl = 'https://www.sprengnetter.de/datenschutz/';
  public imprintUrl = 'https://www.sprengnetter.de/impressum/';

  constructor(
    private _authenticator: AuthenticateService,
    private _router: Router,
    private _userDataService: UserDataService,
    private _errorService: ErrorService,
    private notificationService: NotificationService
  ) {}

  hasCredentials(skipTimeCheck = false) {
    if (
      !window.localStorage.getItem('tt') ||
      !window.localStorage.getItem('token') ||
      !window.localStorage.getItem('system_id')
    ) {
      return false;
    }

    if (
      parseInt(window.localStorage.getItem('tt'), 10) < new Date().getTime() &&
      !skipTimeCheck
    ) {
      this.removeCredentials();
      return false;
    }

    if (!this.authenticatedUser && !this.validating) {
      this.validateCredentials(
        window.localStorage.getItem('token'),
        window.localStorage.getItem('system_id')
      ).subscribe();
    }

    return true;
  }

  removeCredentials() {
    if (environment.authEnabled || environment.production) {
      window.localStorage.removeItem('tt');
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('system_id');
      window.localStorage.removeItem('session_token');
    }
    this.clearPersistedUserData();
  }

  validateCredentials(token: string, id: string): Observable<boolean> {
    this.validating = true;

    const subject = new Subject<boolean>();

    this._authenticator.get(token, id).subscribe({
      next: (data: AuthenticationResult) => {
        window.localStorage.setItem('session_token', data.session_token);
        window.localStorage.setItem(
          'tt',
          (new Date().getTime() + environment.sessionTimeOut).toString()
        );
        window.localStorage.setItem('token', token);

        this._userDataService.get().subscribe({
          next: (userData: UserData) => {
            this.authenticatedUser = userData;
            this.persistUserData(userData);
            this.validating = false;

            userData.urls.data_privacy =
              userData.urls.data_privacy ||
              'https://www.sprengnetter.de/datenschutz/';

            userData.urls.imprint =
              userData.urls.imprint || 'https://www.sprengnetter.de/impressum/';

            this.privacyUrl = userData.urls.data_privacy;
            this.imprintUrl = userData.urls.imprint;
            window.localStorage.setItem('system_id', id);
            this.readOnlyMode = false;

            subject.next(true);
          },
          error: this.errorHandle,
        });
      },
      error: (err: HttpErrorResponse) => {
        if (!environment.authEnabled && !environment.production) {
          if (err.status === 401) {
            this.authenticatedUser = this.retrievePersistedUserData();
            if (!this.authenticatedUser) {
              this.redirectToAccessDenied();
            } else {
              this.validating = false;

              this.privacyUrl =
                this.authenticatedUser.urls.data_privacy ||
                'https://www.sprengnetter.de/datenschutz/';
              this.imprintUrl =
                this.authenticatedUser.urls.imprint ||
                'https://www.sprengnetter.de/impressum/';
              window.localStorage.setItem('system_id', id);
              this.readOnlyMode = false;

              subject.next(true);
            }
          }
        } else {
          this.errorHandle(err);
        }
      },
    });

    return subject.asObservable();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getReportUser(reportId: string) {
    this._userDataService.getReportUser(reportId).subscribe({
      next: (data: UserData) => {
        this.imprintUrl = data.urls.imprint;
        this.privacyUrl = data.urls.data_privacy;
        this.authenticatedUser = data;
      },
      error: (error: any) => {
        this.notificationService.notifyApiError(error);
        setInterval(() => {
          this.errorHandle(error);
        }, 5000);
      },
    });
  }

  getReportUser$(reportId: string) {
    return this._userDataService.getReportUser(reportId).pipe(
      tap((data: UserData) => {
        this.imprintUrl = data.urls.imprint;
        this.privacyUrl = data.urls.data_privacy;
        this.authenticatedUser = data;
      })
    );
  }

  public errorHandle = (error: any): void => {
    this.removeCredentials();
    this.validating = false;
    this._errorService.clearErrorStack();

    error.error.errors.forEach(element => {
      if (element.login_url) {
        this.loginUrl = element.login_url;
      } else {
        Object.keys(element).forEach(e => {
          this._errorService.addToErrorStack({
            error: element[e],
            timestamp: Date.now(),
          });
        });
      }
    });

    this.redirectToAccessDenied();
  };

  private redirectToAccessDenied(): void {
    this._router.navigateByUrl('/accessDenied');
  }

  private persistUserData(userData: UserData): void {
    if (!environment.authEnabled && !environment.production) {
      window.localStorage.setItem('userData', JSON.stringify(userData));
    }
  }

  private retrievePersistedUserData(): UserData {
    const persistedUserData = window.localStorage.getItem('userData');

    return !environment.authEnabled &&
      !environment.production &&
      persistedUserData
      ? JSON.parse(persistedUserData)
      : null;
  }

  private clearPersistedUserData(): void {
    if (!environment.authEnabled && !environment.production) {
      window.localStorage.removeItem('userData');
    }
  }
}
