import { Component, Input } from '@angular/core';
import { TrendCalculator } from 'src/app/core/functions/trendCalculator.function';
import { Report, ReportConfiguration } from 'src/app/core/models/reports.model';
import { IpiGetResponse } from 'src/app/core/models/ipi-get-response.model';
import { Valuation } from 'src/app/core/models/valuation.model';
import { ActiveDataService } from 'src/app/core/services/active-data.service';

@Component({
  selector: 'app-trend',
  templateUrl: './trend.component.html',
  styleUrls: ['./trend.component.scss'],
})
export class TrendComponent {
  @Input() saleMode = false;
  @Input() extended = false;
  @Input() expert = false;
  @Input() printMode = false;

  @Input() reportData: Report;
  @Input() ipiData: IpiGetResponse;
  @Input() valuationData: Valuation;
  @Input() appError = false;

  private trendHelper: TrendCalculator;

  public showTrend = true;

  constructor(private _activeDataService: ActiveDataService) {}

  getTypeClass() {
    if (this.saleMode) {
      return 'value-text-bold-small sale-text valuation-row';
    }
    return 'value-text-bold-small rent-text valuation-row';
  }

  dataLoaded() {
    if (this.extended) {
      return (
        this.reportData !== undefined &&
        this.reportData !== null &&
        this.valuationData !== undefined &&
        this.valuationData !== null &&
        this.ipiData !== undefined &&
        this.ipiData !== null
      );
    }
    return (
      this.reportData !== undefined &&
      this.reportData !== null &&
      this.valuationData !== undefined &&
      this.valuationData !== null &&
      this.ipiData !== undefined &&
      this.ipiData !== null
    );
  }

  getTwoYearText() {
    this.trendHelper = new TrendCalculator(this.ipiData);
    return this.trendHelper.getTwoYearText();
  }

  getTwoYearTrend() {
    this.trendHelper = new TrendCalculator(this.ipiData);
    return Math.round(this.trendHelper.getTwoYearTrend() * 10) / 10;
  }

  getTwoYearTrendValue() {
    this.trendHelper = new TrendCalculator(this.ipiData);
    return this.trendMapper(this.trendHelper.getTwoYearTrendValue());
  }

  getThisYearText() {
    this.trendHelper = new TrendCalculator(this.ipiData);
    return this.trendHelper.getThisYearText();
  }

  getThisYearTrend() {
    this.trendHelper = new TrendCalculator(this.ipiData);
    return Math.round(this.trendHelper.getThisYearTrend() * 10) / 10;
  }

  getThisYearTrendValue() {
    this.trendHelper = new TrendCalculator(this.ipiData);
    return this.trendMapper(this.trendHelper.getThisYearTrendValue());
  }

  getOneYearText() {
    this.trendHelper = new TrendCalculator(this.ipiData);
    return this.trendHelper.getOneYearText();
  }

  getOneYearTrend() {
    this.trendHelper = new TrendCalculator(this.ipiData);
    return Math.round(this.trendHelper.getOneYearTrend() * 10) / 10;
  }

  getOneYearTrendValue() {
    this.trendHelper = new TrendCalculator(this.ipiData);
    return this.trendMapper(this.trendHelper.getOneYearTrendValue());
  }

  trendMapper(trend: string) {
    switch (trend) {
      case '1': {
        return 'spreng-icon-trending-1 trend-icon-1';
      }
      case '2': {
        return 'spreng-icon-trending-2 trend-icon-2';
      }
      case '3': {
        return 'spreng-icon-trending-3 trend-icon-3';
      }
      case '4': {
        return 'spreng-icon-trending-4 trend-icon-4';
      }
      case '5': {
        return 'spreng-icon-trending-5 trend-icon-5';
      }
      default: {
        return '';
      }
    }
  }

  trendAlllowedByConfig() {
    if (this._activeDataService.reportData === undefined) {
      return this.showTrend;
    }
    if (this._activeDataService.reportData.config === undefined) {
      if (
        this._activeDataService.reportData.config === null ||
        this._activeDataService.reportData.config === undefined
      ) {
        this._activeDataService.reportData.config = new ReportConfiguration();
      }
    }
    this.showTrend = this._activeDataService.reportData.config.show_trend;
    return this.showTrend;
  }
}
