import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { HeaderService } from 'src/app/core/services/header.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { ActiveDataService } from 'src/app/core/services/active-data.service';
import { PrintService } from 'src/app/core/services/print.service';
import { ShareComponent } from 'src/app/share/share.component';
import { HelperFunctions } from 'src/app/core/functions/helper.functions';
import { Router } from '@angular/router';
import { ReportService } from 'src/app/core/services/report.service';
import {
  ReportConfigurationUpdateRequest,
  Report,
} from 'src/app/core/models/reports.model';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Subscription } from 'rxjs';
import {
  animate,
  style,
  transition,
  trigger,
  query,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('openCloseMonthlyLimit', [
      transition('void => *', [
        style({ height: 0 }),
        animate('0.25s', style({ height: '*' })),
      ]),
      transition('* => void', [animate('0.25s', style({ height: 0 }))]),
    ]),
    trigger('openCloseSearchBar', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 })),
      ]),
      transition('* => void', [animate('0.5s', style({ opacity: 0 }))]),
    ]),
    trigger('showHideLogo', [
      transition(':leave', [
        query(':self', [animate('0.25s', style({ opacity: 0 }))]),
      ]),
      transition(':enter', [
        query(':self', [
          style({ opacity: 0 }),
          animate('0.25s 0.15s', style({ opacity: 1 })),
        ]),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('menuTrigger') menuTrigger;

  public showSearch: boolean;
  public showExactPrice = true;
  public showTrend = true;
  public showComparable = true;
  public showExpanded = true;
  public showEquip = true;
  public showExpert = true;
  public showMarket = true;
  public showRental = true;
  public showSocio = true;
  public updatingConfig = false;
  public showUserDropdown = false;
  public windowHeight = '';
  public resolutionTest = '';
  public mobile = false;
  public tablet = false;
  public printIconEnabled = true;

  private helper: HelperFunctions = new HelperFunctions();
  private reportSub: Subscription = null;

  setWindowScrollHeight() {
    this.windowHeight = window.innerHeight.toString() + 'px';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setWindowScrollHeight();
    this.resolutionTest = window.innerWidth + 'x' + window.innerHeight;
    if (window.innerWidth <= 1080) {
      this.mobile = true;
    }
    if (window.innerWidth > 1080 && window.innerWidth <= 1260) {
      this.tablet = true;
    }
  }

  constructor(
    public _router: Router,
    public _headerService: HeaderService,
    public _authService: AuthenticationService,
    private _reportService: ReportService,
    public _activeDataService: ActiveDataService,
    public _printService: PrintService,
    private _dialog: MatDialog,
    private _notificationService: NotificationService
  ) {}

  ngOnInit() {
    if (window.innerWidth <= 1080) {
      this.mobile = true;
    }
    if (window.innerWidth > 1080 && window.innerWidth <= 1260) {
      this.tablet = true;
    }
    if (
      this._activeDataService.reportData &&
      this._activeDataService.reportData.config
    ) {
      this.showTrend = this._activeDataService.reportData.config.show_trend;
      this.showComparable =
        this._activeDataService.reportData.config.show_compare_prices;
      this.showExactPrice =
        this._activeDataService.reportData.config.show_exact_price;
      this.showExpanded =
        this._activeDataService.reportData.config.show_expanded_view;
      this.showEquip =
        this._activeDataService.reportData.config.show_equipment_overview;
      this.showExpert =
        this._activeDataService.reportData.config.show_expert_view;
      this.showMarket =
        this._activeDataService.reportData.config.show_valuation;
      this.showRental = this._activeDataService.reportData.config.show_rent;
      this.showSocio =
        this._activeDataService.reportData.config.show_sociodemographic;

      if (!this.showRental && !this.showMarket) {
        this.showMarket = true;
        this._activeDataService.reportData.config.show_valuation =
          this.showMarket;
      }
    } else {
      this.reportSub = this._activeDataService.reportEmitter.subscribe(
        (data: Report) => {
          this.showTrend = data.config.show_trend;
          this.showComparable = data.config.show_compare_prices;
          this.showExactPrice = data.config.show_exact_price;
          this.showExpanded = data.config.show_expanded_view;
          this.showEquip = data.config.show_equipment_overview;
          this.showExpert = data.config.show_expert_view;
          this.showMarket = data.config.show_valuation;
          this.showRental = data.config.show_rent;
          this.showSocio = data.config.show_sociodemographic;
          this.printIconEnabled = data.config.show_expanded_view;

          if (!this.showRental && !this.showMarket) {
            this.showMarket = true;
            this._activeDataService.reportData.config.show_valuation =
              this.showMarket;
          }
        }
      );
    }
    if (!this.showExpanded) {
      this.showExpert = false;
      this.showEquip = false;
    }
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  toggleExpandedConfig() {
    this.showExpanded = !this.showExpanded;
    if (!this.showExpanded) {
      this.showExpert = false;
      this.showSocio = false;
      this.showEquip = false;
    }
  }

  toggleEquipmentConfig() {
    this.showEquip = !this.showEquip;
  }

  toggleExpertConfig() {
    this.showExpert = !this.showExpert;
    if (!this.showExpert) {
      this.showSocio = false;
    }
  }

  getName() {
    if (
      this._authService.authenticatedUser === null ||
      this._authService.authenticatedUser === undefined ||
      this._authService.authenticatedUser.user_data === null ||
      this._authService.authenticatedUser.user_data === undefined
    ) {
      return '';
    }
    const firstname =
      this._authService.authenticatedUser.user_data.firstname || '';
    const lastname =
      this._authService.authenticatedUser.user_data.lastname || '';
    return firstname + ' ' + lastname;
  }

  shareLink() {
    if (this._activeDataService.reportData !== undefined) {
      if (
        (
          'https://' +
          window.location.hostname +
          '/view?report=' +
          this._activeDataService.reportData.report_id
        ).length <= 66
      ) {
        this._dialog.open(ShareComponent, {
          width: '799px',
          height: 'auto',
          minWidth: '350px',
          panelClass: 'share-dialog',
          data: this._activeDataService.reportData,
        });
      }
      if (
        (
          'https://' +
          window.location.hostname +
          '/view?report=' +
          this._activeDataService.reportData.report_id
        ).length > 66
      ) {
        this._dialog.open(ShareComponent, {
          width: '600px',
          height: 'auto',
          minWidth: '350px',
          panelClass: 'share-dialog-small',
          data: this._activeDataService.reportData,
        });
      }
    }
  }

  logoClick(mobile = false) {
    if (this._router.url.startsWith('/edit')) {
      return;
    }

    this.showSearch = false;

    this.helper.delay(350).then(() => {
      if (!this._authService.readOnlyMode) {
        this._headerService.goToAddressSearch(mobile);
      }
    });
  }

  applyChanges() {
    this.updatingConfig = true;
    this._activeDataService.reportData.config = {
      show_trend: this.showTrend,
      show_compare_prices: this.showComparable,
      show_exact_price: this.showExactPrice,
      show_expanded_view: this.showExpanded,
      show_equipment_overview: this.showEquip,
      show_expert_view: this.showExpert,
      show_valuation: this.showMarket,
      show_rent: this.showRental,
      show_sociodemographic: this.showSocio,
    };

    if (this.showRental && !this.showMarket) {
      this._activeDataService.saleMode = false;
      this._activeDataService.modeEmitter.emit(false);
    }

    if (this.showMarket && !this.showRental) {
      this._activeDataService.saleMode = true;
      this._activeDataService.modeEmitter.emit(true);
    }

    const updateRequest: ReportConfigurationUpdateRequest = {
      config: this._activeDataService.reportData.config,
    };
    this._reportService
      .updateConfig(updateRequest, this._activeDataService.reportId)
      .subscribe({
        next: (data: any) => {
          this.updatingConfig = false;
          this._headerService.configChangeEmitter.emit(
            this._activeDataService.reportData.config
          );
          this._notificationService.notifySuccess('Ansicht aktualisiert');
          this.menuTrigger.closeMenu();
        },
        error: (error: any) => {
          this.updatingConfig = false;
        },
      });
  }

  formatDate(d1) {
    const dateTemp = new Date(Date.parse(d1));
    const month = dateTemp.getMonth() + 1;
    const day = dateTemp.getDate();
    const year = dateTemp.getFullYear();

    let dayString = day.toString();
    if (day < 10) {
      dayString = '0' + dayString;
    }

    let monthString = month.toString();
    if (month < 10) {
      monthString = '0' + monthString;
    }

    return dayString + '.' + monthString + '.' + year;
  }

  printClick() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/print'], {
        queryParams: { id: this._activeDataService.reportId },
      })
    );
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this.reportSub.unsubscribe();
  }
}
