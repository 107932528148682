import {
  Component,
  ViewChild,
  HostListener,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { MapboxSettings } from '../core/mapbox.settings';
import { MapComponent } from 'ngx-mapbox-gl';
import { Router, ActivatedRoute } from '@angular/router';
import { HeaderService } from '../core/services/header.service';
import { Coordinates } from '../core/models/coordinates.model';
import { HelperFunctions } from '../core/functions/helper.functions';
import { PointLike } from 'mapbox-gl';
import { ReportRequest } from '../core/models/report-request.model';
import { AuthenticationService } from '../core/services/authentication.service';
import { Address } from '../core/models/address.model';
import { Valuation } from '../core/models/valuation.model';
import { Subscription } from 'rxjs';
import { ValuationService } from '../core/services/valuation.service';
import { Report, ReportConfiguration } from '../core/models/reports.model';
import { environment } from 'src/environments/environment';
import { ActiveDataService } from '../core/services/active-data.service';
import { RentResponse } from '../core/models/rent-response.model';
import { RentService } from '../core/services/rent.service';
import { ReportService } from '../core/services/report.service';
import { GeosearchListItem } from '../core/models/geosearch-list-item.model';
import { PriceRangeService } from '../core/services/price-range.service';
import { PriceRangeRequest } from '../core/models/price-range-request.model';
import { PriceRangeResponse } from '../core/models/price-range-response.model';
import { GeocoderService } from '../core/services/geocoder.service';
import {
  trigger,
  style,
  transition,
  animate,
  group,
} from '@angular/animations';
import { ErrorService } from '../core/services/error.service';
import { MatDialog } from '@angular/material/dialog';
import { UserData } from '../core/models/user-data.model';
import { NotificationService } from '../core/services/notification.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  animations: [
    // openCloseExpert
    trigger('openCloseExpert', [
      transition(':leave', [
        group([
          animate('0.25s', style({ opacity: 0 })),
          animate('0.25s', style({ left: '-100%' })),
        ]),
      ]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.25s 0.15s', style({ opacity: 1 })),
      ]),
    ]),
    trigger('openCloseValReqBox', [
      transition(':leave', [
        group([
          animate('0.25s', style({ opacity: 0 })),
          animate('0.25s', style({ left: '0px' })),
          animate('0.25s', style({ top: '0px' })),
        ]),
      ]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.25s 0.15s', style({ opacity: 1 })),
      ]),
    ]),
    trigger('openCloseAddrSearch', [
      transition(':leave', [
        group([
          animate('0.25s', style({ opacity: 0 })),
          animate('0.25s', style({ left: '0px' })),
          animate('0.25s', style({ top: '0px' })),
        ]),
      ]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.25s 0.15s', style({ opacity: 1 })),
      ]),
    ]),
    trigger('openValResCont', [
      transition(':leave', [
        group([
          animate('0.25s', style({ opacity: 0 })),
          animate('0.25s', style({ left: '0px' })),
          animate('0.25s', style({ top: '0px' })),
        ]),
      ]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.25s 0.15s', style({ opacity: 1 })),
      ]),
    ]),
    trigger('openValResExtCont', [
      transition(':leave', [
        group([
          animate('0.25s', style({ opacity: 0 })),
          // animate('0.25s', style({ left: '0px' })),
          // animate('0.25s', style({ top: '0px' }))
        ]),
      ]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.25s 0.15s', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class MapMainComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MapComponent) mapComponent: MapComponent;
  public mapboxSettings = new MapboxSettings();
  public mapIsLoaded = false;
  public showHeatmap = false;
  public doCalc = false;
  public addressSearch = true;
  public pricingData: number[] = [];
  public location = '';
  public coordinates: Coordinates = new Coordinates();
  public addressSearchPanel = 1;
  public helper: HelperFunctions = new HelperFunctions();
  public needsValuation = false;
  public valuationPopupShown = false;
  public lockedPopup = false;
  public showValuationResult = false;
  public valuationResultLocked = false;
  public valuationReportId = '';
  public reportRequest = new ReportRequest();
  public reportValuation: Valuation = new Valuation();
  private initializingHistoricReport = false;
  private comparableSubscription: Subscription;
  private rentSubscription: Subscription;
  public showMainPin = true;
  public resInfo = '';
  public showDebug = false;
  private preventLocking = false;
  public preventInteraction = true;
  public showComparableObject = false;
  public expanded = false;
  public expert = false;
  public salePrice: number = undefined;
  public reload = false;
  public showLegend = false;
  private reloading = false;
  private min = 0;
  private max = 0;
  public mobileLocation = '';
  public defaultLegendPostion = true;
  public hideAddressSearchOnMobile = true;
  private lastHeatmap = '';
  private usingAlt = false;
  public screenWidth = 0;
  public openResult = false;
  private previousId = '';
  private previousAddress = null;
  private fromExpanded = false;
  private showExactPrice = false;
  public tablet = false;
  private autoShow = false;

  openValResContTrigger = '';

  constructor(
    public _headerService: HeaderService,
    public _router: Router,
    public _route: ActivatedRoute,
    public _authService: AuthenticationService,
    private _valuationService: ValuationService,
    private _rentService: RentService,
    private _dialog: MatDialog,
    public _activeDataService: ActiveDataService,
    private _reportService: ReportService,
    private _priceRangeService: PriceRangeService,
    private _geoCoderService: GeocoderService,
    private _errorService: ErrorService,
    private _notificationService: NotificationService
  ) {
    this._headerService.configChangeEmitter.subscribe(() => {
      if (
        this.expert &&
        !this._activeDataService.reportData.config.show_expert_view
      ) {
        this.expert = false;
      }

      if (
        this.expanded &&
        !this._activeDataService.reportData.config.show_expanded_view
      ) {
        this.expanded = false;
      }

      if (
        this.fromExpanded &&
        this._activeDataService.reportData.config.show_expanded_view
      ) {
        this.expanded = true;
      }
    });

    this._activeDataService.modeEmitter.subscribe(() => {
      this.loadComparables();
    });

    this._headerService.logoClickEmitter.subscribe(() => {
      this.needsValuation = true;
      this.showExactPrice = false;
      this._activeDataService.reportData = undefined;
      this.previousId = this._activeDataService.reportId;
      this._activeDataService.reportId = '';
      this.addressSearchPanel = 1;
      this.addressSearch = true;
      this.lockedPopup = false;
      this.openResult = false;
      this.preventLocking = true;
      this._headerService.showHeader = false;
      this.showLegend = false;
      this.expanded = false;
      this.expert = false;
      this.reloading = true;
      this.valuationResultLocked = false;
      this.tryMove(true);
      this._headerService.setSearch(false);
      this.closeComparables();
    });
    this._headerService.printClickedEmitter.subscribe(() => {
      if (_authService.readOnlyMode) {
        this.expanded = false;
        this.expert = false;
        this.openResult = false;
        this.lockedPopup = false;
        this.valuationResultLocked = false;
        this.closeComparables();
        this.showValuationResult = false;
      }
    });

    this.showDebug = environment.showDebugInfo;
    this._activeDataService.valuationEmitter.subscribe((data: Valuation) => {
      if (data !== undefined) {
        this.salePrice = data.value;
      }
    });
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      // case agent.indexOf('edge') > -1:
      //   return 'edge';
      // case agent.indexOf('opr') > -1 && !!(window as any).opr:
      //   return 'opera';
      case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
        return 'chrome';
      // case agent.indexOf('trident') > -1:
      //   return 'ie';
      // case agent.indexOf('firefox') > -1:
      //   return 'firefox';
      // case agent.indexOf('safari') > -1:
      //   return 'safari';
      default:
        return 'other';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setWindowScrollHeight();
    this.resInfo = window.innerWidth + 'x' + window.innerHeight;
    if (window.innerWidth <= 1080) {
      this.expert = false;
      this.expanded = false;
    }
    if (window.innerWidth > 1080 && window.innerWidth <= 1260) {
      this.tablet = true;
    }
  }

  setWindowScrollHeight() {
    let windowHeight =
      'height:' + window.innerHeight.toString() + 'px !important;';
    if (this.getBrowserName() === 'chrome') {
      windowHeight = 'height: 100vh !important;';
    }
    const addressSearchContainer = document.getElementById(
      'address-search-container-main'
    );
    if (
      addressSearchContainer !== null &&
      addressSearchContainer !== undefined
    ) {
      addressSearchContainer.setAttribute('style', windowHeight);
    }
    document.body.setAttribute('style', windowHeight);
    document.getElementById('theMap').setAttribute('style', windowHeight);
  }

  ngAfterViewInit() {
    this.scrollTop();
    this.setWindowScrollHeight();

    this.resInfo = window.innerWidth + 'x' + window.innerHeight;
    this.tryMove();

    if (window.innerWidth > 1080 && window.innerWidth <= 1260) {
      this.tablet = true;
    }

    this._headerService.reloadHeatMapEmitter.subscribe(() => {
      this.doCalc = true;
      this.calcHeatmap();
    });

    this._headerService.showHeaderSearchEmitter.subscribe(() => {
      this.tryMove();
    });

    this._headerService.showAddressSearchEmiiter.subscribe(() => {
      this.hideAddressSearchOnMobile = !this.hideAddressSearchOnMobile;
      if (this.hideAddressSearchOnMobile) {
        this.tryMove(true);
      }
    });

    this._headerService.hideAddressSearchEmiiter.subscribe((data: any) => {
      if (!data) {
        this.hideAddressSearchOnMobile = false;
      }
      this.hideAddressSearchOnMobile = !this.hideAddressSearchOnMobile;
      if (this.hideAddressSearchOnMobile) {
        this.tryMove(true);
      }
    });

    this._geoCoderService.geocoderSearchEmitter.subscribe((data: any) => {
      this.dataReceived(data);
    });

    if (
      this._router.url.startsWith('/view') ||
      this._router.url.startsWith('/edit')
    ) {
      if (
        this._route.snapshot.queryParams.report_id ||
        this._route.snapshot.queryParams.report
      ) {
        this._route.queryParams.subscribe(
          () => {
            this._headerService.loadHeader();
            this.showMainPin = false;
            this.needsValuation = false;
            this.addressSearch = false;
            this.expanded = false;
            this.expert = false;
          },
          () => {
            this._router.navigate(['/systemError'], {
              state: { errors: 'No Report ID Selected' },
            });
          }
        );
      } else {
        this._router.navigate(['/systemError'], {
          state: { errors: 'No Report Selected' },
        });
      }
    }
  }

  tryMove(allThree = false) {
    if (!this.moveSearchIcon(allThree)) {
      this.helper.delay(100).then(() => {
        this.tryMove(allThree);
      });
    }
  }

  moveSearchIcon(all: boolean = false): boolean {
    if (
      document.querySelector('.geocoder-icon-search') === null ||
      document.querySelector('.geocoder-icon-search').nextElementSibling ===
        null
    ) {
      return false;
    }

    (
      document.querySelectorAll('.geocoder-icon-close')[0] as HTMLElement
    ).before(
      document.querySelectorAll('.geocoder-icon-search')[0] as HTMLElement
    );
    (
      document.querySelectorAll('.geocoder-icon-close')[0] as HTMLElement
    ).style.display = 'block';

    (
      document.querySelectorAll('.geocoder-icon-close')[1] as HTMLElement
    ).before(
      document.querySelectorAll('.geocoder-icon-search')[1] as HTMLElement
    );
    (
      document.querySelectorAll('.geocoder-icon-close')[1] as HTMLElement
    ).style.display = 'block';

    if (document.querySelectorAll('.geocoder-icon-close')[2] !== undefined) {
      (
        document.querySelectorAll('.geocoder-icon-close')[2] as HTMLElement
      ).before(
        document.querySelectorAll('.geocoder-icon-search')[2] as HTMLElement
      );
      (
        document.querySelectorAll('.geocoder-icon-close')[2] as HTMLElement
      ).style.display = 'block';
    } else {
      if (all) {
        return false;
      }
    }

    return true;
  }

  geocoderKeyup(event: any = null) {}

  dataCleared(event: any = null) {
    this._headerService.setSearch(false);
    (
      document.querySelectorAll('.geocoder-icon-close')[0] as HTMLElement
    ).style.display = 'block';
    (
      document.querySelectorAll('.geocoder-icon-close')[2] as HTMLElement
    ).style.display = 'block';
  }

  closeSearch() {
    this._headerService.setSearch(false);
  }

  dataReceived(data: any) {
    this.scrollTop();
    this._activeDataService.saleMode = true;
    this._activeDataService.clearAll(true);
    this.showExactPrice = false;
    this._activeDataService.reportData = undefined;
    this._activeDataService.reportId = '';
    this.needsValuation = true;
    this.lockedPopup = false;
    this.openResult = false;
    this.showValuationResult = false;
    this.expanded = false;
    this.expert = false;
    this.valuationResultLocked = false;
    this.preventLocking = true;
    this.preventInteraction = true;
    this.pricingData = [];
    this.reportValuation = new Valuation();

    if (data.result !== undefined) {
      if (this.addressSearch) {
        this._headerService.toggleHeader();
        this._dialog.closeAll();
      }
      this.addressSearch = false;
      this.coordinates.lng = data.result.center[0];
      this.coordinates.lat = data.result.center[1];

      this.reportRequest.address_geocoded = new Address();
      this.reportRequest.address_geocoded.lng = data.result.center[0];
      this.reportRequest.address_geocoded.lat = data.result.center[1];
      this.reportRequest.address_geocoded.house_number = data.result.address;
      this.reportRequest.address_geocoded.street = data.result.text;
      this.reportRequest.system_id =
        this._authService.authenticatedUser.system_id;
      this.reportRequest.user_id = this._authService.authenticatedUser.user_id;

      this.location = data.result.place_name;
      this.mobileLocation = data.result.text;
      if (data.result.context !== undefined) {
        data.result.context.forEach(element => {
          const check: string = element.id;
          if (check.indexOf('postcode') > -1) {
            this.reportRequest.address_geocoded.zip = element.text_de;
          }
          if (check.indexOf('place') > -1) {
            this.reportRequest.address_geocoded.town = element.text_de;
          }
          if (check.indexOf('country') > -1) {
            const nation: string = element.short_code;
            this.reportRequest.address_geocoded.nation = nation.toUpperCase();
          }
        });
      }
    }

    const rangeRequest: PriceRangeRequest = {
      radius: this.mapboxSettings.valueRadius,
      address_geocoded: this.reportRequest.address_geocoded,
    };

    this.pricingData = [];
    if (
      this.mapboxSettings.showHeatMapFor.indexOf(
        this.reportRequest.address_geocoded.nation.toUpperCase()
      ) > -1
    ) {
      this._priceRangeService.post(rangeRequest).subscribe(
        (rdata: PriceRangeResponse) => {
          this.min = rdata.range.min;
          this.max = rdata.range.max;
          const range = this.max - this.min;
          const step = range / this.mapboxSettings.heatMapColours.length;
          for (let i = 0; i < this.mapboxSettings.heatMapColours.length; i++) {
            this.pricingData[i] = this.min + i * step;
          }
          if (this.needsValuation) {
            this.valuationPopupShown = true;
            this.lockedPopup = false;
            this.openResult = false;
          }
          this.showLegend = true;
          this.doCalc = false;
          this.doPaint(this.getActiveHeatmap());
        },
        () => {}
      );
    } else {
      if (this.needsValuation) {
        this.valuationPopupShown = true;
        this.lockedPopup = false;
        this.openResult = false;
      }
    }

    this.preventInteraction = true;

    let ourOffset: PointLike = [0, 150];
    if (window.innerWidth >= 1080 && window.innerWidth <= 1260) {
      ourOffset = [0, 0];
    }
    if (window.innerWidth < 1080) {
      ourOffset = [-20, 0];
    }
    if (window.innerWidth <= 1080) {
      ourOffset = [-20, 120];
    }
    this.lastHeatmap = '';
    this.mapComponent.mapInstance.flyTo({
      center: data.result.center,
      offset: ourOffset,
      zoom: 16,
      essential: true,
    });
  }

  mapLoaded() {
    this.scrollTop();
    if (
      this._router.url.startsWith('/view') ||
      this._router.url.startsWith('/edit')
    ) {
      if (
        this._route.snapshot.queryParams.report_id ||
        this._route.snapshot.queryParams.report
      ) {
        if (this._route.snapshot.queryParams.report_id) {
          this.obtainReport(this._route.snapshot.queryParams.report_id);
        } else {
          this.obtainReport(this._route.snapshot.queryParams.report);
        }
      }
    }

    this.mapboxSettings.tilesets.forEach(tileset => {
      this.mapComponent.mapInstance.addSource(tileset.id, {
        type: 'vector',
        url: 'mapbox://' + tileset.url,
      });
    });

    this.mapboxSettings.heatmaps.forEach(heatmap => {
      this.mapComponent.mapInstance.addLayer(
        {
          id: heatmap.id,
          type: 'heatmap',
          minzoom: heatmap.minZoom,
          maxzoom: heatmap.maxZoom,
          source: heatmap.source,
          'source-layer': heatmap.source,
          layout: {},
          paint: {
            'heatmap-opacity': 0,
            'heatmap-weight': 0,
            'heatmap-intensity': 1,
            'heatmap-radius': {
              base: 2,
              stops: heatmap.stops,
            },
          },
        },
        'landuse'
      );
    });

    this.mapIsLoaded = true;
  }

  setFilter(layerId) {
    const mapRef = this.mapComponent.mapInstance;
    this.mapboxSettings.heatmaps.forEach(function (heatmap) {
      if (heatmap.id !== layerId) {
        const oldLayer = mapRef.getLayer(heatmap.id);
        if (oldLayer !== undefined) {
          mapRef.removeLayer(heatmap.id);
        }
      }
    });
    const layer = this.mapComponent.mapInstance.getLayer(layerId);
    if (layer === undefined) {
      const thisLayer = this.mapboxSettings.heatmaps.filter(element => {
        return element.id === layerId;
      });
      if (thisLayer.length > 0) {
        this.mapComponent.mapInstance.addLayer(
          {
            id: thisLayer[0].id,
            type: 'heatmap',
            minzoom: thisLayer[0].minZoom,
            maxzoom: thisLayer[0].maxZoom,
            source: thisLayer[0].source,
            'source-layer': thisLayer[0].source,
            layout: {},
            paint: {
              'heatmap-opacity': 0,
              'heatmap-weight': 0,
              'heatmap-intensity': 1,
              'heatmap-radius': {
                base: 2,
                stops: thisLayer[0].stops,
              },
            },
          },
          'landuse'
        );
      }
    }
  }

  zoomEnd() {
    this.mapComponent.mapInstance.once('idle', () => {
      this.mapIsLoaded = true;
      if (
        this.mapboxSettings.showHeatMapFor.indexOf(
          this.reportRequest.address_geocoded.nation.toUpperCase()
        ) > -1
      ) {
        this.showHeatmap = true;
        this.calcHeatmap();
      } else {
        if (this.autoShow) {
          this.showValuationResult = true;
          this.reloading = false;
          this.autoShow = false;
        }
      }
    });
  }

  calcHeatmap() {
    this.preventInteraction = false;
    this.preventLocking = false;
    this.reloading = false;
    const activeLayer = this.getActiveHeatmap();
    if (!this.doCalc) {
      this.doPaint(activeLayer);
    }
  }

  doPaint(layer: string) {
    this.setFilter(this.getActiveHeatmap());
    const useAlt = this.useAltHeatmap();
    if (
      (this.lastHeatmap === layer && useAlt === this.usingAlt) ||
      (useAlt && this.preventInteraction)
    ) {
      return;
    }

    this.usingAlt = useAlt;
    this.lastHeatmap = layer;

    const thisLayer = this.mapComponent.mapInstance.getLayer(layer);
    if (thisLayer === undefined) {
      return;
    }

    const paintProps: any = [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(0,0,0,0)',
    ];

    const step = 10 / this.mapboxSettings.heatMapColours.length / 10;
    this.mapboxSettings.heatMapColours.forEach((value, index) => {
      paintProps.push((index + 1) * step);
      paintProps.push(value);
    });

    let paintMin = this.min;
    let paintMax = this.max * 1.08;

    if (useAlt) {
      const nat =
        this.reportRequest.address_geocoded.nation.toLocaleLowerCase();
      paintMin = this.mapboxSettings[nat + '_altRange'][0];
      paintMax =
        this.mapboxSettings[nat + '_altRange'][
          this.mapboxSettings[nat + '_altRange'].length - 1
        ];
    }

    this.mapComponent.mapInstance.setPaintProperty(layer, 'heatmap-weight', [
      'interpolate',
      ['linear'],
      ['get', 'value_SM'],
      paintMin,
      0.1,
      paintMax,
      1,
    ]);

    this.mapComponent.mapInstance.setPaintProperty(
      layer,
      'heatmap-color',
      paintProps
    );
    this.mapComponent.mapInstance.setPaintProperty(
      layer,
      'heatmap-opacity',
      0.45
    );
  }

  getActiveHeatmap() {
    if (!this.reportRequest?.address_geocoded) {
      return;
    }

    let active =
      'heatmap_' +
      this.reportRequest.address_geocoded.nation.toLocaleLowerCase() +
      '_3200';
    if (this.mapComponent.mapInstance.getZoom() >= 10) {
      active =
        'heatmap_' +
        this.reportRequest.address_geocoded.nation.toLocaleLowerCase() +
        '_200';
    } else if (this.mapComponent.mapInstance.getZoom() >= 7) {
      active =
        'heatmap_' +
        this.reportRequest.address_geocoded.nation.toLocaleLowerCase() +
        '_800';
    }
    return active;
  }

  searchbarCheck() {
    return this._headerService.showSearch();
  }

  addressPanelChange(activePanel: number) {
    this.addressSearchPanel = activePanel;
    if (window.innerWidth <= 1780 && window.innerWidth > 456) {
      const srchBoxes = document.getElementsByClassName(
        'mapboxgl-ctrl-top-right'
      );
      for (let i = 0; i < srchBoxes.length; i++) {
        if (activePanel !== 1) {
          srchBoxes.item(i).setAttribute('style', 'right: calc(66vw - 125px);');
        } else {
          srchBoxes.item(i).setAttribute('style', 'right: calc(50vw - 125px);');
        }
      }
    }
  }

  addressSearchCheck() {
    if (this.addressSearch) {
      return true;
    }
    return false;
  }

  mapClick() {
    if (this.valuationPopupShown) {
      this.lockedPopup = true;
      this.openResult = true;
    }
  }

  markerClick() {
    let ourOffset: PointLike = [0, 300];
    if (window.innerWidth >= 1080 && window.innerWidth <= 1260) {
      ourOffset = [0, 170];
    }
    if (window.innerWidth < 1080) {
      ourOffset = [-20, 0];
    }
    if (window.innerWidth <= 1080) {
      ourOffset = [-20, 120];
    }

    this.mapComponent.mapInstance.flyTo({
      center: [
        this.reportRequest.address_geocoded.lng,
        this.reportRequest.address_geocoded.lat,
      ],
      offset: ourOffset,
      essential: true,
    });

    this.preventLocking = true;

    if (this.valuationPopupShown === false && this.needsValuation) {
      this.lockedPopup = false;
      this.openResult = false;
      this.valuationPopupShown = true;
    } else if (!this.needsValuation && !this.showValuationResult) {
      this.valuationResultLocked = false;
      this.showValuationResult = true;
    }
  }

  toggleValuationRequest() {
    this.valuationPopupShown = !this.valuationPopupShown;
  }

  movingMap() {
    if (this.addressSearch) {
      return;
    }

    if (!this.preventLocking) {
      this.lockedPopup = true;
      this.openResult = true;
    }

    if (
      !this.valuationResultLocked &&
      this.showValuationResult &&
      this.mapIsLoaded &&
      !this.initializingHistoricReport
    ) {
      if (!this.preventLocking) {
        this.valuationResultLocked = true;
        if (
          this._activeDataService.reportData?.config?.show_expanded_view &&
          window.innerWidth >= 1080
        ) {
          this.expanded = true;
        }
      }
    }
  }

  finishedMoving() {
    if (!this.doCalc) {
      this.preventLocking = false;
      this.preventInteraction = false;
    }
  }

  getValuationResultPosition() {
    if (this.valuationResultLocked) {
      const topPx =
        window.innerWidth >= 1080 && window.innerWidth <= 1260 ? '0px' : '70px';
      return { position: 'absolute', top: topPx, left: '0px' };
    }

    let xOffset = 180;
    let yOffset = 555;

    if (window.innerWidth >= 1080 && window.innerWidth <= 1260) {
      xOffset = 180;
      yOffset = 485;
    }

    if (document.getElementsByClassName('main-pin')[0] === undefined) {
      return;
    }

    const rect: any = document
      .getElementsByClassName('main-pin')[0]
      .getBoundingClientRect();
    return {
      position: 'absolute',
      top: rect.y - yOffset + 'px',
      left: rect.x - xOffset + 'px',
    };
  }

  getValuationRequestPosition() {
    if (this.lockedPopup) {
      return { position: 'absolute', top: '81px', right: '11px' };
    }

    const xOffset = 115;
    const yOffset = 257;

    if (document.getElementsByClassName('main-pin')[0] === undefined) {
      return;
    }

    const rect: any = document
      .getElementsByClassName('main-pin')[0]
      .getBoundingClientRect();
    return {
      position: 'absolute',
      top: rect.y - yOffset + 'px',
      left: rect.x - xOffset + 'px',
    };
  }

  valuationResultClass() {
    if (
      !this.valuationResultLocked ||
      !this._activeDataService.reportData?.config?.show_expert_view
    ) {
      return 'valuation-results undocked';
    }
    return 'valuation-results';
  }

  valuationResultClosed() {
    this.showValuationResult = false;
    this.valuationResultLocked = false;
  }

  processValuationReportResponse(reportId: string) {
    this.autoShow = true;
    this.valuationReportId = reportId;
    this.valuationPopupShown = false;
    if (reportId !== '' && reportId !== undefined) {
      this._activeDataService.reportId = this.valuationReportId;
      this._activeDataService.getPoiData();
      this.needsValuation = false;
      let ourOffset: PointLike = [0, 300];

      if (window.innerWidth >= 1080 && window.innerWidth <= 1260) {
        ourOffset = [0, 170];
      }
      if (window.innerWidth < 1080) {
        ourOffset = [-20, 0];
      }
      if (window.innerWidth <= 1080) {
        ourOffset = [-20, 120];
      }
      this.preventInteraction = true;
      this.preventLocking = true;
      this.mapComponent.mapInstance.flyTo({
        center: [this.coordinates.lng, this.coordinates.lat],
        offset: ourOffset,
        zoom: 16,
        essential: true,
      });
      this.loadComparables();
    }
  }

  loadComparables() {
    this.showMainPin = false;
    this.reportValuation = new Valuation();
    if (this._activeDataService.saleMode || this.salePrice === undefined) {
      if (this._activeDataService.valuationData === undefined) {
        this.comparableSubscription = this._valuationService
          .get(this.valuationReportId)
          .subscribe((data: Valuation) => {
            data.compare_prices.forEach(element => {
              element.display = false;
            });
            if (this._activeDataService.saleMode) {
              this.reportValuation = data;
              this.showMainPin = true;
              this.showValuationResult = true;
              this._activeDataService.valuationData = data;
            }
            this.salePrice = data.value;
          });
      } else {
        this.salePrice = this._activeDataService.valuationData.value;
        this.reportValuation = this._activeDataService.valuationData;
        this.showMainPin = true;
        this.showValuationResult = true;
      }
    }

    if (!this._activeDataService.saleMode) {
      if (this._activeDataService.rentData === undefined) {
        this.rentSubscription = this._rentService
          .get(this.valuationReportId)
          .subscribe((data: RentResponse) => {
            data.compare_prices.forEach(element => {
              element.display = false;
            });
            this.reportValuation = data;
            this.showMainPin = true;
            this.showValuationResult = true;
            this._activeDataService.rentData = data;
          });
      } else {
        this.reportValuation = this._activeDataService.rentData;
        this.showMainPin = true;
        this.showValuationResult = true;
      }
    }
  }

  obtainReport(reportId: string) {
    this.valuationReportId = reportId;
    this._reportService.get(reportId).subscribe(
      (data: Report) => {
        this._activeDataService.reportData = data;
        this._activeDataService.reportId = reportId;
        this._authService.getReportUser$(reportId).subscribe({
          next: (user: UserData) => {
            this.autoShow = true;
            this.loadReport(data);
          },
          error: (error: any) => {
            this._notificationService.notifyApiError(error);
            setInterval(() => {
              this._authService.errorHandle(error);
            }, 5000);
          },
        });
      },
      (error: any) => {
        this._headerService.unloadHeader();
        this._errorService.clearErrorStack();
        error.error.errors.forEach(element => {
          Object.keys(element).forEach(e => {
            this._errorService.addToErrorStack({
              error: element[e],
              timestamp: Date.now(),
            });
          });
        });

        this._router.navigateByUrl('/systemError');
      }
    );
  }

  loadReport(report: Report) {
    this.scrollTop();
    this.autoShow = true;
    this._activeDataService.clearAll(true);

    this._activeDataService.saleMode = true;

    this.preventLocking = true;
    this.needsValuation = false;
    this.valuationPopupShown = false;
    this.lockedPopup = false;
    this.openResult = false;
    this.pricingData = [];
    if (this.addressSearch) {
      this._headerService.toggleHeader();
      this._dialog.closeAll();
    }
    this.addressSearch = false;
    this.coordinates.lng = report.coordinates.lng;
    this.coordinates.lat = report.coordinates.lat;

    this.showMainPin = true;
    this.reportRequest.address_geocoded = report.address;
    this.reportRequest.system_id = report.system_id;
    this.reportRequest.user_id = report.user_id;
    this.reportRequest.address_geocoded = new Address();
    this.reportRequest.address_geocoded.lng = report.coordinates.lng;
    this.reportRequest.address_geocoded.lat = report.coordinates.lat;
    this.reportRequest.address_geocoded.nation = report.address.nation;
    this.valuationReportId = report.report_id;
    this._activeDataService.reportId = this.valuationReportId;
    this._activeDataService.getPoiData();
    this.location = report.address.street;
    this.mobileLocation = report.address.town;
    if (report.address.house_number !== ' ') {
      this.location = this.location + ' ' + report.address.house_number;
    }
    this.location =
      this.location + ', ' + report.address.zip + ', ' + report.address.town;

    const rangeRequest: PriceRangeRequest = {
      radius: this.mapboxSettings.valueRadius,
      address_geocoded: this.reportRequest.address_geocoded,
    };

    this.pricingData = [];
    if (
      this.mapboxSettings.showHeatMapFor.indexOf(
        this.reportRequest.address_geocoded.nation.toUpperCase()
      ) > -1
    ) {
      this._priceRangeService.post(rangeRequest).subscribe(
        (data: PriceRangeResponse) => {
          this.min = data.range.min;
          this.max = data.range.max;
          const range = this.max - this.min;
          const step = range / this.mapboxSettings.heatMapColours.length;
          for (let i = 0; i < this.mapboxSettings.heatMapColours.length; i++) {
            this.pricingData[i] = this.min + i * step;
          }

          if (this.needsValuation) {
            this.valuationPopupShown = true;
            this.lockedPopup = false;
            this.openResult = false;
          }
          this.showLegend = true;
          this.doCalc = false;
          this.doPaint(this.getActiveHeatmap());
        },
        () => {}
      );
    }

    this.initializingHistoricReport = true;
    this.preventInteraction = true;

    let ourOffset: PointLike = [0, 300];
    if (window.innerWidth >= 1080 && window.innerWidth <= 1260) {
      ourOffset = [0, 170];
    }
    if (window.innerWidth < 1080) {
      ourOffset = [-20, 0];
    }
    if (window.innerWidth <= 1080) {
      ourOffset = [-20, 75];
    }
    this.lastHeatmap = '';
    this.mapComponent.mapInstance.flyTo({
      center: [report.coordinates.lng, report.coordinates.lat],
      offset: ourOffset,
      zoom: 16,
      essential: true,
    });
    this.initializingHistoricReport = false;
    this.showHeatmap = true;
    this.loadComparables();

    if (
      this.previousId === report.report_id ||
      JSON.stringify(this.previousAddress) === JSON.stringify(report.address)
    ) {
      // In this instance there is no map movement or heatmap reloading
      // So we set the below values to allow the result object to show
      this.showValuationResult = true;
      this.reloading = false;
    }

    this.previousAddress = report.address;
  }

  getValue() {
    if (
      this._activeDataService.saleMode &&
      this._activeDataService.valuationData
    ) {
      return this._activeDataService.valuationData?.value;
    }

    if (!this._activeDataService.saleMode && this._activeDataService.rentData) {
      return this._activeDataService.rentData?.value;
    }

    if (this.salePrice === undefined) {
      return '';
    }
    return this.salePrice;
  }

  valuationUpdated(newValuation: Valuation) {
    if (this.reportValuation.compare_prices.length > 0) {
      newValuation.compare_prices.forEach(newElement => {
        newElement.display = false;
        this.reportValuation.compare_prices.forEach(existingElement => {
          if (
            existingElement.lng === newElement.lng &&
            existingElement.lat === newElement.lat
          ) {
            newElement.hidden = existingElement.hidden;
            newElement.display = existingElement.display;
          }
        });
      });
    }
    this.reportValuation = newValuation;
    this.reload = !this.reload;
  }

  shouldShowResult() {
    return (
      this.mapIsLoaded &&
      !this.addressSearch &&
      this.showValuationResult &&
      !this.reloading &&
      !this.needsValuation
    );
  }

  showComparable(index: number) {
    for (let i = 0; i < this.reportValuation.compare_prices.length; i++) {
      if (i === index) {
        this.reportValuation.compare_prices[i].display = true;
      } else {
        this.reportValuation.compare_prices[i].display = false;
      }
    }

    let ourOffset: PointLike = [0, 75];

    if (window.innerWidth >= 1080 && window.innerWidth <= 1260) {
      ourOffset = [175, -175];
    }

    this.mapComponent.mapInstance.flyTo({
      center: [
        this.reportValuation.compare_prices[index].lng,
        this.reportValuation.compare_prices[index].lat,
      ],
      offset: ourOffset,
      essential: true,
    });

    this.reportValuation.compare_prices[index].display = true;
  }

  shouldShowComparable(index: number) {
    return (
      this.reportValuation.compare_prices[index].display &&
      !this.reportValuation.compare_prices[index].hidden
    );
  }

  closeComparable(index: number) {
    this.reportValuation.compare_prices[index].display = false;
  }

  closeComparables() {
    this.reportValuation.compare_prices.forEach(value => {
      value.display = false;
    });
  }

  toggleExpanded() {
    this.closeComparables();
    this.expanded = !this.expanded;
    if (!this.expanded) {
      this.defaultLegendPostion = true;
    } else {
      this.valuationResultLocked = true;
    }
  }

  toggleExpert() {
    this.expert = !this.expert;
    if (this.expert) {
      this.valuationResultLocked = true;
      this.defaultLegendPostion = false;
      this.fromExpanded = this.expanded;
      this.expanded = !this.expert;
    } else {
      if (this.fromExpanded) {
        this.expanded = !this.expert;
      } else {
        this.defaultLegendPostion = true;
      }
    }

    if (this.expert) {
      if (!this.defaultLegendPostion) {
        this.defaultLegendPostion = true;
      }
      this.closeComparables();
      const popup = document.getElementsByClassName('poi-popup');
      if (popup.length) {
        popup[0].remove();
      }
      const xOffset = window.innerWidth * 0.42;
      this.mapComponent.mapInstance.flyTo({
        center: [this.coordinates.lng, this.coordinates.lat],
        offset: [xOffset, 75],
        essential: true,
      });
    } else {
      this.reload = !this.reload;
    }
  }

  moveLegend() {
    this.defaultLegendPostion = !this.defaultLegendPostion;
  }

  getPoiImage(poi: GeosearchListItem) {
    let pin = '';
    switch (poi.type) {
      case 'arts_and_entertainment':
        pin = 'theatre.svg';
        break;
      case 'education':
        pin = 'college.svg';
        break;
      case 'food_and_drink':
        pin = 'restaurant.svg';
        break;
      case 'food_and_drink_stores':
        pin = 'convenience-store-15.svg';
        break;
      case 'lodging':
        pin = 'hotel.svg';
        break;
      case 'medical':
        pin = 'hospital.svg';
        break;
      case 'park_like':
        pin = 'park.svg';
        break;
      case 'place_like':
        pin = 'place-like.svg';
        break;
      case 'public_facilities':
        pin = 'public-facilities.svg';
        break;
      case 'religion':
        pin = 'religion.svg';
        break;
      case 'sport_and_leisure':
        pin = 'soccer.svg';
        break;
      case 'rail':
        pin = 'train.svg';
        break;
      case 'metro_rail':
        pin = 'metro-rail.svg';
        break;
      case 'light_rail':
        pin = 'light-train.svg';
        break;
      case 'tram':
        pin = 'tram.svg';
        break;
      case 'funicular':
        pin = 'funicular.svg';
        break;
      case 'bus':
        pin = 'bus.svg';
        break;
      case 'ferry':
        pin = 'ferry.svg';
        break;
      default:
        console.log('unknown poi ', poi);
        return '';
    }
    return '../../assets/icons/poi/' + pin;
  }

  getMarker(i) {
    return 'poiMarker' + i;
  }

  useAltHeatmap() {
    const x = this.mapComponent.mapInstance.getZoom();
    if (x < 10 && !this.preventInteraction && !this.preventLocking) {
      return true;
    }
    return false;
  }

  getPricingData() {
    if (this.useAltHeatmap()) {
      const nat =
        this.reportRequest.address_geocoded.nation.toLocaleLowerCase();
      return this.mapboxSettings[nat + '_altRange'];
    }
    return this.pricingData;
  }

  scrollTop() {
    const mainDiv = document.getElementsByClassName(
      'map-wrapper-without-search'
    );
    if (mainDiv[0] !== undefined) {
      window.scroll(0, 0);
    }
  }

  requestItemSelected() {
    this.openResult = true;
  }

  comparablesAlllowedByConfig() {
    if (this._activeDataService.reportData === undefined) {
      return false;
    }
    if (this._activeDataService.reportData.config === undefined) {
      if (
        this._activeDataService.reportData.config === null ||
        this._activeDataService.reportData.config === undefined
      ) {
        this._activeDataService.reportData.config = new ReportConfiguration();
      }
    }
    return this._activeDataService.reportData.config?.show_compare_prices;
  }

  exactPriceAlllowedByConfig() {
    if (this._activeDataService.reportData === undefined) {
      return this.showExactPrice;
    }
    if (this._activeDataService.reportData.config === undefined) {
      if (
        this._activeDataService.reportData.config === null ||
        this._activeDataService.reportData.config === undefined
      ) {
        this._activeDataService.reportData.config = new ReportConfiguration();
      }
    }
    this.showExactPrice =
      this._activeDataService?.reportData?.config?.show_exact_price;
    return this.showExactPrice;
  }

  ngOnDestroy(): void {
    this.rentSubscription?.unsubscribe();
    this.comparableSubscription?.unsubscribe();
  }
}
