import { InsulatedExteriorWalls } from '../enums/insulated-exterior-walls.enum';
import { StoreRoom } from '../enums/store-room.enum';
import { BathRoom } from '../enums/bath-room.enum';
import { RoofCovering } from '../enums/roof-covering.enum';
import { Windows } from '../enums/windows.enum';
import { Floor } from '../enums/floor.enum';
import { GuestToilet } from '../enums/guest-toilet.enum';
import { Heating } from '../enums/heating.enum';
import { SurfaceMountedInstallation } from '../enums/surface-mounted-installation.enum';
import { ResidentialArea } from '../enums/residential-area.enum';

export class Equipment {
  description: string;
  value: string;
  insulated_exterior_walls: InsulatedExteriorWalls;
  store_room: StoreRoom;
  bath_room: BathRoom;
  roof_covering: RoofCovering;
  windows: Windows;
  floor: Floor;
  guest_toilet: GuestToilet;
  heating: Heating;
  surface_mounted_installation: SurfaceMountedInstallation;
  residential_area: ResidentialArea;
}
